import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import set from 'date-fns/set'

export function dateToISO8601(value: Date = new Date()): string {
  if (!value || !(value instanceof Date)) {
    throw new Error(`dateToISO8601: Expected a date, instead got ${value}`)
  }
  const tzo: number = -value.getTimezoneOffset()
  const dif: '+' | '-' = tzo >= 0 ? '+' : '-'
  const parsedValue: string =
    value.getFullYear() +
    '-' +
    pad(value.getMonth() + 1) +
    '-' +
    pad(value.getDate()) +
    'T' +
    pad(value.getHours()) +
    ':' +
    pad(value.getMinutes()) +
    ':' +
    pad(value.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  return parsedValue
}

export const ISO8601ToDate = (value: string): Date => {
  try {
    // Check format of date string
    if (!/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d)$/.test(value) && !checkIsISO8601(value)) {
      return undefined
    }
    return value ? new Date(Date.parse(value)) : undefined
  } catch (err) {
    return undefined
  }
}

export const checkIsISO8601 = (value: string): boolean => {
  /* eslint-disable max-len */
  const regex: RegExp =
    /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
  /* eslint-enable max-len */
  try {
    // Check format of date string
    if (regex.test(value)) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

/**
 * If the date is a date format it using the dateFormat
 * If the date is not a date but a string representing a date performs basic replace on the string to look like yyyy-MM-dd HH:mm
 * @param date
 * @param dateFormat
 * @param locale
 */
// export function formatDate(date: Date | string, dateFormat?: string, locale: Locale = fr) {
//   if (!date) {
//     return null
//   }

//   if (date instanceof Date) {
//     return format(date, dateFormat ? dateFormat : 'yyyy-MM-dd HH:mm', {
//       locale: locale,
//     })
//   }

//   return date.replace('T', ' ').split('+')[0]
// }

/**
 * If the date is a date format it using the dateFormat
 * If the date is not a date but a string representing a date performs basic replace on the string to look like yyyy-MM-dd HH:mm
 * @param date
 * @param dateFormat
 * @param locale
 */
export function formatDate(
  date: Date | string,
  dateFormat: string = 'yyyy-MM-dd HH:mm',
  locale: Locale = fr,
  tz: string = 'Europe/Paris'
) {
  if (!date) {
    return null
  }

  // if (date instanceof Date) {
  //   const year: number = date.getFullYear()
  //   const month: string = String(date.getMonth() + 1).padStart(2, '0')
  //   const day: string = String(date.getDate()).padStart(2, '0')
  //   const hours: string = String(date.getHours()).padStart(2, '0')
  //   const minutes: string = String(date.getMinutes()).padStart(2, '0')
  //   const seconds: string = String(date.getSeconds()).padStart(2, '0')

  //   date = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  // }

  if (typeof date === 'string') {
    date = new Date(date)
  }
  // return formatInTimeZone(date, tz, dateFormat, { locale })

  // if (typeof date === 'string') {
  // const zonedDate: Date = zonedTimeToUtc(date, tz)
  // return formatTZ(zonedDate, dateFormat, { locale, timeZone: tz })
  // }

  return format(date, dateFormat, { locale })
}

function pad(num: number): string {
  const norm: number = Math.floor(Math.abs(num))
  return (norm < 10 ? '0' : '') + norm
}

export function isISODate(date: string) {
  return !isNaN(Date.parse(date))
}

export function dateWithCurrentTime(date: Date | string): string | undefined {
  if (!date) {
    return undefined
  }
  const now: Date = new Date()
  return dateToISO8601(
    set(new Date(date), {
      hours: now.getHours(),
      minutes: now.getMinutes(),
      seconds: now.getSeconds(),
    })
  )
}

export function ISO8601ToDay(value: string): string {
  return value.trim().split('T')?.[0]
}

/**
 * Date Format YYYYMMDD_HHMMSS for file name
 * @returns string
 */
export function dateFormatFileName(): string {
  const now: Date = new Date()
  const year: number = now.getFullYear()
  const month: string = String(now.getMonth() + 1).padStart(2, '0')
  const day: string = String(now.getDate()).padStart(2, '0')
  const hours: string = String(now.getHours()).padStart(2, '0')
  const minutes: string = String(now.getMinutes()).padStart(2, '0')
  const seconds: string = String(now.getSeconds()).padStart(2, '0')

  return `${year}${month}${day}_${hours}${minutes}${seconds}`
}
