import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { TranslateConstantPipe } from '../../pipes'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-menu-overlay',
  templateUrl: './menu-overlay.component.html',
  styleUrls: ['./menu-overlay.component.scss'],
  standalone: true,
  imports: [MatIconModule, TranslateModule, TranslateConstantPipe, CommonModule],
})
export class MenuOverlayComponent {
  @Input() large: boolean = false
  public open: boolean = false

  public show() {
    this.open = true
  }

  public hide() {
    this.open = false
  }

  public toggle() {
    this.open = !this.open
  }
}
