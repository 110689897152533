import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import { IsArray, IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { RequireProperty } from '@agroone/helpers'

export class SaveCropHistoric {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  croptypeId: number

  @IsNumber()
  @IsOptional()
  @RequireProperty('unit')
  area?: number

  @IsString()
  @IsOptional()
  @RequireProperty('area')
  unit?: string
}

export class SavePesticideHistory {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  pesticideProductId: number
}

export class SaveHistoric {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  year: number

  @IsArray()
  @IsOptional()
  problematicWeeds?: string[]

  @IsBoolean()
  @IsOptional()
  nutTrees?: boolean

  @IsArray()
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => SaveCropHistoric)
  cropHistorics?: SaveCropHistoric[]

  @IsArray()
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => SavePesticideHistory)
  pesticides?: SavePesticideHistory[]
}

export class SaveFieldHistoric {
  public static build(raw: any) {
    return plainToInstance(SaveFieldHistoric, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsOptional()
  fieldId?: number

  @IsArray()
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => SaveHistoric)
  historics?: SaveHistoric[]
}
