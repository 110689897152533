import 'reflect-metadata'
import { IsNumber, IsOptional, IsString, IsNotEmpty } from 'class-validator'

export class Farm {
  id: number | undefined
  name!: string

  constructor(farm: FarmDto) {
    this.id = farm?.id
    this.name = farm.name
  }
}

export class FarmDto {
  id?: number
  name!: string
}

export class SaveFarm {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  constructor(farm?: SaveFarm) {
    if (farm?.id) {
      this.id = farm.id
    }

    this.name = farm?.name
  }
}
