<div class="version">
  <mat-dialog-content class="version-content">
    <ng-container *ngIf="isUpdateInProgress; else isNotUpdateInProgress">
      <div>{{ 'DIALOG.UPDATE.MESSAGE_IN_PROGRESS' | translate }}</div>
      <mat-spinner [diameter]="50"></mat-spinner>
    </ng-container>

    <ng-template #isNotUpdateInProgress>
      {{ 'DIALOG.UPDATE.MESSAGE' | translate }}
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions class="version-actions" *ngIf="!isUpdateInProgress">
    <button mat-raised-button color="primary" (click)="reload()">
      {{ 'DIALOG.UPDATE.RELOAD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
