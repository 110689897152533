import { plainToClass, plainToInstance } from 'class-transformer'

import { IsNotEmpty, IsOptional, IsString, IsNumber } from 'class-validator'

export class SaveForecastDay {
  public static build(raw: any) {
    return plainToInstance(SaveForecastDay, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  numberOfDays: number | undefined

  @IsNumber()
  @IsNotEmpty()
  regionId: number | undefined
}

export class SaveCroptypePlanning {
  public static build(raw: any) {
    return plainToInstance(SaveCroptypePlanning, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  cadenceTh: number | undefined

  @IsNumber()
  @IsNotEmpty()
  limitBeforeHarvest: number | undefined

  @IsNumber()
  @IsNotEmpty()
  maximumWeight: number | undefined

  @IsNumber()
  @IsNotEmpty()
  targetWeight: number | undefined

  @IsNumber()
  @IsNotEmpty()
  timeArrivedSite: number | undefined

  @IsNumber()
  @IsNotEmpty()
  timeWaitFactory: number | undefined

  @IsString()
  @IsOptional()
  vegetableType?: string

  @IsNumber()
  @IsNotEmpty()
  regionId: number | undefined

  @IsNumber()
  @IsNotEmpty()
  croptypeId: number | undefined
}

export class SaveFactoryConsumption {
  public static build(raw: any) {
    return plainToInstance(SaveFactoryConsumption, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  startingHour: string | undefined

  @IsNumber()
  @IsNotEmpty()
  regionId: number | undefined
}
