import { Injectable, ErrorHandler } from '@angular/core'
import { LoggerService } from '@agroone-front/shared'

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private logger: LoggerService) {
    super()
  }

  override handleError(error) {
    super.handleError(error)
    this.logger.error(error.message, error)
  }
}
