import { plainToInstance } from 'class-transformer'
import {
  IsDateString,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Length,
  Validate,
} from 'class-validator'
import { IsISO8601WithTimezone } from '@agroone/helpers'

export class SaveWorkList {
  public static build(raw: any) {
    return plainToInstance(SaveWorkList, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  cropId: number

  @IsString()
  @IsNotEmpty()
  region: string // Only for role validator

  @IsString()
  @Length(10)
  @IsDateString()
  targetDate: string

  @IsString()
  @IsNotEmpty()
  taskName: string

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  completedDate?: string

  @IsNumber()
  @IsInt()
  @IsPositive()
  workerId: number

  sourceId?: number
}
