import 'reflect-metadata'
import { Type, plainToInstance } from 'class-transformer'
import { IsNumber, IsOptional, IsString, IsNotEmpty, ValidateNested, IsArray, ArrayNotEmpty } from 'class-validator'
import { SaveTranslation } from './translation'

export class SaveCroptype {
  public static build(raw: any) {
    return plainToInstance(SaveCroptype, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  key: string | undefined

  @IsNotEmpty()
  @Type(() => SaveTranslation)
  @ValidateNested()
  translations: SaveTranslation[] | undefined

  @IsNotEmpty()
  @IsArray()
  @ArrayNotEmpty()
  @IsNumber({}, { each: true })
  regionIds: number[] | undefined

  constructor(croptype?: SaveCroptype) {
    this.id = croptype?.id
    this.key = croptype?.key
    this.translations = croptype?.translations
    this.regionIds = croptype?.regionIds
  }
}
