import { Language } from './language'

export class UserPreferenceFormRaw {
  language: string
  defaultYieldUnit: string
  defaultDateFormat: string
  geolocationRadius: number
  geolocationRadiusUnit: string
  region: string
  limitDisplay: boolean
  campaignId: number
  displayCloseCrops: boolean
}

export class UserPreferenceDto {
  languageId: number
  defaultYieldUnit: string
  defaultDateFormat: string
  geolocationRadius: number
  geolocationRadiusUnit: string
  regionName: string
  limitDisplay: boolean
  campaignId: number
  displayCloseCrops: boolean

  constructor(formValue: UserPreferenceFormRaw, allLanguages: Language[]) {
    this.languageId = allLanguages.find((v) => v.name === formValue.language)?.id
    this.defaultYieldUnit = formValue.defaultYieldUnit
    this.defaultDateFormat = formValue.defaultDateFormat
    this.geolocationRadius = formValue.geolocationRadius
    this.geolocationRadiusUnit = formValue.geolocationRadiusUnit
    this.regionName = formValue.region
    this.limitDisplay = formValue.limitDisplay
    this.campaignId = formValue.campaignId
    this.displayCloseCrops = formValue?.displayCloseCrops
  }
}
