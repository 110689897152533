import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateConstantPipe } from '@agroone-front/shared'
import { TranslateCroptypePipe } from '@agroone-app/shared/constant/pipes/translate-croptype.pipe'

@NgModule({
  imports: [CommonModule, TranslateConstantPipe, TranslateCroptypePipe],
  exports: [TranslateConstantPipe, TranslateCroptypePipe],
})
export class ConstantModule {}
