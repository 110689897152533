import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  private showToast(message: string, severity: string, duration: number) {
    this.messageService.add({
      severity: severity,
      detail: message,
      life: duration,
    })
  }

  public showToastWarning(message: string, duration: number = 3000) {
    return this.showToast(message, 'warn', duration)
  }

  public showToastInfo(message: string, duration: number = 3000) {
    return this.showToast(message, 'info', duration)
  }

  public showToastError(message: string, duration: number = 3000) {
    return this.showToast(message, 'error', duration)
  }

  public showToastSuccess(message: string, duration: number = 3000) {
    return this.showToast(message, 'success', duration)
  }
}
