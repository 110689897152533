import { CropActivity } from '@agroone/entities'
import { createAction, props } from '@ngrx/store'
import { ActionCreator, TypedAction } from '@ngrx/store/src/models'

export enum ActivityActionTypes {
  LOAD_ACTIVITIES = '[Activity] Load Activities',
  LOAD_ACTIVITIES_SUCCESS = '[Activity] Load Activities Success',
  LOAD_ACTIVITIES_FAILURE = '[Activity] Load Activities Failure',
}

export interface ActivityHttpParams {
  cropId: number
}
// LOAD ACTIVITIES
export const loadActivities: ActionCreator<
  ActivityActionTypes.LOAD_ACTIVITIES,
  (props: ActivityHttpParams) => ActivityHttpParams & TypedAction<ActivityActionTypes.LOAD_ACTIVITIES>
> = createAction(ActivityActionTypes.LOAD_ACTIVITIES, props<ActivityHttpParams>())

export const loadActivitiesSuccess: ActionCreator<
  ActivityActionTypes.LOAD_ACTIVITIES_SUCCESS,
  (props: { cropId: number; activities: CropActivity[] }) => {
    cropId: number
    activities: CropActivity[]
  } & TypedAction<ActivityActionTypes.LOAD_ACTIVITIES_SUCCESS>
> = createAction(ActivityActionTypes.LOAD_ACTIVITIES_SUCCESS, props<{ cropId: number; activities: CropActivity[] }>())

export const loadActivitiesFailure: ActionCreator<
  ActivityActionTypes.LOAD_ACTIVITIES_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<ActivityActionTypes.LOAD_ACTIVITIES_FAILURE>
> = createAction(ActivityActionTypes.LOAD_ACTIVITIES_FAILURE, props<{ error: any }>())
