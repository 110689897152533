import 'reflect-metadata'

import { Length } from '../enums/length.enum'
import { UserPermissions } from '../enums/user-permissions.enum'
import { Profil } from '../enums/profils.enum'
import { BusinessUnits } from '../enums/business-units.enum'
import { AgropilotRole } from '../enums/agropilot-role.enum'
import { SaveUserPreference } from './save-user-preference'
import { ReportingDAO } from './reporting'
import { DateFormats } from '@agroone/dates'

export class UserDto {
  id?: number
  email: string | undefined
  firstname: string | undefined
  lastname: string | undefined
  regionName: string | undefined
  regionId: number | undefined
  campaignId: number | undefined
  language: string | undefined
  languageId?: number | undefined
  defaultYieldUnit: string | undefined
  defaultDateFormat: DateFormats | undefined
  geolocationRadius: number | undefined
  geolocationRadiusUnit: Length | undefined
  growerId?: number
  supplierId?: number
  phoneNumber?: string
  phytoCertification?: string
  limitDisplay?: boolean
  isActive?: boolean
  createdBy?: string
  createdDate?: string
  updatedBy?: string
  updatedDate?: string
  availableRegions: string[] | undefined
  permissions: UserPermissions[] | undefined
  businessUnits: BusinessUnits[] | undefined
  profil?: Profil
  reportToUserId?: number
  agropilotRoles?: AgropilotRole[]
  displayCloseCrops?: boolean
  lastConnectionDate?: string

  constructor(user?: UserDto) {
    if (user?.id) {
      this.id = user.id
    }
    this.email = user?.email ?? ''
    this.firstname = user?.firstname ?? ''
    this.lastname = user?.lastname ?? ''
    this.regionName = user?.regionName ?? ''
    this.regionId = user?.regionId ?? null
    this.campaignId = user?.campaignId ?? null
    this.language = user?.language ?? ''
    this.defaultYieldUnit = user?.defaultYieldUnit ?? ''
    this.defaultDateFormat = user?.defaultDateFormat ?? null
    this.geolocationRadius = user?.geolocationRadius ?? 0
    this.geolocationRadiusUnit = user?.geolocationRadiusUnit ?? Length.KM
    this.availableRegions = user?.availableRegions || [this.regionName]
    this.permissions = user?.permissions || []
    this.growerId = user?.growerId
    this.supplierId = user?.supplierId
    this.phoneNumber = user?.phoneNumber
    this.phytoCertification = user?.phytoCertification
    this.limitDisplay = user?.limitDisplay
    this.isActive = user?.isActive
    this.businessUnits = user?.businessUnits
    this.profil = user?.profil
    this.agropilotRoles = user?.agropilotRoles ?? []
    this.reportToUserId = user?.reportToUserId
    if (user?.languageId) {
      this.languageId = user.languageId
    }
    this.displayCloseCrops = user?.displayCloseCrops
    this.lastConnectionDate = user?.lastConnectionDate
  }
}

export class User {
  id?: number
  name: string
  firstname: string
  lastname: string
  email: string
  regionName: string
  defaultDateFormat: string
  language: string
  permissions?: string[]
  availableRegions: string[]
  agropilotRoles: AgropilotRole[]

  constructor(user: User) {
    this.id = user?.id ?? null
    this.lastname = user && user.lastname ? user.lastname : ''
    this.firstname = user && user.firstname ? user.firstname : ''
    this.email = user && user.email ? user.email : ''
    this.regionName = user && user.regionName ? user.regionName : ''
    this.defaultDateFormat = user && user.defaultDateFormat ? user.defaultDateFormat : ''
    this.language = user && user.language ? user.language : ''
    this.permissions = user && user.permissions ? user.permissions : []
    this.availableRegions = user && user.availableRegions ? user.availableRegions : []
    this.agropilotRoles = user && user.agropilotRoles ? user.agropilotRoles : []
  }
}

export class SaveUserPreferenceDAO extends ReportingDAO {
  languageId: number
  defaultDateFormat: string
  geolocationRadius: number
  geolocationRadiusUnit: string
  regionId: number
  limitDisplay: boolean
  defaultYieldUnitConstantId: number
  campaignId: number
  displayCloseCrops: boolean
  constructor(saveUserPreference: SaveUserPreference, regionId: number, defaultYieldUnitConstantId: number) {
    super(saveUserPreference)
    this.languageId = saveUserPreference.languageId
    this.defaultDateFormat = saveUserPreference.defaultDateFormat
    this.geolocationRadius = saveUserPreference.geolocationRadius
    this.geolocationRadiusUnit = saveUserPreference.geolocationRadiusUnit
    this.regionId = regionId
    this.limitDisplay = saveUserPreference.limitDisplay
    this.defaultYieldUnitConstantId = defaultYieldUnitConstantId
    this.campaignId = saveUserPreference.campaignId
    this.displayCloseCrops = saveUserPreference.displayCloseCrops
  }
}
