import { ConstantLite, Technician } from '@agroone/entities'
import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SharedConstantService } from '@agroone-front/shared'
import { UserManagementService } from '@agroone-app/core/user/user-management.service'

@UntilDestroy()
@Component({
  selector: 'app-crop-data-edit',
  templateUrl: './crop-data-edit.component.html',
  styleUrls: ['./crop-data-edit.component.sass'],
})
export class CropDataEditComponent implements OnInit {
  public form: FormGroup
  public technicians: Technician[] = []

  get technician(): AbstractControl {
    return this.form.get('technician')
  }

  get growingSystem(): AbstractControl {
    return this.form.get('growingSystem')
  }

  get associatedCrop(): AbstractControl {
    return this.form.get('associatedCrop')
  }

  constructor(
    public dialogRef: MatDialogRef<
      CropDataEditComponent,
      {
        technician: string
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      technician: string
      growingSystem: string
      associatedCrop: string
    },
    private readonly _userManagementService: UserManagementService,
    public constantService: SharedConstantService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadTechnicians()
    this.initForm()
  }

  private loadTechnicians() {
    this._userManagementService
      .getTechnicians()
      .pipe(untilDestroyed(this))
      .subscribe((technicians: Technician[]) => {
        this.technicians = technicians
        this.technician.setValue(technicians.find((u) => u.email === this.data.technician) ?? '')
      })
  }

  private initForm() {
    this.form = this.formBuilder.group({
      technician: [this.data.technician ?? null, [Validators.required]],
      growingSystem: [this.constantService.growingSystem.find((g) => g.code === this.data?.growingSystem)],
      associatedCrop: [this.constantService.associatedCrop.find((a) => a.code === this.data.associatedCrop)],
    })
  }

  public display(technician?: Technician): string | undefined {
    return technician ? `${technician.firstname} ${technician.lastname}` : undefined
  }

  public filter(value: Technician, input: Technician | string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string =
      input instanceof Object ? `${input.firstname} ${input.lastname}`?.toLowerCase() : input.toLowerCase()
    return `${value.firstname} ${value.lastname}`.toLowerCase().indexOf(filterValue) !== -1
  }

  public sortTechnician(a: Technician, b: Technician): number {
    return `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`)
  }

  public sortAssociatedCrop(a: ConstantLite, b: ConstantLite): number {
    return `${a.translation} ${a.translation}`.localeCompare(`${b.translation} ${b.translation}`)
  }

  public save() {
    if (this.form.valid) {
      const form = {
        technician: this.technician?.value?.email ?? '',
        growingSystem: this.growingSystem?.value?.code ?? '',
        associatedCrop: this.associatedCrop?.value?.code ?? '',
      }
      this.dialogRef.close(form)
    }
  }
}
