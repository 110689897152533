import { Injectable } from '@angular/core'
import { loadVarieties, loadVarietiesFailure, loadVarietiesSuccess } from '../actions/variety.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, distinctUntilChanged, map, mergeMap, of, tap } from 'rxjs'
import { VarietyService } from '@agroone-app/shared/recommendation/services/variety.service'
import { LoadingState, startLoadingVarieties, stopLoadingVarieties } from '@agroone-app/state'
import { Store } from '@ngrx/store'

@Injectable()
export class VarietyEffects {
  constructor(
    private actions$: Actions,
    private varietyService: VarietyService,
    private loadingStore: Store<LoadingState>
  ) {}

  public getVarieties$ = createEffect(() => {
    this.loadingStore.dispatch(startLoadingVarieties())

    return this.actions$.pipe(
      ofType(loadVarieties),
      distinctUntilChanged(),
      mergeMap(() =>
        this.varietyService.getAll().pipe(
          tap(() => this.loadingStore.dispatch(stopLoadingVarieties())),
          map((varieties) => loadVarietiesSuccess({ varieties })),
          catchError((e) => of(loadVarietiesFailure({ error: `${e}` })))
        )
      )
    )
  })
}
