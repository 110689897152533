import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.sass'],
})
export class PictureComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef

  public selectedFiles: File[]
  public readonlyFileNames: string[]
  public readonly MAX_NUMBER_OF_PICTURES: number = 3

  constructor(
    public dialogRef: MatDialogRef<PictureComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      style: string
      files: File[]
      readonly: boolean
      readonlyFileNames: string[]
    }
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close()
    })
  }

  ngOnInit(): void {
    this.selectedFiles = this.data.files || []
    this.readonlyFileNames = this.data.readonlyFileNames || []
  }

  public triggerSelectFiles() {
    if (this.selectedFiles.length < this.MAX_NUMBER_OF_PICTURES) {
      this.fileUpload.nativeElement.click()
    }
  }

  public selectFiles(event: Event) {
    const filesToUpload: FileList = (event?.target as HTMLInputElement)?.files
    if (filesToUpload) {
      // Only 3 pictures can be uploaded
      for (const file of Array.from(filesToUpload)) {
        if (this.selectedFiles.length < this.MAX_NUMBER_OF_PICTURES) {
          this.selectedFiles.push(file)
        }
      }
    }
  }

  public save(): void {
    this.dialogRef.close(this.selectedFiles)
  }

  public close(): void {
    this.dialogRef.close(this.selectedFiles)
  }
}
