import { Contact } from './contact'
import { Crop } from './crop'
import { Grower } from './grower'
import { Pesticide, PesticideTargetCropType } from './pesticide'
import { RecommendationCrop, RecommendationScouting, Sender } from './recommendation'
import { Region } from './region'
import { Supplier } from './supplier'
import { SaveRecommendation } from './save-recommendation'
import { Product } from './product'
import { Regions } from '../enums/regions.enum'
import { UserDto } from './user'

export enum ScoutingSubtypes {
  DISEASE = 'disease',
  WEED = 'weed',
  INSECTS = 'insects',
  FOREIGN_MATERIAL = 'foreign material',
}

export class PdfPesticideProduct extends Product {
  pesticideType: string
  preharvestInterval: string
  reentryInterval: number
}

export class CropScoutingPdf {
  type: string
  item: string
  pressure: string
  cropName: string
  growthStage: string

  constructor(cropName: string, growthStage: string, type?: string, item?: string, pressure?: string) {
    this.type = type
    this.pressure = pressure
    this.item = item
    this.cropName = cropName
    this.growthStage = growthStage
  }
}

export class PdfPesticide {
  grower: Grower
  firstContact: Contact
  region: Region
  user: UserDto
  item: SaveRecommendation
  products: PdfPesticideProduct[]
  crops: Crop[]
  croptype: string
  observationCategories: string[]
  cropScoutings: CropScoutingPdf[]
  sumOfPlantedAreas: number
  firstCrop: Crop
  isSouthWest: boolean
  isFranceNorth: boolean
  sender?: string
  supplier?: Supplier

  constructor(
    grower: Grower,
    region: Region,
    item: SaveRecommendation,
    user: UserDto,
    crops: Crop[],
    pesticides: Pesticide[],
    scoutings: RecommendationScouting[],
    supplier?: Supplier,
    lastGrowthStage?: string
  ) {
    this.grower = grower
    this.firstContact = grower?.contacts[0]
    this.item = item
    this.region = region
    this.user = user
    this.crops = crops
    this.sumOfPlantedAreas = this.getSumOfPlantedAreas() || 0
    this.firstCrop = crops?.[0]
    const { processedScoutings, observations } = this.processCropScoutings(scoutings, item.crops, lastGrowthStage)
    this.cropScoutings = processedScoutings
    this.observationCategories = new Array(...new Set(observations))
    this.supplier = supplier
    this.croptype = crops[0].cropType
    this.isSouthWest = region.name === Regions.FRANCE_SOUTH_WEST
    this.isFranceNorth = region.name === Regions.FRANCE_NORTH

    // We set products based on pesticides/targetCrop
    this.products = (item.pesticides.products as Product[])?.map((p: Product) => {
      const pesticideProduct: PdfPesticideProduct = new PdfPesticideProduct(p)
      const pesticide: Pesticide = pesticides?.find((x) => x.id === Number(p?.pesticideId))
      pesticideProduct.pesticideType = pesticide?.pesticideType
      const targetCrop: PesticideTargetCropType = pesticide?.targetCrops?.find(
        (tc) => tc.cropType === crops[0].cropType
      )
      pesticideProduct.reentries = targetCrop?.reentries ?? []
      pesticideProduct.preharvestInterval = targetCrop?.preharvestInterval ?? null
      pesticideProduct.activeIngredientsNames = pesticide.activeIngredients?.map((x) => x.name)

      return pesticideProduct
    })
    this.sender = Sender[user.regionName] ?? null
  }

  private processCropScoutings(
    scoutings: RecommendationScouting[],
    crops: RecommendationCrop[],
    lastGrowthStage: string
  ): { processedScoutings: CropScoutingPdf[]; observations: string[] } {
    const processedScoutings: CropScoutingPdf[] = []
    const observations: string[] = []

    for (const crop of crops) {
      const cropScoutings: RecommendationScouting[] = scoutings.filter((s) => s.cropId === crop.id)
      if (cropScoutings?.length) {
        for (const scouting of cropScoutings) {
          if ((scouting as RecommendationScouting).disease) {
            processedScoutings.push(
              new CropScoutingPdf(
                crop.name,
                lastGrowthStage,
                ScoutingSubtypes.DISEASE,
                (scouting as RecommendationScouting).disease.item,
                (scouting as RecommendationScouting).disease.pressure
              )
            )
            observations.push(ScoutingSubtypes.DISEASE)
          } else if ((scouting as RecommendationScouting).weed) {
            processedScoutings.push(
              new CropScoutingPdf(
                crop.name,
                lastGrowthStage,
                ScoutingSubtypes.WEED,
                (scouting as RecommendationScouting).weed.item,
                (scouting as RecommendationScouting).weed.pressure
              )
            )
            observations.push(ScoutingSubtypes.WEED)
          } else if ((scouting as RecommendationScouting).insect) {
            processedScoutings.push(
              new CropScoutingPdf(
                crop.name,
                lastGrowthStage,
                ScoutingSubtypes.INSECTS,
                (scouting as RecommendationScouting).insect.item,
                (scouting as RecommendationScouting).insect.pressure
              )
            )
            observations.push(ScoutingSubtypes.INSECTS)
          } else if ((scouting as RecommendationScouting).foreignMaterial) {
            processedScoutings.push(
              new CropScoutingPdf(
                crop.name,
                lastGrowthStage,
                ScoutingSubtypes.FOREIGN_MATERIAL,
                (scouting as RecommendationScouting).foreignMaterial.type,
                (scouting as RecommendationScouting).foreignMaterial.level
              )
            )
            observations.push(ScoutingSubtypes.FOREIGN_MATERIAL)
          }
        }
      } else if (lastGrowthStage) {
        processedScoutings.push(new CropScoutingPdf(crop.name, lastGrowthStage))
      }
    }

    return { processedScoutings, observations }
  }

  private getSumOfPlantedAreas(): number {
    return this.crops?.reduce((a, b) => a + b.plantedArea, 0)
  }
}
