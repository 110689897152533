import { BusinessUnits } from '../enums/business-units.enum'

export class CropTypeForecast {
  static readonly className = 'CropTypeForecast'

  id?: number
  region: string
  businessUnit: BusinessUnits
  growerId: number
  growerName?: string
  factory?: string
  cropType: string
  rawMaterialProcessed: string
  year: number
  week: number
  day1: number
  day2: number
  day3: number
  day4: number
  day5: number
  day6: number
  day7: number
  quantityUnit: string
  brandPercentage?: number
  campaign: string
  userId: string
  updateDate: string

  constructor(cropTypeForecast?: CropTypeForecast) {
    if (cropTypeForecast?.id) {
      this.id = cropTypeForecast?.id
    }
    this.region = cropTypeForecast?.region
    this.businessUnit = cropTypeForecast?.businessUnit
    this.growerId = cropTypeForecast?.growerId
    this.growerName = cropTypeForecast?.growerName
    this.factory = cropTypeForecast?.factory
    this.cropType = cropTypeForecast?.cropType
    this.rawMaterialProcessed = cropTypeForecast?.rawMaterialProcessed
    this.year = cropTypeForecast?.year
    this.week = cropTypeForecast?.week
    this.day1 = cropTypeForecast?.day1
    this.day2 = cropTypeForecast?.day2
    this.day3 = cropTypeForecast?.day3
    this.day4 = cropTypeForecast?.day4
    this.day5 = cropTypeForecast?.day5
    this.day6 = cropTypeForecast?.day6
    this.day7 = cropTypeForecast?.day7
    this.quantityUnit = cropTypeForecast?.quantityUnit
    this.brandPercentage = cropTypeForecast?.brandPercentage ?? 100
    this.campaign = cropTypeForecast?.campaign
    this.userId = cropTypeForecast?.userId
    this.updateDate = cropTypeForecast?.updateDate
  }
}

export class CropTypeForecastRequest {
  id?: number
  region: string
  businessUnit: BusinessUnits
  growerId: number
  growerName?: string
  factory?: string
  cropType: string
  rawMaterialProcessed: string
  year: number
  week: number
  day1: number
  day2: number
  day3: number
  day4: number
  day5: number
  day6: number
  day7: number
  quantityUnit: string
  brandPercentage?: number
  campaign: string
  userId: string
  updateDate: string
}

export class CropTypeForecastDAO {
  id?: number
  regionId: number
  businessUnitId: number
  growerId: number
  factoryId: number
  cropTypeId: number
  rawMaterialProcessed: string
  year: number
  week: number
  day1?: number
  day2?: number
  day3?: number
  day4?: number
  day5?: number
  day6?: number
  day7?: number
  quantityUnit: string
  brandPercentage?: number
  campaignId: number
  userId: string
  updateDate: Date

  constructor(cropTypeForecast?: CropTypeForecastDAO) {
    if (cropTypeForecast?.id) {
      this.id = cropTypeForecast?.id
    }
    this.regionId = cropTypeForecast?.regionId
    this.businessUnitId = cropTypeForecast?.businessUnitId
    this.growerId = cropTypeForecast?.growerId
    this.factoryId = cropTypeForecast?.factoryId
    this.rawMaterialProcessed = cropTypeForecast?.rawMaterialProcessed
    this.cropTypeId = cropTypeForecast?.cropTypeId
    this.campaignId = cropTypeForecast?.campaignId
    this.year = cropTypeForecast?.year
    this.week = cropTypeForecast?.week
    this.day1 = cropTypeForecast?.day1 ?? 0
    this.day2 = cropTypeForecast?.day2 ?? 0
    this.day3 = cropTypeForecast?.day3 ?? 0
    this.day4 = cropTypeForecast?.day4 ?? 0
    this.day5 = cropTypeForecast?.day5 ?? 0
    this.day6 = cropTypeForecast?.day6 ?? 0
    this.day7 = cropTypeForecast?.day7 ?? 0
    this.quantityUnit = cropTypeForecast?.quantityUnit
    this.brandPercentage = cropTypeForecast?.brandPercentage ?? 100
    this.userId = cropTypeForecast?.userId
    this.updateDate = cropTypeForecast?.updateDate ?? new Date()
  }
}
