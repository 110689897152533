import { Component, Inject, OnInit } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.sass'],
})
export class ConfirmComponent implements OnInit {
  public class: string
  public content: string
  public title: string

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string; style: string; title: string }
  ) {}

  ngOnInit(): void {
    this.content = this.data.message
    this.title = this.data.title ? this.data.title : this.translate.instant('DIALOG.CONFIRM.TITLE')
  }

  save(): void {
    this.dialogRef.close(true)
  }
}
