import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { plainToInstance } from 'class-transformer'

export class SaveSpecification {
  public static build(raw: any) {
    return plainToInstance(SaveSpecification, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsString()
  description: string | undefined

  @IsNumber()
  @IsNotEmpty()
  regionId: number | undefined

  @IsBoolean()
  @IsNotEmpty()
  active: boolean | undefined

  @IsBoolean()
  @IsNotEmpty()
  isEditable: boolean | undefined

  @IsOptional()
  @IsBoolean()
  isOrganic: boolean = false
}
