import { Grower } from '@agroone/entities'
import { ActionCreator, ReducerTypes, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import {
  addGrower,
  addGrowerFailure,
  addGrowerSuccess,
  clearGrowers,
  loadGrowers,
  loadGrowersFailure,
  loadGrowersSuccess,
  updateGrower,
  updateGrowerFailure,
  updateGrowerSuccess,
} from '../actions/grower.actions'
export type GrowerState = EntityState<Grower>

export const growerAdapter: EntityAdapter<Grower> = createEntityAdapter<Grower>()

export const initialGrowerState: GrowerState = growerAdapter.getInitialState({
  // eslint-disable-next-line no-empty-pattern
  ids: ([] = []),
})

export const growerOns: ReducerTypes<GrowerState, ActionCreator[]>[] = [
  on(loadGrowers, (state) => ({
    ...state,
  })),
  on(loadGrowersSuccess, (state, action) => growerAdapter.setAll(action?.growers, { ...state })),
  on(loadGrowersFailure, (state) => ({
    ...state,
  })),

  // CREATE
  on(addGrower, (state) => ({
    ...state,
  })),
  on(addGrowerSuccess, (state, action) =>
    growerAdapter.addOne(action.payload.grower, {
      ...state,
      message: 'Grower has been added',
      error: undefined,
    })
  ),
  on(addGrowerFailure, (state) => ({
    ...state,
    error: 'Oups, Grower has not  been added ...',
  })),

  // UPDATE
  on(updateGrower, (state) => ({
    ...state,
  })),
  on(updateGrowerSuccess, (state, action) =>
    growerAdapter.upsertOne(action?.payload?.grower, {
      ...state,
      message: 'Grower has been added',
      error: undefined,
    })
  ),
  on(updateGrowerFailure, (state) => ({
    ...state,
    error: 'Oups, Grower has not  been updated ...',
  })),
  on(clearGrowers, () => initialGrowerState),
]
