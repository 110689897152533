import { WorkOrder } from './work-order'

export class TPATask {
  id?: number
  targetDate: string
  completedDate?: string
  plantedDate?: string
  disablingDate?: string
  validated?: boolean
  growerId: number
  grower: string
  croptypeId: number
  croptype: string
  region: string
  regionId: number
  workOrder: WorkOrder
  supplier: string
  supplierId: number
  sourceId: string
  growerContactsPhoneNumbers: string[]

  constructor(task?: TPATask) {
    this.id = task?.id
    this.targetDate = task?.targetDate ?? ''
    this.completedDate = task?.completedDate
    this.plantedDate = task?.plantedDate ?? null
    this.disablingDate = task?.disablingDate
    this.validated = task?.validated ?? false
    this.growerId = task?.growerId
    this.grower = task?.grower ?? ''
    this.croptypeId = task?.croptypeId
    this.croptype = task?.croptype
    this.regionId = task?.regionId
    this.region = task?.region
    this.workOrder = task?.workOrder
    this.supplierId = task?.supplierId
    this.supplier = task?.supplier
    this.sourceId = task?.sourceId
    this.growerContactsPhoneNumbers = task?.growerContactsPhoneNumbers
  }
}

export class TPATaskDAO {
  id: number
  targetDate: string
  completedDate?: string
  plantedDate?: string
  disablingDate?: string
  validated?: number
  growerId: number
  croptypeId: number
  regionId: number
  workOrderId: number
  supplierId: number
  sourceId: string
}

export class TPARequest {
  id?: number
  targetDate: string
  completedDate?: string
  disablingDate?: string
  plantedDate?: string
  validated?: number
  regionId: number
  supplierId: number
  croptypeId: number
  growerId: number
  woId: number
  woDate: string
  woOrderedBy: string
  woType: string
  woSubType: string
  woNote?: string
  woSendSMS?: number
  woSendEmail?: number
  woTraceabilityId?: number
  woDisablingDate?: string
  woCompletedDate?: string
  region: string
  croptype: string
  supplier: string
  fsId?: number
  fsBufferZone?: number
  fsBufferZoneUnit?: string
  fsLatitude?: string
  fsLongitude?: string
  fsPrecision?: string
  fsGeohash?: string
  plId?: number
  plVarietyId?: number
  plVarietyName?: string
  plLotNumber?: string
  plSeedingRate?: number
  plSeedingRateUnit?: string
  plSeedingArea?: number
  plSeedingAreaUnit?: string
  plTargetDate?: string
  mwId?: number
  mwArea?: string
  mwTargetDate?: string
  ooId?: number
  ooOperationType?: string
  ooArea?: string
  ooTargetDate?: string
  aopId?: number
  aopWaterAmount?: number
  aopWaterUnit?: string
  aopWaterAreaUnit?: string
  aopTargetDate?: string
  aopAlternative?: string
  aopCompletedDate?: string
  pIds?: string
  pIdentificationNumbers?: string
  pNames?: string
  pPesticideTypes?: string
  pRates?: string
  pRateUnits?: string
  pRateAreaUnits?: string
  pArea?: number
  pTargetDate?: string
  pAlternativeSolution?: string
  pNote?: string
  iId?: number
  iStartingDate?: string
  iEndingDate?: string
  iArea?: number
  iAmount?: number
  iAreaUnit?: string
  iAmountUnit?: string
  nIds?: string
  nKeys?: string
  nTypes?: string
  nValues?: string
  nNutrientIds?: string
  cropIds?: string
  cropNames?: string
  cropShortNames?: string
  cropIntendedAreas?: string
  cropAreaUnits?: string
  cropPlantedAreas?: string
  cropPlantedDates?: string
  cropFieldIds?: string
  cropLatitudes?: string
  cropLongitudes?: string
  cropPrecisions?: string
  cropGeohashs?: string
  cropBoundaries?: string
  fieldNames?: string
  picturePaths?: string
  sourceId: string
}
