import { AppError, Message } from './'

export class HttpError extends Error implements Message, AppError {
  okLabel: string

  okAction: () => void

  isFailure: boolean

  retryable: boolean

  retryLabel: string

  retryAction: () => void

  status: number

  constructor(message: string) {
    super(message)
    this.name = 'HttpError'
    this.isFailure = true
    this.retryable = true
  }
}
