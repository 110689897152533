<div [ngClass]="data.style" class="file">
  <h2 mat-dialog-title>{{ 'DIALOG.UPLOAD_CSV.TITLE' | translate }}</h2>

  <mat-dialog-content class="file-content">
    <input type="file" accept=".csv" (change)="import($event)" hidden #fileUpload />
    <div class="file-content-action">
      <button mat-button class="file-content-action-button" (click)="fileUpload.click()">
        {{ 'DIALOG.UPLOAD_CSV.UPLOAD' | translate }}
      </button>
      <span class="file-content-action-filename" *ngIf="file">{{ file.name }}</span>
      <span *ngIf="showError" class="file-errors"> {{ 'DIALOG.UPLOAD_CSV.FILE_ERROR' | translate }}</span>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="file-actions">
    <button color="accent" mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
    <button (click)="save()" color="accent" mat-flat-button [disabled]="!file">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
  </mat-dialog-actions>
</div>
