import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, throwError } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private cognitoClientId: string
  private cognitoDomain: string
  private apiUrl: string

  constructor(private http: HttpClient) {}

  public init(cognitoDomain: string, cognitoClientId: string, apiUrl: string) {
    this.cognitoDomain = cognitoDomain
    this.cognitoClientId = cognitoClientId
    this.apiUrl = apiUrl
  }

  public getApiUrl() {
    return this.apiUrl
  }

  public redirectToLogin() {
    // Clean local data
    indexedDB.deleteDatabase('agronne-store')
    localStorage.clear()
    sessionStorage.clear()
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => registration.unregister())
    })
    // eslint-disable-next-line max-len
    const loginUrl: string = `${this.cognitoDomain}/login?response_type=code&client_id=${
      this.cognitoClientId
    }&redirect_uri=${this.apiUrl}/auth/callback&state=${window.location.hostname.split('.')[0]}`
    window.location.href = loginUrl
  }

  public refreshTokens(): Observable<any> {
    return this.http.get(`${this.apiUrl}/auth/refresh`).pipe(
      catchError((error: any) => {
        this.redirectToLogin()
        const err: Error = new Error(error)
        return throwError(() => err)
      })
    )
  }
}
