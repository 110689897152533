import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { FormElement } from '@agroone-app/shared/form/models/form-element.model'
import { dateToISO8601 } from '@agroone/helpers'
import { SharedConstantService } from '@agroone-front/shared'
import { DateFormatV2, formatDateV2 } from '@agroone/dates'

@Component({
  selector: 'app-crop-close',
  templateUrl: './crop-close.component.html',
  styleUrls: ['./crop-close.component.sass'],
})
export class CropCloseComponent implements OnInit, OnDestroy {
  public form: FormGroup
  public endDateElement: FormElement

  get endDate(): AbstractControl {
    return this.form.get('endDate')
  }

  private destroyed: Subject<void> = new Subject()

  constructor(
    public dialogRef: MatDialogRef<
      CropCloseComponent,
      {
        endDate: string
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      endDate: Date
    },
    private formBuilder: FormBuilder,
    public constantService: SharedConstantService
  ) {}

  ngOnInit() {
    this.endDateElement = new FormElement()
    this.endDateElement.maxDateValue = new Date()
    this.form = this.formBuilder.group({
      endDate: [this.data.endDate ?? new Date(), [Validators.required]],
    })
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  save() {
    if (this.form.valid) {
      const form = {
        endDate: this.endDate.value ? formatDateV2(new Date(this.endDate.value), DateFormatV2.DATETIME_OFFSET) : null,
      }
      this.dialogRef.close(form)
    }
  }

  public display(value: string): string {
    return value
  }

  public filter(value: string, input: string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string = input.toLowerCase()
    return value.toLowerCase().indexOf(filterValue) !== -1
  }
}
