export enum Regions {
  FRANCE_NORTH = 'FRANCE NORTH',
  BEKESCSABA = 'BEKESCSABA',
  ITALIA = 'ITALIA',
  NYIREGYHAZA = 'NYIREGYHAZA',
  RUSSIA = 'RUSSIA',
  AMERICA = 'AMERICA',
  ONTARIO = 'ONTARIO',
  FRANCE_SOUTH_WEST = 'FRANCE SOUTH WEST',
  POLAND = 'POLAND',
  WISCONSIN = 'WISCONSIN',
  NAGYKOROS = 'NAGYKOROS',
  ALBERTA = 'ALBERTA',
  QUEBEC = 'QUEBEC',
  GERMANY = 'GERMANY',
  FRANCE = 'FRANCE',
  PORTUGAL = 'PORTUGAL',
  BFE = 'BFE',
  SPAIN = 'SPAIN',
  BRAZIL = 'BRAZIL',
}
