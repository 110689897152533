import { Recommendation } from '@agroone/entities'
import { ActionCreator, createAction, props } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'

export enum RecommendationActionTypes {
  LOAD_RECOMMENDATIONS = '[Recommendation] Load recommendations',
  LOAD_RECOMMENDATIONS_SUCCESS = '[Recommendation] Load recommendations Success',
  LOAD_RECOMMENDATIONS_FAILURE = '[Recommendation] Load recommendations Failure',

  LOAD_RECOMMENDATION = '[Recommendation] Load recommendation',
  LOAD_RECOMMENDATION_SUCCESS = '[Recommendation] Load recommendation Success',
  LOAD_RECOMMENDATION_FAILURE = '[Recommendation] Load recommendation Failure',

  ADD_RECOMMENDATION = '[Recommendation] Add recommendation',
  ADD_RECOMMENDATION_SUCCESS = '[Recommendation] Add recommendation Success',
  ADD_RECOMMENDATION_FAILURE = '[Recommendation] Add recommendation Failure',

  ADD_RECOMMENDATIONS = '[Recommendation] Add recommendations',
  ADD_RECOMMENDATIONS_SUCCESS = '[Recommendation] Add recommendations Success',
  ADD_RECOMMENDATIONS_FAILURE = '[Recommendation] Add recommendations Failure',

  UPDATE_RECOMMENDATION = '[Recommendation] Update recommendations',
  UPDATE_RECOMMENDATION_SUCCESS = '[Recommendation] Update recommendation Success',
  UPDATE_RECOMMENDATION_FAILURE = '[Recommendation] Update recommendation Failure',

  DELETE_RECOMMENDATION_SUCCESS = '[Recommendation] Delete recommendation Success',
  DELETE_RECOMMENDATION_FAILURE = '[Recommendation] Delete recommendation Failure',
  DELETE_RECOMMENDATION = '[Recommendation] Delete Recommendation',
}

// LOAD RECOMMENDATIONS
export const loadRecommendations: ActionCreator<
  RecommendationActionTypes.LOAD_RECOMMENDATIONS,
  (props: { cropId: number }) => {
    cropId: number
  } & TypedAction<RecommendationActionTypes.LOAD_RECOMMENDATIONS>
> = createAction(RecommendationActionTypes.LOAD_RECOMMENDATIONS, props<{ cropId: number }>())

export const loadRecommendationsSuccess: ActionCreator<
  RecommendationActionTypes.LOAD_RECOMMENDATIONS_SUCCESS,
  (props: { cropId: number; recommendations: Recommendation[] }) => {
    cropId: number
    recommendations: Recommendation[]
  } & TypedAction<RecommendationActionTypes.LOAD_RECOMMENDATIONS_SUCCESS>
> = createAction(
  RecommendationActionTypes.LOAD_RECOMMENDATIONS_SUCCESS,
  props<{ cropId: number; recommendations: Recommendation[] }>()
)

export const loadRecommendationsFailure: ActionCreator<
  RecommendationActionTypes.LOAD_RECOMMENDATIONS_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<RecommendationActionTypes.LOAD_RECOMMENDATIONS_FAILURE>
> = createAction(RecommendationActionTypes.LOAD_RECOMMENDATIONS_FAILURE, props<{ error: any }>())

// LOAD Recommendation
export const loadRecommendation: ActionCreator<
  RecommendationActionTypes.LOAD_RECOMMENDATION,
  (props: { id: number }) => {
    id: number
  } & TypedAction<RecommendationActionTypes.LOAD_RECOMMENDATION>
> = createAction(RecommendationActionTypes.LOAD_RECOMMENDATION, props<{ id: number }>())

export const loadRecommendationSuccess: ActionCreator<
  RecommendationActionTypes.LOAD_RECOMMENDATION_SUCCESS,
  (props: { id: number; recommendation: Recommendation }) => {
    id: number
    recommendation: Recommendation
  } & TypedAction<RecommendationActionTypes.LOAD_RECOMMENDATION_SUCCESS>
> = createAction(
  RecommendationActionTypes.LOAD_RECOMMENDATION_SUCCESS,
  props<{ id: number; recommendation: Recommendation }>()
)

export const loadRecommendationFailure: ActionCreator<
  RecommendationActionTypes.LOAD_RECOMMENDATION_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<RecommendationActionTypes.LOAD_RECOMMENDATION_FAILURE>
> = createAction(RecommendationActionTypes.LOAD_RECOMMENDATION_FAILURE, props<{ error: any }>())

// ADD Recommendation
export const addRecommendation: ActionCreator<
  RecommendationActionTypes.ADD_RECOMMENDATION,
  (props: {
    payload: {
      cropId: number
      recommendation: Recommendation
      pictures?: {
        name: string
        content: string
      }[]
    }
  }) => {
    payload: {
      cropId: number
      recommendation: Recommendation
      pictures?: {
        name: string
        content: string
      }[]
    }
  } & TypedAction<RecommendationActionTypes.ADD_RECOMMENDATION>
> = createAction(
  RecommendationActionTypes.ADD_RECOMMENDATION,
  props<{
    payload: {
      cropId: number
      recommendation: Recommendation
      pictures?: { name: string; content: string }[]
    }
  }>()
)

export const addRecommendationSuccess: ActionCreator<
  RecommendationActionTypes.ADD_RECOMMENDATION_SUCCESS,
  (props: {
    payload: {
      recommendation: Recommendation
    }
  }) => {
    payload: {
      recommendation: Recommendation
    }
  } & TypedAction<RecommendationActionTypes.ADD_RECOMMENDATION_SUCCESS>
> = createAction(
  RecommendationActionTypes.ADD_RECOMMENDATION_SUCCESS,
  props<{
    payload: {
      cropId: number
      recommendation: Recommendation
    }
  }>()
)

export const addRecommendationFailure: ActionCreator<
  RecommendationActionTypes.ADD_RECOMMENDATION_FAILURE,
  (props: { error: any; loading?: boolean; message: string }) => {
    error: any
    loading?: boolean
    message: string
  } & TypedAction<RecommendationActionTypes.ADD_RECOMMENDATION_FAILURE>
> = createAction(
  RecommendationActionTypes.ADD_RECOMMENDATION_FAILURE,
  props<{ error: any; loading?: boolean; message: string }>()
)

// ADD Recommendations
export const addRecommendations: ActionCreator<
  RecommendationActionTypes.ADD_RECOMMENDATIONS,
  (props: {
    payload: {
      recommendations: Recommendation[]
      pictures?: {
        name: string
        content: string
      }[]
    }
  }) => {
    payload: {
      recommendations: Recommendation[]
      pictures?: {
        name: string
        content: string
      }[]
    }
  } & TypedAction<RecommendationActionTypes.ADD_RECOMMENDATIONS>
> = createAction(
  RecommendationActionTypes.ADD_RECOMMENDATIONS,
  props<{
    payload: {
      cropId: number
      recommendations: Recommendation[]
      pictures?: { name: string; content: string }[]
    }
  }>()
)

export const addRecommendationsSuccess: ActionCreator<
  RecommendationActionTypes.ADD_RECOMMENDATIONS_SUCCESS,
  (props: {
    payload: {
      recommendations: Recommendation[]
    }
  }) => {
    payload: {
      recommendations: Recommendation[]
    }
  } & TypedAction<RecommendationActionTypes.ADD_RECOMMENDATIONS_SUCCESS>
> = createAction(
  RecommendationActionTypes.ADD_RECOMMENDATIONS_SUCCESS,
  props<{
    payload: {
      cropId: number
      recommendations: Recommendation[]
    }
  }>()
)

export const addRecommendationsFailure: ActionCreator<
  RecommendationActionTypes.ADD_RECOMMENDATIONS_FAILURE,
  (props: { error: any; loading?: boolean; message: string }) => {
    error: any
    loading?: boolean
    message: string
  } & TypedAction<RecommendationActionTypes.ADD_RECOMMENDATIONS_FAILURE>
> = createAction(
  RecommendationActionTypes.ADD_RECOMMENDATIONS_FAILURE,
  props<{ error: any; loading?: boolean; message: string }>()
)

// UPDATE Recommendation
export const updateRecommendation: ActionCreator<
  RecommendationActionTypes.UPDATE_RECOMMENDATION,
  (props: {
    payload: {
      cropId: number
      recommendation: Recommendation
      pictures: {
        name: string
        content: string
      }[]
    }
  }) => {
    payload: {
      cropId: number
      recommendation: Recommendation
      pictures: {
        name: string
        content: string
      }[]
    }
  } & TypedAction<RecommendationActionTypes.UPDATE_RECOMMENDATION>
> = createAction(
  RecommendationActionTypes.UPDATE_RECOMMENDATION,
  props<{
    payload: {
      cropId: number
      recommendation: Recommendation
      pictures: { name: string; content: string }[]
    }
  }>()
)

export const updateRecommendationSuccess: ActionCreator<
  RecommendationActionTypes.UPDATE_RECOMMENDATION_SUCCESS,
  (props: {
    payload: {
      cropId: number
      recommendation: Recommendation
    }
  }) => {
    payload: {
      cropId: number
      recommendation: Recommendation
    }
  } & TypedAction<RecommendationActionTypes.UPDATE_RECOMMENDATION_SUCCESS>
> = createAction(
  RecommendationActionTypes.UPDATE_RECOMMENDATION_SUCCESS,
  props<{
    payload: {
      cropId: number
      recommendation: Recommendation
    }
  }>()
)

export const updateRecommendationFailure: ActionCreator<
  RecommendationActionTypes.UPDATE_RECOMMENDATION_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<RecommendationActionTypes.UPDATE_RECOMMENDATION_FAILURE>
> = createAction(RecommendationActionTypes.UPDATE_RECOMMENDATION_FAILURE, props<{ error: any }>())

export const deleteRecommendation: ActionCreator<
  RecommendationActionTypes.DELETE_RECOMMENDATION,
  (props: { cropId: number; recommendationId: number }) => {
    cropId: number
    recommendationId: number
  } & TypedAction<RecommendationActionTypes.DELETE_RECOMMENDATION>
> = createAction(RecommendationActionTypes.DELETE_RECOMMENDATION, props<{ cropId: number; recommendationId: number }>())

export const deleteRecommendationSuccess: ActionCreator<
  RecommendationActionTypes.DELETE_RECOMMENDATION_SUCCESS,
  (props: { cropId: number; recommendationId: number }) => {
    cropId: number
    recommendationId: number
  } & TypedAction<RecommendationActionTypes.DELETE_RECOMMENDATION_SUCCESS>
> = createAction(
  RecommendationActionTypes.DELETE_RECOMMENDATION_SUCCESS,
  props<{
    cropId: number
    recommendationId: number
  }>()
)
export const deleteRecommendationError: ActionCreator<
  RecommendationActionTypes.DELETE_RECOMMENDATION_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<RecommendationActionTypes.DELETE_RECOMMENDATION_FAILURE>
> = createAction(RecommendationActionTypes.DELETE_RECOMMENDATION_FAILURE, props<{ error: any }>())
