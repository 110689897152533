import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DateWeekNumberDirective } from './date-week-number.directive'

@NgModule({
  imports: [CommonModule],
  declarations: [DateWeekNumberDirective],
  exports: [DateWeekNumberDirective],
})
export class DateWeekNumberModule {}
