import { FilterQuery } from '@mikro-orm/core'
import { plainToInstance } from 'class-transformer'

export interface MsSqlNotEqualityFilter<T> {
  operator: 'notequality'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlEqualityFilter<T> {
  operator: 'equality'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlSuperiorityFilter<T> {
  operator: 'superiority'
  fieldName: string
  isStrict: boolean
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlInferiorityFilter<T> {
  operator: 'inferiority'
  fieldName: string
  isStrict: boolean
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlLikeFilter<T> {
  operator: 'like'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlStartsWithFilter<T> {
  operator: 'startswith'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlEndsWithFilter<T> {
  operator: 'endswith'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlRangeFilter<T> {
  operator: 'range'
  fieldName: string
  min: T
  max: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlOrFilter<T> {
  operator: 'or'
  fieldName: string
  value: T
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlExistsFilter {
  operator: 'exists'
  fieldName: string
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlNotExistsFilter {
  operator: 'notexists'
  fieldName: string
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export interface MsSqlValuesFilter<T> {
  operator: 'values'
  fieldName: string
  values: T[]
  type: 'string' | 'number' | 'boolean'
  nextSeparator?: string
}

export type MsSqlFilter<T = string | number | boolean> =
  | MsSqlEqualityFilter<T>
  | MsSqlNotEqualityFilter<T>
  | MsSqlSuperiorityFilter<T>
  | MsSqlInferiorityFilter<T>
  | MsSqlLikeFilter<T>
  | MsSqlStartsWithFilter<T>
  | MsSqlEndsWithFilter<T>
  | MsSqlRangeFilter<T>
  | MsSqlOrFilter<T>
  | MsSqlValuesFilter<T>
  | MsSqlExistsFilter
  | MsSqlNotExistsFilter

export class MsSqlFieldSelected {
  required: string[] | undefined
  removed: string[] | undefined
}

export class MsSqlQueryOptionsPagination {
  static firstResult: MsSqlQueryOptionsPagination = MsSqlQueryOptionsPagination.build({ page: 1, length: 1 })

  static build(raw: any, defaultPageSize: number = 20) {
    const result: MsSqlQueryOptionsPagination = plainToInstance(MsSqlQueryOptionsPagination, raw)
    if (!result.page) {
      result.page = 1
    }
    if (!result.length) {
      result.length = defaultPageSize
    }
    return result
  }

  length: number | undefined
  page: number | undefined
  noPagination: number | undefined
}

export interface MsSqlQueryOptions {
  filters?: MsSqlFilter[]
  fields?: MsSqlFieldSelected
  sort?: { [key: string]: 'asc' | 'desc' }
  pagination?: MsSqlQueryOptionsPagination
}

export interface MsSqlQueryJoinClause {
  tableName: string
  columnName: string
  fkColumnName: string
  alias: string
}

export function _buildWhereQuery<T>(filters?: MsSqlFilter[]): FilterQuery<T> {
  if (!filters || filters.length === 0) {
    return {}
  }

  return filters.reduce((where, filter) => {
    switch (filter.operator) {
      case 'equality':
        where[filter.fieldName] = filter.value
        break

      case 'notequality':
        where[filter.fieldName] = { $ne: filter.value }
        break

      case 'superiority':
        where[filter.fieldName] = filter.isStrict ? { $gt: filter.value } : { $gte: filter.value }
        break

      case 'inferiority':
        where[filter.fieldName] = filter.isStrict ? { $lt: filter.value } : { $lte: filter.value }
        break

      case 'like':
        where[filter.fieldName] = { $like: `%${filter.value}%` }
        break

      case 'startswith':
        where[filter.fieldName] = { $like: `${filter.value}%` }
        break

      case 'endswith':
        where[filter.fieldName] = { $like: `%${filter.value}` }
        break

      case 'range':
        where[filter.fieldName] = { $gte: filter.min, $lte: filter.max }
        break

      case 'or':
        ;(where as any).$or = (where as any).$or || []
        ;(where as any).$or.push({ [filter.fieldName]: filter.value })
        break

      case 'values':
        where[filter.fieldName] = { $in: filter.values }
        break

      case 'exists':
        where[filter.fieldName] = { $exists: true }
        break

      case 'notexists':
        where[filter.fieldName] = { $exists: false }
        break

      default:
        throw new Error(`Unsupported filter operator: ${filter}`)
    }

    return where
  }, {} as FilterQuery<T>)
}

export interface MsSqlJoin<T = any> {
  fieldName: keyof T
  alias?: string
  type: 'inner' | 'left' | 'right'
}

export function _buildJoinsQuery<T>(joins?: MsSqlJoin<T>[]): Record<string, { type: string; field: keyof T }> {
  if (!joins || joins.length === 0) {
    return {}
  }

  return joins.reduce((queryJoins, join) => {
    if (!['inner', 'left', 'right'].includes(join.type)) {
      throw new Error(`Unsupported join type: ${join.type}`)
    }

    const alias: string = join.alias || (join.fieldName as string)

    queryJoins[alias] = {
      type: join.type === 'inner' ? 'join' : join.type,
      field: join.fieldName,
    }

    return queryJoins
  }, {} as Record<string, { type: string; field: keyof T }>)
}
