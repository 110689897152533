import { Injectable } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter } from 'rxjs'
import { LoggerService, NewVersionPopupComponent } from '../'

@Injectable()
export class VersionService {
  constructor(private updates: SwUpdate, private dialog: MatDialog, private loggerService: LoggerService) {
    // Check for updates during "visibilitychange" event
    document.addEventListener('visibilitychange', this.visibilityChangeHandler)

    this.updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        this.dialog.open(NewVersionPopupComponent, {
          disableClose: true,
          width: '80vw',
          height: 'auto',
          minHeight: '230px',
          maxWidth: '600px',
        })
      })

    // Check for updates during application start
    this.checkForUpdates()
  }

  private visibilityChangeHandler = () => {
    if (document.visibilityState === 'visible') {
      this.checkForUpdates()
    }
  }

  ngOnDestroy() {
    // Remove the event listener to prevent memory leaks
    document.removeEventListener('visibilitychange', this.visibilityChangeHandler)
  }

  checkForUpdates() {
    if (!this.updates.isEnabled) {
      this.loggerService.log('SW not enabled')
      return
    }

    this.updates
      .checkForUpdate()
      .then((updateFound: boolean) => {
        this.loggerService.log(updateFound ? 'A new version is available.' : 'Already on the latest version.')
      })
      .catch((error) => {
        this.loggerService.log('Error checking for updates', error)
      })
  }
}
