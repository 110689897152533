import 'reflect-metadata'

import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Matches,
} from 'class-validator'
import { EMAIL_REGEX, PHONE_REGEX } from '../constants'
export class SaveLaboratoryContact {
  @IsString()
  @IsNotEmpty()
  firstName: string | undefined

  @IsString()
  @IsNotEmpty()
  lastName: string | undefined

  @IsNumber()
  @IsNotEmpty()
  languageId: number | undefined

  @IsArray()
  @ArrayNotEmpty()
  phoneNumbers: string[] | undefined

  @IsString()
  @IsOptional()
  mailAddress: string | undefined

  @IsNumber()
  @IsNotEmpty()
  priority: number | undefined

  @IsBoolean()
  @IsNotEmpty()
  active: boolean | undefined
}

export class SaveLaboratory {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsArray()
  @IsString({ each: true })
  @ArrayNotEmpty()
  types: string[] | undefined

  @IsArray()
  @IsNumber({}, { each: true })
  @ArrayNotEmpty()
  regionIds: number[] | undefined

  @IsString()
  @IsNotEmpty()
  contactFirstname: string

  @IsString()
  @IsNotEmpty()
  contactLastname: string

  @IsString()
  @IsNotEmpty()
  @Matches(EMAIL_REGEX)
  contactEmail: string

  @IsString()
  @IsNotEmpty()
  @Matches(PHONE_REGEX)
  contactPhone: string

  @IsInt()
  @IsPositive()
  contactLanguageId: number
}
