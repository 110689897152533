<ng-template [ngIf]="open">
  <div class="backdrop" (click)="hide()"></div>
  <div class="ao-menu-overlay" [ngClass]="{ large: large }">
    <div class="ao-menu-overlay-details">
      <div class="ao-menu-overlay-details-header">
        <ng-content select="[header]" style="display: inline"> </ng-content>
      </div>
      <div matRipple matRippleUnbounded="false" matRippleCentered="false" class="ao-menu-overlay-details-animation">
        <mat-icon (click)="hide()" class="ao-menu-overlay-details-close" svgIcon="close-cross"></mat-icon>
      </div>
      <hr size="1" />
      <ng-content select="[body]"> </ng-content>
    </div>
  </div>
</ng-template>
