export enum ScoutingSubType {
  yieldEstimation = 'yield estimate',
  environment = 'environment',
  flowering = 'flowering',
  growthStage = 'growth stage',
  weatherDamage = 'weather damage',
  qualityDefect = 'quality',
  foreignMaterial = 'foreign material',
  weed = 'weed',
  insect = 'insects',
  disease = 'disease',
}
export enum ScoutingTypeEnum {
  OBSERVATION = 'observation',
  CONDITIONS = 'conditions',
  MATURITY = 'maturity',
}
