import { environment } from '@front-app-environments/environment'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  constructor(private http: HttpClient) {}

  public importData(entityName: string, file: File): Observable<void> {
    const formData: FormData = new FormData()
    formData.append('file', file)
    formData.append('entityName', entityName)

    return this.http.post<void>(`${environment.apiUrl}${environment.import}`, formData)
  }
}
