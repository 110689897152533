<div class="map-location">
  <div id="map" class="map-location-content" #mapcontainer></div>

  <div class="map-location-actions" align="center">
    <button color="accent" mat-flat-button (click)="setLocation()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </div>
</div>
