import 'reflect-metadata'
import { plainToInstance } from 'class-transformer'
import { IsNotEmpty, IsNumber, IsOptional, IsString, Max, Min, Validate, validateSync } from 'class-validator'
import { IsDecimalNumber } from '@agroone/helpers'

export class SaveHypothesis {
  public static build(raw: any) {
    return plainToInstance(SaveHypothesis, raw)
  }

  public static validate(raw: any) {
    const saveHypothesis = this.build(raw)
    return validateSync(saveHypothesis)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  hypothesisType: string

  @IsString()
  @IsNotEmpty()
  region: string

  @IsString()
  @IsNotEmpty()
  businessUnit: string

  @IsNumber()
  @IsNotEmpty()
  growerId: number

  @IsString()
  @IsOptional()
  growerName: string

  @IsString()
  @IsNotEmpty()
  productionArea: string

  @IsString()
  @IsNotEmpty()
  cropType: string

  @IsString()
  @IsNotEmpty()
  rawMaterialProcessed: string

  @IsString()
  @IsNotEmpty()
  variety: string

  @IsString()
  @IsNotEmpty()
  fieldType: string

  @IsString()
  @IsNotEmpty()
  growingSystem: string

  @IsNumber()
  @IsNotEmpty()
  @Min(new Date().getFullYear() - 1)
  @Max(new Date().getFullYear() + 1)
  year: number

  @IsNumber()
  @IsNotEmpty()
  @Min(1)
  @Max(53)
  week: number

  @IsNumber()
  @IsOptional()
  sowingCluster: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  firstCutPercentage: number

  @IsNumber()
  @IsOptional()
  landPreparation: number

  @IsNumber()
  @IsOptional()
  firstCutCycle: number

  @IsNumber()
  @IsOptional()
  secondCutCycle: number

  @IsNumber()
  @IsOptional()
  thirdCutCycle: number

  @IsNumber()
  @IsOptional()
  forthCutCycle: number

  @IsNumber()
  @IsOptional()
  fifthCutCycle: number

  @IsNumber()
  @IsOptional()
  sixthCutCycle: number

  @IsNumber()
  @IsOptional()
  seventhCutCycle: number

  @IsNumber()
  @IsOptional()
  securityRate: number

  @IsNumber()
  @IsOptional()
  firstHarvestRate: number

  @IsNumber()
  @IsOptional()
  secondHarvestRate: number

  @IsNumber()
  @IsOptional()
  thirdHarvestRate: number

  @IsNumber()
  @IsOptional()
  forthHarvestRate: number

  @IsNumber()
  @IsOptional()
  fifthHarvestRate: number

  @IsNumber()
  @IsOptional()
  sixthHarvestRate: number

  @IsNumber()
  @IsOptional()
  seventhHarvestRate: number

  @IsNumber()
  @IsOptional()
  forecastFirstHarvestRate: number

  @IsNumber()
  @IsOptional()
  forecastSecondHarvestRate: number

  @IsNumber()
  @IsOptional()
  forecastThirdHarvestRate: number

  @IsNumber()
  @IsOptional()
  forecastForthHarvestRate: number

  @IsNumber()
  @IsOptional()
  forecastFifthHarvestRate: number

  @IsNumber()
  @IsOptional()
  forecastSixthHarvestRate: number

  @IsNumber()
  @IsOptional()
  forecastSeventhHarvestRate: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  density: number

  @IsString()
  @IsOptional()
  densityUnit: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  averageWeight: number

  @IsString()
  @IsOptional()
  averageWeightUnit: string

  @IsString()
  @IsNotEmpty()
  userId: string

  @IsNumber()
  @IsOptional()
  distributionRate: number

  @IsString()
  @IsNotEmpty()
  campaign: string
}
