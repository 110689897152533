import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { MatBadgeModule } from '@angular/material/badge'
import { MatRippleModule } from '@angular/material/core'
import { ConstantModule } from '../constant/constant.module'
import { ScoutingSummaryComponent } from './scouting-summary.component'
import { RecommendationSummaryComponent } from './recommendation-summary.component'
import { InterventionSummaryComponent } from './intervention-summary.component'
import { SampleSummaryComponent } from './sample-summary.component'
import { DateWeekNumberModule } from '../date-week-number/date-week-number.module'
import { PreharvestControlPipe } from './pipes/preharvest-control.pipe'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { LaboratoryNamePipe } from './pipes/laboratory-name'
import { NutrientsModule } from '../nutrients/nutrients.module'
import { AnalysistypeNamePipe } from './pipes/analysistype-name'
import { DownloadBoundariesComponent } from '../download-boundaries'

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    ConstantModule,
    TranslateModule,
    DateWeekNumberModule,
    NutrientsModule,
    DownloadBoundariesComponent,
  ],
  declarations: [
    ScoutingSummaryComponent,
    RecommendationSummaryComponent,
    InterventionSummaryComponent,
    SampleSummaryComponent,
    PreharvestControlPipe,
    LaboratoryNamePipe,
    AnalysistypeNamePipe,
  ],
  exports: [
    ScoutingSummaryComponent,
    RecommendationSummaryComponent,
    InterventionSummaryComponent,
    SampleSummaryComponent,
    PreharvestControlPipe,
  ],
})
export class SummaryModule {}
