import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FileService } from '@agroone-app/core/file/file.service'
import { MapOptions } from '@agroone-app/shared/dialog/models/mapOptions.model'
import { Colors, Crop, FeatureCollection } from '@agroone/entities'
import { MapService } from '@agroone-app/shared/map/lib/map.service'
import { UntilDestroy } from '@ngneat/until-destroy'
import { BoundaryOfInterest, Feature, SharedUserService } from '@agroone-front/shared'
import { layers } from '@agroone-app/scene/crop-management/geolocation/models/layer.enum'

@UntilDestroy()
@Component({
  selector: 'app-map-draw',
  templateUrl: './map-draw.component.html',
  styleUrls: ['./map-draw.component.sass'],
})
export class MapDrawComponent implements OnInit {
  @Input() data: MapOptions

  @Output() closeDialog = new EventEmitter()

  @ViewChild('mapcontainer') mapContainer: ElementRef

  public readonly cropColor = Colors.CROPS
  public readonly cropOtherGrowersColor = Colors.OTHER_CROPS
  public readonly LAYERS = layers

  constructor(
    private readonly _mapService: MapService,
    private readonly _fileService: FileService,
    private readonly _userService: SharedUserService
  ) {}

  async ngOnInit() {
    await this._initMap(this.data)
  }

  public async setBoundary(): Promise<void> {
    const boundary = this._mapService.getBoundary()
    this.closeDialog.emit({ boundary, event: 'close' })
  }

  public async import(event: Event): Promise<void> {
    const fileList: FileList = (event?.target as HTMLInputElement)?.files
    if (fileList) {
      // Read the files (zip, shp or kml)
      const files = await this._fileService.filesToKmlOrShapefile(Array.from(fileList))

      // Extract the feature collection
      const collection = await this._mapService.extract(files.shp[0] ?? files.kml[0])

      this._mapService.resetMap()

      // Re-init the map with the new boundary
      await this._initMap({
        ...this.data,
        boundary: collection as FeatureCollection,
      })
    }
  }

  private async _initMap(data: MapOptions): Promise<void> {
    if (data.location || data.boundary) {
      await this._mapService.initMap('map', this.mapContainer, data.location, null, {
        color: data.style,
        drawable: data.displayMode === 'draw',
        stencil: data.stencil,
      })
    } else {
      const address = !data.address || data.address === '' ? this._userService.currentUser.regionName : data.address
      await this._mapService.initMapByAddress('map', this.mapContainer, address, {
        color: data.style,
        drawable: data.displayMode === 'draw',
        stencil: data.stencil,
      })
    }
    await this._initOtherCropsBoundaries()
  }

  private async _initOtherCropsBoundaries() {
    const data: MapOptions = this.data
    // Draw the location marker
    this._mapService.drawDefaultMarker(Number(data.location.latitude), Number(data.location.longitude))
    // Create markers and boundaries for crops
    this._initLayers()
    // Start watching for changes in displayable data
    this._updateLayers()
    await this._mapService.createEditableBoundaries(this.data.boundary)
  }

  private _initLayers() {
    this._mapService.createBoundaries(layers.CROPS_BOUNDARIES, null, {
      style: this.cropColor,
      boundaries: [],
    })
    this._mapService.createBoundaries(
      layers.CROPS_BOUNDARIES_OTHER_GROWERS,
      null,
      {
        style: this.cropOtherGrowersColor,
        boundaries: [],
      },
      true
    )
  }

  private _getFeatures(crops: Crop[]): Feature {
    const features: Feature = { boundaries: [], points: [] }
    for (const crop of crops) {
      if (!this.data?.entitiesIds?.includes(crop.id)) {
        if (crop.boundary) {
          features.boundaries.push(new BoundaryOfInterest(crop.id, crop.boundary))
        }
      }
    }
    return features
  }

  private _updateLayers() {
    const data: MapOptions = this.data

    if (data?.crops) {
      const cropsGrowerFeatures: Feature = this._getFeatures(
        data.crops?.filter((crop) => crop.growerId === data.growerId)
      )
      const cropsOtherGrowersFeatures: Feature = this._getFeatures(
        data.crops?.filter((crop) => crop.growerId !== data.growerId)
      )

      this._mapService.updateBoundaries(layers.CROPS_BOUNDARIES, this.cropColor, cropsGrowerFeatures.boundaries)
      this._mapService.updateBoundaries(
        layers.CROPS_BOUNDARIES_OTHER_GROWERS,
        this.cropOtherGrowersColor,
        cropsOtherGrowersFeatures.boundaries
      )
    }
  }
}
