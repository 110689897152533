import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { from, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { PdfResponseDto, Recommendation } from '@agroone/entities'
import { NetworkService } from '@agroone-front/shared'

@Injectable({
  providedIn: 'root',
})
export class RecommendationService {
  private recommendationUrl: string = `${environment.apiUrl}${environment.recommendations}`

  constructor(private http: HttpClient, private network: NetworkService) {}

  /**
   * Get all recommendations by cropId
   *
   * @param cropId
   * @returns
   */
  public getByCropId(cropId: number): Observable<Recommendation[]> {
    return from(
      this.network.getAllFromPaginated<Recommendation>(`${this.recommendationUrl}?filter=cropId=${cropId}`, {
        params: {
          noPagination: true,
        },
      })
    )
  }

  public add(item: Recommendation): Observable<Recommendation> {
    return this.http.post<Recommendation>(this.recommendationUrl, item)
  }

  public getPdfForRecommendationId(id: number, cropId: number): Observable<PdfResponseDto> {
    return this.http.get<PdfResponseDto>(`${this.recommendationUrl}/${id}/${cropId}/pdf`)
  }

  /**
   * Remove Recommendation by id
   *
   * @param id
   * @returns
   */
  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.recommendationUrl}/${id}`)
  }
}
