import { environment } from '@front-app-environments/environment'
import { SharedUserService } from '@agroone-front/shared'
import { Grower, MsSqlPaginatedData, Supplier, Technician, UserDto, UserPreferenceDto } from '@agroone/entities'
import { dateToISO8601 } from '@agroone/helpers'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, map } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private readonly _userApiUrl: string = `${environment.apiUrl}${environment.users}`

  constructor(private readonly _http: HttpClient, private readonly _userService: SharedUserService) {}

  public getUserByEmail(userEmail: string): Observable<UserDto> {
    const params: { email: string } = { email: userEmail }
    return this._http.get<UserDto>(`${this._userApiUrl}/email`, {
      params,
    })
  }

  public getTechnicians(): Observable<Technician[]> {
    return this._http.get<Technician[]>(`${environment.apiUrl}${environment.technicians}`)
  }

  public getAllUsers(params: {
    pageLength?: number
    page?: number
    noPagination?: boolean
    lite?: boolean
    search?: string
    fetchAvailableRegion?: boolean
  }): Observable<MsSqlPaginatedData<UserDto>> {
    return this._http.get<MsSqlPaginatedData<UserDto>>(this._userApiUrl, {
      params,
    })
  }

  public updateUserPreference(user: UserPreferenceDto): Observable<UserDto> {
    return this._http.put<UserDto>(`${this._userApiUrl}/me`, user)
  }

  public update(user: UserDto | Partial<UserDto>): Observable<UserDto> {
    if (!user.createdBy) {
      user.updatedBy = this._userService.currentUser.email
      user.updatedDate = dateToISO8601(new Date())
    }
    return this._http.put<UserDto>(this._userApiUrl, user).pipe(
      map((returnedUser: UserDto) => {
        const newUser: UserDto = new UserDto(returnedUser)
        return newUser
      })
    )
  }

  public delete(userId: number): Observable<void> {
    return this._http.delete(`${this._userApiUrl}/${userId}`) as Observable<any>
  }

  public getSuppliers(region: string): Observable<Supplier[]> {
    return this._http
      .get<MsSqlPaginatedData<Supplier>>(
        `${environment.apiUrl}${environment.suppliers}?region=${encodeURIComponent(region)}&filter=active=1`
      )
      .pipe(
        map((suppliers: MsSqlPaginatedData<Supplier>) => {
          if (suppliers?.data?.length) {
            return suppliers.data.map((s) => new Supplier(s))
          }
          return []
        })
      )
  }

  public getGrowers(region: string): Observable<Grower[]> {
    return this._http
      .get<MsSqlPaginatedData<Grower>>(
        `${environment.apiUrl}${environment.growers}?region=${encodeURIComponent(region)}&filter=active=1`
      )
      .pipe(map((growers: MsSqlPaginatedData<Grower>) => growers.data ?? []))
  }
}
