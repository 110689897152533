import { createFeatureSelector, createSelector } from '@ngrx/store'
import { FieldState, fieldAdapter } from '../reducers/field.reducer'
import { Features } from '@agroone-app/state/features.enum'

export const fieldsSelector = createFeatureSelector<FieldState>(Features.FIELD)

export const selectFields = createSelector(fieldsSelector, fieldAdapter?.getSelectors()?.selectAll)

export const selectFieldById = (fieldId: number) =>
  createSelector(selectFields, (f) => f.filter((field) => field?.id === fieldId)[0])

export const selectFieldsByGrowerId = (growerId) =>
  createSelector(selectFields, (f) => f.filter((fields) => fields?.growerId === growerId) || [])
