import 'reflect-metadata'

import { Type, plainToInstance } from 'class-transformer'
import {
  IsString,
  IsOptional,
  IsArray,
  IsNumber,
  ValidateNested,
  IsNotEmpty,
  Min,
  Max,
  IsBoolean,
  ArrayNotEmpty,
} from 'class-validator'
import { Contact } from './contact'

export class SaveSupplierContact {
  @IsNumber()
  @IsOptional()
  id: number | undefined

  @IsString()
  @IsNotEmpty()
  firstName: string | undefined

  @IsString()
  @IsNotEmpty()
  lastName: string | undefined

  @IsNumber()
  @IsNotEmpty()
  languageId: number | undefined

  @IsArray()
  @ArrayNotEmpty()
  phoneNumbers: string[] | undefined

  @IsString()
  @IsOptional()
  emailAddress: string | undefined

  @IsNumber()
  @IsNotEmpty()
  priority: number | undefined

  @IsBoolean()
  @IsNotEmpty()
  active: boolean | undefined
}
export class SaveSupplier {
  public static build(raw: any) {
    return plainToInstance(SaveSupplier, raw)
  }

  @IsNumber()
  @IsOptional()
  id: number | undefined

  @IsNumber()
  @Min(0)
  @Max(1)
  @IsOptional()
  active?: number

  @IsString()
  @IsNotEmpty()
  supplierName: string | undefined

  @IsString()
  @IsOptional()
  certificate?: string

  @IsString()
  @IsNotEmpty()
  supplierType: string | undefined

  @IsArray()
  @IsNumber({}, { each: true })
  @ArrayNotEmpty()
  regionIds: number[] | undefined

  @IsArray()
  @IsOptional()
  cropTypes?: string[]

  @IsArray()
  @IsOptional()
  workOrderTypes?: string[]

  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => SaveSupplierContact)
  contacts: Contact[] | undefined
}
