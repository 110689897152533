export class Notification {
  id?: number
  contactType: string
  sendEmail?: boolean
  sendSms?: boolean

  constructor(notification?: Notification) {
    if (notification?.id) {
      this.id = notification?.id
    }
    this.contactType = notification?.contactType ?? null
    this.sendEmail = notification?.sendEmail
    this.sendSms = notification?.sendSms
  }
}
