import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core'
import { BaseComponent } from '@agroone-app/core/base.component'
import { MapOptions } from '@agroone-app/shared/dialog/models/mapOptions.model'
import { MapService } from '@agroone-app/shared/map/lib/map.service'
import { SharedUserService } from '@agroone-front/shared'

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.sass'],
})
export class MapViewComponent extends BaseComponent implements OnInit {
  @Input() data: MapOptions

  @ViewChild('mapcontainer') mapContainer: ElementRef

  constructor(
    private mapService: MapService,
    public override userService: SharedUserService,
    private injector: Injector
  ) {
    super(injector)
  }

  ngOnInit() {
    this.initMap(this.data)
  }

  private initMap(data: MapOptions) {
    if (data.location || data.boundary) {
      this.mapService.initMap('map', this.mapContainer, data.location, data.boundary, {
        color: data.style,
      })
    } else {
      const address = !data.address || data.address === '' ? this.userService.currentUser.regionName : data.address

      this.mapService.initMapByAddress('map', this.mapContainer, address, {
        color: data.style,
      })
    }
  }
}
