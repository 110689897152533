import { Truck } from './truck'
import { Stock } from './stock'
import { CroptypePlanningSettings } from './croptype-plannings'
import { HarvestDirection, HarvestTeam } from './harvest'
import { Factory } from './factory'

export class WeekFactor {
  id?: number
  interventionWeek: number | undefined
  maturityWeek: number | undefined
  weekFactor: number | undefined
  region?: string | undefined
  regionId: number | undefined
  croptype?: string | undefined
  croptypeId: number | undefined
}

export class TargetLeave {
  id?: number
  targetLeaves: number | undefined
  region?: string
  regionId: number | undefined
  croptype?: string | undefined
  croptypeId: number | undefined
}

export class StandCountsFactor {
  id?: number
  bedWidth: number | undefined
  bedWidthUnit: string | undefined
  seedLines: number | undefined
  standCountsFactor: number | undefined
  region?: string
  regionId: number | undefined
  croptype?: string
  croptypeId: number | undefined
}

export class ForecastDay {
  id?: number
  numberOfDays: number | undefined
  region?: string
  regionId: number | undefined
}

export class TruckSpeed {
  id?: number
  speed: number | undefined
  speedUnit: string | undefined
  region?: string
  regionId: number | undefined
}

export class FactoryConsumption {
  id?: number
  startingHour: string | undefined
  region?: string
  regionId: number | undefined
}

export class CroptypeColor {
  id?: number
  color: string | undefined
  croptype: string | undefined
  region: string | undefined

  constructor(croptypeColor?: CroptypeColor) {
    this.id = croptypeColor?.id
    this.color = croptypeColor?.color
    this.croptype = croptypeColor?.croptype
    this.region = croptypeColor?.region
  }
}

export class CroptypeColorDAO {
  id?: number
  color: string | undefined
  croptypeId: number | undefined
  regionId: number | undefined

  constructor(croptypeColor?: CroptypeColorDAO) {
    if (croptypeColor?.id) {
      this.id = croptypeColor.id
    }
    this.color = croptypeColor?.color
    this.croptypeId = croptypeColor?.croptypeId
    this.regionId = croptypeColor?.regionId
  }
}

export class SettingsDAO {
  [key: string]: any

  id: string
  metadata: string
}

export class Setting {
  // the settings name and as value the array of the values corresponding to this settings name
  [key: string]: any
}

export class Settings {
  // the settings name and as value the array of the values corresponding to this settings name
  [settingsName: string]: Setting[]
}

export enum SortingRuleOrder {
  DESCENDING = 'desc',
  ASCENDING = 'asc',
}

export enum SortingRuleType {
  DATE = 'date',
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
}

export class SortingRule {
  propertyName: string
  type: SortingRuleType
  order: SortingRuleOrder
}

export class SaveSettings {
  id: string
  region: string
  keys: { key: string; value: any }[]
}

export class ISettingsConfig {
  trucks: Truck[]
  destinations: Factory[]
  stocks: Stock[]
  croptypePlannings: CroptypePlanningSettings[]
  factoryConsumptions: FactoryConsumption[]
  harvestTeams: HarvestTeam[]
  directions: HarvestDirection[]
  croptypeColors: CroptypeColor[]
  offersDisplayList: {
    controlCodes: string[]
    cropType: string
    region: string
  }[][]
  truckSpeeds: TruckSpeed[]
}
