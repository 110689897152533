import { Precision } from '@agroone/entities'
import { Component, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-location-mode',
  templateUrl: './location-mode.component.html',
  styleUrls: ['./location-mode.component.sass'],
})
export class LocationModeComponent {
  public precision: typeof Precision = Precision

  constructor(
    public dialogRef: MatDialogRef<LocationModeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      precision?: Precision
    }
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close()
    })
  }

  setAutomaticLocation(): void {
    this.dialogRef.close(true)
  }

  setManualLocation(): void {
    this.dialogRef.close(false)
  }

  public close(): void {
    this.dialogRef.close(null)
  }
}
