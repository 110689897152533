export enum SeedingRateUnit {
  KG_HA = 'kg/ha',
  KG_AC = 'kg/ac',
  PLANTS_HA = 'plants/ha',
  PLANTS_M2 = 'plants/m2',
  NB_SEEDS_M2 = 'nb seeds/m2',
  PLANTS_AC = 'plants/ac',
  NB_SEEDS_AC = 'nb seeds/ac',
  LB_AC = 'lb/ac',
  NB_SEEDS_HA = 'nb seeds/ha',
  G_HA = 'g/ha',
  SEEDS_M = 'seeds/m',
}
