export enum RateUnits {
  L = 'l',
  ML = 'ml',
  MG = 'mg',
  G = 'g',
  KG = 'kg',
  LB = 'lb',
  GAL = 'gal',
  QT = 'qt',
  OZ = 'oz',
  PNT = 'pnt',
  'US GAL' = 'US gal',
  'US PNT' = 'US pnt',
}
