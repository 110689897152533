import { Component } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-file-mode',
  templateUrl: './file-mode.component.html',
  styleUrls: ['./file-mode.component.sass'],
})
export class FileModeComponent {
  constructor(public dialogRef: MatDialogRef<FileModeComponent>) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close()
    })
  }

  setDownloadFile(): void {
    this.dialogRef.close(true)
  }

  setUploadFile(): void {
    this.dialogRef.close(false)
  }

  public close(): void {
    this.dialogRef.close(null)
  }
}
