import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'

export class SaveGesagField {
  @IsString()
  @IsNotEmpty()
  public id: string // Code parcelle producteur

  @IsString()
  @IsNotEmpty()
  public name: string // Nom parcelle producteur

  @IsNumber()
  @IsNotEmpty()
  public area: number // Surface parcelle

  @IsString()
  @IsNotEmpty()
  public areaUnit: string // Surface parcelle unit

  @IsString()
  @IsNotEmpty()
  public growerCode: string // Agreo Code producter

  @IsString()
  @IsNotEmpty()
  public farmName: string

  @IsString()
  @IsNotEmpty()
  public type: string

  @IsString()
  @IsOptional()
  public street?: string // Adresse parcelle

  @IsString()
  @IsOptional()
  public zip?: string // Code postal parcelle

  @IsString()
  @IsOptional()
  public city?: string // Commune Parcelle

  @IsString()
  @IsOptional()
  public country?: string // Pays Parcelle

  @IsString()
  @IsOptional()
  public region?: string

  @IsString()
  @IsOptional()
  public location?: string // Location Degree, Minutes, Seconds
}
