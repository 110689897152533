<app-menu-overlay #menu>
  <div header>
    <div class="crop-details-header">
      {{ 'SCOUTING.DETAILS_CROP.TITLE' | translate }}
      <ng-container *isOffline="false">
        <mat-icon *ngIf="crop?.location" (click)="openMaps(crop.location)" svgIcon="geolocation" />
      </ng-container>
    </div>
  </div>
  <div body>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.GROWER' | translate }}
    </div>
    <div class="crop-details-text">
      {{ crop.growerName }}
      <ng-container *ngIf="!isOffer">
        <button *isOffline="false" mat-icon-button [routerLink]="['/crop-management/grower', crop.growerId, 'field']">
          <mat-icon svgIcon="launch"></mat-icon>
        </button>
      </ng-container>
    </div>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.FIELD' | translate }}
    </div>
    <div class="crop-details-text">
      {{ crop.fieldName }}
      <ng-container *ngIf="!isOffer">
        <button
          mat-icon-button
          *isOffline="false"
          [routerLink]="['/crop-management/grower', crop.growerId, 'field']"
          [queryParams]="{ filter: crop.fieldName }"
        >
          <mat-icon svgIcon="launch"></mat-icon>
        </button>
      </ng-container>
    </div>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.FIELDAREA' | translate }}
    </div>
    <div class="crop-details-text">
      {{ crop.fieldArea }}
      {{ crop.fieldAreaUnit | translateConstant : constantService.areaUnit }}
    </div>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.VARIETY' | translate }}
    </div>
    <div class="crop-details-text">
      {{ crop.varietyName }}
    </div>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.TYPE' | translate }}
    </div>
    <div class="crop-details-text">
      {{ crop.cropType | translateConstant : constantService.croptypes }}
      <ng-container *ngIf="!isOffer">
        <button
          mat-icon-button
          *isOffline="false"
          [routerLink]="['/crop-management/crop']"
          [queryParams]="{ filter: crop.cropType }"
        >
          <mat-icon svgIcon="launch"></mat-icon>
        </button>
      </ng-container>
    </div>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.PLANTED' | translate }}
    </div>
    <div class="crop-details-text">
      {{ crop.plantedArea }}
      {{ crop.areaUnit | translateConstant : constantService.areaUnit }}
    </div>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.DATE' | translate }}
    </div>
    <div class="crop-details-text" *ngIf="crop.plantedDate">
      {{ crop.plantedDate | date : dateFormat }}
    </div>
    <div class="crop-details-title">
      {{ 'SCOUTING.DETAILS_CROP.TECHNICIAN' | translate }}
    </div>
    <div class="crop-details-text">
      {{ crop?.technician }}
    </div>
    <ng-container *ngIf="!isOffer">
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.PLANTED_ID' | translate }}
      </div>
      <div class="crop-details-text" *ngIf="crop?.planting?.plantingId">
        {{ crop?.planting.plantingId }}
      </div>
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.SEEDING_RATE' | translate }}
      </div>
      <div class="crop-details-text" *ngIf="crop?.planting?.seedingRateUnit && crop?.planting?.seedingRate">
        {{ crop?.planting.seedingRate }}
        {{ crop?.planting.seedingRateUnit | translateConstant : constantService.seedingRateUnit }}
      </div>
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.SOIL_CONDITIONS' | translate }}
      </div>
      <div class="crop-details-text" *ngIf="crop?.planting?.soilConditions">
        {{ crop?.planting.soilConditions | translateConstant : constantService.soilConditions }}
      </div>
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.SEED_LINES' | translate }}
      </div>
      <div
        class="crop-details-text"
        *ngIf="crop?.planting?.seedLines && crop?.planting?.beds && crop?.planting?.bedWidth"
      >
        {{ crop?.planting.seedLines }} / {{ crop?.planting.beds }} /
        {{ crop?.planting.bedWidth }}
      </div>
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.WEATHER_CONDITIONS' | translate }}
      </div>
      <div class="crop-details-text" *ngIf="crop?.planting?.weatherConditions">
        {{ crop?.planting.weatherConditions | translateConstant : constantService.weatherConditions }}
      </div>
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.NOTE' | translate }}
      </div>
      <div class="crop-details-text" *ngIf="crop?.notes">
        {{ crop?.notes }}
      </div>
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.PLANTING_NOTE' | translate }}
      </div>
      <div class="crop-details-text" *ngIf="crop?.planting?.plantingNote">
        {{ crop?.planting.plantingNote }}
      </div>
      <div class="crop-details-title">
        {{ 'SCOUTING.DETAILS_CROP.FORECAST_HARVEST_DATE' | translate }}
      </div>
      <div class="crop-details-text" *ngIf="crop?.forecastHarvestDateAsDate">
        {{ crop?.forecastHarvestDateAsDate | date : dateFormat }}
      </div>
    </ng-container>
  </div>
</app-menu-overlay>
