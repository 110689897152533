import { createAction } from '@ngrx/store'
import { ActionCreator, TypedAction } from '@ngrx/store/src/models'

export enum LoadingActionTypes {
  START_LOADING = '[Loading] Start Loading',
  STOP_LOADING = '[Loading] Stop Loading',
  START_LOADING_CROPS = '[Loading] Start Loading Crops',
  STOP_LOADING_CROPS = '[Loading] Stop Loading Crops',
  START_LOADING_GROWERS = '[Loading] Start Loading Growers',
  STOP_LOADING_GROWERS = '[Loading] Stop Loading Growers',
  START_LOADING_VARIETIES = '[Loading] Start Loading Varieties',
  STOP_LOADING_VARIETIES = '[Loading] Stop Loading Varieties',
  START_LOADING_SPECIFICATIONS = '[Loading] Start Loading Specifications',
  STOP_LOADING_SPECIFICATIONS = '[Loading] Stop Loading Specifications',
  CLEAR_LOADING = '[Loading] Clear Loading',
}

export const startLoading: ActionCreator<
  LoadingActionTypes.START_LOADING,
  () => TypedAction<LoadingActionTypes.START_LOADING>
> = createAction(LoadingActionTypes.START_LOADING)
export const stopLoading: ActionCreator<
  LoadingActionTypes.STOP_LOADING,
  () => TypedAction<LoadingActionTypes.STOP_LOADING>
> = createAction(LoadingActionTypes.STOP_LOADING)
export const startLoadingGrowers: ActionCreator<
  LoadingActionTypes.START_LOADING_GROWERS,
  () => TypedAction<LoadingActionTypes.START_LOADING_GROWERS>
> = createAction(LoadingActionTypes.START_LOADING_GROWERS)
export const stopLoadingGrowers: ActionCreator<
  LoadingActionTypes.STOP_LOADING_GROWERS,
  () => TypedAction<LoadingActionTypes.STOP_LOADING_GROWERS>
> = createAction(LoadingActionTypes.STOP_LOADING_GROWERS)
export const startLoadingCrops: ActionCreator<
  LoadingActionTypes.START_LOADING_CROPS,
  () => TypedAction<LoadingActionTypes.START_LOADING_CROPS>
> = createAction(LoadingActionTypes.START_LOADING_CROPS)
export const stopLoadingCrops: ActionCreator<
  LoadingActionTypes.STOP_LOADING_CROPS,
  () => TypedAction<LoadingActionTypes.STOP_LOADING_CROPS>
> = createAction(LoadingActionTypes.STOP_LOADING_CROPS)
export const startLoadingVarieties: ActionCreator<
  LoadingActionTypes.START_LOADING_VARIETIES,
  () => TypedAction<LoadingActionTypes.START_LOADING_VARIETIES>
> = createAction(LoadingActionTypes.START_LOADING_VARIETIES)
export const stopLoadingVarieties: ActionCreator<
  LoadingActionTypes.STOP_LOADING_VARIETIES,
  () => TypedAction<LoadingActionTypes.STOP_LOADING_VARIETIES>
> = createAction(LoadingActionTypes.STOP_LOADING_VARIETIES)
export const startLoadingSpecifications: ActionCreator<
  LoadingActionTypes.START_LOADING_SPECIFICATIONS,
  () => TypedAction<LoadingActionTypes.START_LOADING_SPECIFICATIONS>
> = createAction(LoadingActionTypes.START_LOADING_SPECIFICATIONS)
export const stopLoadingSpecifications: ActionCreator<
  LoadingActionTypes.STOP_LOADING_SPECIFICATIONS,
  () => TypedAction<LoadingActionTypes.STOP_LOADING_SPECIFICATIONS>
> = createAction(LoadingActionTypes.STOP_LOADING_SPECIFICATIONS)
export const clearLoading: ActionCreator<
  LoadingActionTypes.CLEAR_LOADING,
  () => TypedAction<LoadingActionTypes.CLEAR_LOADING>
> = createAction(LoadingActionTypes.CLEAR_LOADING)
