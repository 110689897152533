import { BehaviorSubject, map, Observable, retry } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { UserDto } from '@agroone/entities'
import { LoggerService } from './logger.service'
import { UserDefaultDateFormat, UserServiceModel } from '../models'

export abstract class SharedUserService implements UserServiceModel {
  abstract userApiUrl: string
  protected userSubject: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(null)

  public user: Observable<UserDto>
  public userCanUseNotificationFeature?: boolean

  constructor(protected http: HttpClient, protected logger: LoggerService) {
    this.user = this.userSubject.asObservable()
  }

  public set currentUser(user: UserDto) {
    this.userSubject.next(user)
  }
  public get currentUser(): UserDto {
    return this.userSubject.getValue()
  }

  private readonly _defaultDateUserSubject: BehaviorSubject<UserDefaultDateFormat> =
    new BehaviorSubject<UserDefaultDateFormat>(null)

  private readonly _defaultDateHourUserSubject: BehaviorSubject<UserDefaultDateFormat> =
    new BehaviorSubject<UserDefaultDateFormat>(null)

  public set currentUserDateFormat(userDefaultDateFormat: UserDefaultDateFormat) {
    this._defaultDateUserSubject.next(userDefaultDateFormat)
  }

  public get currentUserDateFormat(): UserDefaultDateFormat {
    return this._defaultDateUserSubject.getValue()
  }

  public set currentUserDateHourFormat(userDefaultDateFormat: UserDefaultDateFormat) {
    this._defaultDateHourUserSubject.next(userDefaultDateFormat)
  }

  public get currentUserDateHourFormat(): UserDefaultDateFormat {
    return this._defaultDateHourUserSubject.getValue()
  }

  public abstract init(): Observable<UserDto>

  protected getCurrentUser(): Observable<UserDto> {
    return this.http.get<UserDto>(`${this.userApiUrl}/me`).pipe(
      retry(1),
      map((user: UserDto) => {
        user.availableRegions.sort((x, y) => x.localeCompare(y))
        const newUser: UserDto = new UserDto(user)
        if (user) {
          this.userSubject.next(newUser)
          this.logger.log('User Initialized')
        }
        return newUser
      })
    )
  }
}
