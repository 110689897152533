import { Pipe, PipeTransform } from '@angular/core'
import { SharedConstantService } from '../services'
import { ConstantLite } from '@agroone/entities'

@Pipe({
  name: 'translateConstant',
  standalone: true,
})
export class TranslateConstantPipe implements PipeTransform {
  constructor(private constantService: SharedConstantService) {}

  transform(code: string, constants: ConstantLite[]): string {
    return this.constantService.translate(code, constants)
  }
}
