export enum layers {
  CROPS = 'crops',
  CROPS_OTHER_GROWERS = 'cropsOtherGrowers',
  ITEM_SELECTED = 'itemSelected',
  ITEM_SELECTED_BOUNDARIES = 'itemSelectedBoundaries',
  FIELDS = 'fields',
  FIELDS_BOUNDARIES = 'fieldsBoundaries',
  CROPS_BOUNDARIES = 'cropsBoundaries',
  CROPS_BOUNDARIES_OTHER_GROWERS = 'cropsBoundariesOtherGrowers',
}
