import { ActionCreator, ReducerTypes, on } from '@ngrx/store'
import { loadActivities, loadActivitiesSuccess, loadActivitiesFailure } from '../actions/activity.actions'
import { CropDetailActivities, CropDetailState, cropDetailAdapter } from './crop-detail.reducer'

export const activityOns: ReducerTypes<CropDetailState, ActionCreator[]>[] = [
  on(loadActivities, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadActivitiesSuccess, (state, { cropId, activities }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        activities: [...activities],
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(loadActivitiesFailure, (state) => ({
    ...state,
  })),
]
