<!-- redirect to harvest-->

<div class="offer">
  <button class="offer-button" (click)="add()">
    <span class="offer-button-span">+</span>
  </button>

  <ng-container *ngFor="let offer of data.offers">
    <div class="offer-infos" (click)="edit(offer?.id)">
      <div class="offer-infos-area">
        {{ offer?.areaToHarvest }}{{ offer?.areaUnit | translateConstant : constantService.areaUnit }} |
        {{ offer?.quantityToHarvest }}{{ offer?.yieldUnit | translateConstant : constantService.yieldUnit }}
      </div>
      <div class="offer-infos-date">
        {{ offer?.forecastHarvestDate | date : userService.currentUserDateFormat.format }}
      </div>
    </div>
  </ng-container>
</div>
