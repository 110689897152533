import { Crop, Field } from '@agroone/entities'
import { ActionCreator, on, ReducerTypes } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import {
  addCropToField,
  addField,
  addFieldFailure,
  addFieldsFailure,
  addFieldsSuccess,
  addFieldSuccess,
  clearFields,
  deleteCropFromField,
  deleteField,
  deleteFieldError,
  deleteFieldSuccess,
  loadField,
  loadFieldFailure,
  loadFields,
  loadFieldsFailure,
  loadFieldsSuccess,
  loadFieldSuccess,
  refreshField,
  updateCropInField,
  updateField,
  updateFieldFailure,
  updateFieldSuccess,
} from '../actions/field.actions'

export type FieldState = EntityState<Field>

export const fieldAdapter: EntityAdapter<Field> = createEntityAdapter<Field>()

export const initialFieldState: FieldState = fieldAdapter.getInitialState({})

export const fieldOns: ReducerTypes<FieldState, ActionCreator[]>[] = [
  on(loadFields, (state) => ({
    ...state,
  })),
  on(loadFieldsSuccess, (state, action) => fieldAdapter.setAll(action?.fields, { ...state })),
  on(loadFieldsFailure, (state) => ({
    ...state,
  })),
  on(loadField, (state) => ({
    ...state,
  })),
  on(loadFieldSuccess, (state, action) => fieldAdapter.addOne(action?.payload?.field, { ...state, loading: false })),
  on(loadFieldFailure, (state) => ({
    ...state,
  })),
  on(refreshField, (state, action) => {
    if (!action?.field) {
      return
    }
    return fieldAdapter.addOne(action.field, {
      ...state,
      message: 'Field has been added',
      error: undefined,
    })
  }),
  // CREATE
  on(addField, (state) => ({
    ...state,
  })),
  on(addFieldSuccess, (state, action) =>
    fieldAdapter.addOne(action.payload.field, {
      ...state,
      message: 'Field has been added',
      error: undefined,
    })
  ),
  on(addFieldFailure, (state) => ({
    ...state,
    error: 'Oups, Field has not  been added ...',
  })),

  on(addFieldsSuccess, (state, action) =>
    fieldAdapter.upsertMany(action.payload.fields, {
      ...state,
      message: 'Fields has been added',
      error: undefined,
    })
  ),
  on(addFieldsFailure, (state) => ({
    ...state,
    error: 'Oups, Fields has not  been added ...',
  })),
  // UPDATE
  on(updateField, (state) => ({
    ...state,
  })),
  on(updateFieldSuccess, (state, action) =>
    fieldAdapter.upsertOne(action?.payload?.field, {
      ...state,
      message: 'Field has been added',
      error: undefined,
    })
  ),
  on(updateFieldFailure, (state, action) => ({
    ...state,
    error: 'Oups, Field has not  been updated ...',
  })),

  // DELETE
  on(deleteField, (state, { fieldId }) => ({
    ...state,
    fieldId,
  })),
  on(deleteFieldSuccess, (state, action) =>
    fieldAdapter.removeOne(action.payload?.fieldId, {
      ...state,
      message: 'Field has been removed',
      error: undefined,
    })
  ),
  on(deleteFieldError, (state) => ({
    ...state,
    error: 'Oups, Field has not  been deleted ...',
  })),
  on(clearFields, () => initialFieldState),
  on(deleteCropFromField, (state, { fieldId, cropId }) => {
    const currentField: Field = state.entities[`${fieldId}`]
    if (currentField) {
      const updatedField: Omit<Field, 'updatedDateAsDate'> & { crops: Crop[] } = {
        ...currentField,
        crops: currentField.crops?.filter((crop) => crop.id !== cropId) ?? [],
      }

      return fieldAdapter.updateOne({ id: fieldId, changes: updatedField }, state)
    }

    return state
  }),
  on(addCropToField, (state, { crop, fieldId }) => {
    const currentField: Field = state.entities[`${fieldId}`]
    if (currentField) {
      const currentFieldCrops: Crop[] = currentField.crops?.length ? currentField.crops : []
      const updatedField: Omit<Field, 'updatedDateAsDate'> & { crops: Crop[] } = {
        ...currentField,
        crops: [...currentFieldCrops, crop],
      }

      return fieldAdapter.updateOne({ id: fieldId, changes: updatedField }, state)
    }

    return state
  }),
  on(updateCropInField, (state, { fieldId, crop }) => {
    const currentField: Field = state.entities[`${fieldId}`]

    if (!currentField) {
      return state
    }
    const currentFieldCrops: Crop[] = currentField?.crops ? currentField?.crops : []
    const updatedFieldCrops: Crop[] = currentFieldCrops.map((item) => {
      if (crop.id === crop.id) {
        return crop
      }
      return item
    })

    const updatedField: Omit<Field, 'updatedDateAsDate'> & { crops: Crop[] } = {
      ...currentField,
      crops: updatedFieldCrops,
    }

    return fieldAdapter.updateOne({ id: fieldId, changes: updatedField }, state)
  }),
]
