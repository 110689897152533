import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-picture-gallery',
  templateUrl: './picture-gallery.component.html',
  styleUrls: ['./picture-gallery.component.sass'],
})
export class PictureGalleryComponent implements OnInit {
  @ViewChild('image', { static: true }) image!: ElementRef<HTMLImageElement>

  public picturesUrl: string[]
  public currentPictureIndex: number = 0
  public loading: boolean = false

  constructor(
    public dialogRef: MatDialogRef<PictureGalleryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      picturesUrl: string[]
    }
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close()
    })
  }

  ngOnInit() {
    this.loading = true
    const callback = () => {
      this.loading = false
    }
    this.image.nativeElement.onload = callback
    this.image.nativeElement.onerror = callback
    this.picturesUrl = this.data.picturesUrl || []
  }

  public hasNext(): boolean {
    return this.picturesUrl.length > this.currentPictureIndex + 1
  }

  public next(): void {
    if (this.hasNext()) {
      this.loading = true
      this.currentPictureIndex++
    }
  }

  public hasPrevious(): boolean {
    return this.currentPictureIndex - 1 >= 0
  }

  public previous(): void {
    if (this.hasPrevious()) {
      this.loading = true
      this.currentPictureIndex--
    }
  }

  public close(): void {
    this.dialogRef.close()
  }
}
