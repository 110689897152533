import { createFeatureSelector, createSelector } from '@ngrx/store'
import { CropState, cropAdapter } from '../reducers/crop.reducer'
import { Features } from '@agroone-app/state/features.enum'

export const crops = createFeatureSelector<CropState>(Features.CROP)

export const selectCrops = createSelector(crops, cropAdapter?.getSelectors()?.selectAll)

export const selectCropsWithoutEndDate = createSelector(selectCrops, (crops) => {
  return crops?.filter((crop) => !crop?.endDate)
})

export const selectCropsByCropType = (cropTypeId: number, includeCropsWithEndDate: boolean = false) =>
  createSelector(selectCrops, (crops) => {
    return crops?.filter((crop) => crop?.cropTypeId === cropTypeId && (includeCropsWithEndDate ? true : !crop?.endDate))
  })

export const selectCropsByGrowers = (growerIds: number[], includeCropsWithEndDate: boolean = false) =>
  createSelector(selectCrops, (crops) => {
    return crops?.filter(
      (crop) => growerIds.includes(crop?.growerId) && (includeCropsWithEndDate ? true : !crop?.endDate)
    )
  })
