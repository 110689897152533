import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import { IsNotEmpty, IsNumber, IsOptional, IsString, Min, ValidateNested } from 'class-validator'

export class SaveSowingPlanningReportError {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  @IsNotEmpty()
  type: string

  @IsString()
  @IsNotEmpty()
  message: string

  @IsNumber()
  @Min(1)
  @IsNotEmpty()
  year: number

  @IsNumber()
  @Min(1)
  @IsNotEmpty()
  week: number

  @IsString()
  @IsNotEmpty()
  region: string

  @IsString()
  @IsNotEmpty()
  grower: string

  @IsString()
  @IsNotEmpty()
  rawMaterialProcessed: string
}

export class SaveSowingPlanningReport {
  public static build(raw: any) {
    return plainToInstance(SaveSowingPlanningReport, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  version: number

  @IsString()
  @IsNotEmpty()
  campaign: string

  @IsString()
  @IsNotEmpty()
  date: string

  @ValidateNested()
  @Type(() => SaveSowingPlanningReportError)
  errors: SaveSowingPlanningReportError[]
}
