import { map, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ShapefileType } from '@agroone-app/shared/map/models/shapefileType.enum'
import { environment } from '@front-app-environments/environment'

@Injectable({
  providedIn: 'root',
})
export class GeolocationHttpService {
  constructor(private readonly _http: HttpClient) {}

  public getShapefile(type: ShapefileType, ids: number[], boundaryIds?: number[]): Observable<string> {
    return this._http
      .post(`${environment.apiUrl}${environment.geolocation}/shapefile`, {
        ids,
        type,
        boundaryIds,
      })
      .pipe(map((res: { base64: string }) => res.base64))
  }
}
