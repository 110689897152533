/**
 * Date format used in the API
 * This enum MUST NOT BE CHANGED
 * We only support 2 date format for the communication between the front and the back
 * The DATETIME_OFFSET that is containing a date with the time and the timezone in use in the front-end
 * The SIMPLE that is containing only a simple date
 */
export enum DateFormatV2 {
  DATETIME_OFFSET = `yyyy-MM-dd'T'HH:mm:ssxxx`,
  SIMPLE = 'yyyy-MM-dd',
}
