<div class="crop-close">
  <mat-dialog-content class="crop-close-content">
    <form [formGroup]="form">
      <!-- End Date -->
      <mat-label>{{ 'CROP.FILTERS.END_DATE' | translate }}</mat-label>
      <div class="crop-close-date">
        <app-datetimepicker icon="calendar_harvest" [formElement]="endDateElement" [control]="endDate">
        </app-datetimepicker>
        <button mat-icon-button color="warn" aria-label="reset dateRange">
          <mat-icon svgIcon="delete-cross" (click)="endDate.reset()"></mat-icon>
        </button>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="crop-close-actions">
    <button [disabled]="!form.valid" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
