import { Component, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-planning-confirm',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.sass'],
})
export class PlanningDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PlanningDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      version: number
      date: string
    }
  ) {}

  save(): void {
    this.dialogRef.close()
  }
}
