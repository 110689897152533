<div class="disp-f j-c-c a-i-c">
  <mat-icon
    aria-label="Download"
    svgIcon="download"
    (click)="onDownload()"
    [ngClass]="{
      'is-enabled': isEnabled,
      'c-p': isEnabled,
      'is-disabled': !isEnabled
    }"
    [ngStyle]="{ width: width + 'px', height: height + 'px' }"
  />
</div>
