import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator'

export class AnomalyConstantTranslation {
  id: number
  name: string
  description: string
  languageId: number
  languageName: string
}

export class AnomalyConstant {
  slug: string
  name: string
  description: string
  translations: AnomalyConstantTranslation[]
}

export interface AnomalyConstantRequest {
  id?: number
  slug: string
  languageId: number
  languageName: string
  name: string
  description: string
}

export class SaveAnomalyConstant {
  @IsInt()
  @IsPositive()
  @IsOptional()
  id?: number

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  languageId: number

  @IsString()
  @IsNotEmpty()
  name: string

  @IsString()
  @IsNotEmpty()
  description: string
}
