import { IsIn, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { plainToInstance, Type } from 'class-transformer'
import { SaveTranslation } from './translation'

export class SaveNutrient {
  public static build(raw: any) {
    return plainToInstance(SaveNutrient, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  key: string | undefined

  @IsString()
  @IsNotEmpty()
  @IsIn(['macro', 'micro'])
  type: string | undefined

  @IsNotEmpty()
  @Type(() => SaveTranslation)
  translations: SaveTranslation[] | undefined
}
