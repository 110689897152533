import { createFeatureSelector, createSelector } from '@ngrx/store'
import { GrowerState, growerAdapter } from '../reducers/grower.reducer'
import { Features } from '@agroone-app/state/features.enum'

const { selectIds, selectAll } = growerAdapter.getSelectors()

export const growersSelector = createFeatureSelector<GrowerState>(Features.GROWER)

export const selectGrowers = createSelector(growersSelector, selectAll)
export const selectGrowersIds = createSelector(growersSelector, selectIds)

export const selectGrowerById = (growerId: number) =>
  createSelector(selectGrowers, (f) => f.filter((grower) => grower?.id === growerId)[0])
