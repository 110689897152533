import { AnalysisType } from './analysis-type'
import { Location, Precision } from './location'
import { Reporting, ReportingDAO } from './reporting'
import { SaveSample } from './save-sample'
import { Notification } from './notification'
import { Crop } from './crop'
import { ISO8601ToDate } from '@agroone/helpers'

export class MicrobiologicalTest {
  id?: number
  name: string
  controls: string[]
  location?: Location
  area: number
  areaUnit: string
  weight: number
  weightUnit: string
  bedCount: number
  size: number

  constructor(test?: MicrobiologicalTest) {
    if (test?.id) {
      this.id = test?.id
    }
    this.name = test?.name ?? ''
    this.location = test?.location ? new Location(test?.location) : undefined
    this.area = test?.area
    this.areaUnit = test?.areaUnit
    this.weight = test?.weight
    this.weightUnit = test?.weightUnit
    this.bedCount = test?.bedCount
    this.size = test?.size
    this.controls = test?.controls
  }
}

export class Microbiological {
  id?: number
  date: string
  humidity?: number
  temperature?: number
  temperatureUnit?: string
  tests: MicrobiologicalTest[]
  user: string
  username: string
  laboratory: string
  laboratoryId: number

  get dateAsDate(): Date {
    return new Date(this.date)
  }

  constructor(microbio: Omit<Microbiological, 'dateAsDate'>) {
    if (microbio?.id) {
      this.id = microbio?.id
    }
    this.date = microbio?.date
    this.humidity = microbio?.humidity
    this.user = microbio?.user
    this.username = microbio?.username
    this.temperature = microbio?.temperature
    this.temperatureUnit = microbio?.temperatureUnit
    this.laboratory = microbio?.laboratory
    this.laboratoryId = microbio?.laboratoryId
    this.tests = microbio?.tests?.map?.((c) => new MicrobiologicalTest(c)) ?? []
  }
}

export class PreharvestControl {
  id: number
  user: string
  date: string
  controlCode: string
  value: number
  unit?: string

  get dateAsDate(): Date {
    return ISO8601ToDate(this.date)
  }

  constructor(control?: Omit<PreharvestControl, 'dateAsDate'>) {
    this.id = control?.id
    this.user = control?.user ?? ''
    this.date = control?.date ?? ''
    this.controlCode = control?.controlCode ?? ''
    this.value = control?.value ?? null
    this.unit = control?.unit
  }
}

export class Preharvest {
  id?: number
  date: string
  controls: PreharvestControl[]
  location?: Location

  get dateAsDate(): Date {
    return ISO8601ToDate(this.date)
  }

  constructor(preharvest?: Omit<Preharvest, 'dateAsDate'>) {
    this.id = preharvest?.id
    this.date = preharvest?.date ?? ''
    this.controls = preharvest?.controls?.map?.((c) => new PreharvestControl(c))
    this.location = preharvest?.location ? new Location(preharvest?.location) : undefined
  }
}

export class SoilAnalysis {
  id?: number
  user: string
  username: string
  date: string
  laboratory: string
  laboratoryId: number

  get dateAsDate(): Date {
    return new Date(this.date)
  }

  constructor(soilAnalysis?: Omit<SoilAnalysis, 'dateAsDate'>) {
    if (soilAnalysis?.id) {
      this.id = soilAnalysis?.id
    }
    this.user = soilAnalysis?.user ?? ''
    this.date = soilAnalysis?.date ?? ''
    this.username = soilAnalysis?.user ?? ''
    this.laboratory = soilAnalysis?.laboratory ?? ''
    this.laboratoryId = soilAnalysis?.laboratoryId
  }
}

export class WaterAnalysis {
  id?: number
  user: string
  username: string
  date: string
  laboratory: string
  laboratoryId: number
  humidity?: number
  temperature?: number
  temperatureUnit?: string
  source?: string
  sourceType: string
  samplingLocation: string
}

export type PreharvestSample = { id: number; cropId: number; dates: string[] }

export class Sample extends Reporting {
  static readonly className: string = 'Sample'

  id?: number
  userReadableId?: string
  date?: string | null
  region: string
  cropId: number
  fieldId: number
  growerName: string
  growerId: number
  farmName: string
  plantingId?: string
  type: string
  attachmentPath?: string
  picturesPath?: string[]
  note?: string
  notifications?: Notification[]
  microbiological?: Microbiological
  preharvest?: Preharvest
  soilAnalysis?: SoilAnalysis
  pesticide?: SamplePesticide
  validated?: boolean

  constructor(sample?: Sample) {
    super()
    if (sample?.userReadableId) {
      this.userReadableId = sample.userReadableId
    }
    if (sample?.id) {
      this.id = sample?.id
    }
    if (sample?.fieldId) {
      this.fieldId = sample?.fieldId
    }
    if (sample?.cropId) {
      this.cropId = sample?.cropId
    }
    this.region = sample?.region ?? ''
    this.type = sample?.type ?? ''
    this.plantingId = sample?.plantingId ?? ''
    this.note = sample?.note ?? ''
    this.date = sample?.date ?? null
    this.createdBy = sample?.createdBy ?? null
    this.picturesPath = sample?.picturesPath ?? []
    this.notifications = sample?.notifications ?? []
    this.attachmentPath = sample?.attachmentPath
    if (sample?.microbiological) {
      this.microbiological = new Microbiological(sample.microbiological)
    }
    if (sample?.preharvest) {
      this.preharvest = new Preharvest(sample.preharvest)
    }
    if (sample?.pesticide) {
      this.pesticide = new SamplePesticide(sample.pesticide)
    }
    if (sample?.soilAnalysis) {
      this.soilAnalysis = new SoilAnalysis(sample.soilAnalysis)
    }
    if (sample?.notifications) {
      this.notifications = sample?.notifications?.map?.((c) => new Notification(c))
    }
    this.validated = sample?.validated ?? false
  }
}

export class SamplePesticide {
  id?: number
  date: string
  humidity?: number
  temperature?: number
  temperatureUnit?: string
  user: string
  laboratory: string
  laboratoryId: number
  location?: Location
  analysisType: AnalysisType
  analysisTypeId?: number
  analysisTypeComment?: string
  harvestDate: string
  invoiceInformation?: string
  sampleType: string

  get dateAsDate(): Date {
    return new Date(this.date)
  }

  get harvestDateAsDate(): Date {
    return new Date(this.harvestDate)
  }

  constructor(pesticide: Omit<SamplePesticide, 'dateAsDate' | 'harvestDateAsDate'>) {
    if (pesticide?.id) {
      this.id = pesticide?.id
    }
    this.date = pesticide?.date
    this.humidity = pesticide?.humidity
    this.temperature = pesticide?.temperature
    this.temperatureUnit = pesticide?.temperatureUnit
    this.sampleType = pesticide?.sampleType
    this.location = pesticide?.location
    this.analysisType = pesticide?.analysisType
    this.analysisTypeComment = pesticide?.analysisTypeComment
    this.harvestDate = pesticide?.harvestDate
    this.laboratory = pesticide?.laboratory
    this.laboratoryId = pesticide?.laboratoryId
    this.invoiceInformation = pesticide?.invoiceInformation
    this.user = pesticide?.user
    if (pesticide?.analysisTypeId) {
      this.analysisTypeId = pesticide.analysisTypeId
    }
  }
}

export class SampleDao extends ReportingDAO {
  id: number
  plantingId: string
  attachmentPath: string
  note: string
  type: string
  validated: boolean
  cropId: number
  fieldId: number
  region: string
  regionFromCrop: string
  farmName: string
  growerId: number
  growerName: string
  microbiologicalId: number
  microbiologicalDate: string
  microbiologicalHumidity: number
  microbiologicalTemperature: number
  microbiologicalTemperatureUnit: string
  microbiologicalUser: string
  microbiologicalUsername: string
  microbiologicalLaboratory: string
  microbiologicalLaboratoryId: number
  preharvestId: number
  preharvestDate: string
  preharvestLocationId: number
  preharvestLocationLatitude: string
  preharvestLocationLongitude: string
  preharvestLocationPrecision: string
  preharvestLocationGeohash: string
  picturesPath: string[]
  microbiologicalTestId: number
  microbiologicalTestName: string
  microbiologicalTestControls: string // A JSON converted to a string
  microbiologicalTestArea: number
  microbiologicalTestAreaUnit: string
  microbiologicalTestWeight: number
  microbiologicalTestWeightUnit: string
  microbiologicalTestBedCount: number
  microbiologicalTestSize: number
  microbiologicalTestLatitude: string
  microbiologicalTestLongitude: string
  microbiologicalTestPrecision: string
  microbiologicalTestGeohash: string
  preharvestControlId: number
  preharvestControlUser: string
  preharvestControlDate: string
  preharvestControlControlCode: string
  preharvestControlValue: number
  preharvestControlUnit: string
  pesticideId: number
  pesticideDate: string
  pesticideHumidity: number
  pesticideTemperature: number
  pesticideTemperatureUnit: string
  pesticideUser: string
  pesticideLaboratoryId: number
  pesticideAnalysisTypeId: number
  pesticideAnalysisTypeName: string
  pesticideAnalysisTypeComment: string
  pesticideHarvestDate: string
  pesticideInvoiceInformation: string
  pesticideSampleType: string
  pesticideLaboratoryName: string
  pesticideLocationId: number
  pesticideLocationLatitude: string
  pesticideLocationLongitude: string
  pesticideLocationPrecision: Precision
  pesticideLocationGeohash: string
  soilAnalysisId: number
  soilAnalysisDate: string
  soilAnalysisUser: string
  soilAnalysisUsername: string
  soilAnalysisLaboratory: string
  soilAnalysisLaboratoryId: number
  notificationId: number
  notificationContactType: string
  notificationSendEmail: boolean
  notificationSendSms: boolean

  constructor(sample?: SaveSample) {
    super(sample)
  }
}

export class DashboardSample extends Sample {
  static override readonly className = 'DashboardSample'

  declare date: string
  user: string
  crops: Crop[]

  constructor(dashboardSample: DashboardSample) {
    super(dashboardSample)
    this.date = dashboardSample?.date
    this.user = dashboardSample?.user
    this.crops = dashboardSample?.crops
  }
}
