import { Injectable } from '@angular/core'
// eslint-disable-next-line no-unused-vars,@typescript-eslint/ban-ts-comment
// @ts-ignore
import { dtrum } from '@dynatrace/dtrum-api-types'
import { ConnectionService } from './connection.service'

// KEEP THE LINE ABOVE IN ORDER TO MAKE DYNATRACE WORKS

@Injectable()
export class MonitoringService {
  constructor(private connectionService: ConnectionService) {}

  public identifyUser(user: string) {
    // Catch Dynatrace error if ever there is one. So this don't crash the whole app
    try {
      if (typeof window.dtrum !== 'undefined' && window.dtrum?.identifyUser && this.connectionService.connected) {
        window.dtrum.identifyUser(user)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Log an error
   */
  public error(message: string, error?: Error) {
    // Catch Dynatrace error if ever there is one. So this don't crash the whole app
    try {
      if (typeof window.dtrum !== 'undefined' && window.dtrum?.reportError && this.connectionService.connected) {
        window.dtrum.reportError(error ?? message)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Begin the monitoring of a custom action.
   * Don't forget to end the action with leaveAction()
   *
   * @returns the ID of the action
   */
  public enterAction(actionName: string): number {
    // Catch Dynatrace error if ever there is one. So this don't crash the whole app
    try {
      if (typeof window.dtrum !== 'undefined' && window.dtrum?.enterAction && this.connectionService.connected) {
        return window.dtrum.enterAction(actionName)
      }
    } catch (error) {
      console.warn(error)
    }
    return null
  }

  /**
   * End the monitoring of a custom action
   *
   * @param actionId
   */
  public leaveAction(actionId: number) {
    // Catch Dynatrace error if ever there is one. So this don't crash the whole app
    try {
      if (
        Number.isInteger(actionId) &&
        typeof window.dtrum !== 'undefined' &&
        window.dtrum?.leaveAction &&
        this.connectionService.connected
      ) {
        window.dtrum.leaveAction(actionId)
      }
    } catch (error) {
      console.warn(error)
    }
  }
}
