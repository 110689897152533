import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms'
import { Field } from '@agroone/entities'
import { Subject } from 'rxjs'
import { FormElement } from '@agroone-app/shared/form/models/form-element.model'
import { FieldFilters } from '@agroone-app/shared/models/field-filters.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SharedConstantService } from '@agroone-front/shared'

@UntilDestroy()
@Component({
  selector: 'app-fields-filter-form',
  templateUrl: './fields-filter-form.component.html',
  styleUrls: ['./fields-filter-form.component.sass'],
})
export class FieldFiltersFormComponent implements OnInit, OnDestroy {
  public form: FormGroup
  public towns: string[] = []
  public fieldNames: string[] = []
  public scoutingDateElement: FormElement

  get fieldName(): AbstractControl {
    return this.form.get('fieldName')
  }

  get town(): AbstractControl {
    return this.form.get('town')
  }

  private destroyed: Subject<void> = new Subject()

  constructor(
    public dialogRef: MatDialogRef<
      FieldFiltersFormComponent,
      {
        fieldName: string
        town: string
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filters: FieldFilters
      fields: Field[]
    },
    private formBuilder: FormBuilder,
    public constantService: SharedConstantService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      fieldName: [
        {
          value: this.data?.filters.fieldName?.value,
          disabled: this.data?.filters.fieldName?.disabled,
        },
      ],
      town: [
        {
          value: this.data?.filters.town?.value,
          disabled: this.data?.filters.town?.disabled,
        },
      ],
    })

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.updateLists(form)
    })

    this.updateLists(this.form.value)
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }

  save() {
    const filters = {
      fieldName: this.fieldName.value,
      town: this.town.value,
    }
    this.dialogRef.close(filters)
  }

  public display(value: string): string {
    return value
  }

  public filter(value: string, input: string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string = input.toLowerCase()
    return value.toLowerCase().indexOf(filterValue) !== -1
  }

  private updateLists(filters: { fieldName: string; town: string }) {
    if (!this.data.fields?.length) {
      return
    }
    const towns = []
    const fieldNames = []

    for (const field of this.data.fields) {
      const fieldNameIsOk = !filters.fieldName || field.name === filters.fieldName
      const townIsOk = !filters.town || field.town === filters.town

      if (fieldNameIsOk && field.town) {
        towns.push(field.town)
      }
      if (townIsOk) {
        fieldNames.push(field.name)
      }
    }
    this.fieldNames = [...new Set([...fieldNames])]
    this.towns = [...new Set([...towns])]
  }
}
