import { CropActivity, Intervention, Recommendation, Sample, Scouting } from '@agroone/entities'
import { ActionCreator, ReducerTypes, on } from '@ngrx/store'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { addCropDetail, clearCropDetail } from '../actions/crop-detail.actions'

export interface CropDetailActivities {
  cropId: number
  scoutings?: Scouting[]
  interventions?: Intervention[]
  recommendations?: Recommendation[]
  samples?: Sample[]
  activities?: CropActivity[]
  error?: any
}

export function selectCropId(a: CropDetailActivities): number {
  return a?.cropId
}

export const cropDetailAdapter: EntityAdapter<CropDetailActivities> = createEntityAdapter<CropDetailActivities>({
  selectId: selectCropId,
})

export interface CropDetailState extends EntityState<CropDetailActivities> {
  crop?: CropDetailActivities[]
}
export const initialCropDetailState: CropDetailState = cropDetailAdapter.getInitialState({ crop: [] })

export const cropDetailOns: ReducerTypes<CropDetailState, ActionCreator[]>[] = [
  on(addCropDetail, (state, { crop }) =>
    cropDetailAdapter.addOne(crop, {
      ...state,
    })
  ),
  on(clearCropDetail, () => initialCropDetailState),
]
