import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator'
import { parse, isValid } from 'date-fns' // Use date-fns for date parsing and validation
import { DateFormatV2 } from '@agroone/dates'

@ValidatorConstraint({ name: 'isDateValidate', async: false })
export class IsDateTimeOffsetConstraint implements ValidatorConstraintInterface {
  validate(value: string, args: ValidationArguments): boolean {
    const [format] = args.constraints

    if (!value || typeof value !== 'string') {
      return false
    }

    // Validate the date string based on the provided format
    const parsedDate = parse(value, format, new Date())
    return isValid(parsedDate)
  }

  defaultMessage(args: any): string {
    const [format] = args.constraints
    return `Date ($value) must be in the format: ${format}`
  }
}

export function IsDateValidate(format: DateFormatV2, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [format],
      validator: IsDateTimeOffsetConstraint,
    })
  }
}
