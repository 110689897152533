<div class="technician">
  <mat-dialog-content class="technician-content">
    <form [formGroup]="form">
      <!-- Technician -->
      <mat-label>{{ 'FIELD.CROP.TECHNICIAN' | translate }}</mat-label>
      <app-autocomplete-select [list]="technicians" [formCtrl]="technician" [displayFn]="display" [filterFn]="filter">
      </app-autocomplete-select>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="technician-actions">
    <button [disabled]="!form.valid" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
