import { GrowerCsrFileType } from '../enums/grower-csr-type-file.enum'
import { IsEnum, IsNotEmpty, IsString } from 'class-validator'
export class SaveGrowerCsrFile {
  @IsString()
  @IsNotEmpty()
  date: string

  @IsString()
  @IsEnum(GrowerCsrFileType)
  @IsNotEmpty()
  type: GrowerCsrFileType

  constructor(growerCsrFile?: SaveGrowerCsrFile) {
    this.date = growerCsrFile?.date
    this.type = growerCsrFile?.type
  }
}
