import { Inject, Injectable } from '@angular/core'
import { set, get, del, entries, createStore, UseStore, setMany } from 'idb-keyval'
import * as idbKeyval from 'idb-keyval'
import { LoggerService } from '@agroone-front/shared'
import { INDEXDB_CONFIG, IndexDBConfig } from '@agroone-app/core/storage/indexdb.config'
import { Action, ActionCreator, ActionReducer, ActionType, ReducerTypes, Store, createReducer } from '@ngrx/store'
import { CropState } from '@agroone-app/scene/crop-management/crop/store/reducers'
import { CropDetailActivities } from '@agroone-app/scene/crop-management/crop/store/reducers/crop-detail.reducer'
import { FieldState } from '@agroone-app/scene/crop-management/field/store/reducers/field.reducer'
import { clearCropDetail } from '@agroone-app/scene/crop-management/crop/store/actions/crop-detail.actions'
import { clearCrops } from '@agroone-app/scene/crop-management/crop/store/actions/crop.actions'
import { clearFields } from '@agroone-app/scene/crop-management/field/store/actions/field.actions'
import { LoadingState } from './loading/loading.reducer'
import { clearLoading } from './loading/loading.actions'
import { clearGrowers, loadGrowers } from '@agroone-app/scene/crop-management/grower/store/actions/grower.actions'
import { GrowerState } from '@agroone-app/scene/crop-management/grower/store/reducers'
import { CropService } from '@agroone-app/shared/crop/services/crop.service'

@Injectable({
  providedIn: 'root',
})
export class IdbStorageService {
  public store: UseStore
  private storageSize: number

  constructor(
    private logger: LoggerService,
    private growerStore: Store<GrowerState>,
    private cropStore: Store<CropState>,
    private cropDetailStore: Store<CropDetailActivities>,
    private fieldStore: Store<FieldState>,
    private loadingStore: Store<LoadingState>,
    @Inject(INDEXDB_CONFIG) private config: IndexDBConfig,
    private cropService: CropService
  ) {
    this.updateStorageSize()
    this.store = createStore(`agronne-store`, 'states')
  }

  // LOCAL STORAGE
  /**
   * Saves Object in localStorage associated with key
   */
  public setLocal(key: string, value: any) {
    let stringValue: string
    if (typeof value !== 'string') {
      stringValue = JSON.stringify(value)
    } else {
      stringValue = value
    }
    localStorage.setItem(key, stringValue)
  }

  /**
   * Get object from localStorage with provided key
   */
  public getLocal(key: string): any {
    const returnValue = localStorage.getItem(key)
    if (!returnValue) {
      throw new Error(`No value in localStorage for '${key}'`)
    }
    try {
      return JSON.parse(returnValue)
    } catch (error) {
      return returnValue
    }
  }

  /**
   * Get all objects from localStorage
   */
  public getAllLocal(): { [key: string]: any } {
    const m: { [key: string]: string } = { ...localStorage }
    for (const entry of Object.entries(m)) {
      let value = entry[1]
      if (value.startsWith('{') || value.startsWith('[')) {
        value = JSON.parse(value)
      }
      m[entry[0]] = value
    }
    return m
  }

  /**
   * Clears object from localStorage with provided key
   */
  public clearLocal(key: string) {
    localStorage.removeItem(key)
  }

  public async clearStore(): Promise<void> {
    await idbKeyval.clear(this.store)
  }

  /**
   * Save an object in indexedDB
   */
  public async set(key: string, value: any): Promise<void> {
    try {
      await set(key, value, this.store ?? undefined)
      await this.updateStorageSize()
    } catch (error) {
      if (error.name === 'QuotaExceededError') {
        this.logger.error(`QuotaExceededError when trying to store ${key} inside indexedDB`)
        throw error
      }
    }
  }

  /**
   * Save multiple objects in indexedDB
   */
  public async setMany(objects: [string, any][]): Promise<void> {
    try {
      await setMany(objects, this.store ?? undefined)
      await this.updateStorageSize()
    } catch (error) {
      if (error.name === 'QuotaExceededError') {
        this.logger.error('QuotaExceededError when trying to store multiple items inside indexedDB')
        throw error
      }
    }
  }

  /**
   * Get an object from indexedDB
   */
  public async get(key: string): Promise<any> {
    return get(key, this.store ?? undefined)
  }

  public async del(key: string): Promise<void> {
    return del(key, this.store ?? undefined)
  }

  /**
   * Get all objects in indexedDB as an array
   */
  public async getAll<T>(): Promise<{ [key: string]: T }> {
    const ks = await entries(this.store ?? undefined)
    return ks.reduce((obj, entry) => {
      const key: string = entry[0].toString()
      const value: string = entry[1]
      obj[`${key}`] = value
      return obj
    }, {})
  }

  public async clear(key: string): Promise<any> {
    return del(key, this.store ?? undefined)
  }

  /**
   *
   * @param reducerKey
   * @param initialState
   * @param ons
   * @returns
   */
  public async createIdbRehydrateReducer<S, A extends Action = Action>(
    reducerKey: string,
    initialState: S,
    ...ons: ReducerTypes<S, ActionCreator[]>[]
  ): Promise<ActionReducer<S, A>> {
    const newInitialState = ((await this.get(reducerKey)) as S) ?? initialState
    const newOns: ReducerTypes<S, ActionCreator[]>[] = []
    ons.forEach((oldOn: ReducerTypes<S, ActionCreator[]>) => {
      const newReducer: ActionReducer<S, A> = (state: S | undefined, action: ActionType<ActionCreator[][number]>) => {
        const newState = oldOn?.reducer(state, action)
        this.set(reducerKey, newState)

        return newState
      }
      newOns.push({ ...oldOn, reducer: newReducer })
    })

    return Promise.resolve(createReducer(newInitialState, ...newOns))
  }

  public clearStorage(): void {
    this.growerStore.dispatch(clearGrowers())
    this.cropStore.dispatch(clearCrops())
    this.cropDetailStore.dispatch(clearCropDetail())
    this.fieldStore.dispatch(clearFields())
    this.loadingStore.dispatch(clearLoading())

    //then refresh main store
    this.growerStore.dispatch(loadGrowers())
    this.cropService.dispatchLoadCrops()
  }

  private async updateStorageSize(): Promise<void> {
    if (navigator.storage && navigator.storage.estimate) {
      const quota = await navigator.storage.estimate()
    }
  }
}
