<div class="technician">
  <mat-dialog-content class="technician-content">
    <form [formGroup]="form">
      <!-- Technician -->
      <mat-label>{{ 'FIELD.CROP.TECHNICIAN' | translate }}</mat-label>
      <app-autocomplete-select
        [list]="technicians"
        [formCtrl]="technician"
        [displayFn]="display"
        [filterFn]="filter"
        [sortFn]="sortTechnician"
      >
      </app-autocomplete-select>
      <!-- Growing system -->
      <div class="crop-edit-growing-system">
        <mat-label>{{ 'FIELD.CROP.GROWING_SYSTEM' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="constantService.growingSystem"
          [formCtrl]="growingSystem"
        ></app-autocomplete-select>
      </div>
      <!-- Associated crop -->
      <div class="crop-edit-associated-crop">
        <mat-label>{{ 'FIELD.CROP.ASSOCIATED_CROP' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="constantService.associatedCrop"
          [formCtrl]="associatedCrop"
          [sortFn]="sortAssociatedCrop"
        ></app-autocomplete-select>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="technician-actions">
    <button [disabled]="!form.valid" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
