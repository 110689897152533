export enum ErrorCodes {
  MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
  MISSING_PERMISSION_MODULE_ACCESS = 'MISSING_PERMISSION_MODULE_ACCESS',
  UNKNOWN_PERMISSIONS = 'UNKNOWN_PERMISSIONS',
  INVALID_TOKEN = 'INVALID_TOKEN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_INACTIVE = 'USER_INACTIVE',
  INVALID_API_KEY = 'INVALID_API_KEY',
  MISSING_AUTH = 'MISSING_AUTH',
  ACTIVE_USER_REQUIRED = 'ACTIVE_USER_REQUIRED',
  ACTIVE_USER_WITH_ACTIVE_REGION_REQUIRED = 'ACTIVE_USER_WITH_ACTIVE_REGION_REQUIRED',
  USER_EMAIL_ALREADY_EXISTS = 'USER_EMAIL_ALREADY_EXISTS',
  NO_ACTIVE_REGION_FOR_API_KEY = 'NO_ACTIVE_REGION_FOR_API_KEY',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  TOKENS_EXPIRED = 'TOKENS_EXPIRED',
}
