import { IsBoolean, IsHexColor, IsInt, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'

export class CreateUpdateStockDto {
  @IsString()
  @IsNotEmpty()
  name: string

  @IsNumber()
  @IsInt()
  destination: number

  @IsNumber()
  @IsInt()
  cropTypeId: number

  @IsString()
  @IsHexColor()
  @ValidateIf((_, value) => value !== null)
  color: string | null

  @IsString()
  @ValidateIf((_, value) => value !== null)
  quality: string | null

  @IsNumber()
  @IsInt()
  regionId: number

  @IsNumber()
  @IsInt()
  factoryId: number

  @IsBoolean()
  @IsOptional()
  actif?: boolean

  @IsNumber()
  @IsInt()
  @IsOptional()
  min?: number

  @IsNumber()
  @IsInt()
  @IsOptional()
  max?: number
}
