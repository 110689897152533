import { plainToClass, plainToInstance, Type } from 'class-transformer'
import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
  IsBoolean,
  IsArray,
  ArrayNotEmpty,
  ArrayMaxSize,
  Validate,
  IsIn,
  Min,
  Max,
} from 'class-validator'
import { SaveLocation } from './save-location'
import { IsDecimalNumber, IsISO8601WithTimezone, IsLaterThan, RequireProperty } from '@agroone/helpers'
import { SaveReporting } from './save-reporting'

export class SaveWorkOrderFoodSafety {
  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: SaveLocation

  @IsNumber()
  @Validate(IsDecimalNumber, [1])
  @IsOptional()
  @Min(0)
  @RequireProperty('bufferZoneUnit')
  bufferZone?: number

  @IsString()
  @IsIn(['m', 'ft', 'ac', 'ha'])
  @IsOptional()
  @RequireProperty('bufferZone')
  bufferZoneUnit?: string
}
export class SaveWorkOrderIrrigationNutrient {
  @IsNumber()
  @IsNotEmpty()
  nutrientId: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @Max(100)
  @IsNotEmpty()
  value: number
}

export class SaveWorkOrderIrrigation {
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  startingDate: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  @IsLaterThan('startingDate')
  endingDate: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @RequireProperty('areaUnit')
  area: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @RequireProperty('amountUnit')
  amount: number

  @IsString()
  @RequireProperty('area')
  @IsNotEmpty()
  areaUnit: string

  @IsString()
  @RequireProperty('amount')
  @IsNotEmpty()
  amountUnit: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWorkOrderIrrigationNutrient)
  nutrients: SaveWorkOrderIrrigationNutrient[]
}

export class SaveWorkOrderPesticidesProduct {
  @IsNumber()
  @IsNotEmpty()
  pesticideId: number

  @IsString()
  @IsOptional()
  oldPesticideId?: string

  @IsNumber()
  @Validate(IsDecimalNumber, [3])
  @Min(0)
  @RequireProperty('rateUnit')
  @IsNotEmpty()
  rate: number

  @IsString()
  @IsIn(['l', 'ml', 'mg', 'g', 'kg', 'lb', 'gal', 'qt', 'oz', 'pnt'])
  @RequireProperty('rate')
  @IsNotEmpty()
  rateUnit: string

  @IsString()
  @RequireProperty('rate')
  @IsNotEmpty()
  rateAreaUnit: string
}

export class SaveWorkOrderPlanting {
  @IsNumber()
  @IsNotEmpty()
  varietyId: number

  @IsNumber()
  @Min(0)
  @RequireProperty('seedingRateUnit')
  @IsNotEmpty()
  seedingRate: number

  @IsString()
  @RequireProperty('seedingRate')
  @IsNotEmpty()
  seedingRateUnit: string

  @IsNumber()
  @Min(0)
  @RequireProperty('seedingAreaUnit')
  @IsNotEmpty()
  seedingArea: number

  @IsString()
  @RequireProperty('seedingArea')
  @IsNotEmpty()
  seedingAreaUnit: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string

  @IsString()
  @IsOptional()
  lotNumber?: string
}

export class SaveWorkOrderMechanicalWeeding {
  @IsString()
  @IsNotEmpty()
  area: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string
}

export class SaveWorkOrderOtherOperation {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  operationType: string

  @IsString()
  @IsNotEmpty()
  area: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string
}

export class SaveWorkOrderPesticide {
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @IsNotEmpty()
  waterAmount: number

  @IsString()
  @IsIn(['l', 'ml', 'gal'])
  @RequireProperty('waterAreaUnit')
  @IsNotEmpty()
  waterUnit: string

  @IsString()
  @RequireProperty('waterUnit')
  @IsNotEmpty()
  waterAreaUnit: string

  @IsArray()
  @ValidateNested()
  @Type(() => SaveWorkOrderPesticidesProduct)
  @ArrayNotEmpty()
  @ArrayMaxSize(4)
  products: SaveWorkOrderPesticidesProduct[]

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string

  @IsString()
  @IsOptional()
  alternativeSolution?: string

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  completedDate?: string
}

export class SaveWorkOrder extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveWorkOrder, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsString()
  @IsNotEmpty()
  orderedBy: string

  @IsString()
  @IsNotEmpty()
  type: string

  @IsString()
  @IsNotEmpty()
  subType: string

  @ArrayNotEmpty()
  @IsNumber({}, { each: true })
  crops: number[]

  @IsString()
  @IsOptional()
  note?: string

  @IsBoolean()
  @IsOptional()
  sendSMS?: boolean

  @IsBoolean()
  @IsOptional()
  sendEmail?: boolean

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWorkOrderPlanting)
  planting?: SaveWorkOrderPlanting

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWorkOrderFoodSafety)
  foodSafety?: SaveWorkOrderFoodSafety

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWorkOrderMechanicalWeeding)
  mechanicalWeeding?: SaveWorkOrderMechanicalWeeding

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWorkOrderOtherOperation)
  otherOperation?: SaveWorkOrderOtherOperation

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWorkOrderPesticide)
  pesticide?: SaveWorkOrderPesticide

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWorkOrderIrrigation)
  irrigation?: SaveWorkOrderIrrigation

  @IsArray()
  @ArrayMaxSize(3)
  @IsOptional()
  picturesPath?: string[]

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  disablingDate?: string

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  completedDate?: string
}
