export class ClusterHypothesis {
  sowingCluster: number
  firstCutPercentage: number

  constructor(hypothesis?: ClusterHypothesis) {
    this.sowingCluster = hypothesis?.sowingCluster
    this.firstCutPercentage = hypothesis?.firstCutPercentage
  }
}
export class CycleHypothesis {
  landPreparation: number
  firstCutCycle: number
  secondCutCycle?: number
  thirdCutCycle?: number
  forthCutCycle?: number
  fifthCutCycle?: number
  sixthCutCycle?: number
  seventhCutCycle?: number

  constructor(hypothesis?: CycleHypothesis) {
    this.landPreparation = hypothesis?.landPreparation
    this.firstCutCycle = hypothesis?.firstCutCycle
    this.secondCutCycle = hypothesis?.secondCutCycle
    this.thirdCutCycle = hypothesis?.thirdCutCycle
    this.forthCutCycle = hypothesis?.forthCutCycle
    this.fifthCutCycle = hypothesis?.fifthCutCycle
    this.sixthCutCycle = hypothesis?.sixthCutCycle
    this.seventhCutCycle = hypothesis?.seventhCutCycle
  }
}

export class RateHypothesis {
  securityRate: number
  firstHarvestRate?: number
  secondHarvestRate?: number
  thirdHarvestRate?: number
  forthHarvestRate?: number
  fifthHarvestRate?: number
  sixthHarvestRate?: number
  seventhHarvestRate?: number

  constructor(hypothesis?: RateHypothesis) {
    this.securityRate = hypothesis?.securityRate
    this.firstHarvestRate = hypothesis?.firstHarvestRate
    this.secondHarvestRate = hypothesis?.secondHarvestRate
    this.thirdHarvestRate = hypothesis?.thirdHarvestRate
    this.forthHarvestRate = hypothesis?.forthHarvestRate
    this.fifthHarvestRate = hypothesis?.fifthHarvestRate
    this.sixthHarvestRate = hypothesis?.sixthHarvestRate
    this.seventhHarvestRate = hypothesis?.seventhHarvestRate
  }
}

export class DensityAndWeightHypothesis {
  density: number
  densityUnit: string
  averageWeight: number
  averageWeightUnit: string

  constructor(hypothesis?: DensityAndWeightHypothesis) {
    this.density = hypothesis?.density
    this.densityUnit = hypothesis?.densityUnit
    this.averageWeight = hypothesis?.averageWeight
    this.averageWeightUnit = hypothesis?.averageWeightUnit
  }
}
export class HypothesisCount {
  hypothesisType: string
  year: number
  businessUnit: string
  count: number
}

export class HypothesisCountRequest {
  hypothesisType: string
  year: number
  businessUnit: string
  nb: number
}

export class Hypothesis {
  id?: number
  hypothesisType: string
  region: string
  businessUnit: string
  growerId: number
  growerName?: string
  productionArea: string
  cropType: string
  cropTypeId: number
  rawMaterialProcessed: string
  variety: string
  varietyId: number
  fieldType: string
  growingSystem: string
  year: number
  week: number
  userId: string
  updateDate: string
  clusterHypothesis?: ClusterHypothesis
  cycleHypothesis?: CycleHypothesis
  rateHypothesis?: RateHypothesis
  densityAndWeightHypothesis?: DensityAndWeightHypothesis
  distributionRate?: number
  campaign: string
  campaignId: number

  constructor(hypothesis?: Hypothesis) {
    if (hypothesis?.id) {
      this.id = hypothesis?.id
    }
    this.hypothesisType = hypothesis?.hypothesisType
    this.region = hypothesis?.region
    this.businessUnit = hypothesis?.businessUnit
    this.growerId = hypothesis?.growerId
    this.growerName = hypothesis?.growerName
    this.productionArea = hypothesis?.productionArea
    this.cropType = hypothesis?.cropType
    this.rawMaterialProcessed = hypothesis?.rawMaterialProcessed
    this.variety = hypothesis?.variety
    this.varietyId = hypothesis?.varietyId
    this.fieldType = hypothesis?.fieldType
    this.growingSystem = hypothesis?.growingSystem
    this.clusterHypothesis = hypothesis?.clusterHypothesis
    this.cycleHypothesis = hypothesis?.cycleHypothesis
    this.rateHypothesis = hypothesis?.rateHypothesis
    this.densityAndWeightHypothesis = hypothesis?.densityAndWeightHypothesis
    this.userId = hypothesis?.userId
    this.updateDate = hypothesis?.updateDate
    this.distributionRate = hypothesis?.distributionRate
    this.campaign = hypothesis?.campaign
  }
}

export class HypothesisRequest {
  id: number
  hypothesisType: string
  region: string
  businessUnit: string
  growerId: number
  growerName?: string
  productionArea: string
  cropType: string
  cropTypeId: number
  rawMaterialProcessed: string
  variety: string
  varietyId: number
  fieldType: string
  growingSystem: string
  campaign: string
  campaignId: number
  year: number
  week: number
  sowingCluster: number
  firstCutPercentage: number
  landPreparation: number
  firstCutCycle: number
  secondCutCycle?: number
  thirdCutCycle?: number
  forthCutCycle?: number
  fifthCutCycle?: number
  sixthCutCycle?: number
  seventhCutCycle?: number
  securityRate: number
  distributionRate?: number
  firstHarvestRate?: number
  secondHarvestRate?: number
  thirdHarvestRate?: number
  forthHarvestRate?: number
  fifthHarvestRate?: number
  sixthHarvestRate?: number
  seventhHarvestRate?: number
  density: number
  densityUnit: string
  averageWeight: number
  averageWeightUnit: string
  forecastFirstHarvestRate?: number
  forecastSecondHarvestRate?: number
  forecastThirdHarvestRate?: number
  forecastForthHarvestRate?: number
  forecastFifthHarvestRate?: number
  forecastSixthHarvestRate?: number
  forecastSeventhHarvestRate?: number
  userId: string
  updateDate: string
}

export class HypothesisDAO {
  id?: number
  hypothesisType: string
  regionId: number
  businessUnitId: number
  growerId: number
  productionAreaId: number
  cropTypeId: number
  rawMaterialProcessed: string
  varietyId: number
  fieldType: string
  growingSystem: string
  campaignId: number
  year: number
  week: number
  plantingClusterHypothesisId?: number
  plantingCycleHypothesisId?: number
  plantingRateHypothesisId?: number
  plantingDensityAndWeightHypothesisId?: number
  userId: string
  updateDate: Date

  constructor(hypothesis?: HypothesisDAO) {
    if (hypothesis?.id) {
      this.id = hypothesis?.id
    }
    this.hypothesisType = hypothesis?.hypothesisType
    this.regionId = hypothesis?.regionId
    this.businessUnitId = hypothesis?.businessUnitId
    this.growerId = hypothesis?.growerId
    this.productionAreaId = hypothesis?.productionAreaId
    this.cropTypeId = hypothesis?.cropTypeId
    this.rawMaterialProcessed = hypothesis?.rawMaterialProcessed
    this.varietyId = hypothesis?.varietyId
    this.fieldType = hypothesis?.fieldType
    this.growingSystem = hypothesis?.growingSystem
    this.campaignId = hypothesis?.campaignId
    this.year = hypothesis?.year
    this.week = hypothesis?.week
    this.plantingClusterHypothesisId = hypothesis?.plantingClusterHypothesisId
    this.plantingCycleHypothesisId = hypothesis?.plantingCycleHypothesisId
    this.plantingRateHypothesisId = hypothesis?.plantingRateHypothesisId
    this.plantingDensityAndWeightHypothesisId = hypothesis?.plantingDensityAndWeightHypothesisId
    this.userId = hypothesis?.userId
    this.updateDate = hypothesis?.updateDate
  }
}

export class ClusterHypothesisDAO {
  id?: number
  sowingCluster: number
  firstCutPercentage: number
  distributionRate: number

  constructor(clusterHypothesisDAO?: ClusterHypothesisDAO) {
    if (clusterHypothesisDAO?.id) {
      this.id = clusterHypothesisDAO?.id
    }
    this.sowingCluster = clusterHypothesisDAO?.sowingCluster ?? 0
    this.firstCutPercentage = clusterHypothesisDAO?.firstCutPercentage ?? 0
    this.distributionRate = clusterHypothesisDAO?.distributionRate ?? 0
  }
}

export class CycleHypothesisDAO {
  id?: number
  landPreparation: number
  firstCutCycle: number
  secondCutCycle: number
  thirdCutCycle: number
  forthCutCycle: number
  fifthCutCycle: number
  sixthCutCycle: number
  seventhCutCycle: number
  distributionRate: number

  constructor(cycleHypothesisDAO?: CycleHypothesisDAO) {
    if (cycleHypothesisDAO?.id) {
      this.id = cycleHypothesisDAO?.id
    }
    this.landPreparation = cycleHypothesisDAO?.landPreparation ?? 0
    this.firstCutCycle = cycleHypothesisDAO?.firstCutCycle ?? 0
    this.secondCutCycle = cycleHypothesisDAO?.secondCutCycle ?? 0
    this.thirdCutCycle = cycleHypothesisDAO?.thirdCutCycle ?? 0
    this.forthCutCycle = cycleHypothesisDAO?.forthCutCycle ?? 0
    this.fifthCutCycle = cycleHypothesisDAO?.fifthCutCycle ?? 0
    this.sixthCutCycle = cycleHypothesisDAO?.sixthCutCycle ?? 0
    this.seventhCutCycle = cycleHypothesisDAO?.seventhCutCycle ?? 0
    this.distributionRate = cycleHypothesisDAO?.distributionRate ?? 0
  }
}

export class RateHypothesisDAO {
  id?: number
  securityRate: number
  distributionRate: number
  firstHarvestRate: number
  secondHarvestRate: number
  thirdHarvestRate: number
  forthHarvestRate: number
  fifthHarvestRate: number
  sixthHarvestRate: number
  seventhHarvestRate: number

  constructor(rateHypothesisDAO?: RateHypothesisDAO) {
    if (rateHypothesisDAO?.id) {
      this.id = rateHypothesisDAO?.id
    }
    this.securityRate = rateHypothesisDAO?.securityRate ?? 0
    this.firstHarvestRate = rateHypothesisDAO?.firstHarvestRate ?? 0
    this.secondHarvestRate = rateHypothesisDAO?.secondHarvestRate ?? 0
    this.thirdHarvestRate = rateHypothesisDAO?.thirdHarvestRate ?? 0
    this.forthHarvestRate = rateHypothesisDAO?.forthHarvestRate ?? 0
    this.fifthHarvestRate = rateHypothesisDAO?.fifthHarvestRate ?? 0
    this.sixthHarvestRate = rateHypothesisDAO?.sixthHarvestRate ?? 0
    this.seventhHarvestRate = rateHypothesisDAO?.seventhHarvestRate ?? 0
    this.distributionRate = rateHypothesisDAO?.distributionRate ?? 0
  }
}
export class DensityAndWeightDAO {
  id?: number
  density: number
  densityUnit: string
  averageWeight: number
  averageWeightUnit: string

  constructor(rateHypothesisDAO?: DensityAndWeightDAO) {
    if (rateHypothesisDAO?.id) {
      this.id = rateHypothesisDAO?.id
    }
    this.density = rateHypothesisDAO?.density ?? 0
    this.densityUnit = rateHypothesisDAO?.densityUnit ?? ''
    this.averageWeight = rateHypothesisDAO?.averageWeight ?? 0
    this.averageWeightUnit = rateHypothesisDAO?.averageWeightUnit ?? ''
  }
}

export enum HypothesisType {
  CLUSTER = 'cluster',
  CYCLE = 'cycle',
  RATE = 'rate',
  DENSITY_AND_WEIGHT = 'density and weight',
}
