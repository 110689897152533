import 'reflect-metadata'
import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'
import { Length } from '../enums/length.enum'
import { Reporting } from './reporting'

export class SaveUserPreference extends Reporting {
  @IsNumber()
  languageId: number

  @IsString()
  @IsNotEmpty()
  defaultYieldUnit: string

  @IsString()
  @IsNotEmpty()
  defaultDateFormat: string

  @IsNumber()
  @IsNotEmpty()
  geolocationRadius: number

  @IsString()
  @IsNotEmpty()
  geolocationRadiusUnit: Length

  @IsString()
  @IsNotEmpty()
  regionName: string

  @IsBoolean()
  @IsNotEmpty()
  limitDisplay: boolean

  @IsNumber()
  @IsOptional()
  campaignId?: number

  @IsBoolean()
  @IsNotEmpty()
  displayCloseCrops: boolean
}
