import { FormGroup } from '@angular/forms'

export const radiusValidator = (formGroup: FormGroup): { [key: string]: boolean } | null => {
  let isValid: boolean
  const radiusUnit: string = formGroup.get('geolocationRadiusUnit')?.value
  const radius: number = formGroup.get('geolocationRadius')?.value
  if (radiusUnit === 'miles') {
    isValid = radius >= 1 && radius <= 100
  } else if (radiusUnit === 'km') {
    isValid = radius >= 1 && radius <= 161
  } else {
    isValid = radius >= 1 && radius <= 100
  }
  return isValid ? null : { geolocationRadius: true }
}
