import { IsBoolean, IsNotEmpty, IsOptional, IsString } from 'class-validator'

export class SaveNotification {
  @IsString()
  @IsNotEmpty()
  contactType: string

  @IsBoolean()
  @IsOptional()
  sendEmail?: boolean

  @IsBoolean()
  @IsOptional()
  sendSms?: boolean
}
