import {
  Crop,
  CropActivity,
  CropActivityReport,
  DashboardSample,
  Intervention,
  Recommendation,
  Sample,
  Scouting,
} from '@agroone/entities'

export class CropActivityUtil {
  static cropActivitiesToCropActivityReport(
    crop: Crop,
    cropActivities: CropActivity[],
    interventions?: Intervention[]
  ): CropActivityReport {
    return new CropActivityReport({
      crop: crop,
      samples: cropActivities
        ?.filter((si) => si.type === Sample.className)
        .map((si) => new DashboardSample(Object.assign(si.activity as Sample, { date: si.date, user: '', crops: [] }))),
      interventions:
        interventions ??
        cropActivities?.filter((si) => si.type === Intervention.className).map((si) => si.activity as Intervention),
      recommendations: cropActivities
        ?.filter((si) => si.type === Recommendation.className)
        .map((si) => si.activity as Recommendation),
      scoutings: cropActivities
        ?.filter((si) => si.type === Scouting.className)
        .map((si) => si.activity as Scouting)
        .filter((s) => !s.disablingDate),
    })
  }
}
