import { IsNumber, IsOptional, IsString, ValidateIf } from 'class-validator'

export class PatchOfferDto {
  @IsString()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  groupCode?: string | null

  @IsString()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  confirmedHarvestDate?: string | null

  @IsString()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  lastCheckOutSiteDate?: string | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  status?: number | null

  @IsNumber()
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  durationLots?: number | null
}
