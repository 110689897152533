import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { firstValueFrom, from, map, MonoTypeOperatorFunction, Observable, of, pipe, switchMap } from 'rxjs'
import { Scouting } from '@agroone/entities'
import { HttpClient } from '@angular/common/http'
import { FileService } from '@agroone-app/core/file/file.service'
import { NetworkService } from '@agroone-front/shared'

@Injectable({
  providedIn: 'root',
})
export class ScoutingService {
  private scoutingUrl = `${environment.apiUrl}${environment.scoutings}`
  constructor(private http: HttpClient, private networkService: NetworkService) {}

  /**
   * Get all scouting by cropId
   *
   * @param cropId
   * @returns
   */
  public getByCropId(cropId: number): Observable<Scouting[]> {
    return from(
      this.networkService.getAllFromPaginated<Scouting>(this.scoutingUrl, {
        params: {
          cropId,
          noPagination: true,
        },
      })
    ).pipe(
      map((scoutings) => {
        scoutings?.map((scouting) => new Scouting(scouting)) ?? []
        return scoutings
      })
    )
  }

  public getScoutingsPictures(scoutingId: number): Observable<string[]> {
    return this.http.get<string[]>(`
      ${this.scoutingUrl}/${scoutingId}/pictures`)
  }

  public get(id: number): Observable<Scouting> {
    return this.http.get<Scouting>(`${this.scoutingUrl}/${id}`)
  }

  /**
   * Create scouting item
   * Add in loop picture (s) to the created scouting
   *
   * @param item
   * @param pictures The Base64 pictures to be sent
   * @return { Scouting }
   */

  public add(item: Scouting, pictures?: { name: string; content: string }[]): Observable<Scouting> {
    return this.http.post<Scouting>(this.scoutingUrl, item).pipe(this.pipeScouting(pictures))
  }

  /**
   * Updated scouting item Add/Update in loop picture(s) to the current scouting
   *
   * @param item
   * @param pictures The Base64 pictures to be sent
   */
  public update(item: Scouting, pictures?: { name: string; content: string }[]): Observable<Scouting> {
    return this.http.put<Scouting>(this.scoutingUrl, item).pipe(this.pipeScouting(pictures))
  }

  /**
   * Remove scouting by id
   *
   * @param id
   * @returns
   */
  public delete(id: number): Observable<Scouting> {
    return this.http.delete<Scouting>(`${this.scoutingUrl}/${id}`)
  }

  private pipeScouting(pictures: { name: string; content: string }[]): MonoTypeOperatorFunction<Scouting> {
    return pipe(
      switchMap((s: Scouting) => from(this.savePictures(pictures, s.id)).pipe(switchMap(() => this.get(s.id))))
    )
  }

  private async savePictures(pictureList: { name: string; content: string }[], scoutingId?: number): Promise<any> {
    if (!pictureList || !pictureList.length) {
      return of([])
    } else {
      for (const picture of pictureList) {
        const formData = FileService.toPictureFormData(picture.content, picture.name)
        await firstValueFrom(this.http.post(`${this.scoutingUrl}/${scoutingId}/pictures`, formData))
      }
    }
  }
}
