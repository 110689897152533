import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, Validate, ValidateNested } from 'class-validator'
import { IsISO8601WithTimezone } from '@agroone/helpers'
import { SaveWorkOrder } from './save-work-order'

export class SaveTPATask {
  public static build(raw: any) {
    return plainToInstance(SaveTPATask, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  growerId: number

  @IsNumber()
  @IsNotEmpty()
  supplierId: number

  @IsNumber()
  @IsOptional()
  croptypeId?: number

  @IsString()
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  completedDate?: string

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  plantedDate?: string

  @IsString()
  @IsNotEmpty()
  region: string

  @IsNumber()
  @IsNotEmpty()
  regionId: number

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => SaveWorkOrder)
  workOrder: SaveWorkOrder

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  disablingDate?: string

  @IsBoolean()
  @IsOptional()
  validated?: boolean

  @IsString()
  @IsNotEmpty()
  sourceId: string
}
