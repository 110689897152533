import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, delay, map, mergeMap, tap } from 'rxjs'
import { forkJoin, of } from 'rxjs'
import { RecommendationService } from '@agroone-app/shared/recommendation/services/recommendation.service'
import {
  loadRecommendationsSuccess,
  loadRecommendations,
  addRecommendation,
  addRecommendationSuccess,
  loadRecommendationsFailure,
  updateRecommendation,
  updateRecommendationSuccess,
  updateRecommendationFailure,
  deleteRecommendation,
  deleteRecommendationSuccess,
  deleteRecommendationError,
  addRecommendations,
  addRecommendationsSuccess,
  addRecommendationsFailure,
  addRecommendationFailure,
} from '../actions/recommendation.actions'
import { Store } from '@ngrx/store'
import { CropDetailState } from '@agroone-app/scene/crop-management/crop/store/reducers'
import { Recommendation } from '@agroone/entities'

@Injectable()
export class RecommendationEffects {
  constructor(
    private actions$: Actions,
    private recommendationService: RecommendationService,
    private store: Store<CropDetailState>
  ) {}

  public getRecommendationsByCropId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecommendations),
      debounceTime(100),
      mergeMap((action) =>
        this.recommendationService.getByCropId(action.cropId).pipe(
          map((recommendations) =>
            loadRecommendationsSuccess({
              cropId: action.cropId,
              recommendations,
            })
          ),
          catchError((e) => of(loadRecommendationsFailure({ error: `${e}` })))
        )
      )
    )
  )

  public addRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addRecommendation),
      mergeMap(({ payload }) =>
        this.recommendationService.add(payload.recommendation).pipe(
          map((recommendationResponse) =>
            addRecommendationSuccess({
              payload: {
                recommendation: recommendationResponse,
              },
            })
          ),
          catchError((e) =>
            of(
              addRecommendationFailure({
                error: `${e}`,
                message: `An error occurred during recommendation creation`,
              })
            )
          )
        )
      )
    )
  )

  public addRecommendations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addRecommendations),
      mergeMap(({ payload }) =>
        forkJoin(payload.recommendations.map((item: Recommendation) => this.recommendationService.add(item))).pipe(
          tap((results) => {
            results.forEach((v: Recommendation) => {
              this.store.dispatch(
                addRecommendationSuccess({
                  payload: {
                    recommendation: v,
                  },
                })
              )
            })
          }),
          map((results) => {
            return addRecommendationsSuccess({
              payload: {
                recommendations: results,
              },
            })
          }),
          catchError((error) =>
            of(
              addRecommendationsFailure({
                error,
                message: '',
              })
            )
          )
        )
      )
    )
  )

  public updateRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRecommendation),
      mergeMap(({ payload }) =>
        this.recommendationService.add(payload.recommendation).pipe(
          debounceTime(500),
          map((recommendation) =>
            updateRecommendationSuccess({
              payload: {
                cropId: payload.cropId,
                recommendation,
              },
            })
          ),
          catchError((e) => of(updateRecommendationFailure({ error: `${e}` })))
        )
      )
    )
  )

  public deleteRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRecommendation),
      mergeMap(({ cropId, recommendationId }) =>
        this.recommendationService.delete(recommendationId).pipe(
          map(() => deleteRecommendationSuccess({ cropId, recommendationId })),
          catchError((e) => of(deleteRecommendationError({ error: `${e}` })))
        )
      )
    )
  )
}
