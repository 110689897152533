export class MapStyle {
  color: string
  opacity?: number
}

export const MAP_STYLES: {
  [style: string]: MapStyle
} = {
  FIELDS: {
    color: '#ffd900',
  },
  CROPS: {
    color: '#f26716',
  },
  SELECTED_MARKER: {
    color: '#8672f1',
  },
  STENCIL: {
    color: '#fc00a8',
    opacity: 0.3,
  },
  DEFAULT_COLOR: {
    color: '#707070',
  },
}
