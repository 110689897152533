export class WorkList {
  id?: number
  workerId: number
  workerFirstName: string
  workerLastName: string
  sourceLastName: string
  sourceFirstName: string
  cropTypeId: number
  cropType: string
  agreoCode?: string
  cropId: number
  cropName: string
  targetDate: string
  taskName: string
  regionId: number
  regionName: string
  sourceId: number
  completedDate?: string
  fieldId: number
  fieldName: string
  area: number
  growerId: number
  growerName: string
  areaUnit: string
  plantedDate?: string
  varietyId: number
  varietyName: string

  constructor(workList?: WorkList) {
    this.id = workList.id
    this.workerId = workList.workerId
    this.workerFirstName = workList.workerFirstName
    this.workerLastName = workList.workerLastName
    this.sourceFirstName = workList.sourceFirstName
    this.sourceLastName = workList.sourceLastName
    this.cropTypeId = workList.cropTypeId
    this.cropType = workList.cropType
    this.agreoCode = workList.agreoCode
    this.cropId = workList.cropId
    this.cropName = workList.cropName
    this.targetDate = workList.targetDate
    this.taskName = workList.taskName
    this.regionId = workList.regionId
    this.regionName = workList.regionName
    this.completedDate = workList.completedDate
    this.fieldId = workList.fieldId
    this.fieldName = workList.fieldName
    this.area = workList.area
    this.growerId = workList.growerId
    this.growerName = workList.growerName
    this.areaUnit = workList.areaUnit
    this.plantedDate = workList.plantedDate
    this.varietyId = workList.varietyId
    this.varietyName = workList.varietyName
    this.sourceId = workList.sourceId
  }
}

export class WorkListDAO {
  id?: number
  workerId: number
  cropId: number
  targetDate: string
  taskName: string
  sourceId: number
  completedDate?: string
}
