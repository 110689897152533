import 'reflect-metadata'
import { plainToInstance, Type } from 'class-transformer'
import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { SaveTranslation } from './translation'
import { GenericEntityWithTranslation } from './generic-entity'
import { ExternalConstantRegion } from './constant'

export class Country {
  id?: number
  name: string | undefined
  translations: GenericEntityWithTranslation[] | undefined

  constructor(country?: Country) {
    this.id = country?.id
    this.name = country?.name
    this.translations = country?.translations
  }
}

export class SaveCountry {
  public static build(raw: any) {
    return plainToInstance(SaveCountry, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string

  @IsNotEmpty()
  @Type(() => SaveTranslation)
  translations: SaveTranslation[]
}
