import { UserPermissions } from '@agroone/entities'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@front-app-environments/environment'
import { SharedPermissionService } from '@agroone-front/shared'

@Injectable({
  providedIn: 'root',
})
export class SingleModuleAccessResolver {
  constructor(private permissionService: SharedPermissionService, private router: Router) {}

  resolve(): boolean {
    const availablePermissions: UserPermissions[] = [
      UserPermissions.CROP_MODULE_ACCESS,
      UserPermissions.HARVEST_MODULE_ACCESS,
      UserPermissions.PLANTING_MODULE_ACCESS,
      UserPermissions.MASTERDATA_MODULE_ACCESS,
      UserPermissions.USER_MODULE_ACCESS,
    ]
    const permissions: UserPermissions[] = []
    for (const permission of availablePermissions) {
      if (this.permissionService.hasPermission(permission)) {
        permissions.push(permission)
      }
    }
    if (permissions.length === 1) {
      switch (permissions[0]) {
        case UserPermissions.PLANTING_MODULE_ACCESS:
          this.router.navigate(['/planting-management'])
          break
        case UserPermissions.CROP_MODULE_ACCESS:
          this.router.navigate(['/crop-management'])
          break
        case UserPermissions.HARVEST_MODULE_ACCESS:
          window.location.href = environment.agropilotUrl
          break
        case UserPermissions.MASTERDATA_MODULE_ACCESS:
          window.location.href = environment.masterdataUrl
          break
        case UserPermissions.USER_MODULE_ACCESS:
          this.router.navigate(['/user-management'])
          break
      }
      return false
    } else {
      return true
    }
  }
}
