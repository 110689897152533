import { Constant, ConstantLite, Constants, ConstantsLite, HarvestGroupementType } from '@agroone/entities'
import { HttpClient, HttpParams } from '@angular/common/http'
import { map, Observable } from 'rxjs'

export abstract class SharedConstantService {
  protected abstract _constantToConstantLite(constant: Constant): ConstantLite[]

  abstract baseUrl: string

  public croptypes: ConstantLite[]

  protected constants: ConstantsLite

  private readonly ALL: string = 'ALL'

  public harvestGroupementType: HarvestGroupementType[] = []

  constructor(protected http: HttpClient) {}

  abstract init(): any

  public get harvestGroupementKey(): ConstantLite[] {
    return this.constants?.harvestGroupementKey ?? []
  }

  public get sectors(): ConstantLite[] {
    return this.constants?.harvestGroupementKey?.filter((c) => c.type === 'sector') ?? []
  }

  public get cropType(): ConstantLite[] {
    if (this.constants) {
      return this.constants.cropType
    }
    return []
  }
  public get harvestQuality(): ConstantLite[] {
    if (this.constants) {
      return this.constants.harvestQuality
    }
    return []
  }
  public get areaUnit(): ConstantLite[] {
    if (this.constants) {
      return this.constants.areaUnit
    }
    return []
  }
  public get yieldUnit(): ConstantLite[] {
    if (this.constants) {
      return this.constants.yieldUnit
    }
    return []
  }

  public get preharvestControl(): ConstantLite[] {
    if (this.constants) {
      return this.constants.preharvestControl
    }
    return []
  }

  public get scoutingSubtype(): ConstantLite[] {
    if (this.constants) {
      return this.constants.scoutingSubtype
    }
    return []
  }

  public get foodSafety(): ConstantLite[] {
    if (this.constants) {
      return this.constants.foodSafety
    }
    return []
  }

  public get scoutingSubitem(): ConstantLite[] {
    if (this.constants) {
      return this.constants.scoutingSubitem
    }
    return []
  }

  public get area(): ConstantLite[] {
    if (this.constants) {
      return this.constants.area
    }
    return []
  }

  public get weatherDamage(): ConstantLite[] {
    if (this.constants) {
      return this.constants.weatherDamage
    }
    return []
  }
  public get pressure(): ConstantLite[] {
    if (this.constants) {
      return this.constants.pressure
    }
    return []
  }
  public get environment(): ConstantLite[] {
    if (this.constants) {
      return this.constants.environment
    }
    return []
  }
  public get growthStage(): ConstantLite[] {
    if (this.constants) {
      return this.constants.growthStage
    }
    return []
  }
  public get weightUnit(): ConstantLite[] {
    if (this.constants) {
      return this.constants.weightUnit
    }
    return []
  }
  public get qualityDefect(): ConstantLite[] {
    if (this.constants) {
      return this.constants.qualityDefect
    }
    return []
  }
  public get scoutingItem(): ConstantLite[] {
    if (this.constants) {
      return this.constants.scoutingItem
    }
    return []
  }
  public get stage(): ConstantLite[] {
    if (this.constants) {
      return this.constants.stage
    }
    return []
  }
  public get recommendationSubtype(): ConstantLite[] {
    if (this.constants) {
      return this.constants.recommendationSubtype
    }
    return []
  }
  public get commentItem(): ConstantLite[] {
    if (this.constants) {
      return this.constants.commentItem
    }
    return []
  }
  public get commentSubItem(): ConstantLite[] {
    if (this.constants) {
      return this.constants.commentSubItem
    }
    return []
  }

  public get previousCropType(): ConstantLite[] {
    return this.constants?.previousCropType ?? []
  }

  public get cropVigor(): ConstantLite[] {
    return this.constants?.cropVigor ?? []
  }

  public get scoutingType(): ConstantLite[] {
    return this.constants?.scoutingType ?? []
  }

  public get pesticideType(): ConstantLite[] {
    return this.constants?.pesticideType ?? []
  }

  public get soilConditions(): ConstantLite[] {
    return this.constants?.soilConditions ?? []
  }

  public get weatherConditions(): ConstantLite[] {
    return this.constants?.weatherConditions ?? []
  }

  public get interventionType(): ConstantLite[] {
    return this.constants?.interventionType ?? []
  }

  public get interventionSubtype(): ConstantLite[] {
    return this.constants?.interventionSubtype ?? []
  }

  public get soilTexture(): ConstantLite[] {
    return this.constants?.soilTexture ?? []
  }

  public get drainage(): ConstantLite[] {
    return this.constants?.drainage ?? []
  }

  public get drainageType(): ConstantLite[] {
    return this.constants?.drainageType ?? []
  }

  public get drainageWidth(): ConstantLite[] {
    return this.constants?.drainageWidth ?? []
  }

  public get drainageClassification(): ConstantLite[] {
    return this.constants?.drainageClassification ?? []
  }

  public get stonePressure(): ConstantLite[] {
    return this.constants?.stonePressure ?? []
  }

  public get irrigationEquipement(): ConstantLite[] {
    return this.constants?.irrigationEquipement ?? []
  }

  public get obstruction(): ConstantLite[] {
    return this.constants?.obstruction ?? []
  }

  public get topography(): ConstantLite[] {
    return this.constants?.topography ?? []
  }

  public get worklistTaskList(): ConstantLite[] {
    return this.constants?.worklistTaskList ?? []
  }

  public get sampleTest(): ConstantLite[] {
    return this.constants?.sampleTest ?? []
  }

  public get seedingRateUnit(): ConstantLite[] {
    return this.constants?.seedingRateUnit ?? []
  }

  public get temperatureUnit(): ConstantLite[] {
    return this.constants?.temperatureUnit ?? []
  }

  public get preharvestDefect(): ConstantLite[] {
    return this.constants?.preharvestDefect ?? []
  }

  public get irrigationUnit(): ConstantLite[] {
    return this.constants?.irrigationUnit ?? []
  }

  public get lengthUnit(): ConstantLite[] {
    return this.constants?.lengthUnit ?? []
  }

  public get contactType(): ConstantLite[] {
    return this.constants?.contactType ?? []
  }

  public get growingSystem(): ConstantLite[] {
    return this.constants?.growingSystem ?? []
  }

  public get associatedCrop(): ConstantLite[] {
    return this.constants?.associatedCrop ?? []
  }

  public get alternativeSolution(): ConstantLite[] {
    return this.constants?.alternativeSolution ?? []
  }

  public get csrRegenAgriAnswer(): ConstantLite[] {
    return this.constants?.csrRegenAgriAnswer ?? []
  }

  public get rawMaterialProcessed(): ConstantLite[] {
    return this.constants?.rawMaterialProcessed ?? []
  }

  public get hypothesisType(): ConstantLite[] {
    return this.constants?.hypothesisType ?? []
  }

  public get productionArea(): ConstantLite[] {
    return this.constants?.productionArea ?? []
  }

  public get fieldType(): ConstantLite[] {
    return this.constants?.fieldType ?? []
  }

  public get windSpeedUnit(): ConstantLite[] {
    return this.constants?.windSpeedUnit ?? []
  }

  public get applicationEquipment(): ConstantLite[] {
    return this.constants?.applicationEquipment ?? []
  }

  public get applicator(): ConstantLite[] {
    return this.constants?.applicator ?? []
  }

  public get operationType(): ConstantLite[] {
    return this.constants?.operationType ?? []
  }

  public get invoiceInformation(): ConstantLite[] {
    return this.constants?.invoiceInformation ?? []
  }

  public get analysisType(): ConstantLite[] {
    return this.constants?.analysisType ?? []
  }

  public get samplePesticideType(): ConstantLite[] {
    return this.constants?.samplePesticideType ?? []
  }

  public get fieldBuffer(): ConstantLite[] {
    return this.constants?.fieldBuffer ?? []
  }

  public get windDirection(): ConstantLite[] {
    return this.constants?.windDirection ?? []
  }

  public get targetPest(): ConstantLite[] {
    return this.constants?.targetPest ?? []
  }

  public get nutrientsQuantityUnit(): ConstantLite[] {
    return this.constants?.nutrientsQuantityUnit ?? []
  }

  public get waterSource(): ConstantLite[] {
    return this.constants?.waterSource ?? []
  }

  public get obstructionSeverity(): ConstantLite[] {
    return this.constants?.obstructionSeverity ?? []
  }

  public get tillageCondition(): ConstantLite[] {
    return this.constants?.tillageCondition ?? []
  }

  public translate(code: string, constants: ConstantLite[]): string {
    if (!Array.isArray(constants) || !code) {
      return code
    }
    const c: ConstantLite = constants.find(
      (constant: ConstantLite) => constant.code.toLowerCase() === code.toLowerCase()
    )
    return c ? c.translation : code
  }

  protected constantsToConstantsLite(constants: Constants): ConstantsLite {
    const constantsLite: ConstantsLite = {}
    for (const key of Object.keys(constants)) {
      constantsLite[key] = this._constantToConstantLite(constants[key])
    }
    return constantsLite
  }

  public getAllGrowthStage(cropType: string): ConstantLite[] {
    return this.growthStage.filter(
      (growthStage) =>
        !!growthStage.cropTypes?.find(
          (tCropType) => tCropType.name?.toLowerCase() === cropType?.toLowerCase() || tCropType.name === this.ALL
        )
    )
  }

  public getAllQualityDefect(cropType: string): ConstantLite[] {
    return this.qualityDefect.filter(
      (qualityDefect) =>
        !!qualityDefect.cropTypes?.find(
          (tCropType) => tCropType.name.toLowerCase() === cropType?.toLowerCase() || tCropType.name === this.ALL
        )
    )
  }

  public getAllScoutingItem(filters: { subType?: string; cropType?: string; regionName?: string }): ConstantLite[] {
    return this.scoutingItem.filter((scoutingItem) => {
      let res: boolean = true

      if (filters?.cropType && scoutingItem.cropTypes) {
        res = !!scoutingItem.cropTypes?.find(
          (tCropType) => tCropType.name.toLowerCase() === filters.cropType?.toLowerCase() || tCropType.name === this.ALL
        )
      }

      if (res && filters?.subType && scoutingItem.subType) {
        res = scoutingItem.subType.toLowerCase() === filters.subType.toLowerCase() || scoutingItem.subType === this.ALL
      }

      if (res && filters?.regionName && scoutingItem.regions) {
        res = !!scoutingItem.regions?.find(
          (region) => region.name.toLowerCase() === filters.regionName?.toLowerCase() || region.name === this.ALL
        )
      }

      return res
    })
  }

  public getAllScoutingSubItem(filters: { item?: string }): ConstantLite[] {
    return this.scoutingSubitem.filter((scoutingItem) =>
      filters?.item && scoutingItem.item
        ? scoutingItem.item.toLowerCase() === filters.item.toLowerCase() || scoutingItem.item === this.ALL
        : true
    )
  }

  public getFiltered(constants: ConstantLite[], type?: string, regionName?: string): ConstantLite[] {
    let filteredConstants: ConstantLite[] = constants
    if (type) {
      filteredConstants = constants.filter((c) => c.type === type)
    }
    if (regionName) {
      filteredConstants = filteredConstants.filter((s) => {
        let res: boolean = true
        if (s.regions?.length) {
          res = s.regions?.some((r) => r.name === regionName) || s.regions?.some((r) => r.name === this.ALL)
        }
        return res
      })
    }
    return filteredConstants.sort((x, y) => x.priority - y.priority)
  }

  public getAllSeedingRateUnits(regionName: string): ConstantLite[] {
    return this.seedingRateUnit.filter((sru) => {
      let res: boolean = true
      if (res && regionName && sru.regions) {
        res = !!sru.regions?.find(
          (region) => region.name.toLowerCase() === regionName?.toLowerCase() || region.name === this.ALL
        )
      }

      return res
    })
  }

  public getStages(subtype: string): ConstantLite[] {
    if (subtype) {
      return this.stage.filter((stage) => stage?.subType?.toLowerCase() === subtype.toLowerCase())
    }
    return []
  }

  public getConstantByName(constantName: string, language: string): Observable<ConstantLite[]> {
    let params: HttpParams = new HttpParams()
    params = params.append('languageName', language)
    const options: {
      params: HttpParams
    } = {
      params,
    }

    return this.http
      .get<Constant>(`${this.baseUrl}/${constantName}`, options)
      .pipe(map((res: Constant) => this._constantToConstantLite(res)))
  }
}
