import { Variety } from '@agroone/entities'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ActionCreator, on, ReducerTypes } from '@ngrx/store'

import { clearVarieties, loadVarieties, loadVarietiesFailure, loadVarietiesSuccess } from '../actions/variety.actions'
export type VarietyState = EntityState<Variety>

export const varietyAdapter: EntityAdapter<Variety> = createEntityAdapter<Variety>()

export const initialVarietyState: VarietyState = varietyAdapter.getInitialState({
  // eslint-disable-next-line no-empty-pattern
  ids: ([] = []),
})

export const varietyOns: ReducerTypes<VarietyState, ActionCreator[]>[] = [
  on(loadVarieties, (state) => ({
    ...state,
  })),
  on(loadVarietiesSuccess, (state, action) => varietyAdapter.setAll(action?.varieties, { ...state })),
  on(loadVarietiesFailure, (state) => ({
    ...state,
  })),
  on(clearVarieties, () => initialVarietyState),
]
