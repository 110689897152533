import { registerDecorator, ValidationArguments } from 'class-validator'

export function RequireProperty(...properties: string[]) {
  return (object: object, propertyName: string) => {
    registerDecorator({
      name: 'Require',
      target: object.constructor,
      propertyName,
      constraints: properties,
      validator: {
        validate,
        defaultMessage,
      },
    })
  }
}

export function validate(value: string, args: ValidationArguments): boolean {
  return args.constraints.every(
    (constraint) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      args.object[`${args.constraints}`] !== undefined &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      args.object[`${args.constraints}`] !== null
  )
}

export function defaultMessage(args?: ValidationArguments): string {
  return `${args?.property} requires the following properties to be defined: ${args?.constraints.join(', ')}`
}
