import { Variety } from '@agroone/entities'
import { ActionCreator, createAction, props } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'

export enum VarietyActionTypes {
  LOAD_VARIETIES = '[Variety] Load Varieties',
  LOAD_VARIETIES_SUCCESS = '[Variety] Load Varieties Success',
  LOAD_VARIETIES_FAILURE = '[Variety] Load Varieties Failure',
  CLEAR_VARIETIES = '[Variety] Clear Varieties',
}

export const loadVarieties: ActionCreator<
  VarietyActionTypes.LOAD_VARIETIES,
  () => TypedAction<VarietyActionTypes.LOAD_VARIETIES>
> = createAction(VarietyActionTypes.LOAD_VARIETIES)
export const loadVarietiesSuccess: ActionCreator<
  VarietyActionTypes.LOAD_VARIETIES_SUCCESS,
  (props: { varieties: Variety[] }) => {
    varieties: Variety[]
  } & TypedAction<VarietyActionTypes.LOAD_VARIETIES_SUCCESS>
> = createAction(VarietyActionTypes.LOAD_VARIETIES_SUCCESS, props<{ varieties: Variety[] }>())
export const loadVarietiesFailure: ActionCreator<
  VarietyActionTypes.LOAD_VARIETIES_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<VarietyActionTypes.LOAD_VARIETIES_FAILURE>
> = createAction(VarietyActionTypes.LOAD_VARIETIES_FAILURE, props<{ error: any }>())
export const clearVarieties: ActionCreator<
  VarietyActionTypes.CLEAR_VARIETIES,
  () => TypedAction<VarietyActionTypes.CLEAR_VARIETIES>
> = createAction(VarietyActionTypes.CLEAR_VARIETIES)
