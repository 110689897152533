import { Injectable, OnDestroy } from '@angular/core'
import { Observable, BehaviorSubject, Subscription, fromEvent, merge, of, map } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ConnectionService implements OnDestroy {
  /**
   * Get an Observable of the application network status.<br/>
   * <b>true</b> => online <br/>
   * <b>false</b> => offline
   */
  public connection$: Observable<boolean>

  /**
   * Get the current status of the network.<br/>
   * <b>true</b> => online <br/>
   * <b>false</b> => offline
   */
  public get connected(): boolean {
    return this.connectionSubject.getValue()
  }

  private connectionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  private connectionDisposable: Subscription

  constructor() {
    this.connection$ = this.connectionSubject.asObservable()

    this.connectionDisposable = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    ).subscribe((connected: boolean) => {
      this.connectionSubject.next(connected)
    })
  }

  ngOnDestroy() {
    if (this.connectionDisposable) {
      this.connectionDisposable.unsubscribe()
    }
  }
}
