import { Injectable, OnDestroy } from '@angular/core'
import { firstValueFrom, Observable, Subject } from 'rxjs'
import { ZipEntry } from './models/zipEntry.interface'
import { ZipTask } from './models/zipTask.interface'
import { ZipTaskProgress } from './models/zipTaskProgress.interface'
import { ShapeFiles } from './models/shapefiles.interface'
import { map } from 'rxjs'
import { LoggerService } from '@agroone-front/shared'
import Compress from 'compress.js'
import { parse } from 'papaparse'
import Rotator from 'exif-auto-rotate'
import exifr from 'exifr'

// This is added globally by the zip.js library
declare const zip: any
@Injectable({
  providedIn: 'root',
})
export class FileService {
  [x: string]: any
  private compressor: any = new Compress()

  constructor(private logger: LoggerService) {
    zip.workerScriptsPath = 'scripts/'
  }

  public static async fileToBase64(file: File): Promise<string> {
    const fileReader: FileReader = new FileReader()
    const promise: Promise<string> = new Promise<string>((resolve, reject) => {
      fileReader.onloadend = () => resolve(fileReader.result as string)
      fileReader.onerror = (err) => reject(err)
    })
    fileReader.readAsDataURL(file)
    return promise
  }

  public static base64ToFile(base64: string, fileName: string): File {
    const [mimePart, image]: string[] = base64.split(',')
    const mime: string = mimePart.substring(mimePart.lastIndexOf(':') + 1, mimePart.lastIndexOf(';'))

    const byteString: string = window.atob(image)
    const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array: Uint8Array = new Uint8Array(arrayBuffer)
    for (let i: number = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i)
    }
    const file: File = new File([int8Array], fileName, {
      type: mime,
    })

    return file
  }

  public static base64ToZip(base64: string, fileName: string): Blob {
    const byteString: string = window.atob(base64)
    const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array: Uint8Array = new Uint8Array(arrayBuffer)
    for (let i: number = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i)
    }
    const file: File = new File([int8Array], fileName, {
      type: 'application/zip',
    })

    return file
  }

  public static toPictureFormData(str: string, fileName: string = 'picture'): FormData {
    const file: File = FileService.base64ToFile(str, fileName)
    const body: FormData = new FormData()
    body.append('picture', file)
    return body
  }

  public getZipEntries(file: File): Observable<Array<ZipEntry>> {
    return new Observable((subscriber) => {
      const reader: any = new zip.BlobReader(file)
      zip.createReader(
        reader,
        (zipReader) => {
          zipReader.getEntries((entries) => {
            subscriber.next(entries)
            subscriber.complete()
          })
        },
        (message) => {
          subscriber.error({ message })
        }
      )
    })
  }

  public getZipData(entry: ZipEntry): ZipTask {
    const progress: Subject<ZipTaskProgress> = new Subject<ZipTaskProgress>()
    const data: Observable<Blob> = new Observable<Blob>((subscriber) => {
      const writer: any = new zip.BlobWriter()
      ;(entry as any).getData(
        // eslint-disable-next-line no-use-before-define
        writer,
        (blob) => {
          subscriber.next(blob)
          subscriber.complete()
          progress.next(null)
        },
        (current, total) => {
          progress.next({ active: true, current, total })
        }
      )
    })
    return { progress, data }
  }

  public async filesToKmlOrShapefile(files: File[]): Promise<{ kml: File[]; shp: ShapeFiles[] }> {
    if (files?.length) {
      return {
        shp: await this.getShpDbfPairFiles(files),
        kml: this.getKmlFiles(files),
      }
    } else {
      throw new Error('No files provided')
    }
  }

  public async rotateFiles(files: File[]) {
    // We force the rotation of images because the exif's orientation metadata (from mobile's camera)
    // is not interpreted in web browsers
    const noExifFiles: File[] = []
    for (const f of files) {
      const orientation: number = await exifr.orientation(f)
      if (orientation) {
        const rotate: string = await Rotator.createRotatedImageAsync(f, 'base64')
        noExifFiles.push(FileService.base64ToFile(rotate, f.name))
      } else {
        noExifFiles.push(f)
      }
    }
    return noExifFiles
  }

  public async compressFiles(files: File[], maxSize: number = 3): Promise<File[]> {
    const orientationFiles: File[] = await this.rotateFiles(files)
    const res: any = await this.compressor.compress(orientationFiles, {
      size: maxSize,
      resize: false,
      quality: 1,
    })
    return res.map((r) => FileService.base64ToFile(`${r.prefix}${r.data}`, r.alt))
  }

  public async readCsvFile(file: File): Promise<string> {
    const fileReader: FileReader = new FileReader()
    const promise: Promise<string> = new Promise<string>((resolve, reject) => {
      fileReader.onloadend = () => resolve(fileReader.result as string)
      fileReader.onerror = (err) => reject(err)
    })
    fileReader.readAsText(file)
    return promise
  }

  public formatData<T>(data: string, titles: string[]): T[] {
    try {
      const rows: string[] = data
        .slice(data.indexOf('\n') + 1)
        .split('\n')
        .filter(Boolean)

      if (titles?.length && rows?.length) {
        return rows.map((row) => {
          const values: string[] = row.replace('\r', '').split(',')
          const formatedValues: string[] = this.formatFloats(values)
          const obj: T = titles.reduce((object, curr, i) => {
            object[curr] = formatedValues[i]
            return object
          }, {}) as T

          return obj
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  public async parseCsv(file: File): Promise<Array<string[]>> {
    const data: Array<string[]> = await new Promise((resolve) =>
      parse(file, {
        complete: (results) => {
          resolve(results.data as Array<string[]>)
        },
        skipEmptyLines: true,
      })
    )
    return data
  }

  public formatParsedCsv<T>(data: Array<string[]>): T[] {
    const titles: string[] = data[0]
    const formatedData: any[] = data
      .slice(1, data.length)
      .map((obj) => titles.reduce((object, title, i) => ({ ...object, [`${title}`]: obj[Number(i)] }), {}))
    return formatedData.map((obj) => obj as T)
  }

  public getCsvTitles(data: string): string[] {
    return data.slice(0, data.indexOf('\n')).replace('\r', '').trim().split(',')
  }

  public async getPicturesAsBase64(pictures: File[]): Promise<{ name: string; content: string }[]> {
    if (pictures?.length) {
      const res: { name: string; content: string }[] = []
      const resizedPictures: File[] = await this.compressFiles(pictures)
      for (const picture of resizedPictures) {
        const b64Picture: string = await FileService.fileToBase64(picture)
        res.push({ name: picture.name, content: b64Picture })
      }
      return res
    }
    return []
  }

  private getKmlFiles(files: File[]): File[] {
    return files.filter((f) => f.name.toLowerCase().endsWith('.kml'))
  }

  private async getShpDbfPairFiles(files: File[]): Promise<ShapeFiles[]> {
    let pairs: ShapeFiles[] = []
    if (files?.length) {
      // Categorize files
      const zipFiles: File[] = []
      const shpFiles: File[] = []
      const dbfFiles: File[] = []
      for (const file of files) {
        if (file.name.toLowerCase().endsWith('.shp')) {
          shpFiles.push(file)
        } else if (file.name.toLowerCase().endsWith('.dbf')) {
          dbfFiles.push(file)
        } else if (file.name.toLowerCase().endsWith('.zip')) {
          zipFiles.push(file)
        }
      }

      // Make file pairs with non-zip shp/dbf files
      for (const shpFile of shpFiles) {
        const dbfFile: File = dbfFiles.find((f) => f.name.slice(0, -4) === shpFile.name.slice(0, -4))
        pairs.push({ shp: shpFile, dbf: dbfFile })
      }

      // Extract zip files and add contained pairs
      for (const zipFile of zipFiles) {
        const unzippedFiles: File[] = await this.unzip(zipFile)
        const zipPairs: ShapeFiles[] = await this.getShpDbfPairFiles(unzippedFiles)
        pairs = pairs.concat(zipPairs)
      }
    }

    return pairs
  }

  private async unzip(file: File): Promise<File[]> {
    const data: File[] = []
    const zipEntries: ZipEntry[] = await firstValueFrom(this.getZipEntries(file))
    const zipTasks: {
      name: string
      lastModified: number
      task: ZipTask
    }[] = []

    for (const entry of zipEntries) {
      zipTasks.push({
        name: entry.filename,
        lastModified: new Date(entry.lastModDate).valueOf(),
        task: this.getZipData(entry),
      })
    }

    for (const task of zipTasks) {
      task?.task?.progress?.subscribe((progress) => this.logger.log('Unzip progress', progress))

      data.push(
        await firstValueFrom(
          task?.task?.data?.pipe(
            map((blob: Blob) => {
              // Transform Blob to File
              const f: any = blob
              f.name = task.name
              f.lastModified = task.lastModified
              return f as File
            })
          )
        )
      )
    }

    return data
  }

  // We use split with ',' to extract values because that's the point of CSV.
  // Except we have floats in our data which are written 0,5 in french instead of 0.5 .
  // The output for 0,5 is ["\"0", "5"\"]
  // This is a problem as the order in the array is crutial for the association {key: value}
  // This function searches for such values and rewrites them properly as one value
  // private formatFloats(values: string[]) {
  //   return values.reduce((arr, value) => {
  //     if (value[value.length - 1] === '"') {
  //       const previousValue = arr[arr.length - 1]
  //       if (previousValue[0] === '"') {
  //         return [
  //           ...arr.slice(0, arr.length - 1),
  //           `${previousValue.slice(1, previousValue.length)}.${value.slice(
  //             0,
  //             value.length - 1
  //           )}`
  //         ]
  //       }
  //     } else {
  //       return [...arr, value]
  //     }
  //   }, [])
  // }

  private formatFloats(values: string[]): string[] {
    if (values.some((v) => v.includes('"'))) {
      const cleanedValues: string[] = []

      for (let i: number = 0; i < values.length; i++) {
        if (values[i].includes('"') && values[i + 1]?.includes('"')) {
          const leftPart: string = values[i].replace('"', '').replace(' ', '')
          const rightPart: string = values[i + 1].replace('"', '').replace(' ', '')
          cleanedValues.push(`${leftPart}.${rightPart}`)
          i++
        } else {
          cleanedValues.push(values[i])
        }
      }
      return cleanedValues
    } else {
      return values
    }
  }
}
