import { dateToISO8601 } from '@agroone/helpers'

export class Reporting {
  createdDate?: string
  updatedDate?: string
  createdBy?: string
  updatedBy?: string
}

export class ReportingDAO {
  createdDate?: string
  updatedDate?: string
  createdBy?: string
  updatedBy?: string

  constructor(reporting: ReportingDAO) {
    if (reporting.createdBy) {
      this.createdBy = reporting.createdBy
      this.createdDate = dateToISO8601()
    }

    if (reporting.updatedBy) {
      this.updatedBy = reporting.updatedBy
      this.updatedDate = dateToISO8601()
    }
  }
}
