<div
  style="
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    font-family: sans-serif;
  "
>
  Access denied. Account inactive.
</div>
