import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SortPipe } from './pipes/sort.pipe'

@NgModule({
  imports: [CommonModule],
  providers: [],
  declarations: [SortPipe],
  exports: [SortPipe],
})
export class SortModule {}
