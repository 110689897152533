import { LoadingState } from './state/loading/loading.reducer'
import { selectLoadingSpecifications, selectLoadingVarieties } from './state/loading/loading.selector'
import { AuthService, LoggerService, SharedConstantService, SharedUserService } from '@agroone-front/shared'
import { UserDto } from '@agroone/entities'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map, switchMap, tap } from 'rxjs'
import { environment } from '@front-app-environments/environment'
import { loadSpecifications } from './scene/crop-management/crop/store/actions/specification.actions'
import { loadVarieties } from './scene/crop-management/crop/store/actions/variety.actions'
import { VarietyState } from './scene/crop-management/crop/store/reducers'
import { SpecificationState } from './scene/crop-management/crop/store/reducers/specification.reducer'
import { loadGrowers } from './scene/crop-management/grower/store/actions/grower.actions'
import { GrowerState } from './scene/crop-management/grower/store/reducers'
import { CropService } from './shared/crop/services/crop.service'
import { LanguageService } from './shared/languages/languages.service'
import { RegionService } from './shared/region/services/region.service'
import { SettingsService } from './shared/settings/settings.service'

export const init =
  (
    userService: SharedUserService,
    constantService: SharedConstantService,
    settingsService: SettingsService,
    regionService: RegionService,
    languageService: LanguageService,
    growerStore: Store<GrowerState>,
    cropService: CropService,
    loadingStore: Store<LoadingState>,
    logger: LoggerService,
    authService: AuthService,
    varietyStore: Store<VarietyState>,
    specificationStore: Store<SpecificationState>
  ) =>
  async () => {
    loadExternalScript(environment.dynatraceScriptSrc)
    let user: UserDto
    // todo: remove the initialization lines below after migrating them to libs/
    logger.init(environment.logLevel, environment.serverLogLevel)
    authService.init(environment.cognitoDomain, environment.cognitoClientId, environment.apiUrl)

    // Skip app init when logout
    if (document.location.pathname === '/logout') {
      return Promise.resolve()
    }

    return new Promise((resolve, _) => {
      const toBeResolved: Promise<any> = environment.local
        ? new Promise((resolve) => resolve(true))
        : navigator.serviceWorker.ready
      toBeResolved.then(() => {
        combineLatest([userService.init(), regionService.init()])
          .pipe(
            switchMap((res) =>
              combineLatest([languageService.init(), regionService.activeRegion]).pipe(map(() => res))
            ),
            switchMap((res) => {
              user = res[0]
              growerStore.dispatch(loadGrowers())
              cropService.dispatchLoadCrops()
              varietyStore.dispatch(loadVarieties())
              specificationStore.dispatch(loadSpecifications())
              return combineLatest([constantService.init(), settingsService.init(res[1])]).pipe(
                tap(() => logger.log('Active region data is loaded')),
                switchMap(() => loadingStore.select(selectLoadingVarieties)),
                filter((isLoadingVarieties) => !isLoadingVarieties),
                tap(() => logger.log('Varieties data is loaded')),
                switchMap(() => loadingStore.select(selectLoadingSpecifications)),
                filter((isLoadingSpecifications) => !isLoadingSpecifications),
                tap(() => logger.log('Specifications data is loaded'))
              )
            })
          )
          .subscribe({
            next: () => {
              resolve(true)
            },
            error: (error) => {
              logger.error(error)
              resolve(false)
            },
          })
      })
    })
  }

function loadExternalScript(scriptUrl: string): void {
  if (!scriptUrl || !navigator.onLine) {
    return
  }

  const scriptElement = document.createElement('script')
  scriptElement.src = scriptUrl
  scriptElement.type = 'text/javascript'
  document.body.appendChild(scriptElement)
}
