import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { AbstractControl, FormBuilder } from '@angular/forms'
import { ConstantLite, SortingRule } from '@agroone/entities'
import { Datasource, IDatasource } from 'ngx-ui-scroll'
import { Subscription } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'app-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.sass'],
})
export class AutocompleteSelectComponent<T> implements OnChanges, OnDestroy {
  @Input() list: T[]
  @Input() mostUsedItemsList: T[] = []
  @Input() formCtrl?: AbstractControl
  @Input() filterFn?: (value: T, input: T | string) => boolean
  @Input() displayFn?: (value: T) => string
  @Input() sortFn?: (a: T, b: T) => number
  @Input() optional?: boolean = false
  @Input() sortingRules?: SortingRule[]
  @Input() itemHeight?: number = 48
  @Input() numberOfItemsToDisplay?: number = 4
  @Input() isValid?: boolean = false
  @Input() customStyle?: boolean = false

  @Output() selectOption: EventEmitter<T> = new EventEmitter()

  public tmpformCtrl: AbstractControl
  public datasource: IDatasource
  public filteredMostUsedItemsList: T[]
  public uniqueId: string

  private tmpformCtrlSubscription: Subscription

  constructor(private formBuilder: FormBuilder) {
    this.uniqueId = uuidv4()
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.formCtrl && changes.formCtrl.currentValue != null) {
      this.tmpformCtrl = this.formCtrl
    }
    if (!this.tmpformCtrl) {
      if (changes.formCtrl) {
        this.tmpformCtrl = this.formCtrl
      } else {
        this.tmpformCtrl = this.formBuilder.control(null)
      }
    }
    if (this.tmpformCtrlSubscription) {
      this.tmpformCtrlSubscription.unsubscribe()
    }
    if (this.tmpformCtrl) {
      this.tmpformCtrlSubscription = this.tmpformCtrl?.valueChanges?.pipe(untilDestroyed(this)).subscribe(async () => {
        this.datasource = await this.updateDatasource(this.list, this.datasource)
        if (this.mostUsedItemsList?.length) {
          this.filteredMostUsedItemsList = this.mostUsedItemsList.filter((item) =>
            this.filter(item, this.tmpformCtrl.value)
          )
        }
      })
    }
    if (changes?.list && this.list?.length) {
      this.datasource = await this.updateDatasource(this.list, this.datasource)
    }
    if (changes?.mostUsedItemsList && this.mostUsedItemsList?.length) {
      this.filteredMostUsedItemsList = this.mostUsedItemsList
    }
  }

  ngOnDestroy() {
    if (this.tmpformCtrlSubscription) {
      this.tmpformCtrlSubscription.unsubscribe()
    }
  }

  /**
   * Filter using the provided filter function or the default constant filter function
   */
  public filter(value: T, input: T | string): boolean {
    return this.filterFn ? this.filterFn(value, input) : this.filterConstant(value as any, input as any)
  }

  /**
   * Display using the provided display function or the default constant display function
   */
  public display(value?: T): string | undefined {
    return this.displayFn ? this.displayFn(value) : this.displayConstant(value as unknown as ConstantLite)
  }

  public sort(valueA: T, valueB: T): number {
    return this.sortFn ? this.sortFn(valueA, valueB) : this.sortConstant(valueA as any, valueB as any)
  }

  public filterConstant(value: ConstantLite, input: ConstantLite | string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string = input instanceof Object ? input.translation?.toLowerCase() : input.toLowerCase()
    return value.translation.toLowerCase().indexOf(filterValue) !== -1
  }

  public displayConstant(value?: ConstantLite): string | undefined {
    return value ? value.translation : undefined
  }

  public sortConstant(a: ConstantLite, b: ConstantLite): number {
    if (typeof a?.priority === 'number') {
      // already sorted by priority if priority exists
      return 0
    }
    if (this.sortFn === null) {
      return 0
    }
    return a?.translation?.toLowerCase().localeCompare(b.translation.toLowerCase())
  }

  onCloseSelect() {
    this.tmpformCtrl?.markAsDirty()
  }

  public async checkOverlay() {
    // Because the virtual scroll opens inside a cdk-overlay panel
    // the initial size is 0. Every computation is wrong unless we
    // tell the viewport to check again after the overlay is opened.
    this.datasource = await this.updateDatasource(this.list, this.datasource)
  }

  private async updateDatasource(list: T[], dataSource: IDatasource) {
    let filteredResult: T[] = list?.filter((value) => this.filter(value, this.tmpformCtrl.value)) ?? []
    filteredResult = filteredResult.sort(this.sort.bind(this))
    if (this.mostUsedItemsList?.length) {
      filteredResult = filteredResult.filter((item) => !this.mostUsedItemsList.includes(item))
    }
    let otherResults: T[] =
      list?.filter((item) => {
        const itemExist: T = filteredResult.find((item2) => item === item2)
        return !itemExist
      }) ?? []
    otherResults = otherResults.sort(this.sort.bind(this))
    const res: T[] = [...filteredResult, ...otherResults]
    const newDatasource: IDatasource = new Datasource<T>({
      get: (index, count, success) => {
        const data: T[] = []
        for (let i: number = 0; i < count; i++) {
          if (res[index + i - 1]) {
            data.push(res[index + i - 1])
          }
        }
        success(data)
      },
    })
    // adapter.relax() is needed to make sure there is no pending
    // action before other actions
    // We need to reset the datasource with actual values and then
    // reload the component to reset the scroll bar at the beginning
    if (dataSource) {
      await dataSource.adapter.relax()
      await dataSource.adapter.reset(newDatasource)
      await dataSource.adapter.relax()
      await dataSource.adapter.check()
      return dataSource
    } else {
      return newDatasource
    }
  }
}
