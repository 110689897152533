<div [ngClass]="data.style" class="confirm">
  <h2 mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content class="confirm-content">
    <div [innerHTML]="content"></div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="confirm-actions">
    <button color="accent" mat-flat-button (click)="save()">
      {{ 'YES' | translate }}
    </button>
    <button class="close" color="accent" mat-flat-button mat-dialog-close>
      {{ 'NO' | translate }}
    </button>
  </mat-dialog-actions>
</div>
