import 'reflect-metadata'
import { Location } from './location'
import { plainToInstance, Type } from 'class-transformer'
import {
  ArrayMaxSize,
  IsArray,
  IsBoolean,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Length,
  Min,
  Validate,
  ValidateIf,
  ValidateNested,
} from 'class-validator'
import { SaveLocation } from './save-location'
import { SaveNotification } from './save-notification'
import { SaveReporting } from './save-reporting'
import { IsISO8601WithTimezone, RequireProperty, UniquePropertyInArray } from '@agroone/helpers'

export class SaveMicrobiologicalTest {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  @IsNotEmpty()
  @Length(1)
  name: string

  @IsArray()
  @IsNotEmpty()
  controls: string[]

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: Location

  @IsNumber()
  @Min(0)
  @RequireProperty('areaUnit')
  @IsNotEmpty()
  area: number

  @IsString()
  @RequireProperty('area')
  @IsNotEmpty()
  areaUnit: string

  @IsNumber()
  @Min(0)
  @RequireProperty('weightUnit')
  @IsNotEmpty()
  weight: number

  @IsString()
  @RequireProperty('weight')
  @IsNotEmpty()
  weightUnit: string

  @IsInt()
  @Min(0)
  @IsNotEmpty()
  bedCount: number

  @IsNumber()
  @IsIn([60, 120, 180, 300])
  @IsNotEmpty()
  size: number
}

export class SaveMicrobiological {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  user: string

  @IsString()
  @IsNotEmpty()
  username: string

  @IsString()
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsNumber()
  @Min(0)
  @IsOptional()
  humidity?: number

  @IsNumber()
  @RequireProperty('temperatureUnit')
  @IsOptional()
  temperature?: number

  @IsString()
  @RequireProperty('temperature')
  @IsOptional()
  temperatureUnit?: string

  @IsNumber()
  @IsNotEmpty()
  laboratoryId: number

  @ValidateNested()
  @Type(() => SaveMicrobiologicalTest)
  tests: SaveMicrobiologicalTest[]
}

export class SavePreharvestControl {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  user: string

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsString()
  @IsNotEmpty()
  controlCode: string

  @IsNotEmpty()
  @IsNumber()
  value: number

  @IsString()
  @IsOptional()
  unit?: string
}

export class SavePreharvest {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SavePreharvestControl)
  controls: SavePreharvestControl[]

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: Location
}

export class SaveSoilAnalysis {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  user: string

  @IsString()
  @IsNotEmpty()
  username: string

  @IsString()
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsNumber()
  @IsNotEmpty()
  laboratoryId: number
}

export class SaveWaterAnalysis {
  @IsString()
  @IsNotEmpty()
  user: string

  @IsString()
  @IsNotEmpty()
  username: string

  @IsString()
  @IsNotEmpty()
  date: string

  @IsNumber()
  @IsNotEmpty()
  laboratoryId: number

  @IsNumber()
  @IsOptional()
  humidity?: number

  @IsNumber()
  @IsOptional()
  temperature?: number

  @IsString()
  @IsOptional()
  temperatureUnit?: string

  @IsString()
  @IsOptional()
  source?: string

  @IsString()
  @IsNotEmpty()
  sourceType: string

  @IsString()
  @IsNotEmpty()
  samplingLocation: string
}

export class SaveSamplePesticide {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsNumber()
  @Min(0)
  @IsOptional()
  humidity?: number

  @IsNumber()
  @RequireProperty('temperatureUnit')
  @IsOptional()
  temperature?: number

  @IsString()
  @RequireProperty('temperature')
  @IsOptional()
  temperatureUnit?: string

  @IsString()
  @IsNotEmpty()
  user: string

  @IsNotEmpty()
  @IsNumber()
  laboratoryId: number

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: Location

  @IsNumber()
  @IsNotEmpty()
  analysisTypeId: number

  @IsString()
  @IsOptional()
  analysisTypeComment?: string

  @IsString()
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  harvestDate: string

  @IsString()
  @IsOptional()
  invoiceInformation?: string

  @IsString()
  @IsNotEmpty()
  sampleType: string
}

export class SaveSample extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveSample, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @ValidateIf((o) => !o.fieldId)
  @IsNumber()
  @IsNotEmpty()
  cropId?: number

  @ValidateIf((o) => !o.cropId)
  @IsNumber()
  @IsNotEmpty()
  fieldId?: number

  @IsString()
  @IsNotEmpty()
  region: string

  @IsString()
  @IsOptional()
  plantingId?: string

  @IsString()
  @IsOptional()
  attachmentPath?: string

  @IsArray()
  @ArrayMaxSize(3)
  @IsOptional()
  picturesPath?: string[]

  @IsString()
  @IsOptional()
  note?: string

  @IsString()
  @IsNotEmpty()
  type: string

  @IsOptional()
  @UniquePropertyInArray('contactType')
  @ValidateNested()
  @Type(() => SaveNotification)
  notifications?: SaveNotification[]

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveMicrobiological)
  microbiological?: SaveMicrobiological

  @IsOptional()
  @ValidateNested()
  @Type(() => SavePreharvest)
  preharvest?: SavePreharvest

  @IsOptional()
  @IsString()
  date?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveSamplePesticide)
  pesticide?: SaveSamplePesticide

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveSoilAnalysis)
  soilAnalysis?: SaveSoilAnalysis

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveWaterAnalysis)
  waterAnalysis?: SaveWaterAnalysis

  @IsOptional()
  @IsBoolean()
  validated?: boolean
}
