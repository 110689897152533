import { Injector } from '@angular/core'

/**
 * Singleton that contains the main injector.
 * We should be very careful when using this sort of hack because it's not
 * an angular behavior !
 */
export class AppInjector {
  private static injector: Injector

  static setInjector(injector: Injector) {
    AppInjector.injector = injector
  }

  static getInjector(): Injector {
    return AppInjector.injector
  }
}
