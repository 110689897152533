import 'reflect-metadata'
import { SaveGrowerContact } from './grower'
import { Language } from './language'

export class Contact {
  id?: number
  firstName: string
  lastName: string
  languageId: number
  language: string | undefined
  phoneNumbers: string[]
  phoneNumbersSMS?: string[] | null
  emailAddress: string
  priority: number
  active: boolean
  growerId?: number
  mailAddress?: string

  constructor(contact: ContactDto | Contact) {
    const emailAddress: string = contact?.emailAddress
    this.id = contact.id
    this.lastName = contact.lastName
    this.firstName = contact.firstName
    this.languageId = contact.languageId
    this.growerId = contact.growerId
    this.language = contact.language
    this.phoneNumbers =
      typeof contact.phoneNumbers === 'string' ? contact.phoneNumbers?.split(',') : contact.phoneNumbers ?? null
    this.phoneNumbersSMS =
      typeof contact.phoneNumbersSMS === 'string'
        ? contact.phoneNumbersSMS?.split(',')
        : contact.phoneNumbersSMS ?? null
    this.emailAddress = emailAddress
    this.priority = contact.priority
    this.active = !!contact.active
  }

  /**
   * AO-4300 - Make the contact first name, last name and email consistent
   * @param contact
   * @private
   */
  static normalizeContactInfo(contact: ContactDto | SaveGrowerContact): ContactDto | SaveGrowerContact {
    const updatedFirstName: string = contact.firstName
      .split(' ')
      .map((v) => {
        v = v.toLowerCase()
        if (v?.length) {
          return v[0].toUpperCase() + v.substring(1)
        }
        return v
      })
      .join(' ')
      .split('-')
      .map((v) => {
        if (v?.length) {
          return v[0].toUpperCase() + v.substring(1)
        }
        return v
      })
      .join('-')

    contact.firstName = updatedFirstName
    contact.lastName = contact.lastName.toUpperCase()
    contact.emailAddress = contact.emailAddress.toLowerCase()
    return contact
  }
}

export class ContactDto {
  id?: number
  firstName: string
  lastName: string
  languageId: number
  language?: string
  phoneNumbers: string
  phoneNumbersSMS?: string | null
  emailAddress: string
  priority: number
  active: number
  growerId?: number
  mailAddress?: string

  constructor(contact: Contact, languages: Language[]) {
    const emailAddress: string = contact?.emailAddress

    if (contact?.id) {
      this.id = contact?.id
    }
    this.lastName = contact.lastName
    this.firstName = contact.firstName
    this.growerId = contact.growerId
    this.languageId = contact.languageId ?? languages?.find((l) => l.name === contact.language)?.id
    this.language = contact.language
    this.phoneNumbers = contact.phoneNumbers?.join(',')
    this.phoneNumbersSMS = contact.phoneNumbersSMS?.join(',') ?? null
    this.emailAddress = emailAddress
    this.priority = contact.priority
    this.active = contact.active ? 1 : 0
  }
}
