import { Message } from './message.interface'
import { AppError } from './error.interface'

export class ConnectionError extends Error implements Message, AppError {
  okLabel: string

  okAction: () => void

  isFailure: boolean

  retryable: boolean

  retryLabel: string

  retryAction: () => void

  constructor(message: string) {
    super(message)
    this.name = 'ConnectionError'
    this.isFailure = false
    this.retryable = true
  }
}
