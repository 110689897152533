import { DateFormats, DateFormatV2 } from '../enums'
import * as date_fns from 'date-fns'

export const formatDateV2 = (date: Date, dateFormat: DateFormatV2): string => {
  if (!date) {
    return null
  }
  return date_fns.format(date, dateFormat)
}

/**
 * Formats a given date based on the specified user format.
 *
 * @param date - The date that needs to be formatted, the date should be DateFormatV2
 * @param userFormat - The desired output format, see DateFormats enum
 *
 *
 * @returns {string} - The date formatted according to the specified format.
 *
 */
export const formatDateForUser = (date: Date, userFormat: DateFormats): string => date_fns.format(date, userFormat)
