import { startWith, Subject } from 'rxjs'

import { ConstantLite, WorkList } from '@agroone/entities'
import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup } from '@angular/forms'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'

import { WorklistFilters } from '../models/worklist-filters.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SharedConstantService } from '@agroone-front/shared'

@UntilDestroy()
@Component({
  selector: 'app-worklist-filters',
  templateUrl: './worklist-filters.component.html',
  styleUrls: ['./worklist-filters.component.sass'],
})
export class WorklistFiltersComponent implements OnInit, OnDestroy {
  public form: FormGroup
  public growers: string[] = []
  public cropTypes: ConstantLite[] = []
  public technicians: string[] = []
  public requestTypes: string[] = []

  get cropType(): AbstractControl {
    return this.form.get('cropType')
  }

  get growerName(): AbstractControl {
    return this.form.get('growerName')
  }

  get technicianName(): AbstractControl {
    return this.form.get('technicianName')
  }

  get requestType(): AbstractControl {
    return this.form.get('requestType')
  }

  private destroyed: Subject<void> = new Subject()

  constructor(
    public dialogRef: MatDialogRef<
      WorklistFiltersComponent,
      {
        cropType: string
        growerName: string
        technicianName: string
        requestType: string
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filters: WorklistFilters
      tasks: WorkList[]
    },
    public constantService: SharedConstantService
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      cropType: new FormControl(this.constantService.croptypes.find((x) => x.code === this.data?.filters.cropType)),
      growerName: new FormControl(this.data?.filters.growerName),
      technicianName: new FormControl(this.data?.filters.technicianName),
      requestType: new FormControl(this.data?.filters.requestType),
    })

    this.form.valueChanges.pipe(startWith(this.form.value), untilDestroyed(this)).subscribe((form) => {
      this.updateLists(form)
    })
  }

  save() {
    this.dialogRef.close(this.form.value)
  }

  public display(value: string): string {
    return value
  }

  public filter(value: string, input: string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string = input.toLowerCase()
    return value.toLowerCase().indexOf(filterValue) !== -1
  }

  private updateLists(filters: { growerName: string; cropType: string; technicianName: string; requestType: string }) {
    filters.cropType = this.cropType.value?.code
    if (!this.data.tasks?.length) {
      return
    }
    const growers = []
    let cropTypes = []
    const technicians = []
    const requestTypes = []

    for (const task of this.data.tasks) {
      const technicianNameIsOk =
        !filters.technicianName || `${task.workerFirstName} ${task.workerLastName}` === filters.technicianName
      const growerNameIsOk = !filters.growerName || task.growerName === filters.growerName
      const cropTypeIsOk = !filters.cropType || task.cropType === filters.cropType
      const requestTypeIsOk = !filters.requestType || task.taskName === filters.requestType

      if (cropTypeIsOk && technicianNameIsOk && requestTypeIsOk) {
        growers.push(task.growerName)
      }

      if (growerNameIsOk && technicianNameIsOk && requestTypeIsOk) {
        cropTypes.push(task.cropType)
      }

      if (growerNameIsOk && cropTypeIsOk && requestTypeIsOk) {
        technicians.push(`${task.workerFirstName} ${task.workerLastName}`)
      }

      if (growerNameIsOk && technicianNameIsOk && cropTypeIsOk) {
        requestTypes.push(task.taskName)
      }
    }

    this.technicians = [...new Set([...technicians])]
    this.growers = [...new Set([...growers])]
    this.requestTypes = [...new Set([...requestTypes])]
    cropTypes = [...new Set([...cropTypes])]
    this.cropTypes = this.constantService.croptypes.filter((c) => cropTypes?.includes(c.code))
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }
}
