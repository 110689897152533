import { Injectable } from '@angular/core'
import { environment } from '@front-app-environments/environment'
import { HttpClient } from '@angular/common/http'
import { map, Observable, switchMap, tap } from 'rxjs'
import { MsSqlPaginatedData, NotificationDto, RecipientNotificationDto, SaveNotificationDto } from '@agroone/entities'

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private _notifications: NotificationDto[]
  private notificationsUrl: string = `${environment.apiUrl}${environment.notifications}`

  constructor(private http: HttpClient) {}

  getMyNotifications$(): Observable<NotificationDto[]> {
    return this.http.get<MsSqlPaginatedData<NotificationDto>>(`${this.notificationsUrl}/mines`).pipe(
      map((v) => v.data),
      tap((v) => (this._notifications = v))
    )
  }

  getMyNotifications(): NotificationDto[] {
    return this._notifications
  }

  getNotificationRecipients(): Observable<RecipientNotificationDto[]> {
    return this.http
      .get<MsSqlPaginatedData<RecipientNotificationDto>>(`${this.notificationsUrl}/recipients`)
      .pipe(map((v) => v.data))
  }

  add(notification: SaveNotificationDto): Observable<SaveNotificationDto> {
    return this.http.post<SaveNotificationDto>(this.notificationsUrl, notification)
  }

  readAll() {
    return this.http
      .post<null>(`${this.notificationsUrl}/read-all`, null)
      .pipe(switchMap((v) => this.getMyNotifications$().pipe(map(() => v))))
  }

  markOneAsRead(notificationId: number) {
    return this.http
      .patch<NotificationDto>(`${this.notificationsUrl}/${notificationId}/mark-as-read`, null)
      .pipe(switchMap((v) => this.getMyNotifications$().pipe(map(() => v))))
  }
}
