// offline.effects.ts
import { Injectable } from '@angular/core'
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { OfflineService } from '@agroone-app/core/connection/offline.service'
import { OfflineState } from '@agroone-app/scene/crop-management/crop/store/reducers'
import { syncOfflineActions, syncOfflineActionsFailure, syncOfflineActionsSuccess } from '../actions/offline.actions'

@Injectable()
export class OfflineEffects {
  constructor(private actions$: Actions, private offlineService: OfflineService, private store: Store<OfflineState>) {}

  public syncOfflineActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncOfflineActions),
      mergeMap(({ actions }) => {
        return this.offlineService.syncOfflineActions(actions).pipe(
          map(() => syncOfflineActionsSuccess()),
          catchError((error) => of(syncOfflineActionsFailure({ error })))
        )
      })
    )
  )
}
