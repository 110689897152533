import { Component, OnInit, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { MapOptions } from '@agroone-app/shared/dialog/models/mapOptions.model'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.sass'],
})
export class MapComponent implements OnInit {
  public displayMode: 'draw' | 'view' | 'location' = 'view'

  constructor(
    public dialogRef: MatDialogRef<MapComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: MapOptions
  ) {}

  ngOnInit() {
    this.displayMode = this.data?.displayMode ?? this.displayMode
  }

  closeDialog(event: any) {
    this.dialogRef.close(event)
  }
}
