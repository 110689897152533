<ng-select
  [attr.data-testid]="mergedConfig?.dataTestId"
  [items]="items"
  [style.minWidth.px]="mergedConfig?.minWidth"
  [bindLabel]="mergedConfig?.bindLabel"
  [bindValue]="mergedConfig.useBindValue ? mergedConfig?.bindValue : null"
  [multiple]="mergedConfig.multiple"
  [searchable]="mergedConfig.searchable"
  [readonly]="mergedConfig.readonly"
  [placeholder]="mergedConfig.placeholder"
  [virtualScroll]="mergedConfig.virtualScroll"
  [closeOnSelect]="mergedConfig?.closeOnSelect"
  [appendTo]="mergedConfig?.appendTo"
  [(ngModel)]="selectedValue"
  [trackByFn]="trackByFn"
  [searchFn]="mergedConfig.searchFn"
  (change)="onSelectionChange($event)"
  clearAllText="{{ 'ACTION.CLEAR' | translate }}"
  [ngClass]="{
    'required-error': control?.invalid && control?.touched,
    'pass-required': control.valid
  }"
  [clearable]="mergedConfig.clearable"
  (blur)="onTouched()"
  (clear)="onClearSelect()"
>
  <ng-template *ngIf="optionTemplate" ng-option-tmp let-item="item" let-select="select">
    <input *ngIf="mergedConfig.multiple" type="checkbox" [checked]="isItemSelected(item)" />
    <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: item, select: this }"></ng-container>
  </ng-template>

  <ng-template *ngIf="labelTemplate" ng-label-tmp let-item="item">
    <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: item, select: this }"></ng-container>
  </ng-template>
</ng-select>
