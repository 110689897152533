import { plainToInstance } from 'class-transformer'
import { IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator'

export class CsrConstant {
  id?: number
  csrConstantId: number
  languageId: number
  languageName: string
  question: string
  tooltip: string
  reference: number
  slug: string
}

export interface CsrConstantGroup {
  slug: string
  csrConstantId: number
  question: string
  tooltip: string
  translations?: CsrConstantTranslation[]
}

interface CsrConstantTranslation {
  languageId: number
  language: string
  question: string
  tooltip: string
  id: number
}

export interface CsrConstantRequest {
  id?: number[]
  csrConstantId: number
  languageId: number
  name: string
  question: string
  tooltip: string
  reference: number
  slug: string
}

export class SaveCsrConstant {
  @IsInt()
  @IsPositive()
  @IsOptional()
  id?: number

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  csrConstantId: number

  @IsInt()
  @IsPositive()
  @IsNotEmpty()
  languageId: number

  @IsString()
  @IsNotEmpty()
  question: string

  @IsString()
  @IsOptional()
  tooltip?: string
}
