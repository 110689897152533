/* eslint-disable @typescript-eslint/typedef */
import { Intervention, SaveIntervention, TPATask } from '@agroone/entities'
import { createAction, props } from '@ngrx/store'

export enum InterventionActionTypes {
  LOAD_INTERVENTIONS = '[Intervention] Load interventions',
  LOAD_INTERVENTIONS_SUCCESS = '[Intervention] Load interventions Success',
  LOAD_INTERVENTIONS_FAILURE = '[Intervention] Load interventions Failure',

  LOAD_INTERVENTION = '[Intervention] Load intervention',
  LOAD_INTERVENTION_SUCCESS = '[Intervention] Load intervention Success',
  LOAD_INTERVENTION_FAILURE = '[Intervention] Load intervention Failure',

  ADD_INTERVENTION = '[Intervention] Add intervention',
  ADD_INTERVENTION_SUCCESS = '[Intervention] Add intervention Success',
  ADD_INTERVENTION_FAILURE = '[Intervention] Add intervention Failure',

  ADD_INTERVENTIONS = '[Intervention] Add interventions',
  ADD_INTERVENTIONS_SUCCESS = '[Intervention] Add interventions Success',
  ADD_INTERVENTIONS_FAILURE = '[Intervention] Add interventions Failure',

  UPDATE_INTERVENTION = '[Intervention] Update interventions',
  UPDATE_INTERVENTION_SUCCESS = '[Intervention] Update intervention Success',
  UPDATE_INTERVENTION_FAILURE = '[Intervention] Update intervention Failure',

  DELETE_INTERVENTION_SUCCESS = '[Intervention] Delete intervention Success',
  DELETE_INTERVENTION_FAILURE = '[Intervention] Delete intervention Failure',
  DELETE_INTERVENTION = '[Intervention] Delete Intervention',
}

// LOAD INTERVENTIONS
export const loadInterventions = createAction(InterventionActionTypes.LOAD_INTERVENTIONS, props<{ cropId: number }>())

export const loadInterventionsSuccess = createAction(
  InterventionActionTypes.LOAD_INTERVENTIONS_SUCCESS,
  props<{ cropId: number; interventions: Intervention[] }>()
)

export const loadInterventionsFailure = createAction(
  InterventionActionTypes.LOAD_INTERVENTIONS_FAILURE,
  props<{ error: any }>()
)

// LOAD Intervention
export const loadIntervention = createAction(
  InterventionActionTypes.LOAD_INTERVENTION,
  props<{ cropId: number; id: number }>()
)

export const loadInterventionSuccess = createAction(
  InterventionActionTypes.LOAD_INTERVENTION_SUCCESS,
  props<{ cropId: number; id: number; intervention: Intervention }>()
)

export const loadInterventionFailure = createAction(
  InterventionActionTypes.LOAD_INTERVENTION_FAILURE,
  props<{ error: any }>()
)

// ADD Intervention
export const addIntervention = createAction(
  InterventionActionTypes.ADD_INTERVENTION,
  props<{
    payload: {
      cropId: number
      intervention: SaveIntervention
      task?: TPATask
    }
  }>()
)

export const addInterventionSuccess = createAction(
  InterventionActionTypes.ADD_INTERVENTION_SUCCESS,
  props<{
    payload: {
      cropId: number
      intervention: Intervention
    }
  }>()
)

export const addInterventionFailure = createAction(
  InterventionActionTypes.ADD_INTERVENTION_FAILURE,
  props<{ error: any; loading?: boolean; message: string }>()
)

// ADD Intervention
export const addInterventions = createAction(
  InterventionActionTypes.ADD_INTERVENTIONS,
  props<{
    payload: {
      cropId: number
      interventions: Intervention[]
      pictures?: { name: string; content: string }[]
    }
  }>()
)

export const addInterventionsSuccess = createAction(
  InterventionActionTypes.ADD_INTERVENTIONS_SUCCESS,
  props<{
    payload: {
      cropId: number
      interventions: Intervention[]
    }
  }>()
)

export const addInterventionsFailure = createAction(
  InterventionActionTypes.ADD_INTERVENTIONS_FAILURE,
  props<{ error: any; loading?: boolean; message: string }>()
)

// UPDATE Intervention
export const updateIntervention = createAction(
  InterventionActionTypes.UPDATE_INTERVENTION,
  props<{
    payload: {
      cropId: number
      intervention: Intervention
      task?: TPATask
    }
  }>()
)

export const updateInterventionSuccess = createAction(
  InterventionActionTypes.UPDATE_INTERVENTION_SUCCESS,
  props<{
    payload: {
      cropId: number
      intervention: Intervention
      message?: string
      loading: boolean
    }
  }>()
)

export const updateInterventionFailure = createAction(
  InterventionActionTypes.UPDATE_INTERVENTION_FAILURE,
  props<{ error: any; loading: boolean }>()
)

export const deleteIntervention = createAction(
  InterventionActionTypes.DELETE_INTERVENTION,
  props<{ cropId: number; interventionId: number }>()
)

export const deleteInterventionSuccess = createAction(
  InterventionActionTypes.DELETE_INTERVENTION_SUCCESS,
  props<{ cropId: number; interventionId: number }>()
)
export const deleteInterventionError = createAction(
  InterventionActionTypes.DELETE_INTERVENTION_FAILURE,
  props<{ error: any }>()
)
