import { IsEnum, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator'
import { NotificationStatus } from '../enums/notification-status.enum'

export class SaveNotificationDto {
  @IsInt()
  @IsPositive()
  @IsOptional()
  id?: number

  @IsInt()
  @IsPositive()
  recipientUserId: number

  @IsInt()
  @IsPositive()
  cropId: number

  @IsString()
  @IsNotEmpty()
  description: string

  @IsInt()
  @IsPositive()
  @IsOptional()
  scoutingId?: number

  @IsInt()
  @IsPositive()
  @IsOptional()
  recommendationId?: number

  @IsInt()
  @IsPositive()
  @IsOptional()
  interventionId?: number

  @IsInt()
  @IsPositive()
  @IsOptional()
  sampleId?: number
}

export class PatchNotificationDto {
  @IsInt()
  @IsPositive()
  id?: number

  @IsEnum(NotificationStatus)
  status: NotificationStatus
}
