import { ActionCreator, ReducerTypes, on } from '@ngrx/store'
import {
  addScouting,
  addScoutingSuccess,
  updateScouting,
  updateScoutingSuccess,
  loadScoutings,
  loadScoutingsSuccess,
  deleteScouting,
  deleteScoutingSuccess,
  loadScoutingsFailure,
} from '../actions/scouting.actions'
import { CropDetailActivities, CropDetailState, cropDetailAdapter } from './crop-detail.reducer'
import { Scouting } from '@agroone/entities'

export const scoutingOns: ReducerTypes<CropDetailState, ActionCreator[]>[] = [
  on(loadScoutings, (state) => ({
    ...state,
  })),
  on(loadScoutingsSuccess, (state, { cropId, scoutings }) => {
    if (!state) {
      return state
    }
    if (state?.entities[`${cropId}`]) {
      const cropDetail: CropDetailActivities = state?.entities[`${cropId}`]

      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        scoutings: [...scoutings],
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(loadScoutingsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(addScouting, (state) => ({
    ...state,
  })),
  on(addScoutingSuccess, (state, { cropId, scouting }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        scoutings: [...cropDetail.scoutings, scouting],
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(updateScouting, (state) => ({
    ...state,
  })),
  on(updateScoutingSuccess, (state, { cropId, scouting }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]

    if (!cropDetail) {
      return state
    }
    const updatedScoutings: Scouting[] = cropDetail?.scoutings.map((sc) => {
      if (sc.id === scouting.id) {
        return scouting
      }
      return sc
    })

    const updatedParent: CropDetailActivities = {
      ...cropDetail,
      scoutings: updatedScoutings,
    }

    return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
  }),
  on(deleteScouting, (state) => ({
    ...state,
  })),
  on(deleteScoutingSuccess, (state, { cropId, scoutingId }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        scoutings: cropDetail?.scoutings.filter((sc) => sc.id !== scoutingId),
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
]
