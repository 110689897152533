import { IsNumber, IsOptional, Validate, IsNotEmpty, IsString } from 'class-validator'
import { IsISO8601WithTimezone } from '@agroone/helpers'

export class SaveForeignMaterial {
  @IsNumber()
  @IsOptional()
  id?: number

  @Validate(IsISO8601WithTimezone)
  @IsNotEmpty()
  targetDate: string

  @IsString()
  @IsNotEmpty()
  type: string

  @IsString()
  @IsNotEmpty()
  subType: string

  @IsString()
  @IsOptional()
  area?: string

  @IsString()
  @IsOptional()
  level?: string
}
