import { catchError, forkJoin, from, map, mergeMap, Observable, of, switchMap, tap, throwError } from 'rxjs'

import { Intervention, SaveIntervention, TPATask } from '@agroone/entities'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { FileService } from '@agroone-app/core/file/file.service'
import { environment } from '@front-app-environments/environment'

import { NetworkService, ToastService } from '@agroone-front/shared'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class InterventionService {
  private interventionUrl = `${environment.apiUrl}${environment.interventions}`

  public attachment: File
  public pictureList: File[] = []

  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private network: NetworkService,
    private translateService: TranslateService,
    private toastService: ToastService
  ) {}

  public save(intervention: SaveIntervention, task?: TPATask): Observable<Intervention> {
    const options = { params: {} }
    if (task) {
      options.params = {
        // @toimprove double encoding because of + encoding failure during the reception of the request
        taskId: task?.id,
      }
    }
    if (intervention?.id) {
      return this.http.put<Intervention>(this.interventionUrl, intervention, options).pipe(
        map((returnedIntervention: Intervention) => new Intervention(returnedIntervention)),
        mergeMap((returnedIntervention: Intervention) =>
          this.updateAttachment(returnedIntervention).pipe(
            map(() => returnedIntervention),
            catchError(() => {
              this.toastService.showToastError(this.translateService.instant('INTERVENTION.SEND_FAILURE'))
              return of(returnedIntervention)
            })
          )
        ),
        mergeMap((returnedIntervention: Intervention) =>
          this.savePictures(returnedIntervention).pipe(
            map(() => returnedIntervention),
            catchError(() => {
              this.toastService.showToastError(this.translateService.instant('INTERVENTION.SEND_FAILURE'))
              return of(returnedIntervention)
            })
          )
        ),
        switchMap((returnedIntervention: Intervention) => this.get(returnedIntervention.id))
      )
    } else {
      return this.http.post<Intervention>(`${this.interventionUrl}`, intervention, options).pipe(
        map((returnedIntervention: Intervention) => new Intervention(returnedIntervention)),
        mergeMap((returnedIntervention: Intervention) =>
          this.saveAttachment(returnedIntervention).pipe(
            map(() => returnedIntervention),
            catchError(() => {
              this.toastService.showToastError(this.translateService.instant('INTERVENTION.SEND_FAILURE'))
              return of(returnedIntervention)
            })
          )
        ),
        mergeMap((returnedIntervention: Intervention) =>
          this.savePictures(returnedIntervention).pipe(
            map(() => returnedIntervention),
            catchError(() => of(returnedIntervention))
          )
        ),
        switchMap((returnedIntervention: Intervention) => this.get(returnedIntervention.id)),
        catchError((error) => {
          this.toastService.showToastError(this.translateService.instant('INTERVENTION.SEND_FAILURE'))
          return throwError(() => new Error(error))
        })
      )
    }
  }

  /**
   * Return interevntion item
   *
   * @param interventionId
   * @returns
   */
  public get(interventionId: number): Observable<Intervention> {
    return this.http.get<Intervention>(`${this.interventionUrl}/${interventionId}`)
  }

  /**
   * Download a picture of interevntion id
   *
   * @param interventionId
   * @returns
   */
  public getInterventionPictures(interventionId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.interventionUrl}/${interventionId}/pictures`)
  }

  /**
   * Download a attachment of interevntion id
   *
   * @param interventionId
   * @returns
   */
  public getAttachment(interventionId: number): Observable<{ presignedUrl: string }> {
    return this.http.get<{ presignedUrl: string }>(`${this.interventionUrl}/${interventionId}/attachments`)
  }

  public savePictures(intervention: Intervention): Observable<any> {
    if (!this.pictureList || !this.pictureList.length) {
      return of([])
    }
    return from(this.fileService.rotateFiles(this.pictureList)).pipe(
      mergeMap((files) =>
        forkJoin(
          files.map((picture) => {
            const formData: FormData = new FormData()
            formData.append('picture', picture)

            return this.http.post<void>(`${this.interventionUrl}/${intervention.id}/pictures`, formData)
          })
        )
      )
    )
  }

  private saveAttachment(intervention: Intervention): Observable<any> {
    if (!this.attachment) {
      return of(undefined)
    }
    const formData: FormData = new FormData()
    formData.append('attachment', this.attachment)

    return this.http
      .post<void>(`${this.interventionUrl}/${intervention.id}/attachments`, formData)
      .pipe(tap(() => (this.attachment = undefined)))
  }

  private updateAttachment(intervention: Intervention): Observable<any> {
    if (!this.attachment) {
      return of(undefined)
    }
    const formData: FormData = new FormData()
    formData.append('attachment', this.attachment)

    return this.http
      .put<void>(`${this.interventionUrl}/${intervention.id}/attachments`, formData)
      .pipe(tap(() => (this.attachment = undefined)))
  }

  /**
   * Return interventions by crop id
   *
   * @param cropId
   * @returns
   */
  public getByCropId(cropId: number): Observable<Intervention[]> {
    return from(
      this.network.getAllFromPaginated<Intervention>(`${this.interventionUrl}?filter=cropId=${cropId}`, {
        params: {
          noPagination: true,
          includeCropDetails: false,
        },
      })
    ).pipe(
      map((interventions) => {
        return interventions?.map((intervention) => new Intervention(intervention)) ?? []
      })
    )
  }

  /**
   * Delete intervention item by id
   *
   * @param id
   * @returns
   */
  public delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.interventionUrl}/${id}`)
  }
}
