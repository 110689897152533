import { Injectable } from '@angular/core'
import {
  addGrower,
  addGrowerFailure,
  addGrowerSuccess,
  loadGrowers,
  loadGrowersFailure,
  loadGrowersSuccess,
  updateGrower,
  updateGrowerFailure,
  updateGrowerSuccess,
} from '../actions/grower.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, tap } from 'rxjs'
import { of } from 'rxjs'
import { GrowerService } from '@agroone-app/shared/grower/services/grower.service'
import { LoadingState } from '@agroone-app/state/loading/loading.reducer'
import { Store } from '@ngrx/store'
import { startLoadingGrowers, stopLoadingGrowers } from '@agroone-app/state/loading/loading.actions'

@Injectable()
export class GrowerEffects {
  constructor(
    private actions$: Actions,
    private growerService: GrowerService,
    private loadingStore: Store<LoadingState>
  ) {}

  public getGrowers$ = createEffect(() => {
    this.loadingStore.dispatch(startLoadingGrowers())

    return this.actions$.pipe(
      ofType(loadGrowers),
      mergeMap((params: any) =>
        this.growerService.getAll(params).pipe(
          tap(() => this.loadingStore.dispatch(stopLoadingGrowers())),
          map((growers) => loadGrowersSuccess({ growers })),
          catchError((e) => of(loadGrowersFailure({ error: `${e}` })))
        )
      )
    )
  })

  public addGrower$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addGrower),
      mergeMap(({ payload }) =>
        this.growerService.save(payload.grower).pipe(
          map((grower) =>
            addGrowerSuccess({
              payload: {
                grower,
              },
            })
          ),
          catchError((e) =>
            of(
              addGrowerFailure({
                error: `${e}`,
              })
            )
          )
        )
      )
    )
  )

  public updateGrower$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGrower),
      mergeMap(({ payload }) =>
        this.growerService.save(payload.grower).pipe(
          map((grower) =>
            updateGrowerSuccess({
              payload: {
                grower,
                loading: false,
              },
            })
          ),
          catchError((e) =>
            of(
              updateGrowerFailure({
                error: `${e}`,
                loading: false,
              })
            )
          )
        )
      )
    )
  )
}
