import { clearOfflineActions } from '@agroone-app/scene/crop-management/crop/store/actions/offline.actions'
import { OfflineState } from '@agroone-app/scene/crop-management/crop/store/reducers'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, debounceTime, forkJoin, from, map, of, switchMap, toArray } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  constructor(private store: Store<OfflineState>) {}

  syncOfflineActions(actions?: any[]): Observable<any> {
    return from(actions).pipe(
      map((action) => {
        if (action?.action) {
          return of(this.store.dispatch(action?.action))
        }
        return of([])
      }),
      toArray(),
      switchMap((actions) => forkJoin(actions)),
      debounceTime(100),
      switchMap(() => of(this.store.dispatch(clearOfflineActions())))
    )
  }
}
