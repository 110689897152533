import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, map, mergeMap } from 'rxjs'
import { of } from 'rxjs'
import { ScoutingService } from '@agroone-app/shared/scouting/services/scouting.service'
import {
  addScoutingFailure,
  addScoutingSuccess,
  loadScoutings,
  loadScoutingsFailure,
  loadScoutingsSuccess,
  addScouting,
  deleteScouting,
  deleteScoutingFailure,
  deleteScoutingSuccess,
  updateScouting,
  updateScoutingSuccess,
  updateScoutingFailure,
} from '../actions/scouting.actions'

@Injectable()
export class ScoutingEffects {
  constructor(private actions$: Actions, private scoutingService: ScoutingService) {}

  public getScoutingsByCropId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadScoutings),
      debounceTime(100),
      mergeMap((action) =>
        this.scoutingService.getByCropId(action.cropId).pipe(
          map((scoutings) =>
            loadScoutingsSuccess({
              cropId: action.cropId,
              scoutings,
            })
          ),
          catchError((error) => of(loadScoutingsFailure({ error })))
        )
      )
    )
  )

  public addScouting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addScouting),
      mergeMap(({ cropId, scouting, pictures }) =>
        this.scoutingService.add(scouting, pictures).pipe(
          map((scoutingResponse) =>
            addScoutingSuccess({
              cropId,
              scouting: scoutingResponse,
            })
          ),
          catchError((e) =>
            of(
              addScoutingFailure({
                error: `${e}`,
              })
            )
          )
        )
      )
    )
  )

  public updateScouting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateScouting),
      mergeMap(({ cropId, scouting, pictures }) =>
        this.scoutingService.update(scouting, pictures).pipe(
          map((scoutingResponse) =>
            updateScoutingSuccess({
              cropId,
              scouting: scoutingResponse,
            })
          ),
          catchError((e) => of(updateScoutingFailure({ error: `${e}` })))
        )
      )
    )
  )

  public deleteScouting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteScouting),
      mergeMap(({ cropId, scoutingId }) =>
        this.scoutingService.delete(scoutingId).pipe(
          map(() => deleteScoutingSuccess({ cropId, scoutingId })),
          catchError((e) => of(deleteScoutingFailure({ error: `${e}` })))
        )
      )
    )
  )
}
