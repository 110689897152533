import 'reflect-metadata'
import { plainToInstance } from 'class-transformer'
import { IsNotEmpty, IsNumber, IsOptional, IsString, validateSync } from 'class-validator'

export class SaveHeatUnitHypothesis {
  public static build(raw: any) {
    return plainToInstance(SaveHeatUnitHypothesis, raw)
  }

  public static validate(raw: any) {
    const saveHeatUnitHypothesis = this.build(raw)
    return validateSync(saveHeatUnitHypothesis)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  hypothesisType: string

  @IsString()
  @IsNotEmpty()
  businessUnit: string

  @IsString()
  @IsNotEmpty()
  region: string

  @IsString()
  @IsNotEmpty()
  productionArea: string

  @IsString()
  @IsNotEmpty()
  heatUnitDate: string

  @IsNumber()
  @IsNotEmpty()
  averageHeatUnit: number
}
