import { registerDecorator, ValidationArguments } from 'class-validator'

export function RequiredOrProperty(...properties: string[]) {
  return (object: object, propertyName: string) => {
    registerDecorator({
      name: 'RequiredOrProperty',
      target: object.constructor,
      propertyName,
      constraints: properties,
      validator: {
        validate,
        defaultMessage,
      },
    })
  }
}

export function validate(value: string, args: ValidationArguments): boolean {
  if (args.constraints.length === 0) {
    return false
  }
  const propertyName = args.constraints[0]
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!value && !args.object[`${propertyName}`]) {
    return false
  }
  return true
}

export function defaultMessage(args?: ValidationArguments): string {
  return `There should be at least one value for ${args?.property} or ${args?.constraints.join(', ')}`
}
