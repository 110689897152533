<div class="worklist-filters">
  <mat-dialog-content class="worklist-filters-content">
    <form [formGroup]="form" (ngSubmit)="save()">
      <!-- TechnicianName -->
      <mat-label>{{ 'CROP.FILTERS.TECHNICIAN' | translate }}</mat-label>
      <app-autocomplete-select
        [list]="technicians"
        [formCtrl]="technicianName"
        [displayFn]="display"
        [filterFn]="filter"
        optional="true"
      >
      </app-autocomplete-select>

      <!-- GrowerName -->
      <mat-label>{{ 'CROP.FILTERS.GROWER_NAME' | translate }}</mat-label>
      <app-autocomplete-select
        [list]="growers"
        [formCtrl]="growerName"
        [displayFn]="display"
        [filterFn]="filter"
        optional="true"
      >
      </app-autocomplete-select>

      <!-- CropType -->
      <mat-label>{{ 'CROP.FILTERS.CROP_TYPE' | translate }}</mat-label>
      <app-autocomplete-select [list]="cropTypes" [formCtrl]="cropType" optional="true"> </app-autocomplete-select>

      <!-- RequestType -->
      <mat-label>{{ 'CROP.FILTERS.REQUEST_TYPE' | translate }}</mat-label>
      <app-autocomplete-select
        [list]="requestTypes"
        [formCtrl]="requestType"
        [displayFn]="display"
        [filterFn]="filter"
        optional="true"
      >
      </app-autocomplete-select>

      <button type="submit" style="display: none"></button>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="worklist-filters-actions">
    <button color="accent" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
