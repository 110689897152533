import 'reflect-metadata'
import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'

export class SaveTranslation {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @IsNotEmpty()
  languageId: number | undefined

  @IsString()
  @IsNotEmpty()
  value: string | undefined

  constructor(translation?: SaveTranslation) {
    if (translation?.id) {
      this.id = translation.id
    }

    this.languageId = translation?.languageId
    this.value = translation?.value
  }
}
