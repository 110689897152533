export enum Features {
  GROWER = 'grower',
  FIELD = 'field',
  CROP = 'crop',
  CROP_DETAIL = 'cropDetail',
  SCOUTING = 'scouting',
  RECOMMENDATION = 'recommendation',
  INTERVENTION = 'intervention',
  ACTIVITY = 'activity',
  LOADING = 'loading',
  OFFLINE = 'offline',
  VARIETY = 'variety',
  SPECIFICATION = 'specification',
}
