import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
  private excludedUrls: string[] = ['api.mapbox.com']

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isExcluded: boolean = this.excludedUrls.some((url) => request.url.includes(url))
    if (!isExcluded) {
      request = request.clone({
        withCredentials: true,
      })
    }

    return next.handle(request)
  }
}
