import 'reflect-metadata'
import { CroptypeDto } from './croptype'
import { IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { plainToInstance } from 'class-transformer'

export class Variety {
  id: number | undefined
  name: string | undefined
  active: boolean | undefined
  croptype?: Pick<CroptypeDto, 'id' | 'key'> | undefined
  cropType?: string
  varietyCode?: string
  commercialOrTrial?: string
  varietalType?: string
  processingType?: string

  constructor(variety?: Variety) {
    this.id = variety?.id
    this.name = variety?.name
    this.active = variety?.active ?? true
    this.croptype = variety?.croptype
    this.cropType = variety?.cropType
    this.varietyCode = variety?.varietyCode
    this.commercialOrTrial = variety?.commercialOrTrial
    this.varietalType = variety.varietalType
    this.processingType = variety?.processingType
  }
}

export interface VarietyDto {
  id: number
  name: string
  active: boolean
  croptypeId: number
  croptypeKey: string
  varietyCode?: string
  commercialOrTrial?: string
  varietalType?: string
  processingType?: string
}

export class VarietyDAO {
  id!: number
  name!: string
  active!: boolean
  croptypeId!: number
  croptypeKey!: string
  varietyCode?: string
  commercialOrTrial?: string
  varietalType?: string
  processingType?: string
}

export class SaveVariety {
  public static build(raw: any) {
    return plainToInstance(SaveVariety, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsBoolean()
  @IsNotEmpty()
  active: boolean

  @IsNumber()
  @IsNumber()
  cropTypeId: number | undefined

  @IsString()
  @IsOptional()
  varietyCode?: string

  @IsString()
  @IsOptional()
  commercialOrTrial?: string

  @IsString()
  @IsOptional()
  varietalType?: string

  @IsString()
  @IsOptional()
  processingType?: string

  constructor(variety?: SaveVariety) {
    if (variety?.id) {
      this.id = variety.id
    }

    this.name = variety?.name
    this.cropTypeId = variety?.cropTypeId
    this.active = variety?.active ?? false
    this.varietyCode = variety?.varietyCode
    this.commercialOrTrial = variety?.commercialOrTrial
    this.varietalType = variety?.varietalType
    this.processingType = variety?.processingType
  }
}
