import 'reflect-metadata'
import { plainToInstance } from 'class-transformer'
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, Validate } from 'class-validator'
import { IsDecimalNumber } from '@agroone/helpers'
import { BusinessUnit } from './business-units'
import { BusinessUnits } from '../enums/business-units.enum'

export class SaveSowingPlanning {
  public static build(raw: any) {
    return plainToInstance(SaveSowingPlanning, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  region: string

  @IsString()
  @IsNotEmpty()
  // TODO: Add and check if it doesn't break anything
  // @IsEnum(BusinessUnits)
  businessUnit: BusinessUnits

  @IsNumber()
  @IsNotEmpty()
  growerId: number

  @IsString()
  @IsOptional()
  growerName?: string

  @IsString()
  @IsNotEmpty()
  productionArea: string

  @IsString()
  @IsNotEmpty()
  cropType: string

  @IsString()
  @IsNotEmpty()
  rawMaterialProcessed: string

  @IsString()
  @IsOptional()
  variety?: string

  @IsNumber()
  @IsOptional()
  varietyId?: number

  @IsString()
  @IsNotEmpty()
  fieldType: string

  @IsString()
  @IsNotEmpty()
  growingSystem: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  demandQuantity?: number

  @IsString()
  @IsNotEmpty()
  demandQuantityUnit: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  planningPlantedArea: number

  @IsString()
  @IsOptional()
  planningPlantedAreaUnit: string

  @IsNumber()
  @IsOptional()
  plantsNumber?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  fieldArea?: number

  @IsNumber()
  @IsOptional()
  fieldAreaUnit?: string

  @IsString()
  @IsNotEmpty()
  sowingDate: string

  @IsString()
  @IsNotEmpty()
  harvestDate: string

  @IsNumber()
  @IsOptional()
  sowingCluster?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  firstCutPercentage?: number

  @IsNumber()
  @IsOptional()
  landPreparation?: number

  @IsNumber()
  @IsOptional()
  firstCutCycle?: number

  @IsNumber()
  @IsOptional()
  secondCutCycle?: number

  @IsNumber()
  @IsOptional()
  thirdCutCycle?: number

  @IsNumber()
  @IsOptional()
  forthCutCycle?: number

  @IsNumber()
  @IsOptional()
  fifthCutCycle?: number

  @IsNumber()
  @IsOptional()
  sixthCutCycle?: number

  @IsNumber()
  @IsOptional()
  seventhCutCycle?: number

  @IsNumber()
  @IsOptional()
  securityRate?: number

  @IsNumber()
  @IsOptional()
  firstHarvestRate?: number

  @IsNumber()
  @IsOptional()
  secondHarvestRate?: number

  @IsNumber()
  @IsOptional()
  thirdHarvestRate?: number

  @IsNumber()
  @IsOptional()
  forthHarvestRate?: number

  @IsNumber()
  @IsOptional()
  fifthHarvestRate?: number

  @IsNumber()
  @IsOptional()
  sixthHarvestRate?: number

  @IsNumber()
  @IsOptional()
  seventhHarvestRate?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  density?: number

  @IsString()
  @IsOptional()
  densityUnit?: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  averageWeight?: number

  @IsString()
  @IsOptional()
  averageWeightUnit?: string

  @IsString()
  @IsOptional()
  heatUnitDate?: string

  @IsNumber()
  @IsOptional()
  averageHeatUnit?: number

  @IsNumber()
  @IsNotEmpty()
  version: number

  @IsString()
  @IsNotEmpty()
  versionDate: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  sowingQuantity?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  harvestQuantityFirstCycle?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  harvestQuantitySecondCycle?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  harvestQuantityThirdCycle?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  harvestQuantityFourthCycle?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  harvestQuantityFifthCycle?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  harvestQuantitySixthCycle?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  harvestQuantitySeventhCycle?: number

  @IsString()
  @IsOptional()
  harvestDateSecondCycle?: string

  @IsString()
  @IsOptional()
  harvestDateThirdCycle?: string

  @IsString()
  @IsOptional()
  harvestDateFourthCycle?: string

  @IsString()
  @IsOptional()
  harvestDateFifthCycle?: string

  @IsString()
  @IsOptional()
  harvestDateSixthCycle?: string

  @IsString()
  @IsOptional()
  harvestDateSeventhCycle?: string

  @IsNumber()
  @IsOptional()
  distributionRate?: number

  @IsString()
  @IsNotEmpty()
  campaign: string
}
