import { Grower, SaveGrower } from '@agroone/entities'
import { ActionCreator, createAction, props } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'

export enum GrowerActionTypes {
  LOAD_GROWERS = '[Grower] Load Growers',
  LOAD_GROWERS_SUCCESS = '[Grower] Load Growers Success',
  LOAD_GROWERS_FAILURE = '[Grower] Load Growers Failure',
  ADD_GROWER = '[Grower] Add Grower',
  ADD_GROWER_SUCCESS = '[Grower] Add Grower Success',
  ADD_GROWER_FAILURE = '[Grower] Add Grower Failure',
  UPDATE_GROWER = '[Grower] Update Grower',
  UPDATE_GROWER_SUCCESS = '[Grower] Update Grower Success',
  UPDATE_GROWER_FAILURE = '[Grower] Update Grower Failure',
  CLEAR_GROWER = '[Grower] Clear Grower',
}

// LOAD GROWERS
export const loadGrowers: ActionCreator<
  GrowerActionTypes.LOAD_GROWERS,
  () => TypedAction<GrowerActionTypes.LOAD_GROWERS>
> = createAction(GrowerActionTypes.LOAD_GROWERS)
export const loadGrowersSuccess: ActionCreator<
  GrowerActionTypes.LOAD_GROWERS_SUCCESS,
  (props: { growers: Grower[] }) => {
    growers: Grower[]
  } & TypedAction<GrowerActionTypes.LOAD_GROWERS_SUCCESS>
> = createAction(GrowerActionTypes.LOAD_GROWERS_SUCCESS, props<{ growers: Grower[] }>())
export const loadGrowersFailure: ActionCreator<
  GrowerActionTypes.LOAD_GROWERS_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<GrowerActionTypes.LOAD_GROWERS_FAILURE>
> = createAction(GrowerActionTypes.LOAD_GROWERS_FAILURE, props<{ error: any }>())

// ADD GROWER
export const addGrower: ActionCreator<
  GrowerActionTypes.ADD_GROWER,
  (props: {
    payload: {
      grower: SaveGrower
      options?: {
        [key: string]: any
      }
    }
  }) => {
    payload: {
      grower: SaveGrower
      options?: {
        [key: string]: any
      }
    }
  } & TypedAction<GrowerActionTypes.ADD_GROWER>
> = createAction(
  GrowerActionTypes.ADD_GROWER,
  props<{
    payload: { grower: SaveGrower; options?: { [key: string]: any } }
  }>()
)
export const addGrowerSuccess: ActionCreator<
  GrowerActionTypes.ADD_GROWER_SUCCESS,
  (props: {
    payload: {
      grower: Grower
    }
  }) => {
    payload: {
      grower: Grower
    }
  } & TypedAction<GrowerActionTypes.ADD_GROWER_SUCCESS>
> = createAction(GrowerActionTypes.ADD_GROWER_SUCCESS, props<{ payload: { grower: Grower } }>())
export const addGrowerFailure: ActionCreator<
  GrowerActionTypes.ADD_GROWER_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<GrowerActionTypes.ADD_GROWER_FAILURE>
> = createAction(GrowerActionTypes.ADD_GROWER_FAILURE, props<{ error: any }>())

// UPDATE GROWER
export const updateGrower: ActionCreator<
  GrowerActionTypes.UPDATE_GROWER,
  (props: {
    payload: {
      grower: SaveGrower
    }
  }) => {
    payload: {
      grower: SaveGrower
    }
  } & TypedAction<GrowerActionTypes.UPDATE_GROWER>
> = createAction(GrowerActionTypes.UPDATE_GROWER, props<{ payload: { grower: SaveGrower } }>())
export const updateGrowerSuccess: ActionCreator<
  GrowerActionTypes.UPDATE_GROWER_SUCCESS,
  (props: {
    payload: {
      grower: Grower
      message?: string
      loading: boolean
    }
  }) => {
    payload: {
      grower: Grower
      message?: string
      loading: boolean
    }
  } & TypedAction<GrowerActionTypes.UPDATE_GROWER_SUCCESS>
> = createAction(
  GrowerActionTypes.UPDATE_GROWER_SUCCESS,
  props<{ payload: { grower: Grower; message?: string; loading: boolean } }>()
)
export const updateGrowerFailure: ActionCreator<
  GrowerActionTypes.UPDATE_GROWER_FAILURE,
  (props: { error: any; loading: boolean }) => {
    error: any
    loading: boolean
  } & TypedAction<GrowerActionTypes.UPDATE_GROWER_FAILURE>
> = createAction(GrowerActionTypes.UPDATE_GROWER_FAILURE, props<{ error: any; loading: boolean }>())
export const clearGrowers: ActionCreator<
  GrowerActionTypes.CLEAR_GROWER,
  () => TypedAction<GrowerActionTypes.CLEAR_GROWER>
> = createAction(GrowerActionTypes.CLEAR_GROWER)
