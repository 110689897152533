import { Injectable } from '@angular/core'
import { ISO8601ToDate } from '@agroone/helpers'
import { SortingRule, SortingRuleOrder, SortingRuleType } from '@agroone/entities'
import { isAfter, isEqual } from 'date-fns'

@Injectable({
  providedIn: 'root',
})
export class SortService {
  public sort<T>(entities: T[], sortingRules: SortingRule[]): T[] {
    const rules: SortingRule[] = sortingRules
    let sorted: T[] = entities

    for (const rule of rules) {
      switch (rule.type) {
        case SortingRuleType.DATE:
          sorted = this.sortByDate(entities, rule)
          break
        case SortingRuleType.BOOLEAN:
          sorted = this.sortByBoolean(entities, rule)
          break
        case SortingRuleType.NUMBER:
        case SortingRuleType.STRING:
        default:
          sorted = this.sortByASCII(entities, rule)
          break
      }
    }

    return sorted
  }

  private sortByASCII<T>(entities: T[], rule: SortingRule): T[] {
    let sorted: T[]
    const [nulls, values]: any = entities.reduce(
      (array, entity) => {
        if (!entity[rule.propertyName]) {
          array[0].push(entity)
        } else {
          array[1].push(entity)
        }
        return array
      },
      [[], []]
    )

    switch (rule.order) {
      case SortingRuleOrder.DESCENDING:
        sorted = values.sort((a, b) => a[rule.propertyName].localeCompare(b[rule.propertyName]))
        break
      case SortingRuleOrder.ASCENDING:
      default:
        sorted = values.sort((a, b) => b[rule.propertyName].localeCompare(a[rule.propertyName]))
        break
    }

    return sorted
  }

  private sortByDate<T>(entities: T[], rule: SortingRule): T[] {
    let sorted: T[]
    const now: Date = new Date()
    const [nulls, future, present, past]: any = entities.reduce(
      (array, entity) => {
        if (!entity[rule.propertyName]) {
          array[0].push(entity)
        } else if (isAfter(ISO8601ToDate(entity[rule.propertyName]), now)) {
          array[1].push(entity)
        } else if (isEqual(ISO8601ToDate(entity[rule.propertyName]), now)) {
          array[2].push(entity)
        } else {
          array[3].push(entity)
        }
        return array
      },
      [[], [], [], []]
    )

    switch (rule.order) {
      case SortingRuleOrder.ASCENDING:
        sorted = future
          .concat(present, past)
          .sort(
            (a: T, b: T) =>
              ISO8601ToDate(a[rule.propertyName]).valueOf() - ISO8601ToDate(b[rule.propertyName]).valueOf()
          )
          .concat(nulls)
        break
      case SortingRuleOrder.DESCENDING:
      default:
        sorted = past
          .concat(present, future)
          .sort(
            (a: T, b: T) =>
              ISO8601ToDate(b[rule.propertyName]).valueOf() - ISO8601ToDate(a[rule.propertyName]).valueOf()
          )
          .concat(nulls)
        break
    }
    return sorted
  }

  private sortByBoolean<T>(entities: T[], rule: SortingRule): T[] {
    let sorted: T[]
    switch (rule.order) {
      case SortingRuleOrder.ASCENDING:
        sorted = entities.sort((a, b) =>
          a[rule.propertyName] === b[rule.propertyName] ? 0 : a[rule.propertyName] ? -1 : 1
        )
        break
      case SortingRuleOrder.DESCENDING:
        sorted = entities.sort((a, b) =>
          a[rule.propertyName] === b[rule.propertyName] ? 0 : a[rule.propertyName] ? 1 : -1
        )
        break
    }
    return sorted
  }
}
