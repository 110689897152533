import { registerDecorator, ValidationArguments } from 'class-validator'

export function IsLaterThan(property: string) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      name: 'isLaterThan',
      target: object.constructor,
      propertyName,
      constraints: [property],
      validator: {
        validate: isLaterThanValidate,
        defaultMessage: isLaterThanDefaultMessage,
      },
    })
  }
}

export function isLaterThanValidate(value: Date, args: ValidationArguments) {
  const startingDateKey = args.constraints[0]
  const startingDate = (args.object as any)[String(startingDateKey)]
  return value >= startingDate
}

export function isLaterThanDefaultMessage(args?: ValidationArguments) {
  return `${args.property} must be after ${args.constraints}`
}
