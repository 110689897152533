<div [ngClass]="data.style" class="note">
  <h2 mat-dialog-title>{{ 'DIALOG.NOTE.TITLE' | translate }}</h2>
  <mat-dialog-content class="note-content">
    <form [formGroup]="form">
      <textarea
        rows="10"
        class="note-content-text"
        formControlName="noteContent"
        matInput
        [placeholder]="'DIALOG.NOTE.INFORMATION' | translate"
        [attr.disabled]="data.readonly ? '' : null"
      >
      </textarea>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="disp-f j-c-c">
    <button color="accent" class="mr-s" mat-flat-button (click)="onCancel()">
      {{ (data.readonly ? 'DIALOG.BUTTON.CLOSE' : 'DIALOG.BUTTON.CANCEL') | translate }}
    </button>
    <button *ngIf="!data.readonly" color="accent" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
  </mat-dialog-actions>
</div>
