import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatBadgeModule } from '@angular/material/badge'
import { MatRippleModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { ConstantModule } from '../constant/constant.module'
import { SummaryModule } from '../summary/summary.module'
import { UserModule } from '../user/user.module'
import { ActionComponent } from '@agroone-front/shared'
import { FooterComponent } from './footer/footer.component'
import { DefaultHeaderTitleComponent } from './header/default-header-title.component'
import { HeaderContentComponent } from './header/header-content.component'
import { HeaderComponent } from './header/header.component'
import { MenuOverlayComponent } from './menu/menu-overlay.component'

@NgModule({
  declarations: [
    MenuOverlayComponent,
    HeaderComponent,
    HeaderContentComponent,
    DefaultHeaderTitleComponent,
    FooterComponent,
  ],
  exports: [
    MenuOverlayComponent,
    HeaderComponent,
    HeaderContentComponent,
    DefaultHeaderTitleComponent,
    ActionComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ConstantModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    RouterModule,
    SummaryModule,
    UserModule,
    ActionComponent,
  ],
})
export class LayoutModule {}
