import { plainToInstance, Type } from 'class-transformer'
import {
  ArrayMaxSize,
  ArrayMinSize,
  Equals,
  IsArray,
  IsIn,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  Validate,
  ValidateNested,
} from 'class-validator'
import { ArraySizes } from '@agroone/helpers'
import { BBox } from './feature-collection'

/**
 * The base GeoJSON object.
 * https://tools.ietf.org/html/rfc7946#section-3
 * The GeoJSON specification also allows foreign members
 * (https://tools.ietf.org/html/rfc7946#section-6.1)
 * Developers should use "&" type in TypeScript or extend the interface
 * to add these foreign members.
 */
export class SaveGeoJsonObject {
  // Don't include foreign members directly into this type def.
  // in order to preserve type safety.
  // [key: string]: any;
  /**
   * Specifies the type of GeoJSON object.
   */
  @IsString()
  @IsIn([
    'Geometry',
    'Feature',
    'FeatureCollection',
    'Point',
    'MultiPoint',
    'LineString',
    'MultiLineString',
    'Polygon',
    'MultiPolygon',
    'GeometryCollection',
  ])
  type: string | undefined

  /**
   * Bounding box of the coordinate range of the object's Geometries, Features, or Feature Collections.
   * The value of the bbox member is an array of length 2*n where n is the number of dimensions
   * represented in the contained geometries, with all axes of the most southwesterly point
   * followed by all axes of the more northeasterly point.
   * The axes order of a bbox follows the axes order of geometries.
   * https://tools.ietf.org/html/rfc7946#section-5
   */
  @IsArray()
  @IsOptional()
  @IsNumber({}, { each: true })
  @Validate(ArraySizes, [4, 6])
  bbox?: BBox
}

/**
 * Point geometry object.
 * https://tools.ietf.org/html/rfc7946#section-3.1.2
 */
export class SavePoint extends SaveGeoJsonObject {
  @IsString()
  @Equals('Point')
  declare type: string | undefined

  @IsArray()
  @IsNumber({}, { each: true })
  @ArrayMinSize(2)
  @ArrayMaxSize(3)
  coordinates: number[] | undefined
}

/**
 * MultiPoint geometry object.
 *  https://tools.ietf.org/html/rfc7946#section-3.1.3
 */
export class SaveMultiPoint extends SaveGeoJsonObject {
  @IsString()
  @Equals('MultiPoint')
  declare type: string | undefined

  @IsArray({ each: true })
  coordinates: number[][] | undefined
}

/**
 * LineString geometry object.
 * https://tools.ietf.org/html/rfc7946#section-3.1.4
 */
export class SaveLineString extends SaveGeoJsonObject {
  @IsString()
  @Equals('LineString')
  declare type: string | undefined

  @IsArray({ each: true })
  coordinates: number[][] | undefined
}

/**
 * MultiLineString geometry object.
 * https://tools.ietf.org/html/rfc7946#section-3.1.5
 */
export class SaveMultiLineString extends SaveGeoJsonObject {
  @IsString()
  @Equals('MultiLineString')
  declare type: string | undefined

  @IsArray({ each: true })
  // @IsNumber({}, { each: true })
  // @toimprove I do not test max / min size of last array
  // @toimprove I do not test number of last array
  coordinates: number[][][] | undefined
}

/**
 * Polygon geometry object.
 * https://tools.ietf.org/html/rfc7946#section-3.1.6
 */
export class SavePolygon extends SaveGeoJsonObject {
  @IsString()
  @Equals('Polygon')
  declare type: string | undefined

  @IsArray({ each: true })
  // @IsNumber({}, { each: true })
  // @toimprove I do not test max / min size of last array
  // @toimprove I do not test number of last array
  coordinates: number[][][] | undefined
}

/**
 * MultiPolygon geometry object.
 * https://tools.ietf.org/html/rfc7946#section-3.1.7
 */
export class SaveMultiPolygon extends SaveGeoJsonObject {
  @IsString()
  @Equals('MultiPolygon')
  declare type: string | undefined

  @IsArray({ each: true })
  // @IsNumber({}, { each: true })
  // @toimprove I do not test max / min size of last array
  // @toimprove I do not test number of last array
  coordinates: number[][][][] | undefined
}

/**
 * A feature object which contains a geometry and associated properties.
 * https://tools.ietf.org/html/rfc7946#section-3.2
 */
export class SaveFeature extends SaveGeoJsonObject {
  @IsString()
  @Equals('Feature')
  declare type: string | undefined

  /**
   * The feature's geometry
   */
  @ValidateNested()
  @Type(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (typeHelpOptions: {
      newObject: any
      object: {
        type: string
        properties: string
        geometry: { type: string; coordinates: any }
      }
      property: string
    }) => {
      switch (typeHelpOptions?.object?.geometry?.type) {
        case 'Point':
          return SavePoint
        case 'MultiPoint':
          return SaveMultiPoint
        case 'LineString':
          return SaveLineString
        case 'MultiLineString':
          return SaveMultiLineString
        case 'Polygon':
          return SavePolygon
        case 'MultiPolygon':
          return SaveMultiPolygon
      }
    }
  )
  geometry:
    | SavePoint
    | SaveMultiPoint
    | SaveLineString
    | SaveMultiLineString
    | SavePolygon
    | SaveMultiPolygon
    | undefined

  /**
   * A value that uniquely identifies this feature in a
   * https://tools.ietf.org/html/rfc7946#section-3.2.
   */
  @IsString()
  @IsOptional()
  id?: string | number

  /**
   * Properties associated with this feature.
   */
  @IsObject()
  properties: any
}

/**
 * A collection of feature objects.
 *  https://tools.ietf.org/html/rfc7946#section-3.3
 */
export class SaveFeatureCollection extends SaveGeoJsonObject {
  public static build(raw: any) {
    return plainToInstance(SaveFeatureCollection, raw)
  }

  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  @Equals('FeatureCollection')
  declare type: string | undefined

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SaveFeature)
  features: SaveFeature[] | undefined
}
