import { Grower, Crop } from '@agroone/entities'
import { Parameters } from '@agroone-front/shared'
import { EntityMap } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'

export enum CropActionTypes {
  LOAD_CROPS = '[Crop] Load Crops',
  LOAD_CROPS_SUCCESS = '[Crop] Load Crops Success',
  LOAD_CROPS_FAILURE = '[Crop] Load Crops Failure',
  LOAD_CROP = '[Crop] Load Crop',
  ADD_CROP = '[Crop] Add Crop',
  ADD_CROP_SUCCESS = '[Crop] Add Crop Success',
  ADD_CROP_FAILURE = '[Crop] Add Crop Failure',
  UPSERT_CROP = '[Crop] Upsert Crop',
  ADD_CROPS = '[Crop] Add Crops',
  ADD_CROPS_SUCCESS = '[Crop] Add Crops Success',
  ADD_CROPS_FAILURE = '[Crop] Add Crops Failure',
  UPSERT_CROPS = '[Crop] Upsert Crops',
  UPDATE_CROP = '[Crop] Update Crop',
  UPDATE_CROP_SUCCESS = '[Crop] Update Crop Success',
  UPDATE_CROP_FAILURE = '[Crop] Update Crop Failure',
  PATCH_CROP = '[Crop] Patch Crop',
  PATCH_CROP_SUCCESS = '[Crop] Patch Crop Success',
  PATCH_CROP_FAILURE = '[Crop] Patch Crop Failure',
  UPDATE_CROPS = '[Crop] Update Crops',
  MAP_CROPS = '[Crop] Map Crops',
  DELETE_CROP = '[Crop] Delete Crop',
  DELETE_CROP_SUCCESS = '[Crop] Delete Crop Success',
  DELETE_CROP_FAILURE = '[Crop] Delete Crop Failure',
  DELETE_CROPS = '[Crop] Delete Crops',
  DELETE_CROPS_BY_PREDICATE = '[Crop] Delete Crops By Predicate',
  CLEAR_CROPS = '[Crop] Clear Crops',
}

export interface CropHttpParams {
  params?: Parameters
  includeOffers?: boolean
  fieldId?: number
  optionsRefresh?: { noMerge?: boolean; forceUpdate?: boolean }
}
// LOAD CROPS
export const loadCrops = createAction(CropActionTypes.LOAD_CROPS, props<CropHttpParams>())

export const loadCropsSuccess = createAction(CropActionTypes.LOAD_CROPS_SUCCESS, props<{ crops: Crop[] }>())

export const loadCropsFailure = createAction(CropActionTypes.LOAD_CROPS_FAILURE, props<{ error: any }>())

// ADD CROP
export const addCrop = createAction(
  CropActionTypes.ADD_CROP,
  props<{ payload: { crop: Crop; options?: { [key: string]: any } } }>()
)

export const addCropSuccess = createAction(
  CropActionTypes.ADD_CROP_SUCCESS,
  props<{ payload: { crop: Crop; fieldId?: number; message?: string; loading?: boolean } }>()
)

export const addCropFailure = createAction(
  CropActionTypes.ADD_CROP_FAILURE,
  props<{ error: any; loading?: boolean; message: string }>()
)
// UPDATE CROP
export const updateCrop = createAction(CropActionTypes.UPDATE_CROP, props<{ payload: { crop } }>())

export const updateCropSuccess = createAction(
  CropActionTypes.UPDATE_CROP_SUCCESS,
  props<{ payload: { crop: Crop; fieldId?: number; message?: string; loading: boolean } }>()
)

export const updateCropFailure = createAction(
  CropActionTypes.UPDATE_CROP_FAILURE,
  props<{ error: any; loading: boolean }>()
)

// Patch CROP
export const patchCrop = createAction(
  CropActionTypes.PATCH_CROP,
  props<{ payload: { crop: Crop; patchValues: { [key: string]: any }; message?: string } }>()
)

export const patchCropSuccess = createAction(
  CropActionTypes.PATCH_CROP_SUCCESS,
  props<{ payload: { crop: Crop; message?: string; loading: boolean } }>()
)

export const patchCropFailure = createAction(
  CropActionTypes.PATCH_CROP_FAILURE,
  props<{ error: any; loading: boolean }>()
)

export const addCropsSuccess = createAction(
  CropActionTypes.ADD_CROPS_SUCCESS,
  props<{ payload: { crops: Crop[]; message?: string; loading?: boolean } }>()
)

export const addCropsFailure = createAction(
  CropActionTypes.ADD_CROPS_FAILURE,
  props<{ error: any; loading?: boolean; message: string }>()
)

export const mapCrops = createAction(CropActionTypes.MAP_CROPS, props<{ entityMap: EntityMap<Crop> }>())

export const deleteCrop = createAction(CropActionTypes.DELETE_CROP, props<{ cropId: number; fieldId?: number }>())

export const deleteCropSuccess = createAction(
  CropActionTypes.DELETE_CROP_SUCCESS,
  props<{ payload: { cropId: number; fieldId?: number; message?: string } }>()
)
export const deleteCropError = createAction(CropActionTypes.DELETE_CROP_FAILURE, props<{ error: any }>())

export const deleteCrops = createAction(CropActionTypes.DELETE_CROPS)

export const loadCrop = createAction(CropActionTypes.LOAD_CROP, props<{ cropId: number }>())
export const clearCrops = createAction(CropActionTypes.CLEAR_CROPS)
