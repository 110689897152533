import { UserDto, UserPermissions } from '@agroone/entities'
import { ConnectionService } from './connection.service'
import { SharedUserService } from './shared-user.service'

export abstract class SharedPermissionService {
  protected abstract getOfflinePermissions(): UserPermissions[]

  constructor(protected userService: SharedUserService, protected connectionService: ConnectionService) {}

  public hasPermission(...permissions: UserPermissions[]): boolean {
    const user: UserDto = this.userService.currentUser
    let permissionCount: number = 0
    if (permissions?.length > 0) {
      permissionCount = permissions.filter((permission) => user?.permissions.includes(permission))?.length
    }
    return permissionCount > 0
  }

  public isGranted(permissions: UserPermissions[], isConnected: boolean = true): boolean {
    if (!isConnected || !this.connectionService.connected) {
      return this.canOffline(permissions)
    }
    return this.hasPermission(...permissions)
  }

  public canOffline(permissions: UserPermissions[]): boolean {
    return permissions.filter((permission) => this.getOfflinePermissions().includes(permission))?.length > 0
  }
}
