import { NgModule } from '@angular/core'
import { ScoutingInfoOverlayComponent } from './scouting-info-overlay.component'
import { MenuOverlayComponent } from '../menu'
import { TranslateModule } from '@ngx-translate/core'
import { TranslateConstantPipe } from '../../pipes'
import { IsOfflineDirective } from '../../directives'
import { MatIconModule } from '@angular/material/icon'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule } from '@angular/material/legacy-button'

@NgModule({
  declarations: [ScoutingInfoOverlayComponent],
  imports: [
    TranslateModule.forChild(),
    TranslateConstantPipe,
    MatIconModule,
    CommonModule,
    RouterModule,
    IsOfflineDirective,
    MatLegacyButtonModule,
    MenuOverlayComponent,
  ],
  exports: [ScoutingInfoOverlayComponent],
})
export class ScoutingInfoOverlayModule {}
