<div class="inherit-field">
  <h2 mat-dialog-title>{{ 'DIALOG.INHERIT_FIELD.TITLE' | translate }}</h2>
  <mat-dialog-content class="inherit-field-content">
    <ng-container *ngIf="create; else contentEdit">
      {{ 'DIALOG.INHERIT_FIELD.CONTENT_CREATE' | translate }}
    </ng-container>
    <ng-template #contentEdit>
      {{ 'DIALOG.INHERIT_FIELD.CONTENT_EDIT' | translate }}
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="inherit-field-actions">
    <button color="accent" mat-flat-button (click)="inherit()">
      {{ 'DIALOG.INHERIT_FIELD.INHERIT' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close>
      <ng-container *ngIf="create; else buttonEdit">
        {{ 'DIALOG.INHERIT_FIELD.CREATE' | translate }}
      </ng-container>
      <ng-template #buttonEdit>
        {{ 'DIALOG.INHERIT_FIELD.EDIT' | translate }}
      </ng-template>
    </button>
  </mat-dialog-actions>
</div>
