import { IsInt, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { plainToInstance } from 'class-transformer'

export class SaveSpecificationPesticide {
  public static build(raw: any) {
    return plainToInstance(SaveSpecificationPesticide, raw)
  }

  @IsNumber()
  @IsNotEmpty()
  specificationId: number

  @IsNumber()
  @IsNotEmpty()
  pesticideProductId: number

  @IsNumber()
  @IsNotEmpty()
  cropTypeId: number

  @IsNumber()
  @IsNotEmpty()
  dosage: number

  @IsNumber()
  @IsOptional()
  cumulative?: number

  @IsNumber()
  @IsNotEmpty()
  phi: number

  @IsInt()
  @IsOptional()
  passageNumber?: number

  @IsString()
  @IsOptional()
  note?: string
}
