import { Component, Inject, OnInit } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms'

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.sass'],
})
export class NoteComponent implements OnInit {
  public form: FormGroup

  public class: string

  get noteContent(): AbstractControl {
    return this.form.get('noteContent')
  }

  constructor(
    public dialogRef: MatDialogRef<NoteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { message: string; style: string; readonly: boolean },
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      noteContent: [this.data.message],
    })
    if (this.data.readonly) {
      this.form.get('noteContent').disable()
    }
  }

  save(): void {
    this.dialogRef.close(this.form.value.noteContent)
  }

  onCancel() {
    this.dialogRef.close(undefined)
  }
}
