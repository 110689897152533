import { Injectable } from '@angular/core'
import {
  addCrop,
  addCropFailure,
  addCropSuccess,
  deleteCrop,
  deleteCropError,
  deleteCropSuccess,
  loadCrops,
  loadCropsFailure,
  loadCropsSuccess,
  patchCrop,
  patchCropFailure,
  patchCropSuccess,
  updateCrop,
  updateCropFailure,
  updateCropSuccess,
} from '../actions/crop.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, distinctUntilChanged, map, mergeMap, of, tap } from 'rxjs'
import { CropService } from '@agroone-app/shared/crop/services/crop.service'
import { TranslateService } from '@ngx-translate/core'
import { Crop } from '@agroone/entities'
import { LoadingState } from '@agroone-app/state/loading/loading.reducer'
import { Store } from '@ngrx/store'
import { startLoadingCrops, stopLoadingCrops } from '@agroone-app/state/loading/loading.actions'

@Injectable()
export class CropEffects {
  constructor(
    private actions$: Actions,
    private cropService: CropService,
    private translate: TranslateService,
    private loadingStore: Store<LoadingState>
  ) {}

  public getCrops$: any = createEffect(() => {
    this.loadingStore.dispatch(startLoadingCrops())

    return this.actions$.pipe(
      ofType(loadCrops),
      distinctUntilChanged(),
      mergeMap((parameters: any) =>
        this.cropService.getAll(parameters).pipe(
          tap(() => this.loadingStore.dispatch(stopLoadingCrops())),
          map((crops) => loadCropsSuccess({ crops })),
          catchError((e) => of(loadCropsFailure({ error: `${e}` })))
        )
      )
    )
  })

  public deleteCrop$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCrop),
      mergeMap(({ cropId, fieldId }) =>
        this.cropService.delete(cropId).pipe(
          map(() => deleteCropSuccess({ payload: { cropId, fieldId } })),
          catchError((e) => of(deleteCropError({ error: `${e}` })))
        )
      )
    )
  )

  public addCrop$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(addCrop),
      mergeMap(({ payload }) => {
        if (payload?.options?.insert === false) {
          return of(
            addCropSuccess({
              payload: {
                crop: payload.crop,
                fieldId: payload.crop.fieldId,
                message: this.translate.instant('FIELD.CROP.SEND_SUCCESS'),
                loading: false,
              },
            })
          )
        }
        return this.cropService.add(payload.crop).pipe(
          map((crop) =>
            addCropSuccess({
              payload: {
                crop,
                fieldId: crop.fieldId,
                message: this.translate.instant('FIELD.CROP.SEND_SUCCESS'),
                loading: false,
              },
            })
          ),
          catchError((e) =>
            of(
              addCropFailure({
                error: `${e}`,
                loading: false,
                message: this.translate.instant('FIELD.CROP.SEND_FAILURE'),
              })
            )
          )
        )
      })
    )
  )

  public updateCrop$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCrop),
      mergeMap(({ payload }) =>
        this.cropService.update(new Crop(payload.crop)).pipe(
          map((crop) =>
            updateCropSuccess({
              payload: {
                crop,
                fieldId: crop?.fieldId,
                message: 'Crop successfully updated',
                loading: false,
              },
            })
          ),
          catchError((e) => of(updateCropFailure({ error: `${e}`, loading: false })))
        )
      )
    )
  )

  public patchCrop$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(patchCrop),
      mergeMap(({ payload }) =>
        this.cropService.patch(new Crop(payload.crop), payload.patchValues).pipe(
          map((crop) =>
            patchCropSuccess({
              payload: {
                crop,
                message: payload?.message ? payload?.message : 'Crop successfully patched',
                loading: false,
              },
            })
          ),
          catchError((e) => of(patchCropFailure({ error: `${e}`, loading: false })))
        )
      )
    )
  )
}
