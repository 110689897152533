import { RecipientNotificationDto } from '@agroone/entities'
import { Component, Inject, OnInit } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { delay, tap } from 'rxjs'
import { NotificationsService } from '@agroone-app/shared/notifications/services/notifications.service'

@UntilDestroy()
@Component({
  selector: 'app-create-notification-form',
  templateUrl: './create-notification-form.component.html',
  styleUrls: ['./create-notification-form.component.sass'],
})
export class CreateNotificationFormComponent implements OnInit {
  public form: FormGroup
  public recipients: RecipientNotificationDto[] = null

  get description(): AbstractControl {
    return this.form.get('description')
  }

  get recipient(): AbstractControl {
    return this.form.get('recipient')
  }

  constructor(
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<CreateNotificationFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { description: string; recipientUserId: number }
  ) {}

  ngOnInit() {
    this.loadUsersList()
  }

  public recipientsDisplay(recipient?: RecipientNotificationDto): string | undefined {
    return recipient ? `${recipient?.firstName} ${recipient?.lastName}` : undefined
  }

  public filterRecipient(value: RecipientNotificationDto, input: RecipientNotificationDto | string): boolean {
    if (!input) {
      return true
    }
    const filterValue: string =
      input instanceof Object ? `${input.firstName} ${input.lastName}`?.toLowerCase() : input.toLowerCase()
    return `${value.firstName} ${value.lastName}`.toLowerCase().indexOf(filterValue) !== -1
  }

  public sortRecipient(a: RecipientNotificationDto, b: RecipientNotificationDto): number {
    return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
  }

  private initForm() {
    this.form = this.formBuilder.group({
      description: [this.data?.description, [Validators.required]],
      recipient: [
        this.recipients?.find((recipient: RecipientNotificationDto) => recipient.id === this.data?.recipientUserId),
        [Validators.required],
      ],
    })
  }

  private loadUsersList() {
    this.notificationsService
      .getNotificationRecipients()
      .pipe(
        untilDestroyed(this),
        tap((v) => {
          this.recipients = v
          this.initForm()
        })
      )
      .subscribe()
  }

  public save(): void {
    this.dialogRef.close(this.form.value)
  }

  public closePopup() {
    this.dialogRef.close(null)
  }
}
