import { FeatureCollection } from './feature-collection'
import { Factory } from './factory'
import { Location } from './location'
import { ISO8601ToDate } from '@agroone/helpers'
import { SaveFeatureCollection } from './save-feature-collection'
import { Crop, CropGrowPreviousYear, Planting } from './crop'

export class VirtualCrop
  implements
    Omit<
      Crop,
      | 'offers'
      | 'foreignMaterialScoutings'
      | 'farmName'
      | 'fieldName'
      | 'fieldId'
      | 'fieldArea'
      | 'fieldAreaUnit'
      | 'fieldOrganic'
      | 'country'
      | 'forecastHarvestDate'
      | 'floweringScoutingId'
      | 'yieldEstimationScoutingId'
      | 'lastScoutingDate'
      | 'plantNumber'
      | 'externalId'
      | 'externalClientId'
      | 'reentryDate'
      | 'forecastHarvestDateAsDate'
      | 'reentryDateAsDate'
    >
{
  id?: number
  cropName?: string
  shortName?: string
  plantedDate?: string
  plantedArea: number
  intendedArea: number
  areaUnit: string
  varietyId?: number
  varietyName?: string
  cropTypeId?: number
  cropType: string
  growerId: number
  growerName: string
  startDate?: string
  campaign: string
  endDate?: string
  regionId: number
  region: string
  location?: Location
  boundaryId?: number
  boundary?: FeatureCollection | SaveFeatureCollection
  planting?: Planting
  growingSystem?: string
  associatedCrop?: string
  notes?: string
  active?: boolean
  userId?: string
  updatedDate?: string
  technician?: string
  destinationFactoryId?: number
  destinationFactory?: Factory
  campaignId: number
  lastTimeCropWasGrown: number
  productsUsedLastYear: number[]
  productsUsedTwoYearsAgo: number[]
  growsLastYear: CropGrowPreviousYear[]
  growsTwoYearsAgo: CropGrowPreviousYear[]
  problematicWeeds: string[]
  nutTrees: boolean
  isVirtual: boolean

  constructor(virtualCrop?: VirtualCrop) {
    this.id = virtualCrop?.id
    if (virtualCrop?.cropName) {
      this.cropName = virtualCrop.cropName
    }
    if (virtualCrop?.shortName) {
      this.shortName = virtualCrop.shortName
    }
    this.plantedDate = virtualCrop?.plantedDate
    this.plantedArea = virtualCrop?.plantedArea ?? 0
    this.intendedArea = virtualCrop?.intendedArea ?? 0
    this.areaUnit = virtualCrop?.areaUnit ?? ''
    this.varietyId = virtualCrop?.varietyId
    this.varietyName = virtualCrop?.varietyName
    this.growerId = virtualCrop?.growerId ?? 0
    this.growerName = virtualCrop?.growerName ?? ''
    this.startDate = virtualCrop?.startDate ?? ''
    this.campaign = virtualCrop?.campaign ?? ''
    this.endDate = virtualCrop?.endDate
    this.growingSystem = virtualCrop?.growingSystem
    this.associatedCrop = virtualCrop?.associatedCrop
    this.cropType = virtualCrop?.cropType ?? ''
    this.cropTypeId = virtualCrop?.cropTypeId
    this.boundaryId = virtualCrop?.boundaryId ?? null
    this.region = virtualCrop?.region ?? ''
    this.regionId = virtualCrop?.regionId
    this.notes = virtualCrop?.notes
    this.technician = virtualCrop?.technician ?? ''
    this.active = !!virtualCrop?.active
    this.updatedDate = virtualCrop?.updatedDate
    this.userId = virtualCrop?.userId
    this.destinationFactory = virtualCrop?.destinationFactory ?? null
    this.destinationFactoryId = virtualCrop?.destinationFactoryId ?? null
    this.campaignId = virtualCrop?.campaignId
    this.lastTimeCropWasGrown = virtualCrop?.lastTimeCropWasGrown ?? null
    this.productsUsedLastYear = virtualCrop?.productsUsedLastYear ?? []
    this.productsUsedTwoYearsAgo = virtualCrop?.productsUsedTwoYearsAgo ?? []
    this.growsLastYear = virtualCrop?.growsLastYear ?? []
    this.growsTwoYearsAgo = virtualCrop?.growsTwoYearsAgo ?? []
    this.nutTrees = virtualCrop?.nutTrees ?? false
    this.isVirtual = true
    this.problematicWeeds = virtualCrop?.problematicWeeds ?? []

    if (virtualCrop?.planting) {
      this.planting = virtualCrop.planting
    }
    if (virtualCrop?.location) {
      this.location = virtualCrop.location
    }
    if (virtualCrop?.boundary) {
      this.boundary = virtualCrop.boundary
    }
  }

  get plantedDateAsDate(): Date {
    return ISO8601ToDate(this.plantedDate)
  }
}
