import { Crop, Location } from '@agroone/entities'
import { Component, Input, ViewChild } from '@angular/core'
import { MenuOverlayComponent } from '../menu'
import { SharedConstantService } from '../../services'

@Component({
  selector: 'app-scouting-info-overlay',
  templateUrl: './scouting-info-overlay.component.html',
  styleUrls: ['./scouting-info-overlay.component.scss'],
})
export class ScoutingInfoOverlayComponent {
  @Input() crop: Crop
  @Input() isOffer?: boolean = false
  @Input() dateFormat: string

  @ViewChild('menu', { static: false }) menu!: MenuOverlayComponent

  constructor(public constantService: SharedConstantService) {}

  public toggle() {
    this.menu.toggle()
  }

  public openMaps(location: Location) {
    if (location.longitude && location.latitude) {
      const url: string = `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`
      window.open(url, '_blank')
    }
  }
}
