import { DefaultProjectorFn, MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { LoadingState } from './loading.reducer'
import { Features } from '../features.enum'

export const selectSharedState: MemoizedSelector<
  object,
  LoadingState,
  DefaultProjectorFn<LoadingState>
> = createFeatureSelector<LoadingState>(Features.LOADING)
export const selectLoading: MemoizedSelector<object, boolean, (s1: LoadingState) => boolean> = createSelector(
  selectSharedState,
  (state) => state?.isLoading
)
export const selectLoadingGrowers: MemoizedSelector<object, boolean, (s1: LoadingState) => boolean> = createSelector(
  selectSharedState,
  (state) => state?.isLoadingGrowers
)
export const selectLoadingCrops: MemoizedSelector<object, boolean, (s1: LoadingState) => boolean> = createSelector(
  selectSharedState,
  (state) => state?.isLoadingCrops
)
export const selectLoadingVarieties: MemoizedSelector<object, boolean, (s1: LoadingState) => boolean> = createSelector(
  selectSharedState,
  (state) => state?.isLoadingVarieties
)
export const selectLoadingSpecifications: MemoizedSelector<object, boolean, (s1: LoadingState) => boolean> =
  createSelector(selectSharedState, (state) => state?.isLoadingSpecifications)
