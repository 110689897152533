import { Component, Inject, OnInit } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-inherit-field',
  templateUrl: './inherit-field.component.html',
  styleUrls: ['./inherit-field.component.sass'],
})
export class InheritFieldComponent implements OnInit {
  public class: string

  public create: boolean

  constructor(
    public dialogRef: MatDialogRef<InheritFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: 'create' | 'edit' }
  ) {}

  ngOnInit(): void {
    this.create = this.data.type === 'create'
  }

  inherit(): void {
    this.dialogRef.close(true)
  }
}
