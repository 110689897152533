import { Scouting } from '@agroone/entities'
import { ActionCreator, createAction, props } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'

export enum ScoutingActionTypes {
  LOAD_SCOUTINGS = '[Scouting] Load Scoutings',
  LOAD_SCOUTINGS_SUCCESS = '[Scouting] Load Scoutings Success',
  LOAD_SCOUTINGS_FAILURE = '[Scouting] Load Scoutings Failure',

  ADD_SCOUTING = '[Scouting] Add Scouting',
  ADD_SCOUTING_SUCCESS = '[Scouting] Add Scouting Success',
  ADD_SCOUTING_FAILURE = '[Scouting] Add Scouting Failure',

  UPDATE_SCOUTING = '[Scouting] Update Scouting',
  UPDATE_SCOUTING_SUCCESS = '[Scouting] Update Scouting Success',
  UPDATE_SCOUTING_FAILURE = '[Scouting] Update Scouting Failure',

  DELETE_SCOUTING = '[Scouting] Delete Scouting ',
  DELETE_SCOUTING_SUCCESS = '[Scouting] Delete Scouting Success',
  DELETE_SCOUTING_FAILURE = '[Scouting] Delete Scouting Failure',
}

// load scouting items and add them to the according crop
export const loadScoutings: ActionCreator<
  ScoutingActionTypes.LOAD_SCOUTINGS,
  (props: { cropId: number }) => {
    cropId: number
  } & TypedAction<ScoutingActionTypes.LOAD_SCOUTINGS>
> = createAction(ScoutingActionTypes.LOAD_SCOUTINGS, props<{ cropId: number }>())

export const loadScoutingsSuccess: ActionCreator<
  ScoutingActionTypes.LOAD_SCOUTINGS_SUCCESS,
  (props: { cropId?: number; scoutings: Scouting[] }) => {
    cropId?: number
    scoutings: Scouting[]
  } & TypedAction<ScoutingActionTypes.LOAD_SCOUTINGS_SUCCESS>
> = createAction(ScoutingActionTypes.LOAD_SCOUTINGS_SUCCESS, props<{ cropId?: number; scoutings: Scouting[] }>())

export const loadScoutingsFailure: ActionCreator<
  ScoutingActionTypes.LOAD_SCOUTINGS_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<ScoutingActionTypes.LOAD_SCOUTINGS_FAILURE>
> = createAction(ScoutingActionTypes.LOAD_SCOUTINGS_FAILURE, props<{ error: any }>())

// Add new scouting item
export const addScouting: ActionCreator<
  ScoutingActionTypes.ADD_SCOUTING,
  (props: {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  }) => {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  } & TypedAction<ScoutingActionTypes.ADD_SCOUTING>
> = createAction(
  ScoutingActionTypes.ADD_SCOUTING,
  props<{
    cropId: number
    scouting: Scouting
    pictures?: { name: string; content: string }[]
  }>()
)

export const addScoutingSuccess: ActionCreator<
  ScoutingActionTypes.ADD_SCOUTING_SUCCESS,
  (props: {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  }) => {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  } & TypedAction<ScoutingActionTypes.ADD_SCOUTING_SUCCESS>
> = createAction(
  ScoutingActionTypes.ADD_SCOUTING_SUCCESS,
  props<{
    cropId: number
    scouting: Scouting
    pictures?: { name: string; content: string }[]
  }>()
)
export const addScoutingFailure: ActionCreator<
  ScoutingActionTypes.ADD_SCOUTING_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<ScoutingActionTypes.ADD_SCOUTING_FAILURE>
> = createAction(ScoutingActionTypes.ADD_SCOUTING_FAILURE, props<{ error: any }>())

// Update scouting item
export const updateScouting: ActionCreator<
  ScoutingActionTypes.UPDATE_SCOUTING,
  (props: {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  }) => {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  } & TypedAction<ScoutingActionTypes.UPDATE_SCOUTING>
> = createAction(
  ScoutingActionTypes.UPDATE_SCOUTING,
  props<{
    cropId: number
    scouting: Scouting
    pictures?: { name: string; content: string }[]
  }>()
)

export const updateScoutingSuccess: ActionCreator<
  ScoutingActionTypes.UPDATE_SCOUTING_SUCCESS,
  (props: {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  }) => {
    cropId: number
    scouting: Scouting
    pictures?: {
      name: string
      content: string
    }[]
  } & TypedAction<ScoutingActionTypes.UPDATE_SCOUTING_SUCCESS>
> = createAction(
  ScoutingActionTypes.UPDATE_SCOUTING_SUCCESS,
  props<{
    cropId: number
    scouting: Scouting
    pictures?: { name: string; content: string }[]
  }>()
)
export const updateScoutingFailure: ActionCreator<
  ScoutingActionTypes.UPDATE_SCOUTING_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<ScoutingActionTypes.UPDATE_SCOUTING_FAILURE>
> = createAction(ScoutingActionTypes.UPDATE_SCOUTING_FAILURE, props<{ error: any }>())

// delete scouting item from scouting crop set
export const deleteScouting: ActionCreator<
  ScoutingActionTypes.DELETE_SCOUTING,
  (props: { cropId: number; scoutingId: number }) => {
    cropId: number
    scoutingId: number
  } & TypedAction<ScoutingActionTypes.DELETE_SCOUTING>
> = createAction(ScoutingActionTypes.DELETE_SCOUTING, props<{ cropId: number; scoutingId: number }>())

export const deleteScoutingSuccess: ActionCreator<
  ScoutingActionTypes.DELETE_SCOUTING_SUCCESS,
  (props: { cropId: number; scoutingId: number }) => {
    cropId: number
    scoutingId: number
  } & TypedAction<ScoutingActionTypes.DELETE_SCOUTING_SUCCESS>
> = createAction(ScoutingActionTypes.DELETE_SCOUTING_SUCCESS, props<{ cropId: number; scoutingId: number }>())
export const deleteScoutingFailure: ActionCreator<
  ScoutingActionTypes.DELETE_SCOUTING_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<ScoutingActionTypes.DELETE_SCOUTING_FAILURE>
> = createAction(ScoutingActionTypes.DELETE_SCOUTING_FAILURE, props<{ error: any }>())
