export enum Languages {
  'en-us' = 'en-us',
  'fr-fr' = 'fr-fr',
  'fr-ca' = 'fr-ca',
  'hu-hu' = 'hu-hu',
  'po-po' = 'po-po',
  'ru-ru' = 'ru-ru',
  'es-es' = 'es-es',
  'it-it' = 'it-it',
  'de-de' = 'de-de',
  'pt-pt' = 'pt-pt',
}
