import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ActionCreator, on, ReducerTypes } from '@ngrx/store'

import {
  addOfflineActions,
  clearOfflineActions,
  syncOfflineActions,
  syncOfflineActionsFailure,
  syncOfflineActionsSuccess,
} from '../actions/offline.actions'

export interface OfflineAction {
  type: string
  payload?: any
}

export interface OfflineState extends EntityState<OfflineAction> {
  actions: { action: OfflineAction; actionId?: number; actionVerb?: string }[]
}

export const offlineAdapter: EntityAdapter<OfflineAction> = createEntityAdapter<OfflineAction>()
export const initialOfflineState: OfflineState = offlineAdapter.getInitialState({
  actions: [],
})

export const offlineOns: ReducerTypes<OfflineState, ActionCreator[]>[] = [
  on(addOfflineActions, (state, action) => {
    let filteredActions: {
      action: OfflineAction
      actionId?: number
      actionVerb?: string
    }[] = state.actions
    if (action.actionVerb === 'PUT') {
      filteredActions = state?.actions?.filter((act) => action?.actionId !== act?.actionId)
    }
    return {
      ...state,
      actions: [...filteredActions, action],
    }
  }),
  on(syncOfflineActions, (state) => ({
    ...state,
  })),
  on(syncOfflineActionsSuccess, (state, action) => ({
    ...state,
  })),
  on(syncOfflineActionsFailure, (state) => ({
    ...state,
  })),
  on(clearOfflineActions, (state) => ({
    ...state,
    actions: [],
  })),
]
