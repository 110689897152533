import 'reflect-metadata'
import { plainToInstance } from 'class-transformer'
import { IsNotEmpty, IsNumber, IsOptional, IsString, Max, Min, Validate, validateSync } from 'class-validator'
import { IsDecimalNumber } from '@agroone/helpers'

export class SaveCropTypeForecast {
  public static build(raw: any) {
    return plainToInstance(SaveCropTypeForecast, raw)
  }

  public static validate(raw: any) {
    const saveCropTypeForecast = this.build(raw)
    return validateSync(saveCropTypeForecast)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  region: string

  @IsString()
  @IsNotEmpty()
  businessUnit: string

  @IsNumber()
  @IsNotEmpty()
  growerId: number

  @IsString()
  @IsOptional()
  growerName?: string

  @IsString()
  @IsOptional()
  factory?: string

  @IsString()
  @IsNotEmpty()
  cropType: string

  @IsString()
  @IsNotEmpty()
  rawMaterialProcessed: string

  @IsNumber()
  @IsNotEmpty()
  @Min(new Date().getFullYear() - 1)
  @Max(new Date().getFullYear() + 1)
  year: number

  @IsNumber()
  @IsNotEmpty()
  @Min(1)
  @Max(53)
  week: number

  @IsOptional()
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsNumber()
  day1?: number

  @IsOptional()
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsNumber()
  day2?: number

  @IsOptional()
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsNumber()
  day3?: number

  @IsOptional()
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsNumber()
  day4?: number

  @IsOptional()
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsNumber()
  day5?: number

  @IsOptional()
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsNumber()
  day6?: number

  @IsOptional()
  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsNumber()
  day7?: number

  @IsString()
  @IsNotEmpty()
  quantityUnit: string

  @IsNumber()
  @Min(0)
  @Max(100)
  @IsOptional()
  brandPercentage?: number

  @IsString()
  @IsNotEmpty()
  campaign: string

  @IsString()
  @IsNotEmpty()
  userId: string
}
