export class ReportError {
  type: string
  message: string
  region: string
  rawMaterialProcessed: string
  year: number
  week: number
  grower: string

  constructor(reportError?: ReportError) {
    this.type = reportError?.type ?? ''
    this.message = reportError?.message ?? ''
    this.region = reportError?.region ?? ''
    this.year = reportError?.year
    this.week = reportError?.week
    this.grower = reportError?.grower ?? ''
    this.rawMaterialProcessed = reportError?.rawMaterialProcessed ?? ''
  }
}
export class PlanningReport {
  id?: number
  version: number
  campaign: string
  date: string
  errors: ReportError[]

  constructor(planningReport?: PlanningReport) {
    if (planningReport?.id) {
      this.id = planningReport?.id
    }
    this.version = planningReport?.version ?? 1
    this.campaign = planningReport?.campaign ?? ''
    this.date = planningReport?.date ?? ''
    this.errors = planningReport?.errors ?? []
  }
}
