import { Contact } from './contact'
import { SaveSupplier } from './save-supplier'

export class Supplier {
  id: number | undefined
  active?: number
  supplierName: string | undefined
  certificate?: string
  supplierType: string | undefined
  regions: string[] | undefined
  contacts: Contact[] | undefined
  cropTypes?: string[]
  workOrderTypes?: string[]

  constructor(supplier?: Supplier) {
    this.id = supplier?.id
    this.active = 1
    this.certificate = supplier?.certificate ?? ''
    this.supplierName = supplier?.supplierName ?? ''
    this.supplierType = supplier?.supplierType ?? ''
    this.regions = supplier?.regions ?? []
    this.contacts = supplier?.contacts?.map((c) => new Contact(c))
    this.cropTypes = supplier?.cropTypes ?? []
    this.workOrderTypes = supplier?.workOrderTypes ?? []
  }
}

export class SupplierDAO {
  id?: number
  name: string | undefined
  active: number
  certificate: string
  type: string
  cropTypes: string | null
  workOrderTypes: string | null

  constructor(supplier: SaveSupplier) {
    this.id = supplier?.id
    this.name = supplier?.supplierName
    this.active = supplier?.active ? 1 : 0
    this.cropTypes = supplier?.cropTypes?.join(',') ?? null
    this.workOrderTypes = supplier?.workOrderTypes?.join(',') ?? null
    this.type = supplier.supplierType ?? ''
    this.certificate = supplier.certificate ?? ''
  }
}

export class SupplierContactDAO {
  supplierId: number | undefined
  contactId: number | undefined
}

export class SupplierRegionDAO {
  supplierId: number | undefined
  regionId: number | undefined
}
