<div class="confirm">
  <h2 mat-dialog-title>{{ 'PLANNING.CONFIRM.TITLE' | translate }}:</h2>
  <mat-dialog-content class="confirm-content">
    <div>
      {{ 'PLANNING.CONFIRM.VERSION' | translate : { version: data.version } }}
    </div>
    <div>
      {{ 'PLANNING.CONFIRM.DATE' | translate : { date: data.date } }}
    </div>
    <div>
      {{ 'PLANNING.CONFIRM.GENERATE' | translate : { version: data.version + 1 } }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="confirm-actions">
    <button color="accent" mat-flat-button (click)="save()">
      {{ 'PLANNING.ACCEPT' | translate }}
    </button>
  </mat-dialog-actions>
</div>
