import { LoggerService, SharedUserService } from '@agroone-front/shared'
import { Component, Inject, Injector, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'

/**
 * @deprecated
 * DON'T USE ANYMORE !!!
 * For destroyed use the @ngneat/until-destroy library already imported in the project
 * For the different services, import them directly in the constructor of your components
 *
 */
@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {
  /**
   * Translate service
   * @deprecated
   */
  protected translate: TranslateService
  /**
   * @deprecated
   */
  protected logger: LoggerService
  /**
   * @deprecated
   */
  protected destroyed: Subject<void> = new Subject()
  /**
   * @deprecated
   */
  protected userService: SharedUserService

  constructor(@Inject(Injector) injector: Injector) {
    // Fetch the main injector.
    this.logger = injector.get(LoggerService)
    this.translate = injector.get(TranslateService)
    this.userService = injector.get(SharedUserService)
  }

  /**
   * @deprecated
   */
  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }
}
