import { Specification } from '@agroone/entities'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ActionCreator, on, ReducerTypes } from '@ngrx/store'

import {
  clearSpecifications,
  loadSpecifications,
  loadSpecificationsFailure,
  loadSpecificationsSuccess,
} from '../actions/specification.actions'
export type SpecificationState = EntityState<Specification>

export const specificationAdapter: EntityAdapter<Specification> = createEntityAdapter<Specification>()

export const initialSpecificationState: SpecificationState = specificationAdapter.getInitialState({
  // eslint-disable-next-line no-empty-pattern
  ids: ([] = []),
})

export const specificationOns: ReducerTypes<SpecificationState, ActionCreator[]>[] = [
  on(loadSpecifications, (state) => ({
    ...state,
  })),
  on(loadSpecificationsSuccess, (state, action) => specificationAdapter.setAll(action?.specifications, { ...state })),
  on(loadSpecificationsFailure, (state) => ({
    ...state,
  })),
  on(clearSpecifications, () => initialSpecificationState),
]
