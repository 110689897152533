import 'reflect-metadata'
import { Type } from 'class-transformer'
import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { SaveTranslation } from './translation'
import { GenericEntityWithTranslation } from './generic-entity'
import { ExternalConstantRegion } from './constant'

export class AnalysisType {
  id?: number
  key: string | undefined
  translations: GenericEntityWithTranslation[] | undefined
  regions?: ExternalConstantRegion[]

  constructor(a?: AnalysisType) {
    this.id = a?.id
    this.key = a?.key
    this.translations = a?.translations
    this.regions = a?.regions ?? []
  }
}

export class SaveAnalysisType {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  key: string | undefined

  @IsNotEmpty()
  @Type(() => SaveTranslation)
  translations: SaveTranslation[] | undefined
}
