import { Crop, Field } from '@agroone/entities'
import { FieldHttpParams } from '@agroone-app/shared/field/services/field.service'
import { createAction, props } from '@ngrx/store'
import { FieldDto } from '@agroone-app/scene/crop-management/field/dto/field.dto'

export enum FieldActionTypes {
  LOAD_FIELDS = '[Field] Load Fields',
  LOAD_FIELDS_SUCCESS = '[Field] Load Fields Success',
  LOAD_FIELDS_FAILURE = '[Field] Load Fields Failure',
  LOAD_FIELD = '[Field] Load Field',
  LOAD_FIELD_SUCCESS = '[Field] Load Field Success',
  LOAD_FIELD_FAILURE = '[Field] Load Field Failure',
  REFRESH_FIELD = '[Field] Refresh Field',
  REFRESH_FIELD_SUCCESS = '[Field] Refresh Field Success',
  REFRESH_FIELD_FAILURE = '[Field] Refresh Field Failure',
  ADD_FIELD = '[Field] Add Field',
  ADD_FIELD_SUCCESS = '[Field] Add Field Success',
  ADD_FIELD_FAILURE = '[Field] Add Field Failure',
  UPDATE_FIELD = '[Field] Update Field',
  UPDATE_FIELD_SUCCESS = '[Field] Update Field Success',
  UPDATE_FIELD_FAILURE = '[Field] Update Field Failure',
  DELETE_FIELD = '[Field] Delete Field',
  DELETE_FIELD_SUCCESS = '[Field] Delete Field Success',
  DELETE_FIELD_FAILURE = '[Field] Delete Field Failure',
  CLEAR_FIELDS = '[Field] Clear Fields',
  ADD_FIELDS = '[Field] Add Fields',
  ADD_FIELDS_SUCCESS = '[Field] Add Fields Success',
  ADD_FIELDS_FAILURE = '[Field] Add Fields Failure',
  ADD_CROP_TO_FIELD = '[Field] Add Crop to Field',
  UPDATE_CROP_IN_FIELD = '[Field] Update Crop in Field',
  DELETE_CROP_FROM_FIELD = '[Field] Delete Crop from Field',
}

// LOAD FIELDS
export const loadFields = createAction(FieldActionTypes.LOAD_FIELDS, props<FieldHttpParams>())

export const loadFieldsSuccess = createAction(FieldActionTypes.LOAD_FIELDS_SUCCESS, props<{ fields: Field[] }>())

export const loadFieldsFailure = createAction(FieldActionTypes.LOAD_FIELDS_FAILURE, props<{ error: any }>())

// LOAD FIELD
export const loadField = createAction(FieldActionTypes.LOAD_FIELD, props<{ fieldId: number }>())

export const loadFieldSuccess = createAction(
  FieldActionTypes.LOAD_FIELD_SUCCESS,
  props<{ payload: { field: any; message?: string } }>()
)
export const loadFieldFailure = createAction(FieldActionTypes.LOAD_FIELD_FAILURE, props<{ error: any }>())

// LOAD FIELD
export const refreshField = createAction(FieldActionTypes.REFRESH_FIELD, props<{ field: Field }>())
// // ADD FIELD
export const addField = createAction(
  FieldActionTypes.ADD_FIELD,
  props<{
    payload: { field: FieldDto; options?: { [key: string]: any } }
  }>()
)

export const addFieldSuccess = createAction(
  FieldActionTypes.ADD_FIELD_SUCCESS,
  props<{ payload: { field: Field; loading?: boolean } }>()
)

export const addFieldFailure = createAction(
  FieldActionTypes.ADD_FIELD_FAILURE,
  props<{ error: any; loading?: boolean }>()
)

export const addFieldsSuccess = createAction(
  FieldActionTypes.ADD_FIELDS_SUCCESS,
  props<{ payload: { fields: Field[] } }>()
)

export const addFieldsFailure = createAction(FieldActionTypes.ADD_FIELDS_FAILURE, props<{ error: any }>())

// // UPDATE FIELD
export const updateField = createAction(FieldActionTypes.UPDATE_FIELD, props<{ payload: { field: FieldDto } }>())

export const updateFieldSuccess = createAction(
  FieldActionTypes.UPDATE_FIELD_SUCCESS,
  props<{ payload: { field: Field; message?: string; loading: boolean } }>()
)

export const updateFieldFailure = createAction(
  FieldActionTypes.UPDATE_FIELD_FAILURE,
  props<{ error: any; loading: boolean }>()
)

export const deleteField = createAction(FieldActionTypes.DELETE_FIELD, props<{ fieldId: number }>())

export const deleteFieldSuccess = createAction(
  FieldActionTypes.DELETE_FIELD_SUCCESS,
  props<{ payload: { fieldId: number; message?: string } }>()
)
export const deleteFieldError = createAction(FieldActionTypes.DELETE_FIELD_FAILURE, props<{ error: any }>())
export const clearFields = createAction(FieldActionTypes.CLEAR_FIELDS)
export const addCropToField = createAction(FieldActionTypes.ADD_CROP_TO_FIELD, props<{ crop: Crop; fieldId: number }>())
export const updateCropInField = createAction(
  FieldActionTypes.UPDATE_CROP_IN_FIELD,
  props<{ fieldId: number; crop: Crop }>()
)
export const deleteCropFromField = createAction(
  FieldActionTypes.DELETE_CROP_FROM_FIELD,
  props<{ fieldId: number; cropId: number }>()
)
