import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, debounceTime, map, mergeMap } from 'rxjs'
import { of } from 'rxjs'
import {
  loadInterventionsSuccess,
  loadInterventions,
  loadInterventionsFailure,
  addIntervention,
  addInterventionSuccess,
  deleteIntervention,
  deleteInterventionError,
  deleteInterventionSuccess,
  addInterventionsFailure,
} from '../actions/intervention.actions'
import { InterventionService } from '@agroone-app/shared/interventions/services/intervention.service'

@Injectable()
export class InterventionEffects {
  constructor(private actions$: Actions, private interventionService: InterventionService) {}

  public getInterventionsByCropId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInterventions),
      debounceTime(100),
      mergeMap((action) =>
        this.interventionService.getByCropId(action.cropId).pipe(
          map((interventionsResponse) =>
            loadInterventionsSuccess({
              cropId: action.cropId,
              interventions: interventionsResponse,
            })
          ),
          catchError((e) => of(loadInterventionsFailure({ error: `${e}` })))
        )
      )
    )
  )

  public addIntervention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addIntervention),
      mergeMap(({ payload }) =>
        this.interventionService.save(payload.intervention, payload.task).pipe(
          map((interventionResponse) =>
            addInterventionSuccess({
              payload: {
                cropId: payload.cropId,
                intervention: interventionResponse,
              },
            })
          ),
          catchError((e) =>
            of(
              addInterventionsFailure({
                error: `${e}`,
                message: `An error occurred during intervention creation`,
              })
            )
          )
        )
      )
    )
  )

  public deleteIntervention$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteIntervention),
      mergeMap(({ cropId, interventionId }) =>
        this.interventionService.delete(interventionId).pipe(
          map(() => deleteInterventionSuccess({ cropId, interventionId })),
          catchError((e) => of(deleteInterventionError({ error: `${e}` })))
        )
      )
    )
  )
}
