import { Reentry } from './pesticide'

export class Product {
  pesticideId: number
  identificationNumber: string
  name: string
  rate: number
  rateUnit: string
  rateAreaUnit: string
  area: number
  targetDate: string
  activeIngredientsNames?: string[]
  reentries?: Reentry[]
  alternativeSolution?: string
  note?: string

  constructor(product?: Product) {
    this.pesticideId = product?.pesticideId ?? 0
    this.identificationNumber = product?.identificationNumber ?? ''
    this.name = product?.name ?? ''
    this.rate = +(product?.rate ?? 0)
    this.rateUnit = product?.rateUnit ?? ''
    this.rateAreaUnit = product?.rateAreaUnit ?? ''
    this.area = product?.area
    this.targetDate = product?.targetDate
    this.note = product?.note
    this.alternativeSolution = product?.alternativeSolution

    if (product.activeIngredientsNames) {
      this.activeIngredientsNames = product.activeIngredientsNames
    }
  }
}
