import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'

export class SaveGesagCrop {
  @IsString()
  @IsNotEmpty()
  public id: string // Code culture Producteur

  @IsString()
  @IsNotEmpty()
  public plot: string // Code Parcelle Producteur

  @IsString()
  @IsNotEmpty()
  public code: string // Code Produit

  @IsString()
  @IsNotEmpty()
  public seed: string // Lot Semence

  @IsString()
  @IsNotEmpty()
  public plant: string // N° Plant

  @IsString()
  @IsNotEmpty()
  public startDate: string // Date début culture

  @IsString()
  @IsOptional()
  public endDate: string // Date fin culture

  @IsString()
  @IsNotEmpty()
  public tracking: string // Code Traça

  @IsString()
  @IsNotEmpty()
  public variety: string // Nom variété

  @IsString()
  @IsNotEmpty()
  public plantedDate: string

  @IsNumber()
  @IsNotEmpty()
  public plantedArea: number

  @IsNumber()
  @IsNotEmpty()
  public intendedArea: number

  @IsNumber()
  @IsNotEmpty()
  public density: number

  @IsString()
  @IsNotEmpty()
  public seedingRateUnit: string
}
