import { IsNumber, IsOptional, IsString, Validate } from 'class-validator'
import { IsISO8601WithTimezone } from '@agroone/helpers'

export class SaveComment {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  public harvestTeamId: number

  @IsString()
  public item: string

  @IsString()
  public subItem: string

  @IsString()
  public text: string

  @IsString()
  @Validate(IsISO8601WithTimezone)
  public dateBegin: string

  @IsString()
  @Validate(IsISO8601WithTimezone)
  public dateEnd: string

  @IsString()
  @IsOptional()
  public color?: string
}
