import { Location } from '@agroone/entities'
import { FeatureCollection } from '@agroone/entities'

export class PointOfInterest {
  public id: number
  public location: Location
  constructor(id: number, location: Location) {
    this.id = id
    this.location = location
  }
}

export class BoundaryOfInterest {
  public id: number
  public boundary: FeatureCollection
  constructor(id: number, boundary: FeatureCollection) {
    this.id = id
    this.boundary = boundary
  }
}

export interface Feature {
  points: PointOfInterest[]
  boundaries: BoundaryOfInterest[]
}
