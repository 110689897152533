import { Location } from './location'
import { Reentry } from './pesticide'
import { FeatureCollection } from './feature-collection'
import { ReportingDAO } from './reporting'
import { SaveWorkOrder } from './save-work-order'
import { AgrooneNutrient } from './agroone-nutrient'
import { dateToISO8601, ISO8601ToDate } from '@agroone/helpers'

export class WorkOrderPlanting {
  id: number
  varietyId: number
  varietyName: string
  lotNumber?: string
  seedingRate: number
  seedingRateUnit: string
  seedingArea: number
  seedingAreaUnit: string
  targetDate: string

  get targetDateAsDate(): Date {
    return ISO8601ToDate(this.targetDate)
  }

  constructor(planting?: Omit<WorkOrderPlanting, 'targetDateAsDate'>) {
    if (planting?.id) {
      this.id = planting.id
    }
    this.varietyId = planting?.varietyId
    this.lotNumber = planting?.lotNumber ?? ''
    this.varietyName = planting?.varietyName ?? ''
    this.seedingRate = planting?.seedingRate
    this.seedingRateUnit = planting?.seedingRateUnit
    this.seedingArea = planting?.seedingArea
    this.seedingAreaUnit = planting?.seedingAreaUnit
    this.targetDate = planting?.targetDate ?? ''
  }
}

export class WorkOrderPlantingDAO {
  id: number
  varietyId: number
  lotNumber?: string
  seedingRate: number
  seedingRateUnit: string
  seedingArea: number
  seedingAreaUnit: string
  targetDate: string
}

export class WorkOrderIrrigation {
  id: number
  startingDate: string
  endingDate: string
  area: number
  amount: number
  areaUnit: string
  amountUnit: string
  nutrients?: AgrooneNutrient[]

  get startingDateAsDate(): Date {
    return ISO8601ToDate(this.startingDate)
  }

  get endingDateAsDate(): Date {
    return ISO8601ToDate(this.endingDate)
  }

  constructor(irrigation?: Omit<WorkOrderIrrigation, 'startingDateAsDate' | 'endingDateAsDate'>) {
    if (irrigation?.id) {
      this.id = irrigation.id
    }
    this.startingDate = irrigation?.startingDate ?? dateToISO8601(new Date())
    this.endingDate = irrigation?.endingDate ?? dateToISO8601(new Date())
    this.area = irrigation?.area ?? 0
    this.amount = irrigation?.amount ?? 0
    this.areaUnit = irrigation?.areaUnit ?? ''
    this.amountUnit = irrigation?.amountUnit ?? ''
    this.nutrients = irrigation?.nutrients
  }
}

export class WorkOrderIrrigationDAO {
  id: number
  startingDate: string
  endingDate: string
  area: number
  amount: number
  areaUnit: string
  amountUnit: string
}

export class IrrigationNutrientDAO {
  irrigationId: number
  nutrientId: number
}

export class WorkOrderMechanicalWeeding {
  id: number
  area: string
  targetDate: string

  get targetDateAsDate(): Date {
    return ISO8601ToDate(this.targetDate)
  }

  constructor(mechanicalWeeding?: Omit<WorkOrderMechanicalWeeding, 'targetDateAsDate'>) {
    this.area = mechanicalWeeding?.area
    this.targetDate = mechanicalWeeding?.targetDate ?? ''
  }
}

export class WorkOrderOtherOperation {
  id: number
  operationType: string
  area: string
  targetDate: string

  constructor(otherOperation?: Omit<WorkOrderOtherOperation, 'targetDateAsDate'>) {
    if (otherOperation?.id) {
      this.id = otherOperation.id
    }
    this.operationType = otherOperation?.operationType
    this.area = otherOperation?.area
    this.targetDate = otherOperation?.targetDate ?? ''
  }
}

export class WorkOrderFoodSafety {
  id: number
  location?: Location
  bufferZone?: number
  bufferZoneUnit?: string

  constructor(foodSafety?: WorkOrderFoodSafety) {
    if (foodSafety?.id) {
      this.id = foodSafety.id
    }
    if (foodSafety?.location) {
      this.location = foodSafety?.location
    }
    if (foodSafety?.bufferZone) {
      this.bufferZone = foodSafety?.bufferZone
    }
    if (foodSafety?.bufferZoneUnit) {
      this.bufferZoneUnit = foodSafety?.bufferZoneUnit
    }
  }
}

export class WorkOrderFoodSafetyDAO {
  id: number
  locationId?: number
  bufferZone?: number
  bufferZoneUnit?: string
}

export class WorkOrderPesticidesProduct {
  pesticideId: number
  oldPesticideId?: string
  identificationNumber: string
  name: string
  activeIngredientsNames: string[]
  rate: number
  rateUnit: string
  rateAreaUnit: string
  reentries?: Reentry[]
  pesticideType: string
  area: number
  targetDate: string
  alternativeSolution?: string
  note?: string

  constructor(product?: WorkOrderPesticidesProduct) {
    this.pesticideId = product?.pesticideId
    this.identificationNumber = product?.identificationNumber ?? ''
    this.name = product?.name ?? ''
    this.rate = +(product?.rate ?? 0)
    this.rateUnit = product?.rateUnit ?? ''
    this.rateAreaUnit = product?.rateAreaUnit ?? ''
    this.area = product?.area
    this.targetDate = product?.targetDate
    this.alternativeSolution = product?.alternativeSolution
    this.note = product?.note

    if (product.activeIngredientsNames) {
      this.activeIngredientsNames = product.activeIngredientsNames
    }
  }
}

export class WorkOrderPesticidesProductDAO {
  pesticideProductId: number
  rate: number
  rateUnit: string
  rateAreaUnit: string
}

export class WorkOrderPesticide {
  id: number
  waterAmount: number
  waterUnit: string
  waterAreaUnit: string
  products: WorkOrderPesticidesProduct[]
  targetDate: string
  alternativeSolution?: string
  completedDate?: string

  get targetDateAsDate(): Date {
    return ISO8601ToDate(this.targetDate)
  }

  constructor(pesticide?: Omit<WorkOrderPesticide, 'targetDateAsDate'>) {
    if (pesticide?.id) {
      this.id = pesticide.id
    }

    this.targetDate = pesticide?.targetDate ?? ''
    this.waterAmount = +(pesticide?.waterAmount ?? 0)
    this.waterUnit = pesticide?.waterUnit ?? ''
    this.waterAreaUnit = pesticide?.waterAreaUnit ?? ''
    this.products = pesticide?.products.map((p) => new WorkOrderPesticidesProduct(p))
    this.alternativeSolution = pesticide?.alternativeSolution ?? ''
  }
}

export class WorkOrderPesticideDAO {
  id: number
  waterAmount: number
  waterUnit: string
  waterAreaUnit: string
  productIds: number[]
  targetDate: string
  alternativeSolution?: string
  completedDate?: string
}

export class WorkOrderCrop {
  id: number
  name: string
  shortName: string
  location?: Location
  boundary?: FeatureCollection
  intendedArea: number
  areaUnit: string
  fieldId: number
  fieldName: string
  plantedArea: number
  plantedDate: string

  get plantedDateAsDate(): Date {
    return ISO8601ToDate(this.plantedDate)
  }

  constructor(crop?: Omit<WorkOrderCrop, 'plantedDateAsDate'>) {
    this.id = crop.id
    this.name = crop.name
    this.shortName = crop.shortName
    this.boundary = crop?.boundary
    this.location = crop?.location
    this.intendedArea = crop?.intendedArea ?? 0
    this.areaUnit = crop?.areaUnit ?? ''
    this.fieldId = crop?.fieldId
    this.fieldName = crop?.fieldName ?? ''
    this.plantedArea = crop?.plantedArea ?? 0
    this.plantedDate = crop?.plantedDate
  }
}

export class WorkOrderCropDAO {
  cropId: number
  workOrderId: number
}

export class WorkOrderPictureDAO {
  pictureId: number
  workOrderId: number
}

export class WorkOrder {
  id: number
  date: string
  orderedBy: string
  region?: string
  crops: WorkOrderCrop[]
  type: string
  subType: string
  note?: string
  sendSMS?: boolean
  sendEmail?: boolean
  foodSafety?: WorkOrderFoodSafety
  planting?: WorkOrderPlanting
  mechanicalWeeding?: WorkOrderMechanicalWeeding
  otherOperation?: WorkOrderOtherOperation
  pesticide?: WorkOrderPesticide
  pesticides?: WorkOrderPesticide // TODO: remove when Interventions will be done
  irrigation?: WorkOrderIrrigation
  picturesPath?: string[]
  traceabilityId?: number
  disablingDate?: string
  completedDate?: string

  get dateAsDate(): Date {
    return ISO8601ToDate(this.date)
  }

  get disablingDateAsDate(): Date {
    return ISO8601ToDate(this.disablingDate)
  }

  constructor(workOrder?: Omit<WorkOrder, 'dateAsDate' | 'disablingDateAsDate'>) {
    this.id = workOrder?.id
    this.date = workOrder?.date ?? ''
    this.orderedBy = workOrder?.orderedBy ?? ''
    this.region = workOrder?.region ?? ''
    this.crops = workOrder?.crops.map((c) => new WorkOrderCrop(c))
    this.type = workOrder?.type ?? ''
    this.subType = workOrder?.subType ?? ''
    this.note = workOrder?.note ?? ''
    this.sendSMS = workOrder?.sendSMS ?? false
    this.sendEmail = workOrder?.sendEmail ?? false
    this.picturesPath = workOrder?.picturesPath ?? []
    this.traceabilityId = workOrder?.traceabilityId
    this.disablingDate = workOrder?.disablingDate
    this.completedDate = workOrder?.completedDate

    if (workOrder?.foodSafety) {
      this.foodSafety = new WorkOrderFoodSafety(workOrder?.foodSafety)
    }
    if (workOrder?.id) {
      this.id = workOrder?.id
    }
    if (workOrder?.planting) {
      this.planting = new WorkOrderPlanting(workOrder?.planting)
    }
    if (workOrder?.pesticide) {
      this.pesticide = new WorkOrderPesticide(workOrder?.pesticide)
    }
    if (workOrder?.mechanicalWeeding) {
      this.mechanicalWeeding = new WorkOrderMechanicalWeeding(workOrder?.mechanicalWeeding)
    }
    if (workOrder?.otherOperation) {
      this.otherOperation = new WorkOrderOtherOperation(workOrder?.otherOperation)
    }
    if (workOrder?.irrigation) {
      this.irrigation = new WorkOrderIrrigation(workOrder?.irrigation)
    }
  }
}

export class WorkOrderDAO extends ReportingDAO {
  id?: number
  date: string
  orderedBy: string
  type: string
  subType: string
  regionId: number
  note?: string
  sendSMS?: number
  sendEmail?: number
  foodSafetyId?: number
  plantingId?: number
  mechanicalWeedingId?: number
  otherOperationId?: number
  pesticideId?: number
  irrigationId?: number
  traceabilityId?: number
  disablingDate?: string
  completedDate?: string

  constructor(workOrder?: SaveWorkOrder) {
    super(workOrder)
  }
}

export class WorkOrderRequest {
  id?: number
  date: string
  orderedBy: string
  type: string
  subType: string
  note?: string
  sendSMS?: number
  sendEmail?: number
  traceabilityId?: number
  disablingDate?: string
  completedDate?: string
  fsId?: number
  fsBufferZone?: number
  fsBufferZoneUnit?: string
  fsLatitude?: string
  fsLongitude?: string
  fsPrecision?: string
  fsGeohash?: string
  plId?: number
  plVarietyId?: number
  plVarietyName?: string
  plLotNumber?: string
  plSeedingRate?: number
  plSeedingRateUnit?: string
  plSeedingArea?: number
  plSeedingAreaUnit?: string
  plTargetDate?: string
  mwId?: number
  mwArea?: string
  mwTargetDate?: string
  ooId?: number
  ooOperationType?: string
  ooArea?: string
  ooTargetDate?: string
  aopId?: number
  aopWaterAmount?: number
  aopWaterUnit?: string
  aopWaterAreaUnit?: string
  aopTargetDate?: string
  aopAlternative?: string
  aopCompletedDate?: string
  pIds?: string
  pIdentificationNumbers?: string
  pNames?: string
  pPesticideTypes?: string
  pRates?: string
  pRateUnits?: string
  pRateAreaUnits?: string
  pArea?: number
  pTargetDate?: string
  pAlternativeSolution?: string
  pNote?: string
  iId?: number
  iStartingDate?: string
  iEndingDate?: string
  iArea?: number
  iAmount?: number
  iAreaUnit?: string
  iAmountUnit?: string
  nIds?: string
  nKeys?: string
  nTypes?: string
  nValues?: string
  nNutrientIds?: string
  cropIds?: string
  cropNames?: string
  cropShortNames?: string
  cropIntendedAreas?: string
  cropAreaUnits?: string
  cropPlantedAreas?: string
  cropPlantedDates?: string
  cropFieldIds?: string
  cropLatitudes?: string
  cropLongitudes?: string
  cropPrecisions?: string
  cropGeohashs?: string
  cropBoundaries?: string
  fieldNames?: string
  picturePaths?: string
}
