import { CropActivityReport, UserPermissions } from '@agroone/entities'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filterByPermission',
  standalone: true,
})
export class FilterByPermissionPipe implements PipeTransform {
  transform(report: CropActivityReport, permissions: UserPermissions[]): CropActivityReport {
    // Filter scouting if no permission SCOUTINGS_HISTORIC
    if (!permissions.find((permission) => permission === UserPermissions.SCOUTINGS_HISTORIC)) {
      report.scoutings = []
    }
    return report
  }
}
