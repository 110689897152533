import 'reflect-metadata'

import { Type } from 'class-transformer'
import { ArrayNotEmpty, IsArray, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'

import { SaveTranslation } from './translation'

export class SaveCampaign {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  key: string | undefined

  @IsNotEmpty()
  @Type(() => SaveTranslation)
  translations: SaveTranslation[] | undefined

  @ArrayNotEmpty()
  @IsArray()
  @IsNumber({}, { each: true })
  regionIds: number[] | undefined

  constructor(campaign?: SaveCampaign) {
    if (campaign?.id) {
      this.id = campaign.id
    }

    this.key = campaign?.key
    this.translations = campaign?.translations
    this.regionIds = campaign?.regionIds
  }
}
