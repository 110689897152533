import { IsInt, IsNumber, IsString, Min, Validate } from 'class-validator'
import { IsISO8601WithTimezone } from '@agroone/helpers'

export class StockEntryDto {
  @IsNumber()
  @IsInt()
  @Min(0)
  stockId: number

  @Validate(IsISO8601WithTimezone)
  @IsString()
  date: string

  @IsNumber()
  @IsInt()
  @Min(0)
  quantity: number
}
