import { ErrorCodes } from '@agroone/entities'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EMPTY, Observable, timer } from 'rxjs'
import { catchError, retry, switchMap } from 'rxjs/operators'
import { ConnectionError, HttpError } from '../models/errors'
import { AuthService, LoggerService } from '../services'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly maxRetryAttempts: number = 1

  constructor(private logger: LoggerService, private router: Router, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const message: string = error.error?.message ?? error.message
        const rError: HttpError | ConnectionError = navigator.onLine
          ? new HttpError(message)
          : new ConnectionError(message)

        if (rError instanceof HttpError) {
          this.logger.debug('Catched error', request.url, location.origin)

          // Check API URLs
          if (this.isApiUrl(request.url)) {
            // Handle authentication errors and redirections
            if (this.handleAuthErrors(error, request, next)) {
              return EMPTY
            }

            // Retry mechanism for GET requests
            if (request.method === 'GET') {
              this.logger.debug('Retrying GET request')
              return this.retryRequest(request, next)
            }
          }

          rError.status = error.status
        }

        // If no specific handling, throw the error
        throw rError
      })
    )
  }

  // Utility function to check if the URL belongs to the API
  private isApiUrl(url: string): boolean {
    return url.startsWith('http://localhost:3000') || url.startsWith('https://api')
  }

  // Function to handle authentication-related errors
  private handleAuthErrors(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): boolean {
    if (error.error?.message === ErrorCodes.USER_INACTIVE) {
      this.router.navigate(['/access-denied'])
      return true
    }

    if (error.error?.message === ErrorCodes.TOKENS_EXPIRED) {
      this.logger.debug('Refreshing tokens')
      this.authService
        .refreshTokens()
        .pipe(switchMap(() => next.handle(request)))
        .subscribe()
      return true
    }

    if (
      error.error?.message === ErrorCodes.REFRESH_TOKEN_EXPIRED ||
      error.error?.message === ErrorCodes.MISSING_AUTH ||
      error.error?.message === ErrorCodes.INVALID_TOKEN ||
      error.error?.message === ErrorCodes.MISSING_PERMISSION_MODULE_ACCESS
    ) {
      this.authService.redirectToLogin()
      return true
    }

    return false
  }

  private retryRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry({
        count: this.maxRetryAttempts,
        delay: (error: any, retryCount: number) => {
          if (retryCount >= this.maxRetryAttempts) {
            throw error // Stop retrying after maxRetryAttempts
          }
          this.logger.debug(`Retrying... attempt #${retryCount + 1}`)
          return timer((retryCount + 1) * 1000) // Delay increasing with each retry
        },
        resetOnSuccess: true,
      }),
      catchError((retryError: HttpErrorResponse) => {
        this.logger.debug('Final retry failed', retryError)
        throw retryError
      })
    )
  }
}
