import { Injectable } from '@angular/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap } from 'rxjs'
import { of } from 'rxjs'

import { CropService } from '@agroone-app/shared/crop/services/crop.service'
import {
  ActivityHttpParams,
  loadActivities,
  loadActivitiesFailure,
  loadActivitiesSuccess,
} from '../actions/activity.actions'

@Injectable()
export class ActivitiesEffects {
  constructor(private actions$: Actions, private cropService: CropService) {}

  public getActivitiy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActivities),
      mergeMap((params: ActivityHttpParams) =>
        this.cropService.getActivitiesByCrop(params.cropId).pipe(
          map((activities) => loadActivitiesSuccess({ cropId: params.cropId, activities })),
          catchError((e) => of(loadActivitiesFailure({ error: `${e}` })))
        )
      )
    )
  )
}
