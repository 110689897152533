import { GeolocationHttpService } from '@agroone-app/scene/crop-management/geolocation/services/geolocation-http.service'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { TranslateModule } from '@ngx-translate/core'
import { MapDrawComponent } from './components/map-draw.component'
import { MapLocationComponent } from './components/map-location.component'
import { MapViewComponent } from './components/map-view.component'
import { MapComponent } from './components/map.component'

@NgModule({
  imports: [CommonModule, TranslateModule, MatDialogModule, MatButtonModule, MatIconModule],
  declarations: [MapComponent, MapDrawComponent, MapViewComponent, MapLocationComponent],
  exports: [MapComponent, MapDrawComponent, MapViewComponent, MapLocationComponent],
  providers: [GeolocationHttpService],
})
export class MapModule {}
