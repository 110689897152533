import 'reflect-metadata'
import { IsArray, IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'
import { Length } from '../enums/length.enum'
import { UserPermissions } from '../enums/user-permissions.enum'
import { BusinessUnits } from '../enums/business-units.enum'
import { Profil } from '../enums/profils.enum'
import { AgropilotRole } from '../enums/agropilot-role.enum'

export class SaveUserDto {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  email: string | undefined

  @IsNumber()
  languageId: number | undefined

  @IsString()
  @IsNotEmpty()
  defaultYieldUnit: string | undefined

  @IsString()
  @IsNotEmpty()
  defaultDateFormat: string | undefined

  @IsNumber()
  geolocationRadius: number

  @IsString()
  @IsEnum(Length)
  geolocationRadiusUnit: Length

  @IsString()
  regionName: string

  @IsString()
  firstname: string

  @IsString()
  lastname: string

  @IsArray()
  @IsString({ each: true })
  availableRegions: string[]

  @IsArray()
  @IsEnum(UserPermissions, { each: true })
  @IsOptional()
  permissions?: UserPermissions[]

  @IsNumber()
  @IsOptional()
  growerId?: number

  @IsNumber()
  @IsOptional()
  supplierId?: number

  @IsString()
  @IsOptional()
  phoneNumber?: string

  @IsString()
  @IsOptional()
  phytoCertification?: string

  @IsBoolean()
  @IsOptional()
  limitDisplay?: boolean

  @IsBoolean()
  isActive: boolean

  @IsArray()
  @IsEnum(BusinessUnits, { each: true })
  @IsNotEmpty()
  businessUnits: BusinessUnits[] | undefined

  @IsEnum(Profil)
  @IsNotEmpty()
  profil: Profil

  @IsOptional()
  @IsNumber()
  reportToUserId?: number

  @IsEnum(AgropilotRole, { each: true })
  @IsArray()
  @IsOptional()
  agropilotRoles?: AgropilotRole[]

  @IsNumber()
  @IsOptional()
  campaignId?: number

  createdBy: string | undefined
  createdDate: string | undefined
  updatedBy: string | undefined
  updatedDate: string | undefined
}
