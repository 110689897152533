import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core'
import { UserPermissions } from '@agroone/entities'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ConnectionService, SharedPermissionService } from '@agroone-front/shared'

@UntilDestroy()
@Directive({
  selector: '[isGranted]',
  standalone: true,
})
export class IsGrantedDirective {
  private _connectionService = inject(ConnectionService)
  private _permissionService = inject(SharedPermissionService)
  private _templateRef = inject(TemplateRef<HTMLElement>)
  private _viewContainer = inject(ViewContainerRef)

  @Input() set isGranted(permissions: UserPermissions[]) {
    this._connectionService.connection$.pipe(untilDestroyed(this)).subscribe((isConnected) => {
      this._viewContainer.clear()
      if (this._permissionService.isGranted(permissions, isConnected)) {
        this._viewContainer.createEmbeddedView(this._templateRef)
      }
    })
  }
}
