import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  standalone: true,
  imports: [MatIconModule],
})
export class ActionComponent {
  @Input() svgIcon: string

  @Input() size?: number
}
