import {
  clearLoading,
  startLoading,
  startLoadingCrops,
  startLoadingGrowers,
  startLoadingSpecifications,
  startLoadingVarieties,
  stopLoading,
  stopLoadingCrops,
  stopLoadingGrowers,
  stopLoadingSpecifications,
  stopLoadingVarieties,
} from './loading.actions'
import { Action, ActionReducer, createReducer, on } from '@ngrx/store'

export interface LoadingState {
  isLoading: boolean
  isLoadingGrowers?: boolean
  isLoadingCrops?: boolean
  isLoadingVarieties?: boolean
  isLoadingSpecifications?: boolean
}

export const initialLoadingState: LoadingState = {
  isLoading: false,
  isLoadingGrowers: false,
  isLoadingCrops: false,
  isLoadingVarieties: false,
  isLoadingSpecifications: false,
}

export const loadingReducer: ActionReducer<LoadingState, Action> = createReducer(
  initialLoadingState,
  on(startLoading, (state) => ({ ...state, isLoading: true })),
  on(stopLoading, (state) => ({ ...state, isLoading: false })),
  on(startLoadingGrowers, (state) => ({ ...state, isLoadingGrowers: true })),
  on(stopLoadingGrowers, (state) => ({ ...state, isLoadingGrowers: false })),
  on(startLoadingCrops, (state) => ({ ...state, isLoadingCrops: true })),
  on(stopLoadingCrops, (state) => ({ ...state, isLoadingCrops: false })),
  on(startLoadingVarieties, (state) => ({ ...state, isLoadingVarieties: true })),
  on(stopLoadingVarieties, (state) => ({ ...state, isLoadingVarieties: false })),
  on(startLoadingSpecifications, (state) => ({ ...state, isLoadingSpecifications: true })),
  on(stopLoadingSpecifications, (state) => ({ ...state, isLoadingSpecifications: false })),
  on(clearLoading, () => initialLoadingState)
)
