import { environment } from '@front-app-environments/environment'
import { FieldDto } from '@agroone-app/scene/crop-management/field/dto/field.dto'
import { refreshField } from '@agroone-app/scene/crop-management/field/store/actions/field.actions'
import { FieldState } from '@agroone-app/scene/crop-management/field/store/reducers/field.reducer'
import { selectFieldById } from '@agroone-app/scene/crop-management/field/store/selectors/field.selectors'
import { Field, MsSqlPaginatedData, SaveField } from '@agroone/entities'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, debounceTime, firstValueFrom, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs'

export interface FieldHttpParams {
  region?: string
  growerId?: number
  active?: boolean
  noPagination?: boolean
  includeCrops?: boolean
  pageLength?: number
}

@Injectable({
  providedIn: 'root',
})
export class FieldService {
  private fieldUrl = `${environment.apiUrl}${environment.fields}`
  constructor(private http: HttpClient, private fieldStore: Store<FieldState>) {}

  /**
   * Get field by id
   * first from the  field state, else from the remote server
   */
  public get(id: number, includeCrops: boolean = false): Observable<Field> {
    return this.fieldStore.select(selectFieldById(id)).pipe(
      take(1),
      debounceTime(200),
      mergeMap((state) => {
        if (state) {
          return of(state)
        }
        // In case we never had this field in the local state we fetch the specific field
        return this.http.get<Field>(`${this.fieldUrl}/${id}`, { params: { includeCrops: includeCrops } }).pipe(
          tap((field) => {
            this.fieldStore.dispatch(refreshField({ field }))
          })
        )
      })
    )
  }

  /**
   * Return all fields
   *
   * @param fieldParams
   * @returns
   */
  public getAll(fieldParams?: FieldHttpParams): Observable<Field[]> {
    let params = new HttpParams()

    params = params.append('active', fieldParams?.active || true)

    if (fieldParams?.region) {
      params = params.append('region', fieldParams.region)
    }

    if (fieldParams?.growerId) {
      params = params.append('growerId', fieldParams.growerId)
    }

    if (fieldParams?.includeCrops) {
      params = params.append('includeCrops', fieldParams.includeCrops)
    }

    if (fieldParams?.noPagination) {
      params = params.append('noPagination', fieldParams.noPagination)
    }

    if (fieldParams?.pageLength) {
      params = params.append('pageLength', fieldParams.pageLength)
    }

    return this.http.get<MsSqlPaginatedData<Field>>(this.fieldUrl, { params }).pipe(
      switchMap((response: MsSqlPaginatedData<Field>) => {
        if (response.currentPage < response.pageCount) {
          return combineLatest(
            Array.from({ length: response.pageCount - 1 }, (_, i) => {
              params = params.set('page', i + 2)
              return this.http.get<MsSqlPaginatedData<Field>>(this.fieldUrl, { params })
            })
          ).pipe(
            map((v) => {
              v.unshift(response)
              return v
            })
          )
        }
        return of([response])
      }),
      map((v) => v.map((v) => v.data).flat())
    )
  }

  public getAddress(field: Field) {
    if (!field) {
      return
    }

    return `${field.civicNumber || ''} ${field.roadName || ''} ${field.zipCode || ''} ${field.town || ''} ${
      field.country || ''
    }`
      .replace(/null/g, '')
      .replace(/\s+/g, ' ')
  }

  /**
   * Save field
   *
   * @param field
   * @returns
   */
  public save(field: SaveField | Field | FieldDto): Observable<Field> {
    return this.http.post(this.fieldUrl, field).pipe(map((returnedField: Field) => new Field(returnedField)))
  }

  /**
   * Update Field
   *
   * @param field
   * @returns
   */
  public update(field: SaveField | Field | FieldDto): Observable<Field> {
    return this.http.put(this.fieldUrl, field).pipe(map((returnedField: Field) => new Field(returnedField)))
  }

  /**
   * Delete field by id
   *
   * @param id
   * @returns
   */
  public delete(id: number): Observable<Field> {
    return this.http.delete<Field>(`${this.fieldUrl}/${id}`)
  }

  /**
   *
   * @param geohashes
   * @returns
   */
  public async getByGeohash(geohashes: string[]): Promise<Field[]> {
    const result: Field[] = (
      await Promise.all(
        geohashes.map((hash) => {
          const filter = `?active=true&` + `filter=geohash STARTSWITH "${hash}"`
          return firstValueFrom(this.http.get<Field[]>(`${this.fieldUrl}${filter}`))
        })
      )
    ).reduce((array: Field[], cs: Field[]) => {
      array = array.concat(cs)
      return array
    }, [])
    return result
  }

  public getFieldBoundaries(fields: Field[]): Observable<Field[]> {
    if (fields.length) {
      return this.http
        .get<Field[]>(
          `${environment.apiUrl}${environment.geolocation}/field/boundaries?ids=${fields.map((i) => i.id).join(',')}`
        )
        .pipe(
          map((res) => {
            if (res) {
              res = res.filter(Boolean).map((field) => new Field(field as Field))
              return res
            }
          })
        )
    }
    return of([])
  }
}
