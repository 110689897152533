import { acceptFileValidator } from '@agroone-front/shared'
import { GrowerCsrFileDto, GrowerCsrFileType } from '@agroone/entities'
import { dateFormatFileName } from '@agroone/helpers'
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'
import { SelectItem } from 'primeng/api'

@Component({
  selector: 'app-grower-csr-file',
  templateUrl: './grower-csr-file.component.html',
  styleUrls: ['./grower-csr-file.component.sass'],
})
export class GrowerCsrFileComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef
  csrFileFormGroup: FormGroup
  documentTypes: SelectItem[] = [
    { label: this._translate.instant('GROWER_CSR_FILE.TYPE_SOIL_TEST'), value: GrowerCsrFileType.SOIL_TEST },
    { label: this._translate.instant('GROWER_CSR_FILE.TYPE_CERTIFICATION'), value: GrowerCsrFileType.CERTIFICATION },
    { label: this._translate.instant('GROWER_CSR_FILE.TYPE_TRAINING'), value: GrowerCsrFileType.TRAINING },
    { label: this._translate.instant('GROWER_CSR_FILE.TYPE_OTHER'), value: GrowerCsrFileType.OTHER },
  ]
  get csrFilesArray(): FormArray {
    return this.csrFileFormGroup.get('csrFilesArray') as FormArray
  }

  constructor(
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<GrowerCsrFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { growerName: string },
    private _translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.csrFileFormGroup = this._fb.group({
      csrFilesArray: this._fb.array([]),
    })
    this.addCsrFileFormArray()
  }

  public triggerSelectFiles(index: number) {
    const fileInput: HTMLInputElement = document.getElementById(`file-upload-${index}`) as HTMLInputElement
    fileInput.click()
  }

  addCsrFileFormArray(): void {
    const documentGroup: FormGroup = this._fb.group({
      type: [null, Validators.required],
      file: [null, [Validators.required, acceptFileValidator(['pdf'])]],
    })

    this.csrFilesArray?.push(documentGroup)
  }

  removeFilesArray(index: number): void {
    this.csrFilesArray.removeAt(index)
  }

  onFileSelected(event: Event, index: number): void {
    const filesToUpload: FileList = (event?.target as HTMLInputElement)?.files
    if (filesToUpload && filesToUpload.length > 0) {
      for (const fileToUpload of Array.from(filesToUpload)) {
        const file = {
          name: fileToUpload.name,
          content: fileToUpload,
        }
        this.csrFilesArray.at(index).patchValue({ file })
      }
    }
  }

  public save(): void {
    this._dialogRef.close(this._growerCsrFileFormDto())
  }

  public cancel(): void {
    this._dialogRef.close()
  }

  private _growerCsrFileFormDto(): GrowerCsrFileDto[] {
    const date: string = dateFormatFileName()
    return this.csrFilesArray?.value?.map((value) => ({
      type: value?.type,
      name: `${this.data.growerName?.toLowerCase()}_${value?.type}_${date}.pdf`,
      date,
      content: value?.file?.content,
    }))
  }
}
