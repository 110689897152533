import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import {
  ArrayMaxSize,
  ArrayNotEmpty,
  IsArray,
  IsEnum,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Max,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'
import { SaveLocation } from './save-location'
import { IsDecimalNumber, IsISO8601WithTimezone, IsLaterThan, RequireProperty } from '@agroone/helpers'
import { SaveReporting } from './save-reporting'
import { SaveForeignMaterial } from './save-foreign-material'
import { RateUnits } from '../enums/rate-units.enum'

export class SaveInterventionFoodSafety {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: SaveLocation

  @IsNumber()
  @Validate(IsDecimalNumber, [1])
  @Min(0)
  @IsOptional()
  bufferZone?: number

  @IsString()
  @IsIn(['m', 'ft', 'ac', 'ha'])
  @IsOptional()
  bufferZoneUnit?: string

  @Validate(IsISO8601WithTimezone)
  @IsNotEmpty()
  completedDate: string

  @IsNumber()
  @IsOptional()
  locationId?: number
}

export class SaveInterventionNutrients {
  @IsNumber()
  @IsNotEmpty()
  nutrientId: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @Max(100)
  @IsNotEmpty()
  value: number
}

export class SaveInterventionIrrigation {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  completedStartingDate: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  @IsLaterThan('completedStartingDate')
  completedEndingDate: string

  @IsNumber()
  @Min(0)
  @Validate(IsDecimalNumber, [2])
  area: number

  @IsNumber()
  @Min(0)
  @Validate(IsDecimalNumber, [2])
  amount: number

  @IsString()
  @IsNotEmpty()
  areaUnit: string

  @IsString()
  @IsNotEmpty()
  amountUnit: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionNutrients)
  nutrients: SaveInterventionNutrients[]
}

export class SaveInterventionMechanicalWeeding {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  area: string

  @Validate(IsISO8601WithTimezone)
  @IsNotEmpty()
  completedDate: string
}

export class SaveInterventionOtherOperation {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  operationType: string

  @IsString()
  @IsNotEmpty()
  area: string

  @Validate(IsISO8601WithTimezone)
  @IsNotEmpty()
  completedDate: string
}

export class SaveInterventionPesticidesProduct {
  @IsNumber()
  @IsNotEmpty()
  pesticideId: number

  @Validate(IsDecimalNumber, [3])
  @IsNumber()
  @Min(0)
  @IsNotEmpty()
  rate: number

  @IsString()
  @IsEnum(RateUnits)
  @IsNotEmpty()
  rateUnit: string

  @IsString()
  @IsNotEmpty()
  rateAreaUnit: string
}

export class SaveInterventionPesticide {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @IsNotEmpty()
  waterAmount: number

  @IsString()
  @IsIn(['l', 'ml', 'gal'])
  @IsNotEmpty()
  waterUnit: string

  @IsString()
  @IsNotEmpty()
  waterAreaUnit: string

  @IsArray()
  @ValidateNested()
  @Type(() => SaveInterventionPesticidesProduct)
  @ArrayNotEmpty()
  // @ArrayMaxSize(4) AgroOne front can only send 4 products max but other consumers of the API can send more than 4
  products: SaveInterventionPesticidesProduct[]

  @IsString()
  @IsNotEmpty()
  weatherConditions: string

  @Validate(IsISO8601WithTimezone)
  @IsNotEmpty()
  completedDate: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @IsOptional()
  @RequireProperty('windSpeedUnit')
  windSpeed?: number

  @IsString()
  @IsOptional()
  @RequireProperty('windSpeed')
  windSpeedUnit?: string

  @IsString()
  @IsOptional()
  applicationEquipment?: string

  @IsNumber()
  @Min(0)
  @Validate(IsDecimalNumber, [4])
  @IsNotEmpty()
  area: number

  @IsString()
  @IsNotEmpty()
  areaUnit: string

  @IsString()
  @IsOptional()
  windDirection?: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @IsOptional()
  @RequireProperty('temperatureUnit')
  temperature?: number

  @IsString()
  @IsOptional()
  @RequireProperty('temperature')
  temperatureUnit?: string

  @IsString()
  @IsOptional()
  applicatorName?: string

  @IsString()
  @IsOptional()
  targetPest?: string
}

export class SaveInterventionNutrient {
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  targetDate: string

  @IsNumber()
  @Validate(IsDecimalNumber, [4])
  @Min(0)
  @RequireProperty('areaUnit')
  area: number

  @IsString()
  @RequireProperty('area')
  @IsNotEmpty()
  areaUnit: string

  @IsNumber()
  @Validate(IsDecimalNumber, [2])
  @Min(0)
  @RequireProperty('amountUnit')
  amount: number

  @IsString()
  @RequireProperty('amount')
  @IsNotEmpty()
  amountUnit: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionNutrients)
  nutrients: SaveInterventionNutrients[]

  @IsOptional()
  @IsString()
  nameProduct?: string
}

export class SaveIntervention extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveIntervention, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsString()
  @IsNotEmpty()
  completedBy: string

  @IsString()
  @IsNotEmpty()
  type: string

  @IsString()
  @IsNotEmpty()
  subType: string

  @IsArray()
  @ArrayNotEmpty()
  crops: number[]

  @IsString()
  @IsNotEmpty()
  region: string

  @IsNumber()
  @IsNotEmpty()
  regionId: number

  @IsNumber()
  @IsOptional()
  recommendationId?: number

  @IsNumber()
  @IsOptional()
  workOrderId?: number

  @IsString()
  @IsOptional()
  note?: string

  @IsString()
  @IsOptional()
  attachmentPath?: string

  @IsArray()
  @ArrayMaxSize(3)
  @IsOptional()
  picturesPath?: string[]

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionFoodSafety)
  foodSafety?: SaveInterventionFoodSafety

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionMechanicalWeeding)
  mechanicalWeeding?: SaveInterventionMechanicalWeeding

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionOtherOperation)
  otherOperation?: SaveInterventionOtherOperation

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionPesticide)
  pesticides?: SaveInterventionPesticide

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionIrrigation)
  irrigation?: SaveInterventionIrrigation

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveForeignMaterial)
  foreignMaterial?: SaveForeignMaterial

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveInterventionNutrient)
  nutrient?: SaveInterventionNutrient

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  disablingDate?: string

  @IsNumber()
  @IsOptional()
  externalId?: number

  @IsInt()
  @IsPositive()
  @IsOptional()
  externalClientId?: number
}
