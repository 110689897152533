<div *ngIf="time && !isDateRange" class="datetimepicker-container">
  <!-- TIME PICKER -->
  <form [formGroup]="timeForm" class="datetimepicker-container-time">
    <input
      id="hour"
      type="number"
      matInput
      [formControl]="inputHour"
      [matAutocomplete]="autoHour"
      #hourInput
      (input)="onHourChange($event.target.value)"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #autoHour="matAutocomplete"
      (optionSelected)="onHourChange($event.option.value); hourInput.blur()"
      [displayWith]="hourDisplay"
    >
      <mat-option *ngFor="let option of hours | filter : inputHour.value : filterHours : sortHours" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <span class="datetimepicker-container-time-separator">:</span>
    <input
      id="minute"
      type="number"
      matInput
      [formControl]="inputMinute"
      [matAutocomplete]="autoMinute"
      #minuteInput
      (input)="onMinuteChange($event.target.value)"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #autoMinute="matAutocomplete"
      (optionSelected)="onMinuteChange($event.option.value); minuteInput.blur()"
      [displayWith]="hourDisplay"
    >
      <mat-option
        *ngFor="let option of minutes | filter : inputMinute.value : filterMinutes : sortMinutes"
        [value]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <span class="datetimepicker-container-time-units">{{ 'TIME.UNIT' | translate }}</span>
  </form>
</div>
<mat-error *ngIf="displayErrors().length > 0">
  {{ displayErrors() }}
</mat-error>
<mat-error *ngIf="time && timeForm.invalid">
  {{ 'TIME.ERROR' | translate }}
</mat-error>

<div *ngIf="!time" class="datepicker-container disp-f a-i-c">
  <p-calendar
    [showIcon]="true"
    [showWeek]="true"
    [firstDayOfWeek]="1"
    [dateFormat]="dateFormatForCalendar"
    [minDate]="minDateAllowed ?? formElement?.minDateValue"
    [maxDate]="maxDateAllowed ?? formElement?.maxDateValue"
    [ngModel]="control.value"
    (ngModelChange)="onDateChange($event)"
    [disabled]="disabled"
    [placeholder]="!isDateRange ? placeholder : placeholder + ' - ' + placeholder"
    selectionMode="{{ isDateRange ? 'range' : 'single' }}"
    [showTime]="showTime"
    [ngClass]="{
      'required-error-auto': customStyle && !isValid && !disabled,
      'pass-required-auto': customStyle && isValid && !disabled
    }"
    appendTo="body"
  ></p-calendar>
  <div *ngIf="cancel && !disabled" class="disp-f ml-xs">
    <mat-icon svgIcon="delete-cross" (click)="control.setValue(null)"></mat-icon>
  </div>
</div>
