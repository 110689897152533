import {
  IsArray,
  IsDefined,
  IsInt,
  IsNotEmpty,
  IsNotEmptyObject,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'
import { IsISO8601WithTimezone } from '@agroone/helpers'
import { Lot } from './lot'
import { Type } from 'class-transformer'

export class PlanificationDto {
  @IsNumber()
  @IsInt()
  offerId: number

  @IsNumber()
  @IsInt()
  harvestTeamId: number

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  dateBegin: string

  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  dateEnd: string

  @IsOptional()
  @IsNumber()
  @Min(0)
  cadenceTh: number = null

  @IsOptional()
  @IsNumber()
  @Min(0)
  vitesse: number = null

  @IsNumber()
  stockId: number

  @IsNumber()
  initTonnage: number

  @IsOptional()
  @IsString()
  quality: string

  @IsOptional()
  @IsString()
  createdDate: string

  @IsOptional()
  @IsString()
  createdBy: string

  @IsOptional()
  @IsString()
  modifiedBy: string
}

export class SavePlanificationDto {
  @IsDefined()
  @IsNotEmptyObject()
  @IsObject()
  @ValidateNested()
  @Type(() => PlanificationDto)
  planification: PlanificationDto

  @IsOptional()
  lots: Lot[]
}

export class UpdateManyPlanifications {
  @IsArray()
  planifications: PlanificationDto[]
}
