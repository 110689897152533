<div class="signature" [ngClass]="data.style">
  <h2 mat-dialog-title>{{ 'DIALOG.SIGNATURE.TITLE' | translate }}</h2>
  <mat-dialog-content>
    <div class="signature-pad" #container>
      <canvas #signaturePad> </canvas>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button color="accent" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button (click)="clearSignature()">
      {{ 'DIALOG.BUTTON.CLEAR' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close (click)="cancel()">
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
