import { Recommendation } from '@agroone/entities'
import { ActionCreator, on, ReducerTypes } from '@ngrx/store'

import {
  addRecommendation,
  addRecommendationFailure,
  addRecommendations,
  addRecommendationsFailure,
  addRecommendationSuccess,
  deleteRecommendation,
  deleteRecommendationError,
  deleteRecommendationSuccess,
  loadRecommendations,
  loadRecommendationsFailure,
  loadRecommendationsSuccess,
  updateRecommendation,
  updateRecommendationFailure,
  updateRecommendationSuccess,
} from '../actions/recommendation.actions'
import { CropDetailActivities, cropDetailAdapter, CropDetailState } from './crop-detail.reducer'

export const recommendationOns: ReducerTypes<CropDetailState, ActionCreator[]>[] = [
  on(loadRecommendations, (state) => ({
    ...state,
  })),
  on(loadRecommendationsSuccess, (state, { cropId, recommendations }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        recommendations: [...recommendations],
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(loadRecommendationsFailure, (state) => ({
    ...state,
  })),
  on(addRecommendation, (state) => ({
    ...state,
  })),
  on(addRecommendationSuccess, (state, { payload: { recommendation } }) => {
    const updatedCrops: CropDetailActivities[] = []
    for (const crop of recommendation.crops) {
      const cropDetail: CropDetailActivities = state.entities[`${crop.id}`]
      if (cropDetail) {
        updatedCrops.push({
          ...cropDetail,
          recommendations: [...cropDetail.recommendations, recommendation],
        })
      }

      return cropDetailAdapter.updateMany(
        updatedCrops.map((v) => ({ id: v.cropId, changes: v })),
        state
      )
    }

    return state
  }),
  on(addRecommendationFailure, (state) => ({
    ...state,
    error: 'Recommendation has not  been added ...',
  })),
  on(addRecommendations, (state) => ({
    ...state,
  })),
  on(addRecommendationsFailure, (state) => ({
    ...state,
    error: 'Oups, Recommendations has not  been added ...',
  })),
  on(updateRecommendation, (state) => ({
    ...state,
  })),

  on(updateRecommendationSuccess, (state, { payload: { cropId, recommendation } }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]

    if (!cropDetail) {
      return state
    }

    // Update the child entity
    const updatedRecommendations: Recommendation[] = cropDetail.recommendations.map((reco) => {
      if (reco.id === recommendation.id) {
        return recommendation
      }
      return reco
    })

    const updatedParent: CropDetailActivities = {
      ...cropDetail,
      recommendations: updatedRecommendations,
    }

    return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
  }),
  on(updateRecommendationFailure, (state) => ({
    ...state,
    error: 'Oups, Recommendation has not  been updated ...',
  })),
  on(deleteRecommendation, (state) => ({
    ...state,
  })),

  on(deleteRecommendationSuccess, (state, { cropId, recommendationId }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        recommendations: cropDetail.recommendations.filter((reco) => reco.id !== recommendationId),
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(deleteRecommendationError, (state) => ({
    ...state,
    error: 'Oups, Recommendation has not  been deleted ...',
  })),
]
