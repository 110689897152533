import { Offer } from '@agroone/entities'
import { Component, Inject } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { environment } from '@front-app-environments/environment'
import { SharedConstantService, SharedUserService } from '@agroone-front/shared'

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.sass'],
})
export class OfferComponent {
  constructor(
    public dialogRef: MatDialogRef<OfferComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cropId: number
      offers: Offer[]
    },
    public constantService: SharedConstantService,
    public userService: SharedUserService
  ) {}

  public add() {
    const url: string = `${environment.agropilotUrl}crop/${this.data.cropId}/offer/`
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(url, '_blank')
  }

  public edit(offerId: number) {
    const url: string = `${environment.agropilotUrl}crop/${this.data.cropId}/offer/${offerId}`
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(url, '_blank')
  }
}
