import {
  IsArray,
  IsIn,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'
import { IsDecimalNumber, RequireProperty } from '@agroone/helpers'
import { Type } from 'class-transformer'

export class SaveGesagIntervention {
  @IsNumber()
  @IsNotEmpty()
  public id: number // Code Intervention Producteur

  @IsString()
  @IsNotEmpty()
  public start: string // Date début intervention

  @IsString()
  @IsOptional()
  public end: string // Date fin intervention

  @IsString()
  @IsNotEmpty()
  public type: string // Type intervention

  @IsString()
  @IsNotEmpty()
  public crop: string // Crop is externalId

  @IsNumber()
  @Min(0)
  @Validate(IsDecimalNumber, [4])
  @IsOptional()
  @RequireProperty('areaUnit')
  public area: number // Surface intervention

  @IsString()
  @IsOptional()
  public areaUnit: string // Unité de la surface d'intervention

  @IsNumber()
  @IsOptional()
  @RequireProperty('wettingUnit')
  @RequireProperty('wettingAreaUnit')
  public wettingValue: number // Mouillage

  @IsString()
  @IsOptional()
  @RequireProperty('wettingValue')
  public wettingUnit: string // Unité mouillage

  @IsString()
  @IsOptional()
  @RequireProperty('areaUnit')
  public wettingAreaUnit: string // Unité de la surface mouillage

  @IsString()
  @IsOptional()
  public weatherConditions: string // Condition météo

  @IsString()
  @IsNotEmpty()
  public completedDate: string // Date d'achèvement

  @IsString()
  @IsNotEmpty()
  public applicator: string // Completé par

  @IsString()
  @IsOptional()
  public nameProduct: string

  @IsString()
  @IsOptional()
  public codePhyto: string // N° Phyto

  @IsNumber()
  @IsOptional()
  @Validate(IsDecimalNumber, [3])
  @RequireProperty('dosageUnit')
  @RequireProperty('dosageAreaUnit')
  public dosageValue: number // Dosage Phyto

  @IsString()
  @IsOptional()
  @RequireProperty('dosageValue')
  public dosageUnit: string // Dosage Phyto unit

  @IsString()
  @IsOptional()
  @RequireProperty('dosageValue')
  public dosageAreaUnit: string // Dosage Phyto area unit

  @IsNumber()
  @IsOptional()
  @Validate(IsDecimalNumber, [4])
  @RequireProperty('areaUnitNutrient')
  public areaNutrient: number

  @IsString()
  @IsOptional()
  @RequireProperty('areaNutrient')
  public areaUnitNutrient: string

  @IsNumber()
  @IsOptional()
  @RequireProperty('amountUnitNutrient')
  public amountNutrient: number

  @IsString()
  @IsOptional()
  @RequireProperty('amountNutrient')
  @IsIn(['g', 'gal', 'kg', 'l', 'lb', 'ml', 'mt', 'oz', 't'])
  public amountUnitNutrient: string

  @IsArray()
  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => Ferti)
  fertis: Ferti[]
}

export class Ferti {
  @IsString()
  @IsNotEmpty()
  public codeFerti: string // N°Ferti

  @IsNumber()
  @IsNotEmpty()
  public dosageFerti: number // Dosage Ferti
}
