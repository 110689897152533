import { dateToISO8601, ISO8601ToDate } from '@agroone/helpers'

import { SupplierType } from '../enums/supplier-type.enum'
import { AgrooneNutrient } from './agroone-nutrient'
import { Crop } from './crop'
import { ForeignMaterial } from './foreign-material'
import { Location } from './location'
import { MechanicalWeeding } from './mechanical-weeding'
import { Notification } from './notification'
import { PesticidesProductDAO, Reentry } from './pesticide'
import { Product } from './product'
import { ReportingDAO } from './reporting'
import { SaveRecommendation } from './save-recommendation'
import { ScoutingDisease, ScoutingForeignMaterial, ScoutingInsect, ScoutingWeed } from './scouting'

export class RecommendationPlanting {
  varietyId: number
  varietyName?: string
  lotNumber?: string
  seedingRate: number
  seedingRateUnit: string
  seedingArea: number
  seedingAreaUnit: string
  targetDate: string
  depthOfPlanting?: number
  depthOfPlantingUnits?: string
  fieldBuffer?: string
  rolledAfterSeed?: boolean
  createdDate?: string
  updatedDate?: string
  createdBy?: string
  updatedBy?: string

  get targetDateAsDate(): Date {
    return ISO8601ToDate(this.targetDate)
  }

  constructor(recommendationPlanting?: Omit<RecommendationPlanting, 'targetDateAsDate'>) {
    this.lotNumber = recommendationPlanting?.lotNumber ?? ''
    this.targetDate = recommendationPlanting?.targetDate ?? ''
    this.seedingRate = recommendationPlanting?.seedingRate
    this.seedingRateUnit = recommendationPlanting?.seedingRateUnit
    this.seedingArea = recommendationPlanting?.seedingArea
    this.seedingAreaUnit = recommendationPlanting?.seedingAreaUnit
    this.targetDate = recommendationPlanting?.targetDate ?? ''
    this.depthOfPlanting = recommendationPlanting?.depthOfPlanting
    this.depthOfPlantingUnits = recommendationPlanting?.depthOfPlantingUnits
    this.fieldBuffer = recommendationPlanting?.fieldBuffer
    this.rolledAfterSeed = recommendationPlanting?.rolledAfterSeed
    this.varietyName = recommendationPlanting?.varietyName
    this.varietyId = recommendationPlanting?.varietyId
  }
}

export class RecommendationIrrigation {
  id?: number
  startingDate: string
  endingDate: string
  area: number
  amount: number
  areaUnit: string
  amountUnit: string
  nutrients?: AgrooneNutrient[]

  get startingDateAsDate(): Date {
    return ISO8601ToDate(this.startingDate)
  }

  get endingDateAsDate(): Date {
    return ISO8601ToDate(this.endingDate)
  }

  constructor(recommendationIrrigation?: Omit<RecommendationIrrigation, 'startingDateAsDate' | 'endingDateAsDate'>) {
    this.startingDate = recommendationIrrigation?.startingDate ?? dateToISO8601(new Date())
    this.endingDate = recommendationIrrigation?.endingDate ?? dateToISO8601(new Date())
    this.area = recommendationIrrigation?.area ?? 0
    this.amount = recommendationIrrigation?.amount ?? 0
    this.areaUnit = recommendationIrrigation?.areaUnit ?? ''
    this.amountUnit = recommendationIrrigation?.amountUnit ?? ''
    this.nutrients = recommendationIrrigation?.nutrients
  }
}

export class RecommendationOtherOperation {
  id?: number
  operationType: string
  area: string
  targetDate: string
  supplierId?: number

  get targetDateAsDate(): Date {
    return ISO8601ToDate(this.targetDate)
  }

  constructor(recommendationOtherOperation?: Omit<RecommendationOtherOperation, 'targetDateAsDate'>) {
    this.area = recommendationOtherOperation?.area
    this.targetDate = recommendationOtherOperation?.targetDate ?? ''
    this.operationType = recommendationOtherOperation?.operationType ?? ''
    this.supplierId = recommendationOtherOperation?.supplierId
  }
}

export class RecommendationFoodSafety {
  id?: number
  location?: Location
  bufferZone?: number
  bufferZoneUnit?: string
  completedDate?: string

  constructor(recommendationFoodSafety?: RecommendationFoodSafety) {
    if (recommendationFoodSafety?.location) {
      this.location = recommendationFoodSafety?.location
    }
    if (recommendationFoodSafety?.bufferZone) {
      this.bufferZone = recommendationFoodSafety?.bufferZone
    }
    if (recommendationFoodSafety?.bufferZoneUnit) {
      this.bufferZoneUnit = recommendationFoodSafety?.bufferZoneUnit
    }
  }
}

export class RecommendationFoodSafetyDAO {
  id: number
  locationId?: number
  bufferZone?: number
  bufferZoneUnit?: string
}

export class RecommendationPesticidesProduct {
  pesticideId: number
  oldPesticideId?: string
  identificationNumber: string
  name: string
  activeIngredientsNames: string[]
  rate: number
  rateUnit: string
  rateAreaUnit: string
  reentries?: Reentry[]
  pesticideType: string
  area: number
  targetDate: string
  alternativeSolution?: string
  note?: string
}

export class RecommendationPesticidesProductDAO extends PesticidesProductDAO {}

export class RecommendationScouting {
  id: number
  foreignMaterial?: ScoutingForeignMaterial
  weed?: ScoutingWeed
  insect?: ScoutingInsect
  disease?: ScoutingDisease
  note?: string
  cropId: number
  region?: string
}

export class RecommendationCrop {
  id: number
  name: string
  shortName: string
  lastGrowthStage?: string
  region?: string
}

export class Recommender {
  language: string
  defaultDateFormat: string
  regionName?: string
  firstname?: string
  lastname?: string
  phoneNumber?: string
  phytoCertification?: string
}

export class Recommendation {
  static readonly className: string = 'Recommendation'

  id?: number
  date: string
  recommendedBy: string
  region: string
  type: string
  subType: string
  note?: string
  signature?: string
  sendSMS?: boolean
  sendEmail?: boolean
  sender?: string
  crops: RecommendationCrop[]
  scoutings?: number[] | RecommendationScouting[]
  notifications?: Notification[]
  foodSafety?: RecommendationFoodSafety
  planting?: RecommendationPlanting
  mechanicalWeeding?: MechanicalWeeding
  otherOperation?: RecommendationOtherOperation
  pesticides?: RecommendationPesticides
  irrigation?: RecommendationIrrigation
  foreignMaterial?: ForeignMaterial
  picturesPath?: string[]
  traceabilityId?: number
  growerId?: number
  createdDate?: string
  updatedDate?: string
  createdBy?: string
  updatedBy?: string
  recommender?: Recommender

  constructor(recommendation?: Recommendation) {
    this.id = recommendation?.id
    this.date = recommendation?.date
    this.recommendedBy = recommendation?.recommendedBy
    this.region = recommendation?.region
    this.type = recommendation?.type
    this.subType = recommendation?.subType
    this.note = recommendation?.note
    this.signature = recommendation?.signature
    this.sendSMS = recommendation?.sendSMS
    this.sendEmail = recommendation?.sendEmail
    this.sender = recommendation?.sender
    this.crops = recommendation?.crops
    this.scoutings = recommendation?.scoutings
    this.notifications = recommendation?.notifications
    this.foodSafety = recommendation?.foodSafety
    this.planting = recommendation?.planting
    this.mechanicalWeeding = recommendation?.mechanicalWeeding
    this.otherOperation = recommendation?.otherOperation
    this.pesticides = recommendation?.pesticides
    this.irrigation = recommendation?.irrigation
    this.foreignMaterial = recommendation?.foreignMaterial
    this.picturesPath = recommendation?.picturesPath
    this.traceabilityId = recommendation?.traceabilityId
    this.growerId = recommendation?.growerId
  }
}

export class RecommendationDAO extends ReportingDAO {
  id?: number
  date: string
  recommendedBy: string
  crops?: RecommendationCrop[]
  region?: string
  type: string
  subType: string
  note?: string
  signature?: string
  sendSMS?: number
  sendEmail?: number
  traceabilityId?: number
  sender?: string
  foodSafetyId?: number
  otherOperationId?: number
  irrigationId?: number
  mechanicalWeedingId?: number
  pesticideId?: number
  plantingId?: number
  growerId?: number
  regionId: number
  scoutingId?: number
  foreignMaterialId?: number

  constructor(recommendation?: SaveRecommendation) {
    super(recommendation)
  }
}

export class RecommendationRequest {
  id?: number
  date: string
  recommendedBy: string
  crops?: RecommendationCrop[]
  region?: string
  scoutings?: RecommendationScouting[]
  type: string
  subType: string
  note?: string
  signature?: string
  sendSMS?: number
  sendEmail?: number
  traceabilityId?: number
  sender?: string
  growerId?: number
  regionId: number
  mechanicalWeedingId?: number
  aopId?: number
  aopWaterAmount?: number
  aopWaterUnit?: string
  aopWaterAreaUnit: string
  aopTargetDate: string
  aopCompletedDate?: string
  aopAlternative?: string
  aopSupplierId?: number
  aopSupplierName?: string
  aopSupplierType?: string
  aopSupplierContactIds?: string
  aopSupplierContactLastNames?: string
  aopSupplierContactFirstNames?: string
  aopSupplierContactPriorities?: string
  aopSupplierContactActives?: string
  aopSupplierContactEmailAddresses?: string
  aopSupplierContactPhoneNumbers?: string
  pIds?: string
  pRates?: string
  pRateUnits?: string
  pRateAreaUnits?: string
  pIdentificationNumbers?: string
  pNames?: string
  pPesticideTypes?: string
  pArea?: string
  pTargetDate?: string
  pAlternativeSolution: string
  pNote?: string
  cropIds?: string
  cropNames?: string
  cropShortNames?: string
  cropGrowthStages?: string
  cropIntendedAreas?: string
  cropAreaUnits?: string
  cropPlantedAreas?: string
  cropPlantedDates?: string
  cropFieldIds?: string
  cropLatitudes?: string
  cropLongitudes?: string
  cropPrecisions?: string
  cropGeohashs?: string
  cropBoundaries?: string
  notifIds?: string
  notifContactType?: string
  notifSendSms?: string
  notifSendEmail?: string
  scoutingIds?: string
  scoutingNotes?: string
  cropScoutingIds?: string
  scoutingWeedItem?: string
  scoutingWeedArea?: string
  scoutingWeedPressure?: string
  scoutingWeedStage?: string
  scoutingDiseaseItem?: string
  scoutingDiseaseArea?: string
  scoutingDiseasePressure?: string
  scoutingFMaterialType?: string
  scoutingFMaterialSubType?: string
  scoutingFMaterialArea?: string
  scoutingFMaterialLevel?: string
  scoutingInsectItem?: string
  scoutingInsectArea?: string
  scoutingInsectPressure?: string
  scoutingInsectStage?: string
  activeIngredientsNames?: string
  picturePaths?: string
  fsId?: number
  fsBufferZone?: number
  fsBufferZoneUnit?: string
  fsCompletedDate?: string
  fsLatitude?: string
  fsLongitude?: string
  fsPrecision?: string
  fsGeohash?: string
  iId?: number
  iStartingDate?: string
  iEndingDate?: string
  iArea?: number
  iAmount?: number
  iAreaUnit?: string
  iAmountUnit?: string
  nIds?: string
  nKeys?: string
  nTypes?: string
  nValues?: string
  nNutrientIds?: string
  mwId?: number
  mwArea?: string
  mwTargetDate?: string
  ooId?: number
  ooOperationType?: string
  ooArea?: string
  ooTargetDate?: string
  fmId?: number
  fmType?: string
  fmSubType?: string
  fmArea?: string
  fmCompletedDate: string
  fmLevel: string
  vaVarietyName?: string
  plVarietyId?: number
  plSeedingRate?: number
  plSeedingRateUnit?: string
  plSeedingArea?: number
  plSeedingAreaUnit?: string
  plTargetDate?: string
  plDepthOfPlanting?: number
  plDepthOfPlantingUnits?: string
}

export class RecommendationPesticides {
  id?: number
  products: Product[]
  waterAmount: number
  waterUnit: string
  waterAreaUnit: string
  completedDate?: string
  supplierId?: number
  supplier?: RecommendationPesticidesSupplier

  constructor(recommendationPesticides?: Omit<RecommendationPesticides, 'targetDateAsDate'>) {
    this.waterAmount = +(recommendationPesticides?.waterAmount ?? 0)
    this.waterUnit = recommendationPesticides?.waterUnit ?? ''
    this.waterAreaUnit = recommendationPesticides?.waterAreaUnit ?? ''
    const products: Product[] = recommendationPesticides?.products.map((p) => new Product(p))

    if (products) {
      this.products = products
    }
    if (recommendationPesticides?.completedDate) {
      this.completedDate = recommendationPesticides?.completedDate
    }

    if (recommendationPesticides?.supplier) {
      this.supplier = new RecommendationPesticidesSupplier(recommendationPesticides?.supplier)
      this.supplierId = recommendationPesticides?.supplier.id
    }
  }

  static getEarliestTargetDate(products: Product[]): Date | null {
    if (!products || products.length === 0) {
      return null
    }

    return products.reduce((earliest, product) => {
      const productDate: Date = new Date(product.targetDate)
      return productDate < earliest ? productDate : earliest
    }, new Date(products[0].targetDate))
  }
}

export class RecommendationPesticidesSupplier {
  id: number
  name: string
  type: SupplierType
  contacts?: SupplierContact[]

  constructor(recommendationPesticidesSupplier?: RecommendationPesticidesSupplier) {
    this.id = recommendationPesticidesSupplier?.id
    this.name = recommendationPesticidesSupplier?.name
    this.type = recommendationPesticidesSupplier?.type
    if (recommendationPesticidesSupplier?.contacts && recommendationPesticidesSupplier?.contacts.length) {
      this.contacts = recommendationPesticidesSupplier?.contacts
    }
  }
}

export class SupplierContact {
  id: number
  name: string
  priority: number
  active: boolean
  emailAddress: string
  phoneNumbers: string[]

  constructor(supplierContact?: SupplierContact) {
    this.id = supplierContact?.id
    this.name = supplierContact?.name
    this.priority = supplierContact?.priority
    this.active = supplierContact?.active
    this.emailAddress = supplierContact?.emailAddress
    this.phoneNumbers = supplierContact?.phoneNumbers
  }
}

export class RecommendationContact {
  contactType: string
  email: string
  language: string
  crops: Crop[];
  [key: string]: any
}

export enum Sender {
  'FRANCE SOUTH WEST' = 'SOLEAL',
}
