import { Component, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.sass'],
})
export class CsvUploadComponent {
  public showError: boolean
  public file: File

  constructor(
    public dialogRef: MatDialogRef<CsvUploadComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { style: string }
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.close()
    })
  }

  public save(): void {
    this.dialogRef.close(this.file)
  }

  public async import(event: Event) {
    const inputElement: HTMLInputElement = event.target as HTMLInputElement
    const fileList: FileList = inputElement.files

    if (fileList) {
      const file: File = fileList[0]
      // Check if file has a valid extension
      if (file?.name.toLowerCase().endsWith('.csv')) {
        this.file = file
        this.showError = false
      } else {
        this.file = null
        this.showError = true
        inputElement.value = null // Clears the input
      }
    }
  }

  public close(): void {
    this.dialogRef.close()
  }
}
