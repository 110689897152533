import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core'
import { FormControl } from '@angular/forms'
import { filter, Subject } from 'rxjs'
import intlTelInput from 'intl-tel-input'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Directive({
  selector: '[aoPhoneNumber]',
  standalone: true,
})
export class PhoneNumberDirective implements OnChanges, AfterViewInit, OnDestroy {
  @Input() aoPhoneNumber: FormControl
  @Input() isRequired?: boolean
  @Input() appendTo?: string = undefined

  private iti: intlTelInput.Iti
  private destroyed: Subject<void> = new Subject()
  private value: string

  constructor(private input: ElementRef) {}

  ngAfterViewInit() {
    if (this.input?.nativeElement) {
      this.iti = intlTelInput(this.input.nativeElement, {
        dropdownContainer: this.appendTo ? document.querySelector(this.appendTo) : document.body,
        initialCountry: 'auto',
        natianalMode: true,
        preferredCountries: ['us', 'fr', 'hu', 'ca', 'de', 'es', 'it', 'pt', 'ru'],
        utilsScript: 'scripts/utils.js',
      })
      this.input.nativeElement.addEventListener('change', this.handleChange.bind(this))
      this.input.nativeElement.addEventListener('keyup', this.handleChange.bind(this))
      this.input.nativeElement.addEventListener('countrychange', this.handleChange.bind(this))
      this.input.nativeElement.addEventListener('blur', () => {
        this.aoPhoneNumber.markAsTouched()
      })
    }
  }

  ngOnChanges() {
    if (this.aoPhoneNumber) {
      // Update the input value with formControl value if a new formControl is passed
      this.input.nativeElement.value = this.aoPhoneNumber.value

      this.aoPhoneNumber.valueChanges
        .pipe(
          untilDestroyed(this),
          filter((value) => value !== this.value)
        )
        .subscribe(() => {
          this.input.nativeElement.value = this.aoPhoneNumber.value
        })
    }
  }

  ngOnDestroy() {
    if (this.iti) {
      this.iti.destroy()
    }
    this.destroyed.next()
    this.destroyed.complete()
  }

  private handleChange() {
    // Retrieve the formatted value
    this.value = this.iti.getNumber()

    // Update the formControl
    this.aoPhoneNumber.setValue(this.value)
    if (!this.iti.isValidNumber() && (this.value?.length > 0 || this.isRequired)) {
      this.aoPhoneNumber.setErrors({
        phone: true,
      })
    }
  }
}
