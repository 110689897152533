import { ActionCreator, createAction, props } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'

import { OfflineAction } from '../reducers'

export enum OfflineActionTypes {
  ADD_ACTIONS = '[Offline] Add Offline Actions',
  ADD_ACTIONS_SUCCESS = '[Offline] Add Offline Actions Success',
  ADD_ACTIONS_FAILURE = '[Offline] Add Offline Actions Failure',
  SYNC_OFFLINE = '[Offline] Sync Offline Actions',
  SYNC_OFFLINE_SUCCESS = '[Offline] Sync Offline Actions Success',
  SYNC_OFFLINE_FAILURE = '[Offline] Sync Offline Actions Failure',
  CLEAR_ACTIONS = '[Offline] Clear Offline Actions',
}

export const addOfflineActions: ActionCreator<
  OfflineActionTypes.ADD_ACTIONS,
  (props: { action: OfflineAction; actionId?: number; actionVerb?: string }) => {
    action: OfflineAction
    actionId?: number
    actionVerb?: string
  } & TypedAction<OfflineActionTypes.ADD_ACTIONS>
> = createAction(
  OfflineActionTypes.ADD_ACTIONS,
  props<{ action: OfflineAction; actionId?: number; actionVerb?: string }>()
)
export const addOfflineActionsSuccess: ActionCreator<
  OfflineActionTypes.SYNC_OFFLINE_SUCCESS,
  (props: {
    payload: {
      action: OfflineAction
      message?: string
    }
  }) => {
    payload: {
      action: OfflineAction
      message?: string
    }
  } & TypedAction<OfflineActionTypes.SYNC_OFFLINE_SUCCESS>
> = createAction(
  OfflineActionTypes.SYNC_OFFLINE_SUCCESS,
  props<{ payload: { action: OfflineAction; message?: string } }>()
)

export const addOfflineActionsFailure: ActionCreator<
  OfflineActionTypes.SYNC_OFFLINE_FAILURE,
  (props: { error: any; message?: string }) => {
    error: any
    message?: string
  } & TypedAction<OfflineActionTypes.SYNC_OFFLINE_FAILURE>
> = createAction(OfflineActionTypes.SYNC_OFFLINE_FAILURE, props<{ error: any; message?: string }>())

export const syncOfflineActions: ActionCreator<
  OfflineActionTypes.SYNC_OFFLINE,
  (props: { actions?: OfflineAction[] }) => {
    actions?: OfflineAction[]
  } & TypedAction<OfflineActionTypes.SYNC_OFFLINE>
> = createAction(OfflineActionTypes.SYNC_OFFLINE, props<{ actions?: OfflineAction[] }>())
export const syncOfflineActionsSuccess: ActionCreator<
  OfflineActionTypes.SYNC_OFFLINE_SUCCESS,
  () => TypedAction<OfflineActionTypes.SYNC_OFFLINE_SUCCESS>
> = createAction(OfflineActionTypes.SYNC_OFFLINE_SUCCESS)

export const syncOfflineActionsFailure: ActionCreator<
  OfflineActionTypes.SYNC_OFFLINE_FAILURE,
  (props: { error: any; message?: string }) => {
    error: any
    message?: string
  } & TypedAction<OfflineActionTypes.SYNC_OFFLINE_FAILURE>
> = createAction(OfflineActionTypes.SYNC_OFFLINE_FAILURE, props<{ error: any; message?: string }>())

export const clearOfflineActions: ActionCreator<
  OfflineActionTypes.CLEAR_ACTIONS,
  () => TypedAction<OfflineActionTypes.CLEAR_ACTIONS>
> = createAction(OfflineActionTypes.CLEAR_ACTIONS)
