<div class="field-filters">
  <mat-dialog-content class="field-filters-content">
    <form [formGroup]="form">
      <!-- FieldName -->
      <ng-container *ngIf="!data?.filters?.fieldName?.disabled">
        <mat-label>{{ 'FIELD.FILTERS.FIELD_NAME' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="fieldNames"
          [formCtrl]="fieldName"
          [displayFn]="display"
          [filterFn]="filter"
          optional="true"
        >
        </app-autocomplete-select>
      </ng-container>

      <!-- town -->
      <ng-container *ngIf="!data?.filters?.town?.disabled">
        <mat-label>{{ 'FIELD.FILTERS.TOWN' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="towns"
          [formCtrl]="town"
          [displayFn]="display"
          [filterFn]="filter"
          optional="true"
        >
        </app-autocomplete-select>
      </ng-container>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="field-filters-actions">
    <button color="accent" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
