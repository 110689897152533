import { Specification } from '@agroone/entities'
import { ActionCreator, createAction, props } from '@ngrx/store'
import { TypedAction } from '@ngrx/store/src/models'

export enum SpecificationActionTypes {
  LOAD_SPECIFICATIONS = '[Specification] Load Specifications',
  LOAD_SPECIFICATIONS_SUCCESS = '[Specification] Load Specifications Success',
  LOAD_SPECIFICATIONS_FAILURE = '[Specification] Load Specifications Failure',
  CLEAR_SPECIFICATIONS = '[Specification] Clear Specifications',
}

export const loadSpecifications: ActionCreator<
  SpecificationActionTypes.LOAD_SPECIFICATIONS,
  () => TypedAction<SpecificationActionTypes.LOAD_SPECIFICATIONS>
> = createAction(SpecificationActionTypes.LOAD_SPECIFICATIONS)

export const loadSpecificationsSuccess: ActionCreator<
  SpecificationActionTypes.LOAD_SPECIFICATIONS_SUCCESS,
  (props: { specifications: Specification[] }) => {
    specifications: Specification[]
  } & TypedAction<SpecificationActionTypes.LOAD_SPECIFICATIONS_SUCCESS>
> = createAction(SpecificationActionTypes.LOAD_SPECIFICATIONS_SUCCESS, props<{ specifications: Specification[] }>())

export const loadSpecificationsFailure: ActionCreator<
  SpecificationActionTypes.LOAD_SPECIFICATIONS_FAILURE,
  (props: { error: any }) => {
    error: any
  } & TypedAction<SpecificationActionTypes.LOAD_SPECIFICATIONS_FAILURE>
> = createAction(SpecificationActionTypes.LOAD_SPECIFICATIONS_FAILURE, props<{ error: any }>())

export const clearSpecifications: ActionCreator<
  SpecificationActionTypes.CLEAR_SPECIFICATIONS,
  () => TypedAction<SpecificationActionTypes.CLEAR_SPECIFICATIONS>
> = createAction(SpecificationActionTypes.CLEAR_SPECIFICATIONS)
