import 'reflect-metadata'

import { IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { plainToInstance, Type } from 'class-transformer'
import { SaveTranslation } from './translation'

export class SaveLaboratoryTypes {
  @IsNumber()
  @IsOptional()
  id: number | undefined

  @IsString()
  @IsNotEmpty()
  key: string | undefined

  @IsOptional()
  @Type(() => SaveTranslation)
  @ValidateNested()
  translations: SaveTranslation[] | undefined
}
