import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import {
  ArrayMaxSize,
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  Max,
  Min,
  Validate,
  ValidateNested,
} from 'class-validator'
import { IsISO8601WithTimezone, RequireProperty } from '@agroone/helpers'
import { SaveReporting } from './save-reporting'
import { SaveLocation } from './save-location'
import { SaveBoundary } from './save-boundary'

// CONDITIONS
export class SaveScoutingEnvironment {
  @IsNotEmpty()
  @IsString()
  item: string

  @IsString()
  @IsNotEmpty()
  cropVigor: string

  @IsBoolean()
  @IsNotEmpty()
  cropConsistent: boolean
}

export class SaveScoutingWeatherDamage {
  @IsString()
  @IsNotEmpty()
  item: string

  @IsString()
  @IsOptional()
  area: string

  @IsString()
  @IsNotEmpty()
  severity: string
}

export class SaveScoutingFlowering {
  @IsNotEmpty()
  @Validate(IsISO8601WithTimezone)
  date: string

  @IsInt()
  @Min(0)
  @Max(100)
  percentage: number
}

// MATURITY
export class SaveScoutingGrowthStage {
  @IsString()
  @IsNotEmpty()
  item: string
}

export class SaveScoutingYieldStandCount {
  @IsNumber()
  @IsOptional()
  id?: number

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  headCount: number

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  standCount: number

  @IsNotEmpty()
  @IsNumber()
  @Min(0)
  @RequireProperty('weightUnit')
  weight: number

  @IsNotEmpty()
  @IsString()
  @RequireProperty('weight')
  weightUnit: string
}

export class SaveScoutingYield {
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  forecastHarvestDate?: string

  @IsOptional()
  @IsNumber()
  @Min(0.01)
  value?: number

  @IsOptional()
  @IsString()
  @IsIn(['kg/ha', 'kg/m2', 'lb/ac', 'mt/ac', 'mt/ha', 't/ac', 't/ha'])
  unit?: string

  @IsOptional()
  @IsInt()
  @Min(1)
  @Max(8)
  week?: number

  @IsOptional()
  @IsInt()
  @Min(0)
  leafCount?: number

  @IsOptional()
  @IsArray()
  @ValidateNested()
  @Type(() => SaveScoutingYieldStandCount)
  standCounts?: SaveScoutingYieldStandCount[]
}

export class SaveScoutingQualityDefect {
  @IsString()
  @IsNotEmpty()
  item: string

  @IsString()
  @IsOptional()
  area: string

  @IsNotEmpty()
  @IsInt()
  @Min(0)
  @Max(100)
  fieldPart: number
}

// OBSERVATION
export class SaveScoutingForeignMaterial {
  @IsString()
  @IsNotEmpty()
  type: string

  @IsString()
  @IsNotEmpty()
  subType: string

  @IsString()
  @IsOptional()
  area: string

  @IsString()
  @IsNotEmpty()
  level: string
}

export class SaveScoutingWeed {
  @IsString()
  @IsNotEmpty()
  item: string

  @IsString()
  @IsOptional()
  area: string

  @IsString()
  @IsNotEmpty()
  pressure: string

  @IsString()
  @IsNotEmpty()
  stage: string
}

export class SaveScoutingInsect {
  @IsString()
  @IsNotEmpty()
  item: string

  @IsString()
  @IsOptional()
  area: string

  @IsString()
  @IsNotEmpty()
  pressure: string

  @IsString()
  @IsNotEmpty()
  stage: string
}

export class SaveScoutingDisease {
  @IsString()
  @IsNotEmpty()
  item: string

  @IsString()
  @IsOptional()
  area: string

  @IsString()
  @IsNotEmpty()
  pressure: string
}

export class SaveScouting extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveScouting, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @Validate(IsISO8601WithTimezone)
  @IsNotEmpty()
  date: string

  @IsString()
  @IsNotEmpty()
  scoutedBy: string

  @IsString()
  @IsNotEmpty()
  type: string

  @IsString()
  @IsNotEmpty()
  subType: string

  @ArrayNotEmpty()
  @IsNumber({}, { each: true })
  crops: number[]

  @IsString()
  @IsNotEmpty()
  region: string

  @IsNumber()
  @IsNotEmpty()
  regionId: number

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingYield)
  yieldEstimation?: SaveScoutingYield

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingEnvironment)
  environment?: SaveScoutingEnvironment

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingFlowering)
  flowering?: SaveScoutingFlowering

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingGrowthStage)
  growthStage?: SaveScoutingGrowthStage

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingWeatherDamage)
  weatherDamage?: SaveScoutingWeatherDamage

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingQualityDefect)
  qualityDefect?: SaveScoutingQualityDefect

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingForeignMaterial)
  foreignMaterial?: SaveScoutingForeignMaterial

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingWeed)
  weed?: SaveScoutingWeed

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingInsect)
  insect?: SaveScoutingInsect

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveScoutingDisease)
  disease?: SaveScoutingDisease

  @IsString()
  @IsOptional()
  note?: string

  @IsArray()
  @ArrayMaxSize(3)
  @IsOptional()
  picturesPath?: string[]

  @IsArray()
  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  locations?: SaveLocation[]

  @IsArray()
  @IsOptional()
  @ValidateNested()
  @Type(() => SaveBoundary)
  boundaries?: SaveBoundary[]

  @IsString()
  @IsOptional()
  @Validate(IsISO8601WithTimezone)
  disablingDate?: string
}
