import { Type } from 'class-transformer'
import { IsNotEmpty, IsString, IsOptional, IsNumber, ValidateNested } from 'class-validator'
import { SaveFeatureCollection } from './save-feature-collection'

export class SaveBoundary {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => SaveFeatureCollection)
  featureCollection: SaveFeatureCollection

  @IsString()
  @IsOptional()
  geohash?: string
}
