import { IsNotEmpty, IsString, IsEnum, IsOptional, IsNumber } from 'class-validator'
import { Precision } from './location'

export class SaveLocation {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsNotEmpty()
  @IsString()
  latitude: string | undefined

  @IsNotEmpty()
  @IsString()
  longitude: string | undefined

  @IsEnum(Precision)
  @IsOptional()
  precision?: Precision

  @IsString()
  @IsOptional()
  geohash?: string
}
