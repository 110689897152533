import { Intervention } from '@agroone/entities'
import { ActionCreator, ReducerTypes, on } from '@ngrx/store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import {
  loadInterventionsFailure,
  loadInterventions,
  loadInterventionsSuccess,
  addIntervention,
  addInterventionFailure,
  addInterventionSuccess,
  addInterventions,
  addInterventionsFailure,
  addInterventionsSuccess,
  deleteIntervention,
  deleteInterventionError,
  deleteInterventionSuccess,
} from '../actions/intervention.actions'
import { CropDetailActivities, CropDetailState, cropDetailAdapter } from './crop-detail.reducer'

export interface InterventionState extends EntityState<Intervention> {
  cropId: number
  interventions: Intervention[]
}

function sortByDateDesc(s1: Intervention, s2: Intervention) {
  return s1.date > s2.date ? -1 : 1
}

export const interventionAdapter: EntityAdapter<Intervention> = createEntityAdapter<Intervention>({
  sortComparer: sortByDateDesc,
})

export const initialInterventionState: InterventionState = interventionAdapter.getInitialState({
  cropId: 0,
  interventions: [],
})

export const interventionOns: ReducerTypes<CropDetailState, ActionCreator[]>[] = [
  on(loadInterventions, (state) => ({
    ...state,
  })),
  on(loadInterventionsSuccess, (state, { cropId, interventions }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        interventions: [...interventions],
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(loadInterventionsFailure, (state) => ({
    ...state,
  })),
  on(addIntervention, (state) => ({
    ...state,
  })),
  on(addInterventionSuccess, (state, { payload: { cropId, intervention } }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        interventions: [...(cropDetail.interventions ?? []), intervention],
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(addInterventionFailure, (state) => ({
    ...state,
    error: 'Oups, Intervention has not  been added ...',
  })),
  on(addInterventions, (state) => ({
    ...state,
  })),
  on(addInterventionsSuccess, (state, { payload: { cropId, interventions } }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        interventions: [...cropDetail.interventions, ...interventions],
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(addInterventionsFailure, (state) => ({
    ...state,
    error: 'Oups, Interventions has not  been added ...',
  })),
  on(deleteIntervention, (state) => ({
    ...state,
  })),
  on(deleteInterventionSuccess, (state, { cropId, interventionId }) => {
    const cropDetail: CropDetailActivities = state.entities[`${cropId}`]
    if (cropDetail) {
      const updatedParent: CropDetailActivities = {
        ...cropDetail,
        interventions: cropDetail.interventions.filter((int) => int.id !== interventionId),
      }

      return cropDetailAdapter.updateOne({ id: cropId, changes: updatedParent }, state)
    }

    return state
  }),
  on(deleteInterventionError, (state) => ({
    ...state,
    error: 'Oups, Intervention has not  been deleted ...',
  })),
]
