<div [ngClass]="data.style" class="picture">
  <h2 mat-dialog-title>{{ 'DIALOG.PICTURE.TITLE' | translate }}</h2>
  <mat-dialog-content align="center" class="picture-content">
    <ng-container *ngIf="!data.readonly">
      <input type="file" accept="image/*" (change)="selectFiles($event)" multiple hidden #fileUpload />
      <button
        mat-stroked-button
        class="picture-content-upload"
        (click)="triggerSelectFiles()"
        [disabled]="selectedFiles.length === MAX_NUMBER_OF_PICTURES"
      >
        <mat-icon svgIcon="save_alt"></mat-icon>
        {{ 'DIALOG.PICTURE.INFORMATION' | translate }}
      </button>
    </ng-container>
    <ul class="picture-content-list">
      <li *ngFor="let item of selectedFiles">
        <span class="picture-content-list-name">{{ item.name }}</span>
      </li>
      <li *ngFor="let filename of readonlyFileNames">
        <span class="picture-content-list-name">{{ filename }}</span>
      </li>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="picture-actions">
    <button color="accent" mat-flat-button (click)="save()" *ngIf="!data.readonly">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close *ngIf="!data.readonly">
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
    <button color="accent" mat-flat-button (click)="close()" *ngIf="data.readonly">
      {{ 'DIALOG.BUTTON.CLOSE' | translate }}
    </button>
  </mat-dialog-actions>
</div>
