/* eslint-disable no-underscore-dangle */
import { ConnectionService, VersionService } from '@agroone-front/shared'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngrx/store'
import { catchError, of, switchMap } from 'rxjs'
import { syncOfflineActions } from './scene/crop-management/crop/store/actions/offline.actions'
import { OfflineState } from './scene/crop-management/crop/store/reducers'
import { selectOfflineActions } from './scene/crop-management/crop/store/selectors/offline.selectors'

// eslint-disable-next-line
declare var dT_
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private connectionService: ConnectionService,
    private offlineStore: Store<OfflineState>,
    private versionService: VersionService
  ) {
    // Init icons
    this.matIconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-set.svg'))

    this.versionService.checkForUpdates()

    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(this.http, HttpHeaders)
    }
  }

  ngOnInit(): void {
    this.onSyncBackground()
  }

  private onSyncBackground(): void {
    this.connectionService.connection$
      .pipe(
        untilDestroyed(this),
        catchError(() => of([])),
        switchMap((isConnected) => {
          if (isConnected) {
            return this.offlineStore.select(selectOfflineActions)
          }
          return of([])
        })
      )
      .subscribe((actions) => {
        if (actions?.length) {
          this.offlineStore.dispatch(syncOfflineActions({ actions }))
        }
      })
  }

  // TODO enable this method later
  // private initStoreActions(): void {
  //   this.actions$.pipe(untilDestroyed(this), ofType(syncOfflineActionsSuccess)).subscribe(() => {
  //     alert('end of sync')
  //   })
  // }
}
