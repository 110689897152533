import { Location } from './location'

export class Factory {
  constructor(factory?: Factory) {
    this.id = factory?.id ?? null
    this.name = factory?.name ?? ''
    this.quality = factory?.quality ?? ''
    this.color = factory?.color ?? ''
    this.regionId = factory?.regionId ?? null
    this.region = factory?.region ?? null
    this.locationId = factory?.regionId ?? null
    this.location = factory?.location ?? null
    this.quality = factory?.quality ?? null
  }

  id?: number
  name: string | undefined
  regionId: number | undefined
  region: string | undefined
  color?: string
  quality?: string
  locationId: number | undefined
  location?: Location
  countryId: number | undefined
  country: string | undefined
}

export class FactoryDAO {
  id?: number
  name: string
  color: string
  quality?: string | null
  regionId: number
  locationId: number

  constructor(factory: FactoryDAO) {
    this.name = factory?.name ?? null
    this.color = factory?.color ?? null
    this.quality = factory?.quality ?? null
    this.regionId = factory?.regionId ?? null
    this.locationId = factory?.locationId ?? null
  }
}
