import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef, inject } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Subscription } from 'rxjs'
import { ConnectionService } from '../services'
@UntilDestroy()
@Directive({
  selector: '[isOffline]',
  standalone: true,
})
export class IsOfflineDirective implements OnDestroy {
  private connectionService = inject(ConnectionService)
  private templateRef = inject(TemplateRef<any>)
  private viewContainer = inject(ViewContainerRef)
  private subscription: Subscription

  @Input() set isOffline(show: boolean) {
    this.connectionService.connection$.pipe(untilDestroyed(this)).subscribe((isConnected) => {
      if (show === isConnected) {
        this.viewContainer.clear()
        return
      }
      this.viewContainer.createEmbeddedView(this.templateRef)
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
