import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

/**
 * Check if file extension is allowed and accepted
 */
export function acceptFileValidator(allowedExtensions: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control?.value as File
    if (file && allowedExtensions?.length > 0) {
      const extension = file?.name?.split('.').pop()
      const accept = allowedExtensions.map((ext) => ext.toLowerCase())
      return accept.indexOf(extension.toLowerCase()) !== -1 ? null : { invalidFileType: true }
    }
    return null
  }
}
