import { Component, ElementRef, HostListener, Inject, Renderer2, ViewChild } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import SignaturePad from 'signature_pad'
import { TranslateService } from '@ngx-translate/core'
import { Region } from '@agroone-app/assets/enums/region.enum'
import { Toast } from 'primeng/toast'
import { ToastService } from '@agroone-front/shared'

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.sass'],
})
export class SignatureComponent {
  @ViewChild('container') container: ElementRef

  // eslint-disable-next-line accessor-pairs
  @ViewChild('signaturePad', { static: false }) set signaturePadElement(value: ElementRef) {
    if (value) {
      this.signaturePadElementValue = value
      this.initSignaturePad()
    }
  }

  private signaturePadElementValue: ElementRef
  private signaturePad: SignaturePad

  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<SignatureComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { style: string; region: string; existingSignature: string }
  ) {}

  // The canvas size is fixed, so we've to redraw it each time the screen is resized
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.initSignaturePad()
  }

  clearSignature() {
    this.signaturePad?.clear()
  }

  save(): void {
    if (this.data.region === Region.FRANCE_SOUTH_WEST && (!this.signaturePad || this.signaturePad.isEmpty())) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      this.toastService.showToastWarning(this.translateService.instant('DIALOG.SIGNATURE.FILL_IN_SIGNATURE'))
    } else {
      this.dialogRef.close(this.signaturePad.isEmpty() ? null : this.signaturePad.toDataURL())
    }
  }

  cancel() {
    this.dialogRef.close()
  }

  private initSignaturePad() {
    if (this.signaturePad) {
      this.signaturePad.off()
    }
    if (this.signaturePadElementValue.nativeElement.offsetWidth) {
      this.renderer.setAttribute(
        this.signaturePadElementValue.nativeElement,
        'width',
        this.container.nativeElement.offsetWidth
      )
      this.signaturePad = new SignaturePad(this.signaturePadElementValue.nativeElement)
      if (this.data.existingSignature) {
        this.signaturePad.fromDataURL(this.data.existingSignature, {
          width: this.container.nativeElement.offsetWidth,
          height: this.signaturePadElementValue.nativeElement.offsetHeight,
        })
      }
    }
  }
}
