import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core'
import { BaseComponent } from '@agroone-app/core/base.component'
import { MapOptions } from '@agroone-app/shared/dialog/models/mapOptions.model'
import { MapService } from '@agroone-app/shared/map/lib/map.service'
import { SharedUserService } from '@agroone-front/shared'

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html',
  styleUrls: ['./map-location.component.sass'],
})
export class MapLocationComponent extends BaseComponent implements OnInit {
  @Input() data: MapOptions

  @Output() closeDialog = new EventEmitter()

  @ViewChild('mapcontainer') mapContainer: ElementRef

  private initThen: Promise<void>

  constructor(
    public override userService: SharedUserService,
    private mapService: MapService,
    private injector: Injector
  ) {
    super(injector)
  }

  ngOnInit() {
    this.initThen = this.initMap(this.data)
  }

  async setLocation() {
    if (this.initThen) {
      await this.initThen
    }
    const manualLocation = this.mapService.getManualLocation()
    this.closeDialog.emit(manualLocation)
  }

  private async initMap(data: MapOptions) {
    if (data.location || data.boundary) {
      await this.mapService.initMap('map', this.mapContainer, data.location, data.boundary, {
        color: data.style,
        draggable: data.displayMode !== 'view',
        stencil: data.stencil,
      })
    } else {
      const address = !data.address || data.address === '' ? this.userService.currentUser.regionName : data.address
      await this.mapService.initMapByAddress('map', this.mapContainer, address, {
        color: data.style,
        stencil: data.stencil,
      })
    }
  }
}
