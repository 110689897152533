<div class="container">
  <h2 mat-dialog-title class="container-title">{{ 'NOTIFICATION.SEND_TITLE' | translate }}</h2>
  <mat-dialog-content class="container-content">
    <form *ngIf="form; else spinner" [formGroup]="form">
      <!-- Description -->
      <mat-label aoRequired>{{ 'NOTIFICATION.DESCRIPTION_LABEL' | translate }}</mat-label>
      <textarea
        formControlName="description"
        rows="10"
        class="container-content-description"
        matInput
        placeholder="{{ 'NOTIFICATION.TYPE_HERE_HINT' | translate }}"
        [ngClass]="{
          'container-content-description-pass': description.valid && description.touched && description.dirty,
          'container-content-description-error': description.invalid && description.touched && description.dirty
        }"
      >
      </textarea>

      <!-- Recipients -->
      <mat-label aoRequired>{{ 'NOTIFICATION.RECIPIENT_LABEL' | translate }}</mat-label>
      <app-autocomplete-select
        [customStyle]="true"
        [isValid]="recipient.valid"
        [list]="recipients"
        [formCtrl]="recipient"
        [displayFn]="recipientsDisplay"
        [filterFn]="filterRecipient"
        [sortFn]="sortRecipient"
      >
      </app-autocomplete-select>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="form" align="center" class="container-actions">
    <button [disabled]="!form.valid" (click)="save()" mat-flat-button>
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button mat-flat-button (click)="closePopup()">
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>

<ng-template #spinner>
  <div class="disp-f j-c-c">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
