import { Intervention } from './intervention'
import { Recommendation } from './recommendation'
import { Sample } from './sample'
import { Scouting } from './scouting'
import { WorkOrder } from './work-order'

export type Activity = Scouting | Recommendation | Intervention | WorkOrder | Sample

export class CropActivity {
  id: number
  date: string
  activity: Activity
  type: string
  subtype: string
  user?: string
  disablingDate?: string

  constructor(activity?: CropActivity) {
    this.id = activity?.id
    this.date = activity?.date ?? ''
    this.activity = activity?.activity
    this.type = activity?.type ?? ''
    this.subtype = activity?.subtype ?? ''
    this.user = activity?.user ?? ''
    this.disablingDate = activity?.disablingDate
  }
}
