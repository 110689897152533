// CONDITIONS
import { Location } from './location'
import { FeatureCollection } from './feature-collection'
import { ReportingDAO } from './reporting'
import { SaveScouting } from './save-scouting'
import { SaveFeatureCollection } from './save-feature-collection'
import { SaveBoundary } from './save-boundary'
import { ISO8601ToDate } from '@agroone/helpers'

export class ScoutingEnvironment {
  id?: number
  item: string
  cropVigor: string
  cropConsistent: boolean
}

export class ScoutingWeatherDamage {
  id?: number
  item: string
  area: string
  severity: string
}

export class ScoutingFlowering {
  id?: number
  date: string
  percentage: number

  get dateAsDate(): Date {
    return ISO8601ToDate(this.date)
  }

  constructor(scoutingFlowering?: ScoutingFlowering) {
    if (scoutingFlowering?.id) {
      this.id = scoutingFlowering.id
    }
    this.date = scoutingFlowering?.date ?? ''
    this.percentage = parseInt(scoutingFlowering?.percentage ?? (0 as any), 10)
  }
}

// MATURITY
export class ScoutingGrowthStage {
  id?: number
  item: string
}

export class ScoutingYieldStandCount {
  id: number
  headCount: number
  standCount: number
  weight: number
  weightUnit: string

  constructor(standCount?: ScoutingYieldStandCount) {
    this.id = standCount?.id
    this.headCount = +(standCount?.headCount ?? 0)
    this.standCount = +(standCount?.standCount ?? 0)
    this.weight = +(standCount?.weight ?? 0)
    this.weightUnit = standCount?.weightUnit
  }
}

export class ScoutingYield {
  id: number
  forecastHarvestDate?: string
  value?: number
  unit?: string
  week?: number
  leafCount?: number
  standCounts?: ScoutingYieldStandCount[]

  get forecastHarvestDateAsDate(): Date {
    return ISO8601ToDate(this.forecastHarvestDate)
  }

  /** @deprecated */
  plannedHarvestDate?: string
  /** @deprecated */
  standCount?: number
  /** @deprecated */
  weight?: number
  /** @deprecated */
  weightUnit?: string

  constructor(scoutingYield?: ScoutingYield) {
    this.forecastHarvestDate = scoutingYield?.forecastHarvestDate
    this.value = +(scoutingYield?.value ?? 0)
    this.unit = scoutingYield?.unit
    this.leafCount = +(scoutingYield?.leafCount ?? 0)
    const mappedStandCounts = scoutingYield?.standCounts?.map?.((c) => new ScoutingYieldStandCount(c))
    if (mappedStandCounts) {
      this.standCounts = scoutingYield?.standCounts?.map?.((c) => new ScoutingYieldStandCount(c))
    }

    if (scoutingYield?.week) {
      this.week = scoutingYield?.week
    }

    if (scoutingYield?.plannedHarvestDate) {
      this.plannedHarvestDate = scoutingYield?.plannedHarvestDate
    }
    if (scoutingYield?.standCount) {
      this.standCount = scoutingYield?.standCount
    }
    if (scoutingYield?.weight) {
      this.weight = scoutingYield?.weight
    }
    if (scoutingYield?.weightUnit) {
      this.weightUnit = scoutingYield?.weightUnit
    }
  }
}

export class ScoutingQuality {
  id?: number
  item: string
  area: string
  fieldPart: number
}

// OBSERVATION
export class ScoutingForeignMaterial {
  id: number
  type: string
  subType: string
  area: string
  level: string
}

export class ScoutingWeed {
  id: number
  item: string
  area: string
  pressure: string
  stage: string
}

export class ScoutingInsect {
  id: number
  item: string
  area: string
  pressure: string
  stage: string
}

export class ScoutingDisease {
  id: number
  item: string
  area: string
  pressure: string
}

export class ScoutingCrop {
  id: number
  name?: string
  shortName: string
  cropTypeId: number
  campaignId?: number

  constructor(crop?: ScoutingCrop) {
    this.id = crop.id
    this.name = crop.name
    this.shortName = crop.shortName
    this.cropTypeId = crop.cropTypeId
    this.campaignId = crop?.campaignId
  }
}

export class Scouting {
  static readonly className = 'Scouting'

  get dateAsDate(): Date {
    return ISO8601ToDate(this.date)
  }

  id?: number
  date: string
  scoutedBy: string
  crops: ScoutingCrop[]
  cropIds?: number[]
  regionId: number
  region: string
  type: string
  subType: string
  note?: string
  disablingDate?: string
  yieldEstimation?: ScoutingYield
  environment?: ScoutingEnvironment
  flowering?: ScoutingFlowering
  growthStage?: ScoutingGrowthStage
  weatherDamage?: ScoutingWeatherDamage
  qualityDefect?: ScoutingQuality
  foreignMaterial?: ScoutingForeignMaterial
  weed?: ScoutingWeed
  insect?: ScoutingInsect
  disease?: ScoutingDisease
  picturesPath?: string[]
  locations?: Location[]
  boundaries?: FeatureCollection[] | SaveFeatureCollection[] | SaveBoundary[]
  standCount?: ScoutingStandCount

  constructor(scouting?: Scouting) {
    this.id = scouting?.id
    this.date = scouting?.date
    this.scoutedBy = scouting?.scoutedBy
    this.crops = scouting?.crops
    this.cropIds = scouting?.cropIds
    this.regionId = scouting?.regionId
    this.region = scouting?.region
    this.type = scouting?.type
    this.subType = scouting?.subType
    this.note = scouting?.note
    this.disablingDate = scouting?.disablingDate
    this.yieldEstimation = scouting?.yieldEstimation
    this.environment = scouting?.environment
    this.flowering = scouting?.flowering
    this.growthStage = scouting?.growthStage
    this.weatherDamage = scouting?.weatherDamage
    this.qualityDefect = scouting?.qualityDefect
    this.foreignMaterial = scouting?.foreignMaterial
    this.weed = scouting?.weed
    this.insect = scouting?.insect
    this.disease = scouting?.disease
    this.picturesPath = scouting?.picturesPath
    this.locations = scouting?.locations
    this.boundaries = scouting?.boundaries
    this.standCount = scouting?.standCount
  }
}

export class ScoutingCropDAO {
  cropId: number
  scoutingId: number
}

export class ScoutingPictureDAO {
  pictureId: number
  scoutingId: number
}

export class ScoutingLocationDAO {
  locationId: number
  scoutingId: number
}

export class ScoutingBoundaryDAO {
  boundaryId: number
  scoutingId: number
}

export class ScoutingDAO extends ReportingDAO {
  id?: number
  date: string
  scoutedBy: string
  regionId: number
  type: string
  subType: string
  note?: string
  disablingDate?: string
  yieldId?: number
  environmentId?: number
  floweringId?: number
  growthStageId?: number
  weatherDamageId?: number
  qualityDefectId?: number
  foreignMaterialId?: number
  weedId?: number
  insectId?: number
  diseaseId?: number

  constructor(scouting?: SaveScouting) {
    super(scouting)
  }
}

export class ScoutingGeoInfoRequest {
  id?: number
  latitudes?: string
  locationIds?: string
  longitudes?: string
  precisions?: string
  geohashs?: string
  boundaryIds?: string
  boundaries?: string
}

export class ScoutingRequest {
  id?: number
  date: string
  scoutedBy: string
  type: string
  subType: string
  note?: string
  disablingDate?: string
  regionId: number
  region: string
  cropIds?: string
  cropNames?: string
  cropTypeIds?: string
  cropShortNames?: string
  latitudes?: string
  locationIds?: string
  longitudes?: string
  precisions?: string
  geohashs?: string
  boundaryIds?: string
  boundaries?: string
  picturePaths?: string

  eId?: number
  eItem?: string
  eCropVigor?: string
  eCropConsistent?: number
  wdId?: number
  wdItem?: string
  wdArea?: string
  wdSeverity?: string
  fId?: number
  fDate?: string
  fPercentage?: number
  gsId?: number
  gsItem?: string
  qdId?: number
  qdItem?: string
  qdArea?: string
  qdFieldPart?: number
  fmId?: number
  fmType?: string
  fmSubType?: string
  fmArea?: string
  fmLevel?: string
  wId?: number
  wItem?: string
  wArea?: string
  wPressure?: string
  wStage?: string
  iId?: number
  iItem?: string
  iArea?: string
  iPressure?: string
  iStage?: string
  dId?: number
  dItem?: string
  dArea?: string
  dPressure?: string
  yId?: number
  yForecastHarvestDate?: string
  yValue?: number
  yUnit?: string
  yWeek?: number
  yLeafCount?: number
  scIds?: string
  scHeadCount?: string
  scStandCount?: string
  scWeight?: string
  scWeightUnit?: string
  yStandCountIds?: string
}

export class ScoutingStandCount {
  standCount: number

  constructor(scoutingStandCount?: ScoutingStandCount) {
    this.standCount = scoutingStandCount?.standCount
  }
}
