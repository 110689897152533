import { Pipe, PipeTransform } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs'

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform<T, W>(
    tArray: Observable<T[]> | T[],
    filterValue: string | W,
    // eslint-disable-next-line @typescript-eslint/ban-types
    filterFunction: (a: T, b: string | W) => {},
    sortFunction?: (list: T[]) => T[]
  ): Observable<T[]> | T[] {
    if (Array.isArray(tArray)) {
      let filteredResult: T[] = tArray.filter((value: T) => filterFunction(value, filterValue))
      if (sortFunction) {
        filteredResult = sortFunction(filteredResult)
      }
      let otherResults: T[] = this.getOppositeElements(tArray, filteredResult)
      if (sortFunction) {
        otherResults = sortFunction(otherResults)
      }
      return [...filteredResult, ...otherResults]
    } else if (tArray instanceof Observable) {
      return tArray.pipe(
        map((values: T[]) => {
          let filteredResult: T[] = values.filter((value: T) => filterFunction(value, filterValue))
          if (sortFunction) {
            filteredResult = sortFunction(filteredResult)
          }
          let otherResults: T[] = this.getOppositeElements(values, filteredResult)
          if (sortFunction) {
            otherResults = sortFunction(otherResults)
          }
          return [...filteredResult, ...otherResults]
        })
      )
    } else {
      return []
    }
  }

  private getOppositeElements<T>(fullArray: T[], reducedArray: T[]): T[] {
    return fullArray.filter((item) => {
      const itemExist: T = reducedArray.find((item2) => item === item2)
      return !itemExist
    })
  }
}
