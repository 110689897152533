import { IsDateValidate, IsDecimalNumber, IsISO8601WithTimezone, RequireProperty } from '@agroone/helpers'
import { plainToInstance, Type } from 'class-transformer'
import {
  IsArray,
  IsBoolean,
  IsDateString,
  IsEnum,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  Length,
  Max,
  Min,
  Validate,
  ValidateIf,
  ValidateNested,
} from 'class-validator'

import { SaveFeatureCollection } from './save-feature-collection'
import { SaveLocation } from './save-location'
import { SaveReporting } from './save-reporting'
import { SeedingRateUnit } from '../enums/seeding-rate-unit.enum'
import { DateFormatV2 } from '@agroone/dates'

export class SaveCropPlanting {
  @IsString()
  @IsOptional()
  plantingId: string | undefined

  @IsNumber()
  @Min(0)
  @IsOptional()
  @Validate(IsDecimalNumber, [2])
  @RequireProperty('seedingRateUnit')
  seedingRate: number | undefined

  @IsEnum(SeedingRateUnit)
  @IsOptional()
  @RequireProperty('seedingRate')
  seedingRateUnit: SeedingRateUnit

  @IsOptional()
  @Length(10)
  @IsDateValidate(DateFormatV2.SIMPLE)
  germinationDate?: string

  @IsOptional()
  @IsString()
  weatherConditions?: string

  @IsOptional()
  @IsString()
  soilConditions?: string

  @IsOptional()
  @IsString()
  plantingNote?: string

  @IsInt()
  @Min(1)
  @IsOptional()
  beds?: number

  @IsInt()
  @Min(1)
  @IsOptional()
  seedLines?: number

  @IsNumber()
  @Min(0.01)
  @IsOptional()
  @RequireProperty('bedWidthUnit')
  bedWidth?: number

  @IsOptional()
  @IsString()
  @RequireProperty('bedWidth')
  @IsIn(['in', 'cm', 'mm'])
  bedWidthUnit?: string

  @IsBoolean()
  @IsOptional()
  plantingValidated?: boolean | undefined

  @IsInt()
  @Min(0)
  @IsOptional()
  @RequireProperty('seedSpacingUnit')
  seedSpacing?: number

  @IsOptional()
  @IsString()
  @RequireProperty('seedSpacing')
  @IsIn(['in', 'cm', 'mm'])
  seedSpacingUnit?: string

  @IsOptional()
  @IsDateValidate(DateFormatV2.DATETIME_OFFSET)
  initialPlannedHarvestDate?: string

  @IsString()
  @IsOptional()
  lotNumber!: string

  @IsNumber()
  @IsOptional()
  @RequireProperty('depthOfPlantingUnits')
  depthOfPlanting?: number

  @IsString()
  @IsOptional()
  @RequireProperty('depthOfPlanting')
  depthOfPlantingUnits?: string

  @IsString()
  @IsOptional()
  fieldBuffer?: string

  @IsBoolean()
  @IsOptional()
  rolledAfterSeed?: boolean

  @IsNumber()
  @IsOptional()
  @IsInt()
  specificationId?: number

  @IsNumber()
  @IsOptional()
  @IsInt()
  sectorId?: number
}

export class SaveCrop extends SaveReporting {
  public static override build(raw: any) {
    return plainToInstance(SaveCrop, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @Length(10)
  @IsOptional()
  cropName?: string

  @IsString()
  @Length(5)
  @IsOptional()
  shortName?: string

  @Length(10)
  @IsDateString()
  @IsOptional()
  plantedDate?: string

  @IsNumber()
  @Validate(IsDecimalNumber, [4])
  @IsOptional()
  plantedArea?: number

  @IsNumber()
  @Min(0)
  @Validate(IsDecimalNumber, [4])
  @IsNotEmpty()
  intendedArea!: number

  @IsString()
  @IsNotEmpty()
  areaUnit!: string

  @IsNumber()
  @IsOptional()
  varietyId?: number

  @IsNumber()
  @IsNotEmpty()
  cropTypeId!: number

  @ValidateIf((o) => !o.isVirtual)
  @IsNumber()
  @IsNotEmpty()
  fieldId!: number

  @IsOptional()
  @IsDateValidate(DateFormatV2.DATETIME_OFFSET)
  startDate?: string

  @IsOptional()
  @IsDateValidate(DateFormatV2.DATETIME_OFFSET)
  endDate?: string

  @IsNumber()
  @IsNotEmpty()
  campaignId!: number

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: SaveLocation

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveCropPlanting)
  planting!: SaveCropPlanting

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveFeatureCollection)
  boundary?: SaveFeatureCollection

  @IsOptional()
  @IsDateValidate(DateFormatV2.DATETIME_OFFSET)
  plannedHarvestDate?: string

  @IsString()
  @IsOptional()
  technician?: string

  @IsOptional()
  @IsDateValidate(DateFormatV2.DATETIME_OFFSET)
  forecastHarvestDate?: string

  @IsString()
  @IsOptional()
  associatedCrop?: string

  @IsString()
  @IsOptional()
  growingSystem?: string

  @IsString()
  @IsOptional()
  notes?: string

  @IsBoolean()
  @IsOptional()
  active?: boolean

  @IsString()
  @IsOptional()
  userId?: string

  @IsString()
  @IsOptional()
  plantNumber?: string

  @IsString()
  @IsOptional()
  externalId?: string

  @IsInt()
  @IsPositive()
  @IsOptional()
  externalClientId?: number

  @IsNumber()
  @IsOptional()
  destinationFactoryId?: number

  @IsString()
  @IsNotEmpty()
  region!: string

  @IsOptional()
  @IsDateValidate(DateFormatV2.DATETIME_OFFSET)
  lastScoutingDate?: string

  @IsNumber()
  @IsOptional()
  lastTimeCropWasGrown?: number

  @IsString()
  @IsOptional()
  lastFloweringDate?: string

  @IsNumber()
  @IsOptional()
  floweringScoutingId?: number

  @IsNumber()
  @IsOptional()
  yieldEstimationScoutingId?: number

  @IsNumber()
  @IsOptional()
  growthStageScoutingId?: number

  @IsOptional()
  @IsDateValidate(DateFormatV2.DATETIME_OFFSET)
  reentryDate?: string

  @IsOptional()
  @IsArray()
  @IsInt({ each: true })
  @Min(1, { each: true })
  productsUsedTwoYearsAgo?: number[]

  @IsOptional()
  @IsArray()
  @IsInt({ each: true })
  @Min(1, { each: true })
  productsUsedLastYear?: number[]

  @IsOptional()
  @IsArray()
  @Type(() => SaveCropGrowPreviousYear)
  @ValidateNested({ each: true })
  growsLastYear?: SaveCropGrowPreviousYear[]

  @IsOptional()
  @IsArray()
  @Type(() => SaveCropGrowPreviousYear)
  @ValidateNested({ each: true })
  growsTwoYearsAgo?: SaveCropGrowPreviousYear[]

  @IsOptional()
  @IsBoolean()
  nutTrees?: boolean

  @IsOptional()
  @IsArray()
  @IsString({ each: true })
  problematicWeeds?: string[]

  @IsOptional()
  @IsBoolean()
  isVirtual?: boolean

  @ValidateIf((o) => o.isVirtual)
  @IsInt()
  @IsPositive()
  growerId?: number

  @IsOptional()
  @IsBoolean()
  isFromVirtualCrop?: boolean

  @ValidateIf((o) => o.isFromVirtualCrop)
  @IsInt()
  @IsPositive()
  virtualCropId?: number

  @IsOptional()
  @IsString()
  tillageCondition?: string
}

export class SaveCropGrowPreviousYear {
  @IsNumber()
  @IsInt()
  @IsPositive()
  constantKeyId: number

  @IsNumber()
  @IsPositive()
  @Max(999999.99)
  area: number

  @IsString()
  @IsNotEmpty()
  unit: string
}
