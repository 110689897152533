import { registerDecorator, ValidationArguments } from 'class-validator'

// You must include as first argument of the decorator the name of the property you want to be unique in the array.
export function UniquePropertyInArray(...properties: string[]) {
  return (object: object, propertyName: string) => {
    registerDecorator({
      name: 'UniquePropertyInArray',
      target: object.constructor,
      propertyName,
      constraints: properties,
      validator: {
        validate: UniquePropertyInArrayValidate,
        defaultMessage: UniquePropertyInArrayDefaultMessage,
      },
    })
  }
}

export function UniquePropertyInArrayValidate(values: any[], args: ValidationArguments): boolean {
  if (args.constraints.length === 0) {
    return false
  }
  const propertyName = args.constraints[0]
  const propertyValues = []
  for (const item of values) {
    if (propertyValues.find((value) => item[String(propertyName)] === value)) {
      return false
    }
    if (item[String(propertyName)]) {
      propertyValues.push(item[String(propertyName)])
    }
  }
  return true
}

export function UniquePropertyInArrayDefaultMessage(args?: ValidationArguments): string {
  return `${args.property} requires each ${args.constraints[0]} value of the array to be unique`
}
