import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator'

export class ResetOfferPriorisationDto {
  @IsString()
  @IsNotEmpty()
  date: string

  @IsNumber()
  @IsOptional()
  id?: number
}
