import {
  LoggerService,
  SharedPermissionService,
  SharedUserGuard,
  SharedUserService,
  ToastService,
} from '@agroone-front/shared'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class UserGuard extends SharedUserGuard {
  constructor(
    override readonly logger: LoggerService,
    override readonly toastService: ToastService,
    override readonly translate: TranslateService,
    override readonly userService: SharedUserService,
    override readonly permissionService: SharedPermissionService
  ) {
    super(logger, toastService, translate, userService, permissionService)
  }
}
