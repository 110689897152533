import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MapService } from '../map/lib/map.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { catchError } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { ShapefileType } from '@agroone/entities'
import { CommonModule } from '@angular/common'
import { ToastService } from '@agroone-front/shared'

@Component({
  selector: 'app-download-boundaries',
  templateUrl: './download-boundaries.component.html',
  standalone: true,
  imports: [MatIconModule, CommonModule],
})
@UntilDestroy()
export class DownloadBoundariesComponent {
  @Input() ids: number[] // crops or fields ids
  @Input() boundaryIds?: number[] // optional boundaries ids
  @Input() isEnabled: boolean
  @Input() shapeType: ShapefileType = ShapefileType.CROP
  @Input() height: number = 34
  @Input() width: number = 40

  public pendingExport: boolean
  public successDownloadMsg = 'GEOLOCATION.SHAPEFILE.SUCCESS_DOWNLOAD'
  public errorDownloadMsg = 'GEOLOCATION.SHAPEFILE.ERROR_DOWNLOAD'

  constructor(
    private readonly _mapService: MapService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService
  ) {}

  onDownload(): void {
    if (!this.isEnabled) {
      return
    }

    this._mapService
      .exportBoundariesShapefile(this.shapeType, this.ids, this.boundaryIds)
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          this._toastService.showToastError(this._translateService.instant(this.errorDownloadMsg))
          return error
        })
      )
      .subscribe(() => {
        this._toastService.showToastSuccess(this._translateService.instant(this.successDownloadMsg))
      })
  }
}
