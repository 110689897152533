<div class="map-draw">
  <div class="map-draw-import">
    <input type="file" accept=".shp,.zip,.kml" (change)="import($event)" hidden #fileUpload />
    <button mat-button class="map-draw-import-button" (click)="fileUpload.click()">
      {{ 'DIALOG.MAP.IMPORT' | translate }}
    </button>
  </div>

  <div id="map" class="map-draw-content" #mapcontainer></div>

  <div class="map-draw-actions" align="center">
    <button color="accent" mat-flat-button (click)="setBoundary()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </div>
</div>
