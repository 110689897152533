import { NgIf } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { AuthService } from '../services'

@Component({
  selector: 'app-logout',
  templateUrl: './shared-logout.component.html',
  standalone: true,
  imports: [NgIf],
})
export class SharedLogoutComponent implements OnInit {
  constructor(private http: HttpClient, private authService: AuthService) {}

  async ngOnInit() {
    await this.logout()
  }

  private async logout() {
    await firstValueFrom(this.http.get(`${this.authService.getApiUrl()}/auth/logout`))
    // Clean local data
    indexedDB.deleteDatabase('agronne-store')
    localStorage.clear()
    sessionStorage.clear()
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => registration.unregister())
    })
    this.authService.redirectToLogin()
  }
}
