import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IsGrantedDirective, IsOfflineDirective } from '@agroone-front/shared'
import { EmailToNamePipe } from './pipes/email-to-name.pipe'

@NgModule({
  imports: [CommonModule, IsOfflineDirective, IsGrantedDirective],
  declarations: [EmailToNamePipe],
  exports: [EmailToNamePipe, IsGrantedDirective, IsOfflineDirective],
})
export class UserModule {}
