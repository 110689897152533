import { Crop } from '@agroone/entities'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ActionCreator, on, ReducerTypes } from '@ngrx/store'

import {
  addCrop,
  addCropFailure,
  addCropsFailure,
  addCropsSuccess,
  addCropSuccess,
  clearCrops,
  deleteCrop,
  deleteCropError,
  deleteCropSuccess,
  loadCrops,
  loadCropsFailure,
  loadCropsSuccess,
  patchCrop,
  patchCropFailure,
  patchCropSuccess,
  updateCrop,
  updateCropFailure,
  updateCropSuccess,
} from '../actions/crop.actions'

function sortByEndDateAndId(a: Crop, b: Crop): number {
  const dateA = new Date(a.endDate)
  const dateB = new Date(b.endDate)

  if (dateA < dateB) return -1
  if (dateA > dateB) return 1
  return a.id - b.id
}

export type CropState = EntityState<Crop>

export const cropAdapter: EntityAdapter<Crop> = createEntityAdapter<Crop>({
  sortComparer: sortByEndDateAndId,
})

export const initialCropState: CropState = cropAdapter.getInitialState({
  // eslint-disable-next-line no-empty-pattern
  ids: ([] = []),
})

export const cropOns: ReducerTypes<CropState, ActionCreator[]>[] = [
  on(loadCrops, (state) => ({
    ...state,
  })),
  on(loadCropsSuccess, (state, action) => cropAdapter.setAll(action?.crops, { ...state })),
  on(loadCropsFailure, (state) => ({
    ...state,
  })),

  // CREATE
  on(addCrop, (state) => ({
    ...state,
  })),
  on(addCropSuccess, (state, action) =>
    cropAdapter.addOne(action.payload.crop, {
      ...state,
      message: 'Crop has been added',
      error: undefined,
    })
  ),
  on(addCropFailure, (state) => ({
    ...state,
    error: 'Oups, Crop has not  been added ...',
  })),

  // UPDATE
  on(updateCrop, (state) => ({
    ...state,
  })),
  on(updateCropSuccess, (state, action) =>
    cropAdapter.upsertOne(action?.payload?.crop, {
      ...state,
      message: 'Crop has been updated',
      error: undefined,
    })
  ),
  on(updateCropFailure, (state) => ({
    ...state,
    error: 'Oups, Crop has not been updated ...',
  })),

  // DELETE
  on(deleteCrop, (state) => ({
    ...state,
  })),
  on(deleteCropSuccess, (state, action) =>
    cropAdapter.removeOne(action.payload.cropId, {
      ...state,
      message: 'Crop has been removed',
      error: undefined,
    })
  ),
  on(deleteCropError, (state) => ({
    ...state,
    error: 'Oups, Crop has not  been deleted ...',
  })),

  on(addCropsSuccess, (state, action) =>
    cropAdapter.upsertMany(action.payload.crops, {
      ...state,
      message: 'Crops has been added',
      error: undefined,
    })
  ),
  on(addCropsFailure, (state) => ({
    ...state,
    error: 'Oups, Crops has not  been added ...',
  })),

  // PATCH
  on(patchCrop, (state) => ({
    ...state,
  })),
  on(patchCropSuccess, (state, action) =>
    cropAdapter.upsertOne(action?.payload?.crop, {
      ...state,
      message: 'Crop has been patched',
      error: undefined,
    })
  ),
  on(patchCropFailure, (state) => ({
    ...state,
    error: 'Oups, Crop has not  been patched ...',
  })),
  on(clearCrops, () => initialCropState),
]
