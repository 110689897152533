import { environment } from '@front-app-environments/environment'
import { LoggerService, NetworkService, SharedConstantService, SharedUserService } from '@agroone-front/shared'
import {
  Constant,
  ConstantLite,
  Constants,
  ConstantsLite,
  Croptype,
  MsSqlPaginatedData,
  UserDto,
} from '@agroone/entities'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, filter, forkJoin, map, mergeMap, Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ConstantService extends SharedConstantService {
  baseUrl: string = `${environment.apiUrl}${environment.constants}`

  constructor(
    private logger: LoggerService,
    private userService: SharedUserService,
    protected override http: HttpClient,
    protected networkService: NetworkService
  ) {
    super(http)
  }

  protected override _constantToConstantLite(constant: Constant): ConstantLite[] {
    const constantLites: ConstantLite[] = []
    for (const key of Object.keys(constant.values)) {
      const constantLite: ConstantLite = {
        code: key,
        ...constant.values[String(key)],
        translation: constant.values[String(key)]?.translations?.[0]?.value,
      }
      constantLites.push(constantLite)
    }
    return constantLites
  }

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.userService.user
        .pipe(
          mergeMap((user: UserDto) => (user ? this.getAll(user.language) : of(new Constant()))),
          filter(Boolean),
          map(([constants, croptypes]: [Constants, Croptype[]]) => {
            const constantsLite: ConstantsLite = this.constantsToConstantsLite(constants)
            for (const constantName of Object.keys(constantsLite)) {
              if (typeof constantsLite[String(constantName)][0].priority === 'number') {
                constantsLite[String(constantName)] = constantsLite[String(constantName)].sort(
                  (a, b) => a.priority - b.priority
                )
              }
            }
            const mappedCroptypes: ConstantLite[] = []
            for (const croptype of croptypes) {
              mappedCroptypes.push({
                croptypeId: croptype.id,
                code: croptype.key,
                regions: croptype.regions,
                translation: croptype.translations?.[0]?.value,
              })
            }
            return [constantsLite, mappedCroptypes]
          }),
          filter((i) => i && Boolean(Object.keys(i).length)),
          catchError((error) => {
            this.logger.error('Constants loading error', JSON.stringify(error))
            return of(this.constants)
          })
        )
        .subscribe(([constants, croptypes]: [ConstantsLite, ConstantLite[]]) => {
          this.constants = constants
          this.croptypes = croptypes
          this.logger.log('Constants initialized')
          resolve()
        })
    })
  }

  private getAll(language: string): Observable<[Constants, Croptype[]]> {
    let constantsParams: HttpParams = new HttpParams()
    constantsParams = constantsParams.append('languageName', language)
    const constantsOptions: {
      params: HttpParams
    } = {
      params: constantsParams,
    }

    let croptypesParams: HttpParams = new HttpParams()
    croptypesParams = croptypesParams.append('filter', `languageName="${language}"`)
    const croptypesOptions: {
      params: HttpParams
    } = {
      params: croptypesParams,
    }

    const constants: Observable<Constants> = this.http.get<Constants>(this.baseUrl, constantsOptions)
    const croptypes: Observable<Croptype[]> = this.http
      .get<MsSqlPaginatedData<Croptype>>(`${environment.apiUrl}${environment.croptypes}`, croptypesOptions)
      .pipe(map((v) => v.data))

    return forkJoin([constants, croptypes])
  }
}
