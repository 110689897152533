import { ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from 'class-validator'

@ValidatorConstraint({ name: 'arraySizes', async: false })
export class ArraySizes implements ValidatorConstraintInterface {
  validate(array: any[], args: ValidationArguments) {
    const sizes: any[] = args.constraints
    return sizes.includes(array.length)
  }

  defaultMessage(args: ValidationArguments) {
    return `Array must be of size: ${args.constraints.join(', ')}`
  }
}
