<div class="file-mode">
  <h2 mat-dialog-title>{{ 'DIALOG.FILE_MODE.TITLE' | translate }}</h2>
  <mat-dialog-actions align="center" class="file-mode-actions">
    <button color="accent" mat-flat-button (click)="setDownloadFile()">
      {{ 'DIALOG.FILE_MODE.DOWNLOAD' | translate }}
    </button>
    <button color="accent" mat-flat-button (click)="setUploadFile()">
      {{ 'DIALOG.FILE_MODE.UPLOAD' | translate }}
    </button>
  </mat-dialog-actions>
</div>
