import { SaveFeatureCollection } from './save-feature-collection'
import { ReportingDAO } from './reporting'
import { PesticideProductLite } from './pesticide'
import { Location, Precision } from './location'
import { Obstruction } from './obstruction'
import { FeatureCollection } from './feature-collection'
import { ISO8601ToDate } from '@agroone/helpers'
import { Crop } from './crop'
import { DateFormatV2, formatDateV2 } from '@agroone/dates'

export class CropGrown {
  previousCropType: string
  area: number
  unit: string

  constructor(cropsGrown?: CropGrown) {
    this.previousCropType = cropsGrown?.previousCropType ?? ''
    this.area = cropsGrown?.area ?? 0
    this.unit = cropsGrown?.unit ?? ''
  }
}

export class FieldYearHistory {
  year: number
  cropsGrown: CropGrown[] | null
  productsUsed: PesticideProductLite[] | null

  constructor(fieldYearHistory?: FieldYearHistory) {
    this.year = fieldYearHistory?.year ?? 0
    this.cropsGrown = fieldYearHistory?.cropsGrown ?? null
    this.productsUsed = fieldYearHistory?.productsUsed ?? null
  }
}

export class FieldHistory {
  id: string | null
  region: string | null
  problematicWeeds?: string[] | null
  nutTrees?: boolean | null
  history?: FieldYearHistory[] | null

  constructor() {
    this.id = null
    this.region = null
    this.problematicWeeds = null
    this.nutTrees = false
    this.history = null
  }
}

export class FieldHistoryDAO {
  id: string | null
  metadata: string | undefined
  region: string | null
  problematicWeeds?: string[] | null
  nutTrees?: boolean
  history?: FieldYearHistory[] | null

  constructor() {
    this.id = null
    this.region = null
    this.problematicWeeds = null
    this.nutTrees = false
    this.history = null
  }
}

export class Field {
  id?: number | null
  active: boolean | null
  boundary?: FeatureCollection | SaveFeatureCollection | null
  boundaryId?: number | null
  certificationEndDate?: string | null
  certificationOrganicNumber?: string | null
  certificationStartDate?: string | null
  civicNumber?: string | null
  zipCode?: string | null
  country?: string | null
  countryId?: number | null
  createdDate?: string | null
  createdBy?: string | null
  drainage?: string | null
  drainageClassification?: string | null
  drainageType?: string | null
  drainageWidth?: string | null
  equipment?: string | null
  externalId?: string | null
  externalClientId?: number | null
  farmId: number
  farmName: string
  fieldType?: string | null
  growerId: number
  growerName: string
  hillsideReservoirName?: string | null
  irrigable: boolean
  lastSoilAnalysisDate?: string | null
  location?: Location | null
  name: string
  notes?: string | null
  organic: boolean
  organicMatter?: number | null
  clayPercentage?: number | null
  pH: number
  region: string
  regionId: number
  roadName?: string | null
  size: number
  slopePercentage?: number | null
  soilTexture: string
  stonePressure: string
  surfaceWaterName?: string | null
  topography: string
  town?: string | null
  unit: string
  updatedBy?: string | null
  updatedDate?: string | null
  userId?: string | null
  waterSource?: string | null
  obstructions: Obstruction[]
  crops?: Crop[]

  constructor(field?: Omit<Field, 'updatedDateAsDate'>) {
    this.id = field?.id ?? null
    this.name = field?.name ?? null
    this.size = field?.size ?? null
    this.unit = field?.unit ?? null
    this.region = field?.region ?? null
    this.regionId = field?.regionId ?? null
    this.farmId = field?.farmId ?? null
    this.farmName = field?.farmName ?? null
    this.growerName = field?.growerName ?? null
    this.growerId = field?.growerId ?? null
    this.location = field?.location ?? null
    this.active = field?.active ?? null
    this.country = field?.country ?? null
    this.town = field?.town ?? null
    this.roadName = field?.roadName ?? null
    this.civicNumber = field?.civicNumber ?? null
    this.zipCode = field?.zipCode ?? null
    this.notes = field?.notes ?? null
    this.soilTexture = field?.soilTexture ?? null
    this.pH = field?.pH ?? null
    this.organicMatter = field?.organicMatter ?? null
    this.clayPercentage = field?.clayPercentage ?? null
    this.fieldType = field?.fieldType ?? null
    this.drainage = field?.drainage ?? null
    this.drainageType = field?.drainageType ?? null
    this.drainageClassification = field?.drainageClassification ?? null
    this.topography = field?.topography ?? null
    this.obstructions = field?.obstructions ?? null
    this.slopePercentage = field?.slopePercentage ?? null
    this.stonePressure = field?.stonePressure ?? null
    this.drainageWidth = field?.drainageWidth ?? null
    this.organic = field?.organic ?? null
    this.lastSoilAnalysisDate = field?.lastSoilAnalysisDate
      ? formatDateV2(new Date(field?.lastSoilAnalysisDate), DateFormatV2.DATETIME_OFFSET)
      : null
    this.irrigable = field?.irrigable ?? null
    this.equipment = field?.equipment ?? null
    this.boundaryId = field?.boundaryId ?? null
    this.boundary = field?.boundary ?? null
    this.userId = field?.userId ?? null
    this.updatedDate = field?.updatedDate ?? null
    this.externalId = field?.externalId ?? null
    this.externalClientId = field?.externalClientId ?? null
    this.waterSource = field?.waterSource ?? null
    this.surfaceWaterName = field?.surfaceWaterName ?? null
    this.hillsideReservoirName = field?.hillsideReservoirName ?? null
    this.certificationOrganicNumber = field?.certificationOrganicNumber ?? null
    this.certificationStartDate = field?.certificationStartDate
      ? formatDateV2(new Date(field?.certificationStartDate), DateFormatV2.SIMPLE)
      : null
    this.certificationEndDate = field?.certificationEndDate
      ? formatDateV2(new Date(field?.certificationEndDate), DateFormatV2.SIMPLE)
      : null
    if (field?.crops) {
      this.crops = field.crops
    }
    if (field?.countryId) {
      this.countryId = field.countryId
    }
  }

  get updatedDateAsDate(): Date {
    return ISO8601ToDate(this.updatedDate)
  }
}

export class FieldDAO extends ReportingDAO {
  id?: number | null
  regionId: number | null
  name: string | null
  size: number | null
  unit: string | null
  farmId: number | null
  growerId: number | null
  countryId: number | null
  locationId?: number | null
  active: number | null
  town?: string | null
  roadName?: string | null
  civicNumber?: string | null
  notes?: string | null
  soilTexture: string | null
  pH: number | null
  organicMatter?: number | null
  clayPercentage?: number | null
  fieldType?: string | null
  drainage?: string | null
  drainageType?: string | null
  drainageClassification?: string | null
  topography: string | null
  slopePercentage?: number | null
  stonePressure: string | null
  drainageWidth?: string | null
  organic: number | null
  lastSoilAnalysisDate?: string | null
  irrigable: number | null
  equipment?: string | null
  boundaryId?: number | null
  userId?: string | null
  externalId?: string | null
  externalClientId?: number | null
  waterSource?: string | null
  surfaceWaterName?: string | null
  hillsideReservoirName?: string | null
  certificationOrganicNumber?: string | null
  certificationStartDate?: string | null
  certificationEndDate?: string | null
  zipCode?: string | null

  constructor(fieldDao?: FieldDAO) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    super(fieldDao)
    if (fieldDao?.id) {
      this.id = fieldDao?.id
    }
    this.regionId = fieldDao?.regionId ?? null
    this.name = fieldDao?.name ?? null
    this.size = fieldDao?.size ?? null
    this.unit = fieldDao?.unit ?? null
    this.farmId = fieldDao?.farmId ?? null
    this.growerId = fieldDao?.growerId ?? null
    this.countryId = fieldDao?.countryId ?? null
    this.locationId = fieldDao?.locationId ?? null
    this.active = fieldDao?.active ?? 0
    this.town = fieldDao?.town ?? null
    this.zipCode = fieldDao?.zipCode ?? null
    this.roadName = fieldDao?.roadName ?? null
    this.civicNumber = fieldDao?.civicNumber ?? null
    this.notes = fieldDao?.notes ?? null
    this.soilTexture = fieldDao?.soilTexture ?? null
    this.pH = fieldDao?.pH ?? null
    this.organicMatter = fieldDao?.organicMatter ?? null
    this.clayPercentage = fieldDao?.clayPercentage ?? null
    this.fieldType = fieldDao?.fieldType ?? null
    this.drainage = fieldDao?.drainage ?? null
    this.drainageType = fieldDao?.drainageType ?? null
    this.drainageClassification = fieldDao?.drainageClassification ?? null
    this.topography = fieldDao?.topography ?? null
    this.slopePercentage = fieldDao?.slopePercentage ?? null
    this.stonePressure = fieldDao?.stonePressure ?? null
    this.drainageWidth = fieldDao?.drainageWidth ?? null
    this.organic = fieldDao?.organic ?? 0
    this.lastSoilAnalysisDate = fieldDao?.lastSoilAnalysisDate ?? null
    this.irrigable = fieldDao?.irrigable ?? 0
    this.equipment = fieldDao?.equipment ?? null
    this.boundaryId = fieldDao?.boundaryId ?? null
    this.userId = fieldDao?.userId ?? null
    this.externalId = fieldDao?.externalId ?? null
    this.externalClientId = fieldDao?.externalClientId ?? null
    this.waterSource = fieldDao?.waterSource ?? null
    this.surfaceWaterName = fieldDao?.surfaceWaterName ?? null
    this.hillsideReservoirName = fieldDao?.hillsideReservoirName ?? null
    this.certificationOrganicNumber = fieldDao?.certificationOrganicNumber ?? null
    this.certificationStartDate = fieldDao?.certificationStartDate ?? null
    this.certificationEndDate = fieldDao?.certificationEndDate ?? null
  }
}

export class FieldRequest {
  id: number | undefined
  name: string | undefined
  size: number | undefined
  unit: string | undefined
  regionId: number | undefined
  region: string | undefined
  active: number
  country?: string
  town?: string
  roadName?: string
  civicNumber?: string
  notes?: string
  soilTexture: string | undefined
  pH: number | undefined
  organicMatter?: number
  clayPercentage?: number
  fieldType?: string
  drainage?: string
  drainageType?: string
  drainageClassification?: string
  topography: string | undefined
  obsIds?: string
  obsNames?: string
  obsSeverities?: string
  obsLatitudes?: string
  obsLongitudes?: string
  obsPrecisions?: string
  obsLocationGeohashs?: string
  obsfeatureCollections?: string
  obsBoundaryGeohashs?: string
  slopePercentage?: number
  stonePressure: string | undefined
  drainageWidth?: string
  organic: number | undefined
  lastSoilAnalysisDate?: string
  irrigable: number | undefined
  equipment?: string
  userId?: string
  updatedDate?: string
  externalId?: string
  externalClientId?: number
  latitude: string | undefined
  longitude: string | undefined
  precision?: Precision
  geohash?: string
  farmName?: string
  farmId?: number
  growerName?: string
  growerId?: number
  boundaryId?: number
  boundary: string | undefined
  waterSource?: string
  surfaceWaterName?: string
  hillsideReservoirName?: string
  certificationOrganicNumber?: string
  certificationStartDate?: string
  certificationEndDate?: string
  zipCode?: string
}

export class FieldBoundaries {
  id: number
  name: string
  boundary: string
}

export class FieldBoundary {
  id: number
  name: string
  boundary?: FeatureCollection

  constructor(fieldBoundary?: FieldBoundary) {
    this.id = fieldBoundary?.id
    this.name = fieldBoundary?.name ?? ''
    this.boundary = fieldBoundary?.boundary ?? null
  }
}
