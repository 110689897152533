import { FileService } from '@agroone-app/core/file/file.service'
import { ShapeFiles } from '@agroone-app/core/file/models/shapefiles.interface'
import { environment } from '@front-app-environments/environment'
import { GeolocationHttpService } from '@agroone-app/scene/crop-management/geolocation/services/geolocation-http.service'
import { DialogService } from '@agroone-app/shared/dialog/services/dialog.service'
import { RegionService } from '@agroone-app/shared/region/services/region.service'
import {
  BoundaryOfInterest,
  LoggerService,
  PointOfInterest,
  SharedConstantService,
  SharedUserService,
} from '@agroone-front/shared'
import { BBox, FeatureCollection, Length, Location, SaveFeatureCollection, ShapefileType } from '@agroone/entities'
import { getGeohashPrecision } from '@agroone/helpers'
import { HttpClient } from '@angular/common/http'
import { ElementRef, Injectable } from '@angular/core'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import GeoService from '@mapbox/mapbox-sdk/services/geocoding'
import { TranslateService } from '@ngx-translate/core'
import { AllGeoJSON, area, bbox, buffer, featureCollection, point } from '@turf/turf'
import configureMeasurements, { allMeasures } from 'convert-units'
import * as mapboxgl from 'mapbox-gl'
import { bboxes } from 'ngeohash'
import { BehaviorSubject, debounceTime, finalize, firstValueFrom, map, Observable, of, take, tap } from 'rxjs'
import { read } from 'shapefile'
import { Colors } from '../models/colors.enum'
import { MAP_STYLES } from '../models/mapStyles.const'

const convert = configureMeasurements(allMeasures)

const toGeoJSON = require('@tmcw/togeojson')

export class MapBoxToken {
  token: string
  expires: string
}

export class MapBoxOptions {
  color?: Colors
  drawable?: boolean
  geolocate?: boolean
  draggable?: boolean
  stencil?: FeatureCollection | SaveFeatureCollection
}

export class ClickedObject {
  id: string | number
  objectType: string

  constructor(gPoint?: GeoJSON.Feature<any>) {
    this.id = gPoint?.properties.id
  }
}

export class AreaPopup {
  id: string
  popup: mapboxgl.Popup
}

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public viewportChanges: Observable<{ viewport: BBox; zoom: number }>
  public mapInitiated: BehaviorSubject<boolean> = new BehaviorSubject(false)

  private temporaryToken: MapBoxToken = null
  private satelliteMap: string = 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true'
  private map: mapboxgl.Map
  private containerId: string
  private containerWidth: number
  private draw: MapboxDraw
  private draggable: boolean = false
  private defaultMarker: mapboxgl.Marker
  private areaPopups: AreaPopup[] = []

  private manualLocation: Location = new Location({
    longitude: '0',
    latitude: '0',
  })

  private viewportSubject: BehaviorSubject<{ viewport: BBox; zoom: number }> = new BehaviorSubject(null)

  get tokenHasExpired(): boolean {
    return this.temporaryToken.expires < new Date(new Date().setHours(new Date().getHours() + 1)).toISOString()
  }

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private userService: SharedUserService,
    private regionService: RegionService,
    private constantService: SharedConstantService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private geolocationHttpService: GeolocationHttpService
  ) {
    this.viewportChanges = this.viewportSubject.asObservable()
  }

  public async initMap(
    containerId: string,
    container: ElementRef,
    location?: Location,
    boundary?: FeatureCollection | SaveFeatureCollection,
    options?: MapBoxOptions
  ): Promise<void> {
    this.containerId = containerId
    this.containerWidth = container?.nativeElement?.offsetWidth ?? 360 // default map width on a standard mobile device

    this.draggable = options.draggable ?? false

    const center: { latitude: number; longitude: number } = location
      ? this.mapToCoordinate(location)
      : { latitude: 0, longitude: 0 }
    if (!this.temporaryToken || this.tokenHasExpired) {
      await this.retrieveToken()
    }
    await this.drawMap(center, boundary, options)
    this.initWatch()
    // !!! DO NOT REMOVE this message. It's caught by the automated Playwright tests in order to know when mapbox has been initialised
    this.logger.debug('Map - init map done')
  }

  public async initMapByAddress(
    containerId: string,
    container: ElementRef,
    address: string,
    options?: MapBoxOptions
  ): Promise<any> {
    this.containerId = containerId
    this.containerWidth = container?.nativeElement?.offsetWidth ?? 360 // default map width on a standard mobile device

    if (!this.temporaryToken || this.tokenHasExpired) {
      await this.retrieveToken()
    }
    await this.getFeature(address, options)
    this.initWatch()
  }

  private milesToKm(miles: number): number {
    return miles / 0.62137119223
  }

  public mapInitListener(): Observable<boolean> {
    if (this.mapInitiated.isStopped) {
      of(true)
    } else {
      return this.mapInitiated.asObservable()
    }
  }

  public resetMap() {
    if (this.draw) {
      this.map.removeControl(this.draw)
    }
  }

  public updateMarkers(name: string, style: string, pointsOfInterest: PointOfInterest[]) {
    const geoJsonData = this.mapToGeoJsonData(style, pointsOfInterest)
    const source: mapboxgl.GeoJSONSource = this.map?.getSource(name) as mapboxgl.GeoJSONSource
    if (!source) {
      return
    }
    source.setData({
      type: 'FeatureCollection',
      features: geoJsonData as any,
    })
  }

  public createMarkers(name: string, style: string, pointsOfInterest?: PointOfInterest[]) {
    let geoJsonData: any[] = []
    if (pointsOfInterest) {
      geoJsonData = this.mapToGeoJsonData(style, pointsOfInterest)
    }
    this.map?.addSource(name, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: geoJsonData as any,
      },
    })
    this.map?.addLayer({
      id: name,
      type: 'circle',
      source: name,
      paint: {
        // make circles larger as the user zooms from z12 to z22
        'circle-radius': {
          base: 4,
          stops: [
            [12, 6],
            [22, 9],
          ],
        },
        'circle-color': 'white',
        'circle-stroke-color': style,
        'circle-stroke-width': {
          base: 3,
          stops: [
            [12, 5],
            [22, 8],
          ],
        },
      },
    })
  }

  public featureClicks(layers: string[]): Observable<ClickedObject> {
    let callback: (e: any) => void
    const obs: Observable<ClickedObject> = new Observable((subscriber) => {
      callback = (e) => {
        const features: GeoJSON.Feature[] = this.map.queryRenderedFeatures(e.point, {
          layers,
        })
        if (features?.length > 0) {
          this.logger.debug(
            'Point clicked: id= ' + features[0].properties.id + ', coordinates: ' + JSON.stringify(features[0].geometry)
          )

          const clickedObject: ClickedObject = new ClickedObject(features[0])
          subscriber.next(clickedObject)
        }
      }
      this.map.on('touchend', callback)
      this.map.on('click', callback)
    })

    return obs.pipe(
      finalize(() => {
        // Remove the listeners when the observable is ended
        this.map.off('touchend', callback)
        this.map.off('click', callback)
      })
    )
  }

  public featureChanges(...layers: string[]): Observable<{ features: GeoJSON.Feature[]; zoom: number }> {
    let render: () => void

    const obs: Observable<{
      features: GeoJSON.Feature[]
      zoom: number
    }> = new Observable((subscriber) => {
      render = () => {
        subscriber.next()
      }
      this.map.on('render', render)
    })
    return obs.pipe(
      // Throttle the render event to have at most 1 event every 50ms
      debounceTime(50),
      map(() => {
        const features: GeoJSON.Feature[] = this.map.queryRenderedFeatures(null, { layers })
        return { features, zoom: this.map.getZoom() }
      }),
      finalize(() => {
        // Remove the listeners when the observable is ended
        this.map.off('render', render)
      })
    )
  }

  public getActiveFeaturesForLayers(layers: string[]) {
    return this.map.queryRenderedFeatures(null, { layers })
  }

  public getManualLocation() {
    return this.manualLocation
  }

  public getBoundary() {
    if (this.draw?.getAll()?.features?.length) {
      const boundary: GeoJSON.FeatureCollection = this.draw.getAll()
      if (boundary) {
        // Calculate the bounding box
        const turfBbox = bbox(boundary as AllGeoJSON)
        boundary.bbox = turfBbox
      }
      return boundary
    }

    return null
  }

  public updateBoundaries(name: string, style: string, boundaries?: BoundaryOfInterest[]) {
    const geoJsonData: any = this.BoundariesMapToFeatureList(boundaries)
    const source: mapboxgl.GeoJSONSource = this.map?.getSource(name) as mapboxgl.GeoJSONSource
    if (!source) {
      return
    }
    source.setData({
      type: 'FeatureCollection',
      features: geoJsonData as any,
    })
  }

  public createBoundaries(
    name: string,
    boundary?: {
      [style: string]: { boundaries: BoundaryOfInterest[] }
    },
    boundaryOfInterest?: {
      style: string
      boundaries: BoundaryOfInterest[]
    },
    disabled: boolean = false
  ) {
    if (boundaryOfInterest) {
      const geoJsonData: any = this.BoundariesMapToFeatureList(boundaryOfInterest.boundaries)
      this.map?.addSource(name, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: geoJsonData,
        },
      })
      this.map?.addLayer({
        id: name,
        type: 'fill',
        source: name,
        paint: {
          'fill-color': boundaryOfInterest.style,
          'fill-opacity': disabled ? 0.8 : 0.5,
          'fill-outline-color': '#000000',
        },
      })
    } else if (boundary) {
      for (const [style, b] of Object.entries(boundary)) {
        const geoJsonData = this.BoundariesMapToFeatureList(b.boundaries)
        this.map?.addSource(name + style, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: geoJsonData,
          },
        })
        this.map?.addLayer({
          id: name + style,
          type: 'fill',
          source: name + style,
          paint: {
            'fill-color': style,
            'fill-opacity': 0.7,
            'fill-outline-color': '#000000',
          },
        })
      }
    }
  }
  async createEditableBoundaries(boundary?: FeatureCollection | SaveFeatureCollection): Promise<void> {
    if (!this.draw) {
      this.map.addControl(new mapboxgl.NavigationControl())
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      })

      this.map.addControl(this.draw)
    }

    // Ensure map and draw control are fully loaded before adding features
    return new Promise((resolve) => {
      if (this.draw && boundary?.features?.length) {
        this.draw.add(boundary)
        this.addAreaPopup(boundary.features)
      }

      // Set up event listeners for area management
      this.map.on('draw.create', (event) => {
        this.manageAreaPopup(event)
      })
      this.map.on('draw.update', (event) => {
        this.manageAreaPopup(event)
      })
      this.map.on('draw.delete', (event) => {
        this.manageAreaPopup(event)
      })

      resolve()
    })
  }

  public drawDefaultMarker(latitude: number, longitude: number, options?: MapBoxOptions) {
    if (!this.map) {
      return
    }
    if (this.defaultMarker) {
      this.defaultMarker.remove()
    }
    if (this.draggable && !options?.drawable) {
      this.defaultMarker = new mapboxgl.Marker({
        draggable: true,
      })

      this.defaultMarker.on('dragend', () => this.getMarkerPosition(this.defaultMarker))
    } else {
      this.defaultMarker = new mapboxgl.Marker()
    }

    this.defaultMarker.setLngLat([longitude, latitude]).addTo(this.map)
  }

  /**
  /**
   * Extracts a single consolidated feature collection from
   * a list of KML or Shapefile.
   */
  public async extract(...files: (File | ShapeFiles)[]): Promise<GeoJSON.FeatureCollection> {
    const featureCollections: GeoJSON.FeatureCollection[] = []

    for (const file of files) {
      if (file instanceof File) {
        try {
          featureCollections.push(await this.kmlToFeatures(file))
        } catch (err) {
          throw new Error(`Cannot read kml file - ${err?.message}`)
        }
      } else {
        try {
          featureCollections.push(await read((file.shp as any).stream?.(), (file.dbf as any)?.stream?.()))
        } catch (err) {
          throw new Error(`Cannot read shapefile - ${err?.message}`)
        }
      }
    }

    // Merge all the feature collections into a single one. Recalculates the bounding box.
    const collection = this.mergeFeatures(featureCollections)
    return collection
  }

  public async kmlToFeatures(kmlFile: File): Promise<GeoJSON.FeatureCollection> {
    const parser: DOMParser = new DOMParser()
    const xmlDoc: XMLDocument = parser.parseFromString(await (kmlFile as any).text(), 'text/xml')
    return toGeoJSON.kml(xmlDoc)
  }

  /**
   * Merge a list of FeatureCollection into a single FeatureCollection.
   * Once merged, the bounding box is recalculated
   */
  public mergeFeatures(featuresList: GeoJSON.FeatureCollection[]): GeoJSON.FeatureCollection {
    const features = featuresList.reduce((feats, fl: GeoJSON.FeatureCollection) => {
      feats = feats.concat(fl.features)
      return feats
    }, [])
    const boundingBox = this.getBoundingBox(features)
    return featureCollection(features, {
      boundingBox,
    } as any) as GeoJSON.FeatureCollection
  }

  /**
   * See https://docs.mapbox.com/help/troubleshooting/address-geocoding-format-guide/
   * For the best possible way to format the address
   * @param address
   * @returns
   */
  public async forwardGeocode(address: string) {
    if (!this.temporaryToken || this.tokenHasExpired) {
      await this.retrieveToken()
    }
    const mapboxClient = GeoService({ accessToken: this.temporaryToken.token })
    const response = await mapboxClient
      .forwardGeocode({
        query: address,
        autocomplete: false,
        limit: 1,
      })
      .send()
    // Take the first result
    return response?.body?.features?.[0]
  }

  public async createGeohashes(location?: Location, boundary?: FeatureCollection, address?: string): Promise<string[]> {
    let bBox = null
    if (location) {
      const turfPoint = point([Number(location.longitude), Number(location.latitude)])
      const buffered = buffer(turfPoint, 500, { units: 'meters' })
      bBox = bbox(buffered)
    } else if (boundary?.bbox) {
      bBox = boundary.bbox
    } else if (address) {
      bBox = (await this.forwardGeocode(address))?.bbox
    } else return null

    // Precision should be between 5 and 6 for 500 meters
    return bboxes(bBox[1], bBox[0], bBox[3], bBox[2], getGeohashPrecision(bBox))
  }

  private initWatch() {
    const callback = () => {
      const bounds: mapboxgl.LngLatBounds = this.map.getBounds()
      const viewport: BBox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()] as BBox

      this.viewportSubject.next({ viewport, zoom: this.map.getZoom() })
    }

    this.map.on('boxzoomend', callback)
    this.map.on('moveend', callback)
    this.map.on('pitchend', callback)
    this.map.on('resize', callback)
    this.map.on('rotateend', callback)
    this.map.on('zoomend', callback)
    callback()
  }

  private async getFeature(address: string, options?: MapBoxOptions) {
    try {
      const feature = await this.forwardGeocode(address)

      this.draggable = true

      return this.drawMap(
        {
          longitude: feature?.center?.[0],
          latitude: feature?.center?.[1],
        },
        null,
        options
      )
    } catch (error) {
      return this.drawMap(
        {
          longitude: 0,
          latitude: 0,
        },
        null,
        options
      )
    }
  }

  private getZoomLevelFromRadiusAndSize(): number {
    const radius =
      this.userService.currentUser.geolocationRadius === 0 ? 5 : this.userService.currentUser.geolocationRadius
    const radiusUnit = this.userService.currentUser.geolocationRadiusUnit ?? Length.KM

    const radiusKm = radiusUnit === Length.KM ? radius : this.milesToKm(radius)
    const radiusMeters = radiusKm * 1000
    const scaleValue = (radiusMeters * 2) / this.containerWidth

    // https://docs.mapbox.com/help/glossary/zoom-level/
    const zoomLevels = [
      { level: 22, size: 0.019 },
      { level: 21, size: 0.037 },
      { level: 20, size: 0.075 },
      { level: 19, size: 0.149 },
      { level: 18, size: 0.299 },
      { level: 17, size: 0.597 },
      { level: 16, size: 1.194 },
      { level: 15, size: 2.389 },
      { level: 14, size: 4.777 },
      { level: 13, size: 9.555 },
      { level: 12, size: 19.109 },
      { level: 11, size: 38.218 },
      { level: 10, size: 76.437 },
      { level: 9, size: 152.874 },
      { level: 8, size: 305.748 },
      { level: 7, size: 611.496 },
      { level: 6, size: 1222.992 },
      { level: 5, size: 2445.984 },
      { level: 4, size: 4891.968 },
      { level: 3, size: 9783.936 },
      { level: 2, size: 19567.871 },
      { level: 1, size: 39135.742 },
      { level: 0, size: 78271.484 },
    ]
    return zoomLevels.find((z) => z.size >= scaleValue).level
  }

  private mapToCoordinate(location: Location) {
    return {
      latitude: +location.latitude,
      longitude: +location.longitude,
    }
  }

  private drawMap(
    center: {
      latitude: number
      longitude: number
    },
    boundary?: FeatureCollection | SaveFeatureCollection,
    options?: MapBoxOptions
  ): Promise<void> {
    this.manualLocation.longitude = center?.longitude?.toString()
    this.manualLocation.latitude = center?.latitude?.toString()
    const bounds = boundary?.bbox as mapboxgl.LngLatBoundsLike

    this.map = new mapboxgl.Map({
      accessToken: this.temporaryToken.token,
      container: this.containerId,
      zoom: this.getZoomLevelFromRadiusAndSize(),
      center: [center.longitude, center.latitude],
      bounds,
      style: this.satelliteMap,
    })

    // Add zoom and rotation controls to the map.
    this.map.addControl(new mapboxgl.NavigationControl())
    // Display scale bar
    this.map.addControl(new mapboxgl.ScaleControl())

    this.map.on('styledata', () => {
      this.mapInitiated.next(true)
      this.mapInitiated.complete()
    })

    if (options.geolocate) {
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        })
      )
    }

    if (options.drawable) {
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
        },
      })
      this.map.addControl(this.draw)
      this.map.on('draw.create', (event) => {
        this.manageAreaPopup(event)
      })
      this.map.on('draw.update', (event) => {
        this.manageAreaPopup(event)
      })
      this.map.on('draw.delete', (event) => {
        this.manageAreaPopup(event)
      })
    } else {
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
      })
      this.map.addControl(this.draw)
    }

    if (!boundary) {
      this.drawDefaultMarker(center.latitude, center.longitude, options)
    }

    return new Promise((resolve, reject) => {
      this.map.on('load', () => {
        if (this.draw && boundary?.features?.length) {
          this.draw.add(boundary)
          this.addAreaPopup(boundary.features)
        }

        if (options.stencil) {
          // Find the first boundary layers in order to apply kind of z-index to the stencil layer
          const firstExistingBoundaryLayer = this.map
            ?.getStyle()
            ?.layers?.map((layer) => layer.id)
            ?.filter((id) => id.startsWith('boundaries'))
            ?.sort((a, b) => a.localeCompare(b))?.[0]
          const name = 'stencil'
          this.map?.addSource(name, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: options.stencil.features as any,
            },
          })
          this.map?.addLayer(
            {
              id: name,
              type: 'fill',
              source: name,
              paint: {
                'fill-color': MAP_STYLES.STENCIL.color,
                'fill-opacity': MAP_STYLES.STENCIL.opacity,
              },
            },
            firstExistingBoundaryLayer
          )
        }

        resolve()
      })

      this.map.on('error', (error) => {
        reject(error)
      })
    })
  }

  private async retrieveToken(): Promise<MapBoxToken> {
    return firstValueFrom(
      this.http.get<MapBoxToken>(`${environment.apiUrl}${environment.geolocation}/token`).pipe(
        tap((result: MapBoxToken) => {
          this.temporaryToken = result
        })
      )
    )
  }

  private getMarkerPosition(marker: mapboxgl.Marker) {
    const lngLat: mapboxgl.LngLat = marker.getLngLat()
    this.manualLocation.longitude = lngLat?.lng?.toString()
    this.manualLocation.latitude = lngLat?.lat?.toString()
  }

  private mapToGeoJsonData(style: string, pointsOfInterest: PointOfInterest[]) {
    const geoJsonDatas = []
    for (const p of pointsOfInterest) {
      if (p.location) {
        const geoJson = {
          type: 'Feature',
          properties: { 'marker-color': style, id: p.id },
          geometry: {
            type: 'Point',
            coordinates: [+p.location.longitude, +p.location.latitude],
          },
        }
        geoJsonDatas.push(geoJson)
      }
    }
    return geoJsonDatas
  }

  private BoundariesMapToFeatureList(boundariesOfInterest: BoundaryOfInterest[]): GeoJSON.Feature[] {
    const featureList = []
    for (const boundaryOfInterest of boundariesOfInterest) {
      for (const feature of boundaryOfInterest?.boundary?.features ?? []) {
        featureList.push({
          ...feature,
          properties: { id: boundaryOfInterest.id },
        })
      }
    }

    return featureList
  }

  private getBoundingBox(features: GeoJSON.Feature<GeoJSON.Polygon>[]) {
    if (!features?.length) {
      return undefined
    }

    const bBox = bbox({
      type: 'FeatureCollection',
      features,
    })
    return bBox
  }

  private manageAreaPopup(event: any) {
    if (event.type === 'draw.delete') {
      const message: string = this.translate.instant('DIALOG.FIELD.BOUNDARY_DELETE.MESSAGE')
      const dialogRef = this.dialogService.initConfirm(message, '')
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((confirmed) => {
          if (confirmed) {
            const popupToDelete: mapboxgl.Popup = this.areaPopups.find((p) => p.id === event.features?.[0]?.id)?.popup
            if (popupToDelete) {
              popupToDelete.remove()
            }
          } else {
            this.draw.add(event.features[0])
          }
        })
    } else {
      if (event?.features?.length) {
        this.addAreaPopup(event.features)
      } else {
        // Delete all areaPopups
        for (const areaPopup of this.areaPopups) {
          areaPopup.popup.remove()
        }
      }
    }
  }

  private addAreaPopup(features) {
    const polygon = features[0].geometry as GeoJSON.Polygon
    const position = polygon?.coordinates[0]
    const turfArea: number = area(features[0])
    const polygonId: string = features[0].id

    // default turf's area unit is square meters
    // retrieve user's region's defaultAreaUnit and convert
    const defaultAreaUnit = this.regionService.currentActiveRegion?.defaultAreaUnit
    let convertedArea = null
    if (defaultAreaUnit) {
      convertedArea = convert(turfArea)
        .from('m2')
        .to(defaultAreaUnit as any)
    }

    // restrict to area to 2 decimal points
    const roundedArea: number = Math.round((convertedArea || turfArea) * 100) / 100

    // Remove exisiting popup with same id
    const popupToDelete: mapboxgl.Popup = this.areaPopups.find((p) => p.id === polygonId)?.popup
    if (popupToDelete) {
      popupToDelete.remove()
    }

    this.areaPopups.push({
      id: polygonId,
      popup: new mapboxgl.Popup({
        closeOnClick: false,
      })
        .setLngLat([position[0][0], position[0][1]])
        .setHTML(
          `${roundedArea.toString()} ${
            this.constantService.areaUnit.find((constant) => constant.code === defaultAreaUnit)?.translation
          }`
        )
        .addTo(this.map),
    })
  }

  public exportBoundariesShapefile(type: ShapefileType, ids: number[], boundaryIds?: number[]) {
    return this.geolocationHttpService.getShapefile(type, ids, boundaryIds).pipe(
      tap((data) => {
        this._downloadFile(data, `Geolocation_export_${type}_${new Date().toLocaleString()}.zip`)
      })
    )
  }

  private _downloadFile(data: string, fileName: string) {
    if (data) {
      const file = FileService.base64ToZip(data, fileName)
      // Insert a link that allows the user to download the PDF file
      const linkDownload: HTMLAnchorElement = window.document.createElement('a')
      const url: string = window.URL.createObjectURL(file)
      linkDownload.href = url
      linkDownload.download = fileName
      linkDownload.click()
    }
  }
}
