import { Component, OnInit } from '@angular/core'
import { AuthService } from '../services'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  template: '<mat-spinner/>',
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.redirectToLogin()
  }
}
