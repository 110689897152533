import { plainToInstance } from 'class-transformer'
import { RequiredOrProperty } from '@agroone/helpers'

export class SaveReporting {
  public static build(raw: any) {
    return plainToInstance(SaveReporting, raw)
  }

  @RequiredOrProperty('updatedBy')
  createdBy?: string

  @RequiredOrProperty('createdBy')
  updatedBy?: string

  @RequiredOrProperty('updatedDate')
  createdDate?: string

  @RequiredOrProperty('createdDate')
  updatedDate?: string
}

export class SaveReportingV2 {
  public static build(raw: any) {
    return plainToInstance(SaveReporting, raw)
  }

  @RequiredOrProperty('updatedBy')
  createdBy?: string

  @RequiredOrProperty('createdBy')
  updatedBy?: string
}
