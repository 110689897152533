import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
  selector: '[aoKeyboard]',
})
export class KeyboardDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.el.nativeElement.blur()
    }
  }
}
