import { ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'

@ValidatorConstraint({ name: 'isDecimalNumber', async: false })
export class IsDecimalNumber implements ValidatorConstraintInterface {
  /**
   * @param {ValidationArguments} args
   * As first arg in constraints[0] we must have how many digits are authorized
   * @returns
   * @memberof IsDecimalNumber
   */
  validate(numberValue: number, args: ValidationArguments) {
    const digitsNumber: number = args.constraints[0] ?? 2
    const exp = digitsNumber ? `^\\d*(\\.\\d{0,${digitsNumber}})?$` : `^\\d*$`
    const regex = new RegExp(exp)
    const numberValueStr = String(numberValue)
    return regex.test(numberValueStr)
  }

  defaultMessage(args: ValidationArguments) {
    // here you can provide default error message if validation failed
    return `Number ${args.property} has too many decimals. Maximum ${args.constraints[0]}`
  }
}
