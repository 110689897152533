import * as turf from '@turf/turf'
import { Feature, FeatureCollection, Point } from '@turf/turf'

type Location = {
  latitude: string
  longitude: string
}

type BBox = [number, number, number, number] | [number, number, number, number, number, number]

function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}

export function getDistanceInKm(pointA: Location, pointB: Location) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(Number(pointB.latitude) - Number(pointA.latitude))
  const dLon = deg2rad(Number(pointB.longitude) - Number(pointA.longitude))
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(Number(pointA.latitude))) *
      Math.cos(deg2rad(Number(pointB.latitude))) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

/*
 * The size of the grid for geohashing given a precision goes as follows :
 * 1	≤ 5,000km	×	5,000km
 * 2	≤ 1,250km	×	625km
 * 3	≤ 156km	×	156km
 * 4	≤ 39.1km	×	19.5km
 * 5	≤ 4.89km	×	4.89km
 * 6	≤ 1.22km	×	0.61km
 * 7	≤ 153m	×	153m
 * 8	≤ 38.2m	×	19.1m
 * 9	≤ 4.77m	×	4.77m
 * 10	≤ 1.19m	×	0.596m
 * 11	≤ 149mm	×	149mm
 * 12	≤ 37.2mm	×	18.6mm
 *
 * With this configuration we divide the research areas in
 * approximately 4 to 20 geo hashes which seems to be a good
 * compromise between size and speed.
 */
export function getGeohashPrecision(bbox: BBox): number {
  const diagonal = getDistanceInKm(
    {
      latitude: String(bbox[1]),
      longitude: String(bbox[0]),
    },
    {
      latitude: String(bbox[3]),
      longitude: String(bbox[2]),
    }
  )

  if (diagonal < 0.00015) {
    return 12
  } else if (diagonal < 0.0006) {
    return 11
  } else if (diagonal < 0.005) {
    return 10
  } else if (diagonal < 0.02) {
    return 9
  } else if (diagonal < 0.15) {
    return 8
  } else if (diagonal < 0.6) {
    return 7
  } else if (diagonal < 2) {
    return 6
  } else if (diagonal < 10) {
    return 5
  } else if (diagonal < 156) {
    return 4
  } else if (diagonal < 625) {
    return 3
  } else if (diagonal < 5000) {
    return 2
  } else {
    return 1
  }
}

export function getCentroid(boundary: FeatureCollection | any): Location {
  const bounds = boundary?.bbox as turf.BBox
  const polygon = turf.bboxPolygon(bounds)
  const center: Feature<Point> = turf.centroid(polygon)

  return {
    longitude: center?.geometry?.coordinates?.[0]?.toString(),
    latitude: center?.geometry?.coordinates?.[1]?.toString(),
  }
}

export function isInsideBBox(point: Location, bbox: BBox): boolean {
  return (
    bbox[0] <= Number(point.longitude) &&
    Number(point.longitude) <= bbox[2] &&
    bbox[1] <= Number(point.latitude) &&
    Number(point.latitude) <= bbox[3]
  )
}
