import 'reflect-metadata'

import { plainToInstance, Type } from 'class-transformer'
import { IsString, IsOptional, IsNotEmpty, ValidateNested, IsNumber } from 'class-validator'
import { SaveLocation } from './save-location'

export class SaveFactory {
  public static build(raw: any) {
    return plainToInstance(SaveFactory, raw)
  }

  @IsNumber()
  @IsOptional()
  id?: number

  @IsString()
  @IsNotEmpty()
  name: string | undefined

  @IsString()
  @IsOptional()
  quality?: string

  @IsNumber()
  @IsNotEmpty()
  regionId: number | undefined

  @IsString()
  @IsOptional()
  color?: string

  @IsOptional()
  @ValidateNested()
  @Type(() => SaveLocation)
  location?: SaveLocation

  @IsString()
  @IsNotEmpty()
  region: string | undefined
}
