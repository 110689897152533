import { Crop } from './crop'
import { Intervention } from './intervention'
import { Recommendation } from './recommendation'
import { DashboardSample } from './sample'
import { Scouting, ScoutingCrop } from './scouting'

export class CropActivityReport {
  crop: Crop | ScoutingCrop
  scoutings: Scouting[]
  recommendations: Recommendation[]
  interventions: Intervention[]
  samples: DashboardSample[]

  constructor(report?: CropActivityReport) {
    this.crop = report?.crop
    this.scoutings = report?.scoutings?.length ? report.scoutings.map((v) => new Scouting(v)) : []
    this.recommendations = report?.recommendations?.length
      ? report.recommendations.map((v) => new Recommendation(v))
      : []
    this.interventions = report?.interventions?.length ? report.interventions.map((v) => new Intervention(v)) : []
    this.samples = report?.samples?.length ? report.samples.map((v) => new DashboardSample(v)) : []
  }
}
