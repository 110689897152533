import { createAction, props } from '@ngrx/store'
import { ActionCreator, TypedAction } from '@ngrx/store/src/models'

import { CropDetailActivities } from '../reducers/crop-detail.reducer'

export enum CropDetailActionTypes {
  ADD_CROP_DETAIL = '[CropDetail] Add Crop Detail',
  CLEAR_CROP_DETAIL = '[Crop] Clear Crop Detail',
}

export const addCropDetail: ActionCreator<
  CropDetailActionTypes.ADD_CROP_DETAIL,
  (props: { crop: CropDetailActivities }) => {
    crop: CropDetailActivities
  } & TypedAction<CropDetailActionTypes.ADD_CROP_DETAIL>
> = createAction(CropDetailActionTypes.ADD_CROP_DETAIL, props<{ crop: CropDetailActivities }>())

export const clearCropDetail: ActionCreator<
  CropDetailActionTypes.CLEAR_CROP_DETAIL,
  () => TypedAction<CropDetailActionTypes.CLEAR_CROP_DETAIL>
> = createAction(CropDetailActionTypes.CLEAR_CROP_DETAIL)
