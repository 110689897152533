<div class="crop-filters">
  <mat-dialog-content class="crop-filters-content">
    <form [formGroup]="form">
      <!-- CropType -->
      <ng-container *ngIf="!data?.filters?.cropType?.disabled">
        <mat-label>{{ 'CROP.FILTERS.CROP_TYPE' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="cropTypes"
          [formCtrl]="cropType"
          optional="true"
          data-testid="crops-filter-cropType"
        >
        </app-autocomplete-select>
      </ng-container>

      <!-- GrowerName -->
      <ng-container *ngIf="!data?.filters?.growerName?.disabled">
        <mat-label>{{ 'CROP.FILTERS.GROWER_NAME' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="growers"
          [formCtrl]="growerName"
          [displayFn]="display"
          [filterFn]="filter"
          optional="true"
          data-testid="crops-filter-growerName"
        >
        </app-autocomplete-select>
      </ng-container>

      <!-- Technicians -->
      <ng-container *ngIf="!data?.filters?.technicianName.disabled">
        <mat-label>{{ 'CROP.FILTERS.TECHNICIANS' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="technicians"
          [formCtrl]="technicianName"
          [displayFn]="display"
          [filterFn]="filter"
          [optional]="true"
          data-testid="crops-filter-technicianName"
        ></app-autocomplete-select>
      </ng-container>

      <!-- FieldName -->
      <ng-container *ngIf="!data?.filters?.fieldName?.disabled">
        <mat-label>{{ 'CROP.FILTERS.FIELD_NAME' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="fields"
          [formCtrl]="fieldName"
          [displayFn]="display"
          [filterFn]="filter"
          [optional]="true"
          data-testid="crops-filter-fieldName"
        >
        </app-autocomplete-select>
      </ng-container>

      <!-- CropName -->
      <ng-container *ngIf="!data?.filters?.cropName?.disabled">
        <mat-label>{{ 'CROP.FILTERS.CROP_NAME' | translate }}</mat-label>
        <div class="crop-filters-cropName">
          <input data-testid="crops-filter-cropName" matInput formControlName="cropName" type="text" aoKeyboard />
          <div class="cross-container">
            <mat-icon (click)="resetCropname()" svgIcon="delete-cross"></mat-icon>
          </div>
        </div>
      </ng-container>

      <!-- Variety -->
      <ng-container *ngIf="!data?.filters?.varietyName?.disabled">
        <mat-label>{{ 'CROP.FILTERS.VARIETY' | translate }}</mat-label>
        <app-autocomplete-select
          [list]="varieties"
          [formCtrl]="varietyName"
          [displayFn]="display"
          [filterFn]="filter"
          optional="true"
          data-testid="crops-filter-varietyName"
        >
        </app-autocomplete-select>
      </ng-container>

      <!-- Date Range -->
      <div class="crop-filters-daterange" *ngIf="!data?.filters?.dateRange?.disabled">
        <div class="crop-filters-daterange-container">
          <mat-label>{{ 'CROP.FILTERS.DATE' | translate }}</mat-label>
          <div>
            <app-datetimepicker
              icon="calendar_harvest"
              [formElement]="scoutingDateElement"
              data-testid="crops-filter-plantedDate"
              [maxDateAllowed]="maxPlantedDate"
              [isDateRange]="true"
              [control]="plantedDateRange"
              [cancel]="true"
            >
            </app-datetimepicker>
          </div>
        </div>
      </div>

      <!-- Has Flowering -->
      <div *ngIf="!data?.filters?.doesNotHasFloweringScoutingId?.disabled" class="crop-filters-checkbox">
        <mat-slide-toggle
          formControlName="floweringScoutingId"
          data-testid="crops-filter-hasFlowering"
          [checked]="floweringScoutingId.value"
        >
          {{ 'CROP.FILTERS.NO_FLOWERING' | translate }}
        </mat-slide-toggle>
      </div>

      <!-- Has Growth Stage -->
      <div *ngIf="!data?.filters?.doesNotHasGrowthStageScoutingId?.disabled" class="crop-filters-checkbox">
        <mat-slide-toggle
          formControlName="growthStageScoutingId"
          data-testid="crops-filter-hasGrowthStage"
          [checked]="growthStageScoutingId.value"
        >
          {{ 'CROP.FILTERS.NO_GROWTH_STAGE' | translate }}
        </mat-slide-toggle>
      </div>

      <!-- Has Yield Estimation -->
      <div *ngIf="!data?.filters?.doesNotHasYieldEstimationScoutingId?.disabled" class="crop-filters-checkbox">
        <mat-slide-toggle
          formControlName="yieldEstimationScoutingId"
          data-testid="crops-filter-hasYield"
          [checked]="yieldEstimationScoutingId.value"
        >
          {{ 'CROP.FILTERS.NO_YIELD_ESTIMATION' | translate }}
        </mat-slide-toggle>
      </div>

      <!-- Has Organic Field -->
      <div *ngIf="!data?.filters?.isOrganic?.disabled" class="crop-filters-checkbox">
        <mat-slide-toggle
          formControlName="hasOrganicField"
          data-testid="crops-filter-hasOrganic"
          [checked]="hasOrganicField.value"
        >
          {{ 'CROP.FILTERS.HAS_ORGANIC_FIELD' | translate }}
        </mat-slide-toggle>
      </div>

      <!-- Scouting Date -->
      <div class="crop-filters-date" *ngIf="!data?.filters?.lastScoutingDate?.disabled">
        <mat-label>{{ 'CROP.FILTERS.LATEST_SCOUTING' | translate }}</mat-label>
        <div class="crop-filters-date-container">
          <app-datetimepicker
            icon="calendar_harvest"
            [formElement]="scoutingDateElement"
            [control]="lastScoutingDate"
            data-testid="crops-filter-lastScoutingDate"
            [maxDateAllowed]="maxLatestScoutingDate"
          >
          </app-datetimepicker>
          <button mat-icon-button color="warn" aria-label="reset dateRange">
            <mat-icon svgIcon="delete-cross" (click)="lastScoutingDate.reset()"></mat-icon>
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="crop-filters-actions">
    <button color="accent" mat-flat-button (click)="save()">
      {{ 'DIALOG.BUTTON.OK' | translate }}
    </button>
    <button color="accent" mat-flat-button mat-dialog-close>
      {{ 'DIALOG.BUTTON.CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</div>
